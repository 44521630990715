import React from "react";
import Tabs from "@material-ui/core/Tabs";
import { Switch, Route, RouteProps, Redirect } from "react-router-dom";
import { useTheme, makeStyles, Translation, useLocation, TabLink, Container, Paper, Box, ButtonLink } from "lib";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import { AccountPayments } from "./payments";
import { AccountProfile } from "./profile";
import { AccountSubscription } from "./subscription";

export default function Account() {
    const theme = useTheme();
    const classes = useStyles();
    const t = Translation.useMap(["profile", "payments", "subscription", "home"]);

    const {
        location: { pathname },
    } = useLocation();

    const value = "/" + pathname.split("/")[2];
    return (
        <Container className={classes.root}>
            <div className={classes.row}>
                <ButtonLink
                    to={"/"}
                    color={"primary"}
                    children={
                        <>
                            {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            {t.render("home")}
                        </>
                    }
                />
            </div>
            <Paper className={classes.paper}>
                <Tabs
                    value={["/profile", "/payments", "/subscription"].includes(value) ? value : false}
                    onChange={() => {}}
                    scrollButtons="on"
                    indicatorColor="primary"
                    className={classes.tabs}
                >
                    <TabLink value="/profile" to="/account/profile" label={t.render("profile")} />
                    <TabLink value="/payments" to="/account/payments" label={t.render("payments")} />
                    <TabLink value="/subscription" to="/account/subscription" label={t.render("subscription")} />
                </Tabs>

                <Box className={classes.children}>
                    <Switch>
                        <Route<RouteProps> exact path="/account/profile" component={AccountProfile} />
                        <Route<RouteProps> exact path="/account/payments" component={AccountPayments} />
                        <Route<RouteProps> exact path="/account/subscription" component={AccountSubscription} />
                        <Redirect to="/account/profile" />
                    </Switch>
                </Box>
            </Paper>
        </Container>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: "flex",
            flexGrow: 1,
            flexShrink: 0,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            paddingTop: "1.5rem",
            paddingBottom: "3rem",

            [theme.breakpoints.down("sm")]: {
                paddingTop: "2rem",
                paddingBottom: "2rem",
            },
        },

        paper: {
            backgroundColor: "rgba(42, 42, 42, 0.85)",
            width: "100%",
            maxWidth: "100%",

            [theme.breakpoints.down("md")]: {
                height: "auto",
            },
        },
        tabs: {
            flexGrow: 0,
            flexShrink: 0,
            "& .MuiTab-root, a": {
                paddingTop: "0.6rem",
            },

            borderBottom: `1px solid ${theme.palette.grey[800]}`,
        },
        row: {
            width: "100%",
            display: "flex",
            alignSelf: "flex-start",
            marginBottom: "1.5rem",
        },
        children: {},
    }),
    { name: "Account" },
);
