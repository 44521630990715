import React from "react";
import moment from "moment";
import { omit, pick } from "lib/utils";
import { Translation } from "lib/store";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";

import { Value, ValueProps } from "../value";

import { DateValueProps } from "./date-value.types";

export class DateValue extends Value<string> {
    static useDateValue(props: DateValueProps) {
        const dateValue = React.useMemo(() => new DateValue(props), []);

        React.useEffect(() => () => dateValue.clear(), []);

        return dateValue;
    }

    static selectDateValueProps<PT extends DateValueProps, T = any>(
        props: PT,
    ): Omit<PT, keyof DateValueProps> & {
        dateValueProps: DateValueProps;
    } {
        const { valueProps, ...allProps } = Value.selectValueProps(props);

        return {
            ...omit(allProps, "onChange", "format", "label"),
            dateValueProps: {
                ...valueProps,
                ...pick(allProps, "onChange", "format", "label"),
            },
        } as any;
    }

    dateProps: Omit<DateValueProps, keyof ValueProps<string>>;

    get format() {
        return this.dateProps.format || "YYYY-MM-DD";
    }

    constructor(props: DateValueProps = {}) {
        const { valueProps, ...dateProps } = Value.selectValueProps(props);

        super(valueProps);

        this.dateProps = dateProps;

        this.onChange = this.onChange.bind(this);
    }

    private formatted: ParsableDate;

    use() {
        const usedValue = super.use();

        const current = usedValue.current;

        let formatted: ParsableDate = this.formatted || null;

        if (formatted == null && current != null) {
            formatted = moment(current, this.format);
        }

        return {
            ...usedValue,
            current,
            formatted,
            label: Translation.use(this.valueProps.label),
        };
    }

    onChange(moment: moment.Moment) {
        if (moment != null) {
            this.formatted = moment;

            if (!moment.isValid()) {
            } else {
                this.set(moment.format(this.format));
            }
        } else {
            this.formatted = null;
            this.set(null);
        }

        if (this.current !== undefined) {
            if (this.dateProps.onChange) {
                return this.dateProps.onChange(this.current);
            }
        }
    }
}
