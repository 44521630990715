import React from "react";
import { Abon, omit, pick, UnsubscribeFn } from "lib/utils";
import { Translation } from "lib/store/translation";

import { Status, StatusProps } from "../../status";

import { ValueProps } from "./value.types";

export class Value<T, PT = unknown> extends Status {
    static VALUE_PROPS_KEYS = [
        "useCurrent",
        "getCurrent",
        "setCurrent",
        "initial",
        "readOnly",
        "required",
        "group",
        "name",
        "label",
        "placeholder",
        "tableOnly",
        "onBlur",
        "onFocus",
        "getFromParent",
        "getFromParentAsync",
        "getFromParentKeys",
        "getParentSubscription",
        "parentContext",
    ];
    static REQUIRED_ERROR = "$required";

    static useValue<T>(props: ValueProps<T>) {
        const value = React.useMemo(() => new Value(props), []);

        React.useEffect(() => () => value.clear(), []);

        return value;
    }

    static selectValueProps<PT extends ValueProps<T>, T = any>(
        props: PT,
    ): Omit<PT, keyof ValueProps<T>> & {
        valueProps: ValueProps<T>;
    } {
        const { statusProps, ...allProps } = Status.selectStatusProps(props);

        return {
            ...omit(allProps, ...Value.VALUE_PROPS_KEYS),
            valueProps: {
                ...statusProps,
                ...pick(allProps, ...Value.VALUE_PROPS_KEYS),
            },
        } as any;
    }

    value: Abon<T>;
    disabled: Abon<boolean>;
    external: boolean;
    valueProps: Omit<ValueProps<T, PT>, keyof StatusProps>;
    undefinedType: null;

    protected parentSubscription?: UnsubscribeFn;

    constructor(props: ValueProps<T, PT>) {
        const { statusProps, ...valueProps } = Status.selectStatusProps(props);

        super(statusProps);

        if (
            (valueProps.setCurrent || valueProps.getCurrent || valueProps.useCurrent) &&
            !((valueProps.getFromParent || valueProps.getFromParentAsync || valueProps.getParentSubscription) && valueProps.parentContext)
        ) {
            this.external = true;
        }

        if (valueProps.getFromParent && valueProps.parentContext) {
            this.value = new Abon(valueProps.getFromParent(valueProps.parentContext));
        } else if (valueProps.initial != null) {
            this.value = new Abon(valueProps.initial);

            if (valueProps.setCurrent) {
                valueProps.setCurrent(valueProps.initial);
            }
        } else {
            this.value = new Abon(valueProps.getCurrent ? valueProps.getCurrent() : valueProps.initial);
        }

        let setCallback = false;

        if (valueProps.getFromParentAsync) {
            Promise.resolve(valueProps.getFromParentAsync(valueProps.parentContext)).then((value) => {
                if (!setCallback) {
                    this.set(value);
                }
            });
        }

        if (valueProps.getParentSubscription && valueProps.parentContext) {
            this.parentSubscription = valueProps.getParentSubscription((value) => this.set(value), valueProps.parentContext as any);
        } else if ((valueProps.getFromParent || valueProps.getFromParentAsync) && valueProps.parentContext) {
            const valueParentSubscriptionCallback = async () => {
                setCallback = true;

                const value = valueProps.getFromParentAsync
                    ? await valueProps.getFromParentAsync(valueProps.parentContext)
                    : valueProps.getFromParent(valueProps.parentContext);

                return this.set(value);
            };

            if (valueProps.getFromParentKeys) {
                const subscriptions: UnsubscribeFn[] = valueProps.getFromParentKeys.map((key) =>
                    valueProps.parentContext.subscribe(key, valueParentSubscriptionCallback),
                );

                this.parentSubscription = () => subscriptions.every((subscription) => subscription());
            } else {
                this.parentSubscription = valueProps.parentContext.subscribe(valueParentSubscriptionCallback);
            }
        }

        this.valueProps = valueProps;

        this.disabled = new Abon(false);
    }

    get current() {
        if (this.valueProps.getCurrent) {
            return this.valueProps.getCurrent();
        }

        return this.value.current;
    }

    useCurrent() {
        const internalCurrent = this.value.use().current;
        const disabled = this.disabled.use().current;
        const externalCurrent = this.external
            ? this.valueProps.useCurrent
                ? this.valueProps.useCurrent()
                : this.valueProps.getCurrent
                ? this.valueProps.getCurrent()
                : undefined
            : undefined;
        return this.external ? externalCurrent : internalCurrent;
    }

    use() {
        const internalCurrent = this.value.use().current;
        const disabled = this.disabled.use().current;
        const externalCurrent = this.external
            ? this.valueProps.useCurrent
                ? this.valueProps.useCurrent()
                : this.valueProps.getCurrent
                ? this.valueProps.getCurrent()
                : undefined
            : undefined;
        const current = this.external ? externalCurrent : internalCurrent;

        if (current == null) {
            this.undefinedType = current as any;
        }

        return {
            ...super.use(),
            disabled,
            label: Translation.useRender(this.valueProps.label),
            placeholder: Translation.useRender(this.valueProps.placeholder),
            readOnly:
                this.valueProps.readOnly ||
                ((this.valueProps.getFromParent || this.valueProps.getFromParentAsync) &&
                    this.valueProps.parentContext &&
                    this.valueProps.readOnly !== false),
            required: this.valueProps.required,
            internal: internalCurrent !== undefined,
            current,
        };
    }

    set(value: T) {
        if (this.valueProps.required) {
            if (value == null) {
                this.error.add(Value.REQUIRED_ERROR);
            } else {
                this.error.remove(Value.REQUIRED_ERROR);
            }
        }

        if (this.valueProps.setCurrent && this.external) {
            return this.valueProps.setCurrent(value);
        }

        this.value.set(value);
    }

    clear() {
        super.clear();

        if (this.valueProps.setCurrent && this.external) {
            return this.valueProps.setCurrent(this.valueProps.initial);
        }

        this.value.set(this.valueProps.initial);
    }

    clearSubscription() {
        if (this.parentSubscription) {
            this.parentSubscription();
            delete this.parentSubscription;
        }
    }
}
