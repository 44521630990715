import React from "react";
import { Helmet } from "react-helmet";
import InfoIcon from "@material-ui/icons/Info";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { Container, makeStyles, Translation, Abon, FileField, TextField, useService, SliderField, Form } from "lib";

import { CreateUrlaService } from "./create-urla.service";

export default function CreateUrla() {
    const c = useStyles();
    const t = Translation.useMap([
        "create",
        "pasteLink",
        "pasteLinkTooltip",
        "chooseName",
        "chooseNameTooltip",
        "selectAmount",
        "selectAmountTooltip",
        "orTypeALink",
        "anyUrlToShare",
        "or",
        "back",
        "createUrla",
        "createUrlas",
        "next",
        "fileFieldHelper",
    ]);

    const service = useService(CreateUrlaService);
    const file = service.file.useCurrent();
    const name = service.name.useCurrent();
    const valueError = service.store.valueError.use().current;
    const fileName = service.file.fileName.use().current;
    const link = service.link.useCurrent();
    const step = service.step.use();

    const getDisabledNext = React.useCallback(
        () =>
            (step.current === 0 ? !service.file.current && !service.link.current : step.current === 1 ? !service.name.current : false) ||
            valueError,
        [step, service],
    );

    const onNext = React.useCallback(() => !getDisabledNext() && step.set(step.current + 1), [step, getDisabledNext]);
    const onBack = React.useCallback(() => step.set(step.current - 1), [step]);

    const onPaste = React.useCallback(() => setTimeout(onNext), [onNext]);

    const typeSubmit = Abon.use(() => false);

    React.useMemo(() => {
        if (step.current === 2) {
            setTimeout(() => {
                if (step.current === 2) {
                    typeSubmit.set(true);
                }
            }, 250);
        } else {
            typeSubmit.set(false);
        }
    }, [step.current === 2]);

    const onKeyDown = async (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            if (step.current < 2) {
                event.preventDefault();

                if (step.current === 0) {
                    const valid = await service.store.validateValue("link");

                    if (!valid) {
                        return;
                    }
                }

                onNext();
            }
        }
    };

    return (
        <>
            <Helmet title={[t.render("create"), "URLA"].join(" - ")} />

            <Container className={c.root}>
                <Form service={service}>
                    <Stepper activeStep={step.current} orientation={"vertical"} className={c.stepper}>
                        <Step key={0}>
                            <Tooltip title={t.render("pasteLinkTooltip")} placement="bottom-start">
                                <StepLabel>
                                    {t.render("pasteLink")}
                                    {step.current > 0 && <span children={` (${fileName || link})`} />}
                                    <InfoIcon className={c.info} />
                                </StepLabel>
                            </Tooltip>
                            <StepContent className={c.step}>
                                {file ? (
                                    <React.Fragment key={"_file"}>
                                        <FileField
                                            value={service.file}
                                            className={c.field}
                                            helperText={t.render("fileFieldHelper")}
                                            fullWidth
                                            key={"_file"}
                                        />

                                        <Button
                                            variant="text"
                                            children={t.render("orTypeALink")}
                                            onClick={() => service.file.setClear()}
                                            key={"_linkbtn"}
                                            className={c.orTypeButton}
                                        />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment key={"_nofile"}>
                                        <TextField
                                            value={service.link}
                                            className={c.field}
                                            helperText={t.get("anyUrlToShare")}
                                            key={"_link"}
                                            autoFocus
                                            InputProps={{ onPaste, onKeyDown }}
                                        />
                                        <FileField
                                            value={service.file}
                                            className={c.field}
                                            helperText={t.get("fileFieldHelper")}
                                            fullWidth
                                            key={"_file"}
                                        />
                                    </React.Fragment>
                                )}
                            </StepContent>
                        </Step>

                        <Step key={1}>
                            <Tooltip title={t.render("chooseNameTooltip")} placement="bottom-start">
                                <StepLabel>
                                    {t.render("chooseName")}
                                    {step.current > 1 && <span children={` (${name})`} />}
                                    <InfoIcon className={c.info} />
                                </StepLabel>
                            </Tooltip>
                            <StepContent className={c.step}>
                                <TextField
                                    className={c.field}
                                    value={service.name}
                                    autoFocus
                                    InputProps={{ onPaste, onKeyDown }}
                                    fullWidth
                                    key="_name"
                                />
                            </StepContent>
                        </Step>

                        <Step key={2}>
                            <Tooltip title={t.render("selectAmountTooltip")} placement="bottom-start">
                                <StepLabel>
                                    {t.render("selectAmount")}
                                    <InfoIcon className={c.info} />
                                </StepLabel>
                            </Tooltip>
                            <StepContent className={c.step}>
                                <SliderField value={service.amount} onKeyDown={onKeyDown} className={c.slider} />
                            </StepContent>
                        </Step>
                        <React.Fragment>
                            <div>
                                <Button
                                    disabled={step.current === 0}
                                    onClick={onBack}
                                    className={c.navigationButton}
                                    children={t.render("back")}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={step.current < 2 ? onNext : undefined}
                                    disabled={getDisabledNext()}
                                    type={typeSubmit.current ? "submit" : undefined}
                                    className={c.navigationButton}
                                >
                                    {step.current === 2 ? t.render("create") : t.render("next")}
                                </Button>
                            </div>
                        </React.Fragment>
                    </Stepper>
                </Form>
            </Container>
        </>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            paddingTop: "4rem",

            [theme.breakpoints.down("sm")]: {
                paddingTop: "2rem",
            },
        },
        stepper: {
            backgroundColor: "rgba(42, 42, 42, 0.75)",
            borderRadius: 3,

            "& .MuiStepLabel-label": {
                marginTop: 2,

                "& > span": {
                    color: theme.palette.text.secondary,
                },
            },
        },
        step: {
            "& .MuiCollapse-wrapperInner": {
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
            },
        },
        field: {
            width: "100%",
            "&:not(last-of-type)": {
                marginTop: "1rem",
            },
        },
        slider: {
            width: "20rem",
            maxWidth: "100%",
        },

        orTypeButton: {
            textTransform: "none",
            width: "auto",
            marginTop: "1rem",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
            marginLeft: "-0.5rem",
            color: theme.palette.text.secondary,
        },

        navigationButton: {
            paddingLeft: "1rem",
            paddingRight: "1rem",
            fontWeight: 600,
            marginRight: "0.5rem",
        },
        info: {
            height: "1rem",
            width: "1rem",
            marginLeft: "0.5rem",
            marginBottom: -3,
            fill: theme.palette.text.secondary,
        },
    }),
    { name: "CreateUrla" },
);
