import { Service, Abon, OneOf, useService } from "lib";

export class ContainerService extends Service {
    readonly backgroundColor: OneOf<string>;

    constructor() {
        super();

        this.backgroundColor = new OneOf();
    }

    static useBackgroundColor(color: string) {
        const container = useService(ContainerService);
        container.backgroundColor.use(color);
        return container;
    }
}
