import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

import { Loading } from "../lib/store";

export function AppLoading() {
    const classes = useStyles();

    const { linear, circular, determinateCircular, buffer, determinate } = Loading.useState();

    return (
        <>
            <Modal
                color={"white"}
                open={!!circular}
                key={"circular"}
                className={[
                    classes.modal,
                    circular === "white" && classes.modalWhite,
                    circular === "transparent" && classes.modalTransparent,
                ]
                    .filter(Boolean)
                    .join(" ")}
            >
                <CircularProgress
                    variant={(buffer || determinate) && determinateCircular ? "determinate" : undefined}
                    className={circular !== true ? classes.circularWhite : classes.circular}
                    value={
                        determinateCircular ? (buffer ? buffer[0] * 100 : determinate ? determinate.current * 100 : undefined) : undefined
                    }
                    size={50}
                />
            </Modal>

            {linear && (
                <LinearProgress
                    className={classes.linear}
                    key={"linear"}
                    variant={linear}
                    valueBuffer={buffer && !determinateCircular ? buffer[1] * 100 : undefined}
                    value={
                        buffer && !determinateCircular
                            ? buffer[0] * 100
                            : determinate && !determinateCircular
                            ? determinate.current * 100
                            : undefined
                    }
                />
            )}
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        flexDirection: "column",

        "& > div": {
            "&:first-child": {
                backgroundColor: "rgba(0,0,0,0.4) !important",
            },
        },
    },
    modalWhite: {
        "& > div": {
            "&:first-child": {
                backgroundColor: "rgba(255,255,255,0.4) !important",
            },
        },
    },
    modalTransparent: {
        "& > div": {
            "&:first-child": {
                backgroundColor: "rgba(0,0,0,0) !important",
            },
        },
    },

    circular: {
        outline: "none",
        color: "white !important",
    },

    circularWhite: {
        outline: "none",
        color: `${theme.palette.primary.main} !important`,
    },

    linear: {
        position: "absolute",
        bottom: "4rem",
        left: 0,
        width: "100%",
        zIndex: 9,
        height: 2,
        opacity: 0.5,

        [theme.breakpoints.down("sm")]: {
            bottom: "3.5rem",
        },
    },

    "@global": {
        body: {
            "& > #root": {
                position: "relative",
            },
        },
    },
}));
