const en: Record<string, string> = {
    //
    // GENERAL

    about: "About",
    contact: "Contact",
    faq: "FAQ",
    createdAndDevelopedInSweden: "Created and developed in Sweden",
    showMenu: "Show menu",
    invalidEmail: "Invalid email",
    submit: "Submit",
    mustBeDefined: "Must be defined",
    unknownError: "Unknown error",
    mustBeAtLeastXCharacters: "Must be at least {x} characters",
    mustBeLessThanXCharacters: "Must be less than {x} characters",
    fileIsTooLarge: "Max. file size is 5 MB",
    fileFieldHelper: ".pdf, .xls, .word, .jpg < 5 MB",
    home: "Home",
    statistics: "Statistics",
    mailNotifications: "Email Notes",
    projects: "Projects",
    requiresUrlaProOrEnterprise: "Requires Urla Pro or Enterprise",
    requiresUrlaEnterprise: "Requires Urla Enterprise",
    account: "Account",
    upgrade: "Upgrade",
    plans: "Plans",
    logOut: "Log out",
    unknownErrorOccured: "Unknown error occured",
    errorOccured: "An error occured",
    errorOccuredWhen: "An error occured when",
    fetchingUrlas: "Fetching Urlas",
    updatedSuccessfully: "Updated successfully",
    required: "Required",
    invalid: "Invalid",
    mustBeANumber: "Must be a number",
    allValuesSelected: "Selected all {valueMany}",
    selectAllValues: "Select all {valueMany}",
    unselectAllValues: "Unselect all {valueMany}",
    deleteSelectedValues: "Delete the selected {valueTheMany}",
    selectedValues: "selected {valueMany}",
    selectedValue: "selected {valueSingle}",
    language: "Language",
    swedish: "Swedish",
    english: "English",
    invalidUrl: "Invalid URL",
    deletion: "Deletion",
    upgradeRequired: "Upgrade required",
    to: "to",
    delete: "Delete",
    instantly: "Instantly",
    never: "Never",
    noneFound: "None found",
    uploadedFileX: 'Uploaded file "{x}"',
    deletedValue: "Deleted one {single}",
    deletedValues: "Deleted {x} {many}",
    deselectAllPage: "Deselect entire page",
    selectAllPage: "Select entire page",
    deselectAll: "Deselect all",
    selectAll: "Select all",

    //
    // AUTH

    logIn: "Log in",
    signUp: "Sign up",
    email: "Email",
    phoneNumber: "Phone number",
    password: "Password",
    passwordVerification: "Password verification",
    forgotPassword: "Forgot password",
    resetPassword: "Reset password",
    signUpForUrla: "Sign up for Urla",
    signUpForFree: "Sign up for Free",
    signUpForProTrial: "Sign up for Pro Trial",
    incorrectPassword: "Incorrect password",
    doesntMatch: "Doesn't match",
    userNotFound: "User not found",
    userAlreadyExists: "User already exists",
    successfullySignedUp: "Successfully signed up",
    sentEmail: "Sent email",
    recaptchaDetails: "This site is protected by reCAPTCHA",
    andTheGoogle: "and the Google",
    privacyPolicy: "Privacy Policy",
    and: "and",
    termsOfService: "Terms of Service",
    apply: "apply",
    iAcceptThe: "I accept the",
    signUpEmailTooltip: "This email will be used to sign in",
    invalidToken: "The link you are using is broken or expired.",
    newPasswordSuccess: "Your password was updated.",
    signUpProTrialDescription: "Try Urla Pro with a 20 views limit",
    //
    // HOME

    urla: "Urla",
    urlaTooltip:
        "The unique link that you will send to the receiver. You can copy the link to your clipboard or use the share buttons for more options.",
    linkName: "Link name",
    stats: "Stats",
    created_date: "Created",
    latest_view: "Latest",
    expires: "Expires",
    notify: "Notify",
    receiver: "Receiver",
    copy: "Copy",
    receiverTooltip:
        'Used as label to display who the link is for, which is also used in the email notification, e.g. "Jan Jansson - IKEA".',
    views: "Views",
    createUrla: "Create Urla",
    downloadExcelSample: "Download an Excel Sample",
    link: "Link",
    name: "Name",
    numberOfLinks: "Number of links",
    or: "or",
    orTypeALink: "Or type a link",
    project: "Project",
    notificationSettingsUpgradeInfo:
        "With the Pro or Enterprise subscription, you can pick a time and day on which to receive your email notification.",
    day: "Day",
    days: "days",
    hours: "hours",
    minutes: "minutes",
    time: "Time",
    date: "Date",
    now: "Now",
    requiresSubscription: "Requires subscription",
    everyDay: "Every day",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    notifications: "Notifications",
    notificationSettings: "Notification settings",
    update: "Update",
    expireDateUpgradeInfo: "With the Pro or Enterprise subscription, you can set a custom expiration date for your links.",
    projectsUpgradeInfo:
        "With the Pro or Enterprise subscription, you can attach links to projects, making it easier to organize a larger number of urlas.",
    file: "File",
    anyUrlToShare: "Any URL to share",
    excelList: "Excel list",
    excelListUpgradeInfo:
        "With the Enterprise subscription, you can upload a spreadsheet to automatically create a set of urlas with the correctly attached receivers.",
    proLinksAmountUpgradeInfo:
        "With Pro, you can create up to 100 urlas. Until you upgrade, you must delete a link in order to create a new one.",
    enterpriseLinksAmountUpgradeInfo:
        "With Enterprise, you can create an unlimited amount of links. Until you upgrade, you must delete an urla in order to create a new one.",
    shareUrlaInfo: "Share the urla to email contacts or social media",
    updatingTheLinkName: "updating the link name",
    updatingTheReceiver: "updating the receiver",
    updatingTheNotificationStatus: "updating the notification status",
    copyToClipboard: "Copy to clipboard",
    deletingUrlas: "Deleting urlas",
    aLinkOrFileIsRequired: "A link or file is required",
    extension: "Custom link",
    amount: "Amount",
    searchForUrlas: "Search for urlas",
    newProject: "New project",
    viewStatistics: "View statistics",
    attachReceivers: "Attach receivers",
    share: "Share",
    mailNotify: "Email notify",
    changeExpiryDate: "Change expiration date",
    expiryDateUpgradeTooltip: "In Urla Pro or Enterprise, you're able to change the expiration date for your created urlas.",
    changingNotificationSettings: "changing notification settings",
    noExpiryDate: "No expiration date",
    deleteSelectedValuesConfirmation: "Are you sure you want to delete {valueTheMany} that are selected?",
    deleteSelectedValueConfirmation: "Are you sure you want to delete {valueThe} that is selected?",
    deleteSelectedValuedDescription: "This action is not reversible.",
    sortByCreationTime: "Sort by creation time",
    filterUrlasByProject: "Filter urlas by project",
    stopFilteringUrlasByProject: "Stop filtering urlas by project",
    resetViews: "Reset views",
    resetUrlaViewsConfirmation: "Are you sure you want to reset the views?",
    createdXUrlas: "Created {x, plural, one {one urla} other {# urlas}}",
    createdProjectX: `Created project "{x}"`,
    updatedMailNotifications: "Updated email notifications",
    changedExpiryDate: "Changed expiration date",
    toggledMailNotify: "Toggled email notifications",

    projectSingle: "project",
    projectThe: "project",
    projectTheMany: "projects",
    projectMany: "projects",
    urlaSingle: "urla",
    urlaThe: "urla",
    urlaTheMany: "urlas",
    urlaMany: "urlas",
    statisticSingle: "statistic",
    statisticThe: "statistic",
    statisticTheMany: "statistics",
    statisticMany: "statistics",
    linkStatisticSingle: "link statistic",
    linkStatisticThe: "link statistic",
    linkStatisticTheMany: "link statistics",
    linkStatisticMany: "link statistics",

    //
    // Account

    settings: "Settings",
    profile: "Profile",
    payments: "Payments",
    subscription: "Subscription",
    newEmail: "New email",
    confirmNewEmail: "Confirm new email",
    updateEmail: "Update email",
    currentPassword: "Current password",
    newPassword: "New password",
    confirmNewPassword: "Confirm new password",
    updatePassword: "Update password",
    status: "Status",
    paid: "Paid",
    invoiceId: "Invoice ID",
    remainingAmount: "Remaining amount",
    paidAmount: "Paid amount",
    invoice: "Invoice",
    pdf: "PDF",
    paymentDue: "Payment due",
    active: "Active",
    notActive: "Not Active",

    //
    // UPGRADE

    perMonth: "per month",
    get: "Get",
    billedMonthly: "billed monthly",
    featuresIncluded: "Features included",
    links: "links",
    linksExpireAfterOneMonth: "Links expire after one month",
    toggleMailNotifications: "Toggle email notifications",
    scheduledEmailNotifications: "Scheduled email notifications",
    customExpiryDates: "Custom expiration dates",
    advancedStatistics: "Advanced statistics",
    customUrls: "Custom URL:s",
    attachLinksToProjects: "Attach links to projects",
    allFeaturesFromPro: "All features from Pro",
    unlimitedLinks: "Unlimited urlas",
    adminAndUserAccess: "Admin and user access",
    uploadReceiverSpreadsheets: "Upload receiver spreadsheets",
    bulkMailTemplates: "Bulk Email & Templates",
    bulkSMSTemplates: "Send bulk SMS with unique links",
    cancelSubscription: "Cancel subscription",
    cancelProTrialSubscription: "Cancel Pro Trial subscription",
    free: "Free",
    pro: "Pro",
    proTrial: "Pro Trial",
    enterprise: "Enterprise",
    upgradeSubscription: "Upgrade subscription",
    pay: "pay",
    currency: "Currency",
    sek: "SEK",
    euro: "EUR",
    usd: "USD",
    billingEmail: "Billing Email",
    paymentsAreHandledByStripe:
        "Payments are handled by Stripe. Your credit card and other sensitive information will only be exposed to Stripe in order to handle the payment, and will therefore not be visible to Urla.",
    upgradeTo: "Upgrade to",
    downgradeTo: "Downgrade to",
    cancel: "Cancel",
    cancellingTheSubscription: "cancelling the subscription",
    cancelEnterpriseSubscriptionInfo:
        "By cancelling your enterprise subscription you will no longer have access to the enterprise features.",
    cancelEnterpriseSubscriptionInfoExtra:
        "You will also lose all your Urla links. If you want to keep your 100 latest Urla links, downgrade to Urla Pro.",
    cancelProSubscriptionInfo: "By cancelling your Pro subscription you will no longer have access to the Urla Pro features.",
    cancelProSubscriptionInfoExtra: "You will also lose all your Urla links.",
    cancelProTrialSubscriptionInfo: "By cancelling your Pro Trial subscription you will no longer have access to the Urla Pro features.",
    cancelProTrialSubscriptionInfoExtra: "You will also lose all your Urla links.",
    completingThePayment: "completing the payment",
    notAvailable: "Not available",
    commingSoon: "Coming Soon",
    upgradedToX: "Upgraded to {x}",
    downgradedToX: "Downgraded to {x}",
    cancelledSubscriptionForX: "Cancelled subscription for {x}",
    close: "Close",
    keepLatestUrlas: "Don't delete my latest 100 URLA's",

    // Statistics

    viewData: "View data",
    user: "User",
    statisticsUserTooltip: "The user that created the link",
    lastView: "Last view",
    created: "Created",
    exportList: "Export List",
    view: "View",
    ip: "IP",
    linkIpTooltip:
        "Shows if different IP numbers are viewing your link, that indicates your link is being shared. Your first viewer will be named IP-1, if a viewer with a different IP address uses your link they will be named IP-2 etc.",
    referrer: "Referer",
    linkReferrerTooltip: "Shows where your viewers came from.",
    country: "Country",
    location: "Location",
    linkLocationTooltip: "Shows where your viewer is located (city and country).",
    linkTimeTooltip: "Shows time for each view.",
    statisticsUpgradeTitle: "Upgrade to see statistics",
    statisticsUpgradeBody: "In Urla Pro or Enterprise, you're able to see statistics for your created urlas.",
    fromDate: "From date",
    toDate: "To date",

    // Create Flow

    create: "Create",
    pasteLink: "Paste a link",
    pasteLinkPlaceholder: "Paste here (https://...)",
    pasteLinkTooltip:
        "Paste the link that you want to track here, for example a YouTube-video (https://www.youtube.com/watch?v=). " +
        "If you want to track a file, you can upload it here and Urla will generate a trackable link for you (max 5 MB).",
    chooseName: "Choose a name",
    chooseNameTooltip: "Give your link a name so you can distinguish it.",
    nameYourLink: "Name your link",
    selectAmount: "Select the amount",
    selectAmountTooltip:
        "Select the amount of trackable links that Urla should create based on your specified link or file, e.g. one for every recipient. " +
        "You are limited to four links when on Urla Free.",
    back: "Back",
    createUrlas: "Create Urlas",
    creatingTheUrla: "creating the urla",

    // Welcome

    welcome: "Welcome",
    welcomeCaption: "You're only three steps from knowing who clicked your links",
    welcomeToUrla: "Welcome to Urla",
    getStarted: "Get started",

    // Marketing

    marketing: "Marketing",
    campaignsEmailActivity: "Email Activity",
    campaignsSMSActivity: "SMS Activity",
    marketingActivityUpgradeTitle: "Upgrade to see Marketing Activity",
    marketingActivityUpgradeBody: "In Urla Enterprise, you're able to see Marketing Activity for your created urlas.",

    // Activity

    searchForMarketingActivity: "Search for messages",
    unknown: "Unknown",
    sent: "Sent",
    delivered: "Delivered",
    queued: "Queued",
    removed: "Removed",
    rejected: "Rejected",
    failed: "Failed",
    expired: "Expired",
    retry: "Retry",
    unsubscribed: "Unsubscribed",
    undelivered: "Undelivered",
    blocked: "Blocked",

    // Unsubscriptions

    campaignsUnsubscriptions: "Unsubscriptions",
    searchForUnsubscriptions: "Search for unsubscriptions",
    unsubscriptionsUpgradeTitle: "Upgrade to see Unsubscriptions",
    unsubscriptionsUpgradeBody: "In Urla Enterprise, you're able to see Unsubscriptions for your created urlas.",

    // Campaigns

    mailCampaigns: "Email Campaigns",
    mailTemplateTitle: "Email Template",
    mailTemplateSubhead: "Create a new email template or select a saved one",
    mailReceivers: "Email Receivers",
    mailReceiversTitle: "Email Receivers",
    mailReceiversSubhead: "Select receivers from a project.\nThe project must come from an uploaded Excel file used when creating urlas.",
    sendMailTitle: "Send Email",
    sendMailSubhead: "Send an email to every receiver selected above",
    sendMailAction: "Send Email",

    smsCampaigns: "SMS Campaigns",
    smsTemplateTitle: "SMS Template",
    smsTemplateSubhead: "Create a new SMS template or select a saved one",
    smsReceivers: "SMS Receivers",
    smsReceiversTitle: "SMS Receivers",
    smsReceiversSubhead: "Select receivers from a project.\nThe project must come from an uploaded Excel file used when creating urlas.",
    sendSMSTitle: "Send SMS",
    sendSMSSubhead: "Send an SMS to every receiver selected above",
    sendSMSAction: "Send SMS",

    selectProject: "Select project",
    loadProjectLabel: "Load Project",
    loadProjectTooltip: "Add the receivers of the project with an email",
    receivers: "Receivers",
    addSelected: "Add selected",
    addAll: "Add all",
    remove: "Remove",
    removeSelected: "Remove selected",
    removeAll: "Remove all",
    selectedReceivers: "Selected receivers",
    noReceiversFoundInProjectX: `No receivers found in project "{x}"`,
    sendMessageAt: "Send message at",
    minMaxScheduleDate: "from {x1} {u1} to {x2} {u2}",
    send: "Send",
    sentMessageToTheReceivers: "Sent message to the receivers",
    senderName: "Sender",

    // Templates

    emailTitle: "Subject",
    templateThe: "the template",
    templateSingle: "template",
    templateTheMany: "the templates",
    templateMany: "templates",
    templateName: "Template Name",
    template: "Template",
    variables: "Variables",
    variableTooltip: "Insert custom variable",
    failedSelectingTemplate: "Failed to select template",
    incorrectTemplateType: "Incorrect template type",
    templateEditorPlaceholder: "Write your message here",
    selectTemplate: "Select template",
    createdMailTemplateX: `Created email template "{x}"`,
    savedMailTemplateX: `Saved email template "{x}"`,
    createdSMSTemplateX: `Created sms template "{x}"`,
    savedSMSTemplateX: `Saved sms template "{x}"`,
    loadTemplateLabel: "Load Template",
    loadTemplateTooltip: "Load content from Template",
    saveTemplateLabel: "Save Template",
    saveTemplateTooltip: "Save content as template",
    removeTemplateLabel: "Remove Template",
    removeTemplateTooltip: "Remove Template from list",
    urlaURL: "URLA Link",
    urlaName: "URLA Name",
    receiverName: "Receiver Name",
    receiverDate: "Receiver Date",
    receiverTime: "Receiver Time",
    unsubscribeURL: "Unsubscribe Link",
    unsubscribeSMS: "SMS unsubscribe",
    unsubscribe: "Unsubscribe",
    buttons: "Buttons",
    primaryButton: "Primary Button",
    secondaryButton: "Secondary Button",
    textLink: "Text Link",
    ImageGalleryTooltip: "Upload and organize your images.",
    TemplateWithChangesModalTitle: "Do you want to Save the changes you made to",
    TemplateWithChangesModalDescription: "Your changes will be lost if you don't save them.",
    save: "Save",
    dontSave: "Don't Save",
    untitled: "Untitled",
    newDocumentTooltip: "New Blank template",
    notSavedWithChanges: "Template with changes",
    withoutChanges: "Template without changes",
    sms: "sms",

    // Image Manager

    ImageGalleryTitle: "Image Manager",
    ImageGallerySubtitle: "Upload and select the image to insert to the content.",
    uploadImageTooltip: "Upload an image to use it on your templates",
    uploadImage: "Upload image",
    imageSingle: "image",
    imageMany: "images",
    imageTheMany: "the images",
    imageThe: "the image",
    deleteImageTooltip: "Delete image",

    // TABLE

    first: "First",
    previous: "Previous",
    next: "Next",
    last: "Last",
    rowsPerPage: "Rows per page",

    // ADMIN
    administration: "Administration",

    // USERS ADMIN
    users: "Users",
    userSingle: "user",
    userThe: "user",
    userTheMany: "users",
    userMany: "users",

    inviteUser: "Invite User",
    inviteUserTitle: "Invite a User",
    inviteUserSubtitle: "Invite a user to be part of your organization. The user will receive a validation link via email.",
    userInvited: "An email invitation was successfully sent to the user!",
    permissionGranted: "You have granted a user permission to view your statistics.",
    permissionBeenGranted: "A new user has granted you permission to view statistics.",
    resendInvite: "Resend Invitation",

    invited: "Invited",
    accepted: "Accepted",
    revoked: "Revoked",

    // Account Reminders

    viewsLimitHalfReachedTitle: "You have received {views} out of {limit} free views!",
    viewsLimitHalfReachedDescription:
        "Your Urla Pro Trial account have reached {views} out of {limit} views. Upgrade to Urla Pro to keep all features and unlimited views!",
    viewsLimitReachedTitle: "You have reached the limit of {limit} views!",
    viewsLimitReachedDescription:
        "Your Urla Pro Trial account have reached the limit of {limit} views. Upgrade to Urla Pro to keep all features and unlimited views!",
    viewsLimitOverReachedTitle: "You have reached the limit of {limit} views!",
    viewsLimitOverReachedDescription:
        "Your Urla Pro Trial account have new views, but as you have reached the limit of {limit} free views, you need to upgrade to Urla Pro to be able to see all stats and get access to all Pro features.",
    upgradeToPro: "Upgrade to Pro",
    remindLater: "Remind me later!",
};

export default en;
