import { Api, ENDPOINT, Status, ErrorAlert, LoadingStatus, Abon, AbonDeep, ServiceAsync } from "lib";

import { CustomerInvoice } from "../types";

export class AccountPaymentsService extends ServiceAsync {
    status = new Status();
    initializing = new LoadingStatus();
    page = new Abon(0);
    perPage = new Abon(6);
    ids = new Abon<CustomerInvoice["invoice_id"][]>([]);
    values = new AbonDeep<Record<CustomerInvoice["invoice_id"], CustomerInvoice>>({});

    async fetchInvoices() {
        const stopLoading = this.status.loading.start();

        try {
            const response = await Api.request(ENDPOINT.getStripeCustomerInvoices, {});

            this.setValueArray(response || ([] as any));
        } catch (error) {
            console.error({ invoices: error });

            if (!error.response || !error.response.data.message) {
                return;
            }

            if (error.response.status !== 401) {
                ErrorAlert.alert({ error, when: "fetchingInvoices" });
            }
        } finally {
            stopLoading();
        }
    }

    async constructorAsync() {
        const stopLoading = this.initializing.start();

        await this.fetchInvoices();

        stopLoading();
    }

    protected setValueArray(array: CustomerInvoice[]) {
        this.values.set(this.recordFromArray(array));
        this.ids.set(array.map((value) => value.invoice_id));
    }

    protected recordFromArray(array: CustomerInvoice[]) {
        const record: Record<CustomerInvoice["invoice_id"], CustomerInvoice> = array.reduce((obj, value) => {
            return {
                ...obj,
                [value.invoice_id]: value,
            };
        }, {} as Record<CustomerInvoice["invoice_id"], CustomerInvoice>);

        return record;
    }
}
