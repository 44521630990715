import { IsEmail, IsString, MinLength, MaxLength } from "class-validator";
import { ROLE } from "lib";

export class SignUpProps {
    @IsString()
    @MinLength(3)
    @MaxLength(15)
    name: string;

    @IsEmail()
    email: string;

    @MinLength(6)
    @IsString()
    password: string;

    @MinLength(6)
    @IsString()
    passwordVerification: string;

    role?: ROLE;
}
