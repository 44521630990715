import "react-hot-loader";

import React from "react";
import ReactDOM from "react-dom";

import { App } from "./app";

// if (process.env.NODE_ENV !== "production") {
//     // eslint-disable-next-line @typescript-eslint/no-var-requires
//     const axe = require("react-axe");
//     axe(React, ReactDOM, 1000);
// }

ReactDOM.render(<App />, document.getElementById("root"));
