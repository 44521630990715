import React from "react";
import { Table, useTheme, useService } from "lib";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { HomeService } from "./service";
import {
    HomeTableExpiresCell,
    HomeTableLinkNameCell,
    HomeTableNotifyCell,
    HomeTableReceiverCell,
    HomeTableShareCell,
    HomeTableStatsCell,
    HomeTableUrlaCell,
    HomeTableViewsCell,
    HomeTablelastestViewDateCell,
    HomeTableCreatedCell,
} from "./table-cell";
import { ViewsSymbol } from "./table-cell/home-table-cell.symbols";

export function HomeTable() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));
    const service = useService(HomeService);

    return (
        <Table
            service={service}
            rowHeight={58}
            columns={[
                {
                    label: "urla",
                    tooltip: "urlaTooltip",
                    width: 160,
                    weight: undefined,
                    component: HomeTableUrlaCell,
                    valueKey: "id",
                },
                {
                    valueKey: "url_name",
                    label: "linkName",
                    component: HomeTableLinkNameCell,
                    weight: 3,
                },
                {
                    valueKey: "receiver_name",
                    label: "receiver",
                    tooltip: "receiverTooltip",
                    component: HomeTableReceiverCell,
                    weight: 3,
                },
                {
                    label: "share",
                    component: HomeTableShareCell,
                    width: isMobile ? 64 : 128,
                    align: "center",
                },
                {
                    valueKey: "number_of_views",
                    labelChildren: <ViewsSymbol service={service} />,
                    component: HomeTableViewsCell,
                    width: isMobile ? 100 : 120,
                },
                {
                    valueKey: "latest_view",
                    label: "latest_view",
                    width: 64,
                    component: HomeTablelastestViewDateCell,
                    align: "center",
                },
                {
                    label: "stats",
                    width: 64,
                    component: HomeTableStatsCell,
                    align: "center",
                },
                !isMobile &&
                    !isTablet && {
                        valueKey: "created_date",
                        label: "created_date",
                        width: 74,
                        component: HomeTableCreatedCell,
                        align: "center",
                    },
                !isMobile &&
                    !isTablet && {
                        label: "expires",
                        width: 110,
                        component: HomeTableExpiresCell,
                        align: "center",
                    },
                !isMobile &&
                    !isTablet && {
                        label: "mailNotify",
                        width: 80,
                        component: HomeTableNotifyCell,
                        align: "center",
                    },
            ]}
            orderOptions={{
                project_name: "project_name",
                receiver_name: "receiver_name",
                url_name: "link_name",
                latest_view: "latest_view",
                number_of_views: "num_views",
                created_date: "created_date",
            }}
        />
    );
}
