import { ROLE } from "../auth";

export const API_URL = process.env.NODE_ENV == "production" ? "https://www.urla.se/api" : "http://localhost/urla.se/api";
/** This is obviously ridiculous, but necessary to be included in every POST to the api because
 * of its current state. Time crunch, I guess. */
export const STATIC_JWT =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvd3d3LnVybGEuc2UiLCJkYXRhIjp7InVzZXJfaWQiOiIyMSIsInJvbGVfaWQiOiIwIiwiZmlyc3RuYW1lIjoiIiwibGFzdG5hbWUiOiIiLCJlbWFpbCI6ImRldmVsb3BtZW50QHVybGEuc2UifX0.Zbeih7QfkyThhHpNb6FVX726OnsteDoR1rU8uIHbfqM";

export enum ENDPOINT {
    login = "api_login.php",
    createUser = "api_create_user.php",
    getSelf = "jwt_get_user_self.php",
    getUrlas = "jwt_get_urlas_for_user.php",
    resetPassword = "api_reset_password.php",
    validateResetPasswordToken = "api_validate_reset_password_token.php",
    confirmResetPassword = "api_confirm_reset_password.php",
    createUrlas = "jwt_create_urlas_combo.php",
    fileToUrlas = "jwt_file_to_urlas.php",
    fileToUrl = "jwt_file_to_url.php",
    updateSelf = "jwt_update_self.php",
    updateUsername = "jwt_update_username.php",
    updatePassword = "jwt_update_password.php",
    setNotificationSettings = "jwt_set_notification_settings.php",
    getNotificationSettings = "jwt_get_notification_settings.php",
    setUrlName = "jwt_set_url_name.php",
    updateReceiver = "jwt_update_receiver.php",
    updateNotifications = "jwt_update_notifications.php",
    updateUrlaExpirationDate = "jwt_update_urla_expiration_date.php",
    excelFileToUrlas = "jwt_excel_file_to_urlas.php",
    getNumViewsForUrla = "api_get_num_views_for_urla.php",
    resetNumOfViews = "jwt_reset_num_views_for_urla.php",
    deleteUrlas = "jwt_delete_urlas.php",
    registerStripeSubscription = "jwt_stripe_register_subscription.php",
    getStripeCustomer = "jwt_stripe_get_stripe_customer.php",
    cancelStripeSubscription = "jwt_stripe_cancel_subscription.php",
    canceltrialSubscription = "jwt_cancel_trial_subscription.php",
    getStripeCustomerInvoices = "jwt_stripe_get_customer_invoices.php",
    getStripeCustomerSubscriptions = "jwt_stripe_get_customer_subscriptions.php",
    getUrlaStatistics = "jwt_get_urla_statistics.php",
    exportUrlaStatistics = "jwt_export_urla_statistics.php",
    getLinkStatistics = "jwt_get_link_statistics.php",
    getUrlById = "jwt_get_url_by_id.php",
    getUrlaById = "jwt_get_urla_by_id.php",
    getProjects = "jwt_get_projects_for_user.php",
    createProject = "jwt_create_project.php",
    deleteProjects = "jwt_delete_projects.php",
    getTemplates = "jwt_get_templates.php",
    createTemplate = "jwt_create_template.php",
    updateTemplate = "jwt_update_template.php",
    deleteTemplate = "jwt_delete_template.php",
    getReceiversForProject = "jwt_get_receivers_for_project.php",
    sendMessageToReceivers = "jwt_send_message_to_receivers.php",
    getTemplateFiles = "jwt_get_user_template_files.php",
    uploadTemplateFile = "jwt_upload_user_template_file.php",
    deleteTemplateFile = "jwt_delete_user_template_file.php",
    getUserRelations = "jwt_get_connected_users.php",
    createUserInvitation = "jwt_create_invitation.php",
    AcceptUserInvitation = "api_accept_invitation.php",
    deleteUserRelations = "jwt_revoke_user_admin_affiliation.php",
    getCampaignsActivity = "jwt_get_user_message_log.php",
    deleteCampaignsActivity = "jwt_delete_queued_message.php",
    getMessage = "jwt_get_message.php",
    getUnsubscriptions = "jwt_get_unsubscriptions.php",
    getAccountReminder = "jwt_get_account_reminder.php",
}

/** Defines the value types required to be sent as the body of the request mapped to specific endpoints. */
export type PARAMS_MAP = {
    [ENDPOINT.login]: {
        username: string;
        password: string;
    };

    [ENDPOINT.createUser]: {
        name: string;
        username: string;
        password: string;
        role: ROLE;
    };

    [ENDPOINT.getSelf]: {};

    [ENDPOINT.resetPassword]: {
        username: string;
    };

    [ENDPOINT.validateResetPasswordToken]: {
        token: string;
    };

    [ENDPOINT.confirmResetPassword]: {
        user_id: string;
        password: string;
        token: string;
    };

    [ENDPOINT.updateSelf]: {
        name?: string;
    };

    [ENDPOINT.updateUsername]: {
        new_username: string;
        password?: string;
    };

    [ENDPOINT.updatePassword]: {
        password: string;
    };

    [ENDPOINT.createUrlas]: {
        project_id: number;
        project_name?: string;
        url_id?: string;
        url_orig: string;
        is_custom: 0 | 1;
        custom_urla: string;
        url_name: string;
        url_amount: number;
        expiration_date: "2099-12-31" | string;
    };

    [ENDPOINT.setNotificationSettings]: {
        notification_day: NOTIFICATION_DAY;
        notification_time: string | "10:00:00";
    };

    [ENDPOINT.setUrlName]: {
        url_id: string;
        url_name: string;
    };

    [ENDPOINT.updateReceiver]: {
        urla_id: string;
        receiver_name: string;
    };

    [ENDPOINT.updateNotifications]: {
        urla_id: string;
        notifications: 0 | 1;
    };

    [ENDPOINT.updateUrlaExpirationDate]: {
        urla_id: string;
        expiration_date: "2099-12-31" | string;
    };

    [ENDPOINT.getNumViewsForUrla]: {
        urla_id: number;
    };

    [ENDPOINT.deleteUrlas]: {
        urla_ids: (number | string)[];
    };

    [ENDPOINT.registerStripeSubscription]: {
        stripeToken: string | number;
        stripeEmail?: string;
        subscription_plan: SUBSCRIPTION_PLAN;
        currency: CURRENCY;
        keep_latest_urlas: boolean;
    };

    [ENDPOINT.cancelStripeSubscription]: {
        stripe_subscription_id?: string | number;
    };

    [ENDPOINT.canceltrialSubscription]: {};
    [ENDPOINT.getStripeCustomer]: {};
    [ENDPOINT.getStripeCustomerInvoices]: {};
    [ENDPOINT.getStripeCustomerSubscriptions]: {};

    [ENDPOINT.getProjects]: {};

    [ENDPOINT.deleteProjects]: {
        project_ids: number[] | string[];
    };

    [ENDPOINT.createProject]: {
        project_name: string;
    };

    [ENDPOINT.resetNumOfViews]: {
        urla_id: string | number;
    };

    [ENDPOINT.getTemplates]: Pick<Template, "template_type">;
    [ENDPOINT.createTemplate]: Omit<Template, "id">;
    [ENDPOINT.updateTemplate]: Omit<Template, "template_type" | "id"> & { template_id: string | number };
    [ENDPOINT.deleteTemplate]: { template_id: string | number };
    [ENDPOINT.getReceiversForProject]: { project_id: string | number; message_type: TemplateType };
    [ENDPOINT.sendMessageToReceivers]: {
        message_type: TemplateType;
        receiver_ids: (number | string)[];
        template_text: string;
        template_title: string;
        send_at: number;
        sender_name: string;
        project_id: number;
    };
    [ENDPOINT.getTemplateFiles];
    [ENDPOINT.uploadTemplateFile]: { file_name: string } | FormData;
    [ENDPOINT.deleteTemplateFile]: { file_id: number };
    [ENDPOINT.getUserRelations]: {};
    [ENDPOINT.createUserInvitation]: {
        receiver_email: string;
    };
    [ENDPOINT.AcceptUserInvitation]: {
        activation_token: string;
    };
    [ENDPOINT.deleteUserRelations]: { activation_token: string };
    [ENDPOINT.getCampaignsActivity]: { message_type: TemplateType; search_param: string };
    [ENDPOINT.deleteCampaignsActivity]: { message_log_id: number | string; message_type: TemplateType };
    [ENDPOINT.getMessage]: { msg_db_id: number | string };
    [ENDPOINT.getUnsubscriptions]: { search_param: string };
    [ENDPOINT.exportUrlaStatistics]: {
        date_from: string;
        date_to: string;
    };
    [ENDPOINT.getAccountReminder]: {};
};

export enum NOTIFICATION_DAY {
    NEVER = 0,
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
    SUNDAY = 7,
    INSTANT = 8,
    DAILY = 9,
}

export enum SUBSCRIPTION_PLAN {
    FREE = "free",
    PRO = "pro",
    ENTERPRISE = "enterprise",
}

export enum CURRENCY {
    EUR = "eur",
    SEK = "sek",
    USD = "usd",
}

export type CURRENCY_PRICE = {
    [CURRENCY.EUR]: number;
    [CURRENCY.SEK]: number;
    [CURRENCY.USD]: number;
};

export const CURRENCY_SYMBOL: Partial<Record<CURRENCY, string>> = {
    [CURRENCY.EUR]: "€",
    [CURRENCY.SEK]: "kr",
    [CURRENCY.USD]: "$",
};

/** Defines the value types returned as the data of the response mapped to specific endpoints. */
export type RESPONSE_MAP = {
    [ENDPOINT.login]: {
        jwt: string;
        user_id: string;
        role_id: string;
        username: string;
    };

    [ENDPOINT.createUser]: {
        user_id: string;
        message: string;
    };

    [ENDPOINT.getSelf]: {
        message: string;
        user_id?: string;
        role_id?: number;
        username?: string;
        name?: string;
        permissions?: userPermissions;
    };

    [ENDPOINT.resetPassword]: {
        message: string;
        success?: boolean;
    };

    [ENDPOINT.validateResetPasswordToken]: {
        message: string;
        user_id?: string;
        success?: boolean;
    };

    [ENDPOINT.confirmResetPassword]: {
        message: string;
        success?: boolean;
    };

    [ENDPOINT.createUrlas]: {
        urla_id: string[];
        message: string;
    };

    [ENDPOINT.updateSelf]: {
        message: "User updated successfully." | string;
    };

    [ENDPOINT.updateUsername]: {
        message: "Username updated successfully." | "Unable to update username." | string;

        error?: "Something went wrong while updating the username: The email address 'test@test.com' has already been registered." | string;
    };

    [ENDPOINT.updatePassword]: {
        message: "Password updated successfully." | "Unable to update password." | string;
        error?: "Something went wrong while updating the password: The entered password does not match the current password." | string;
    };

    [ENDPOINT.setNotificationSettings]: {
        message: string;
        error?: string;
    };

    [ENDPOINT.setUrlName]: {
        message: string;
        error?: string;
    };

    [ENDPOINT.updateReceiver]: {
        message: string;
        error?: string;
    };

    [ENDPOINT.updateNotifications]: {
        message: string;
        error?: string;
    };

    [ENDPOINT.updateUrlaExpirationDate]: {
        message: string;
        error?: string;
    };

    [ENDPOINT.getNumViewsForUrla]: {
        message: string;
        numViews: "0" | string;
        error?: string;
    };

    [ENDPOINT.deleteUrlas]: {
        message: string;
        error?: string;
    };

    [ENDPOINT.registerStripeSubscription]: {
        message: string;
        error?: string;
        jwt?: string;
    };

    [ENDPOINT.cancelStripeSubscription]: {
        message: string;
        error?: string;
        jwt?: string;
    };

    [ENDPOINT.canceltrialSubscription]: {
        message: string;
        error?: string;
        jwt?: string;
    };

    [ENDPOINT.getStripeCustomer]: {
        message: string;
        error?: string;
    };

    [ENDPOINT.getStripeCustomerSubscriptions]: object[];
    [ENDPOINT.getStripeCustomerInvoices]: object[];

    [ENDPOINT.getProjects]: any[];

    [ENDPOINT.deleteProjects]: {
        message: string;
        error?: string;
    };

    [ENDPOINT.createProject]: {
        message: string;
        error?: string;
    };

    [ENDPOINT.resetNumOfViews]: {};

    [ENDPOINT.getTemplates]: Template[];
    [ENDPOINT.createTemplate]: {
        message: string;
        template_id: string;
    };
    [ENDPOINT.updateTemplate]: Template;
    [ENDPOINT.deleteTemplate]: {
        message: string;
        error?: string;
    };
    [ENDPOINT.getReceiversForProject]: Receiver[];
    [ENDPOINT.sendMessageToReceivers]: {
        message: string;
        error?: string;
    };
    [ENDPOINT.getTemplateFiles]: TemplateFile[];
    [ENDPOINT.uploadTemplateFile]: TemplateFile;
    [ENDPOINT.deleteTemplateFile]: {
        message: string;
        error?: string;
    };

    [ENDPOINT.getUserRelations]: ConnectedUser[];
    [ENDPOINT.createUserInvitation]: {
        message: string;
        success?: boolean;
    };
    [ENDPOINT.AcceptUserInvitation]: {
        message: string;
        success?: boolean;
    };
    [ENDPOINT.deleteUserRelations]: {
        message: string;
        error?: string;
    };
    [ENDPOINT.getCampaignsActivity]: MessageActivity[];
    [ENDPOINT.getMessage]: Message;
    [ENDPOINT.getUnsubscriptions]: UnsubscribedUsers[];
    [ENDPOINT.exportUrlaStatistics]: string;
    // {
    //       url: string;
    //       message: string;
    //       error?: string;
    //   };
    [ENDPOINT.getAccountReminder]: AccountReminder;
};
export interface TemplateFile {
    file_id: number;
    file_url: string;
    file_name: string;
    file?: File;
}
export interface Template {
    id: string;
    /** template_type 1 = email template_type 2 = sms */
    template_type: TemplateType;
    template_name: string;
    template_title: string;
    template_text: string;
}

export enum TemplateType {
    email = "1",
    sms = "2",
}

export enum ConnectedUserStatus {
    invited = "0",
    accepted = "1",
    revoked = "2",
}

export enum MessageActiviyStatus {
    unknown = 0,
    sent = 1,
    delivered = 2,
    queued = 3,
    removed = 4,
    rejected = 5,
    failed = 6,
    expired = 7,
    invalid = 8,
    retry = 9,
    unsubscribed = 10,
    undelivered = 11,
    blocked = 12,
}

export enum ReminderType {
    TRIAL_VIEWS_LIMITS_NOT_REACHED,
    TRIAL_VIEWS_LIMITS_HALF_REACHED,
    TRIAL_VIEWS_LIMITS_REACHED,
}

export interface Receiver {
    id: number | string;
    urla_id: string;
    email_address: string;
    phone_number: string;
    first_name: string;
}

export interface ConnectedUser {
    id: number | string;
    name: string;
    username: string;
    status: ConnectedUserStatus;
    activation_token: string;
    created_at: string;
}

export interface MessageActivity {
    msg_db_id: number | string;
    message_log_id: number | string;
    message_id: number | string;
    message_type: TemplateType;
    receiver_name: string;
    receiver_data: string;
    project_name: string;
    status: MessageActiviyStatus;
    send_timestamp: number | string;
}
export interface Message {
    id: number | string;
    type: TemplateType;
    subject: string;
    message: string;
}

export interface UnsubscribedUsers {
    id: number;
    message_type: TemplateType;
    email_address: string;
    phone_number: string;
    receiver_name: string;
    project_name: string;
    unsubscription_time: number | string;
}

export interface userPermissions {
    name: string;
    username: string;
    activationToken: string;
}

export interface AccountReminder {
    role?: ROLE;
    type: ReminderType;
    reminded_at?: string;
    data?: object;
}

/** The endpoints that have defined parameters as well as response types. */
export type DECLARED_ENDPOINT = ENDPOINT & keyof PARAMS_MAP & keyof RESPONSE_MAP;
