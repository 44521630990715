import React from "react";

import { Auth } from "../lib/store/auth";
import { Container } from "../container";
import AuthRoot from "../auth";

import { AppRouter } from "./app.router";
import { AccountReminders } from "account/reminders/account-reminders";

/** The root renderer of the displayed parts of the app. */
export function AppBody() {
    const authenticated = Auth.use("authenticated");

    return (
        <Container>
            {authenticated ? (
                <>
                    <AppRouter key="router" />
                    <AccountReminders />
                </>
            ) : (
                <AuthRoot key={"auth"} />
            )}
        </Container>
    );
}
