import { TextField, makeStyles, useTheme, useService, Translation, Form, Typography, Divider, Box, ButtonLink } from "lib";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import LogInIcon from "@material-ui/icons/LockOpen";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import { BrowserRouter as Router, Route, Link, Switch, useParams } from "react-router-dom";
import { AuthHead, AuthSubmit, AuthLinks } from "../shared";
import { ResetPasswordService } from "./reset-password.service";
import { ValidateResetPasswordTokenService } from "./validate-reset-password-token.service";
import { ResetPasswordParamTypes } from "./reset-password.props";

export default function ResetPassword(props) {
    const classes = useStyles();
    const [showFom, setShowForm] = useState(false);
    const [invalidMessage, setInvalidMessage] = useState(null);

    const vrptservice = useService(ValidateResetPasswordTokenService);
    const rpservice = useService(ResetPasswordService);
    const params = useParams<ResetPasswordParamTypes>();
    const t = Translation.useMap(["resetPassword", "logIn", "signUpForUrla", "submit", "invalidToken", "newPasswordSuccess"]);

    const checkValidToken = () => {
        if (params != null && params.token != null) {
            vrptservice
                .validateResetPasswordToken({ token: params.token })
                .then((r) => {
                    setShowForm(true);
                    rpservice.store.setValue("user_id", r.id);
                    rpservice.store.setValue("token", params.token);
                })
                .catch((e) => {
                    rpservice.store.addError("invalidToken");
                    setShowForm(false);
                });
        } else {
            rpservice.store.addError("invalidToken");
            setShowForm(false);
        }
    };

    const submittable = rpservice.store.submittable.use().current;

    const inputs = (
        <>
            <TextField value={rpservice.store.getValue("password") as any} fullWidth className={classes.input} />
            <TextField value={rpservice.store.getValue("passwordVerification") as any} fullWidth className={classes.input} />
            <AuthSubmit service={rpservice} disabled={!submittable} children={t.get("submit")} />
        </>
    );

    const error = rpservice && rpservice.error.message.use().current;
    const success = rpservice && rpservice.success.message.use().current;
    const message = <FormHelperText error={!!error} className={classes.status} children={error || success} />;
    useEffect(checkValidToken, []);
    return (
        <>
            <Helmet>
                <title children={[t.get("resetPassword"), "URLA"].join(" - ")} />
            </Helmet>
            <Form service={rpservice}>
                <AuthHead title={t.get("resetPassword")} />
                {error || success ? message : inputs}
                <AuthLinks>
                    <ButtonLink children={t.render("logIn")} to="/auth/log-in" />
                    <ButtonLink children={t.render("signUpForUrla")} to="/auth/sign-up" />
                </AuthLinks>
            </Form>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    input: {
        marginBottom: "1rem",
    },
    status: {
        paddingTop: "0.5rem",
        fontSize: "16px",
    },
}));
