import React, { useEffect } from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Elements, CardElement, StripeProvider } from "react-stripe-elements";
import {
    Paper,
    useService,
    Form,
    TextField,
    makeStyles,
    Translation,
    useTheme,
    Typography,
    Abon,
    StackItemProps,
    CardStack,
    CURRENCY,
    CURRENCY_SYMBOL,
    LOCALE,
} from "lib";
import { animated } from "react-spring";

import { UpgradeCheckoutService } from "./upgrade-checkout.service";
import { UpgradeCheckoutProps } from "./upgrade-checkout.types";
import { Checkbox, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";

export function UpgradeCheckout(props: UpgradeCheckoutProps) {
    const { open = Abon.use(() => true), plan } = props;

    CardStack.use(
        open.current,
        () => ({
            onClose: () => open.set(false),
            renderer: (stackProps) => <UpgradeCheckoutModal {...stackProps} {...props} open={open} />,
        }),
        [open, plan],
    );

    return null;
}

function UpgradeCheckoutModal(props: UpgradeCheckoutProps & StackItemProps) {
    const { open = Abon.use(() => true), plan, interpolations, downgrade } = props;

    const service = useService(UpgradeCheckoutService);

    const classes = useClasses({});
    const initializing = service.initializing.use().current;
    const loading = service.loading.use().current;
    const locale = Translation.locale.use().current;
    const theme = useTheme();
    const error = service.error.message.use().current;
    const submittable = service.store.submittable.use().current;
    const success = service.success.message.use().current;
    const currencyValue = service.currency.use().current;
    const cardValue = service.card.use().current;
    const keepLatestUrlas = service.keepLatestUrlas.useCurrent();
    const t = Translation.useMap([
        "pay",
        "upgradeSubscription",
        "paymentsAreHandledByStripe",
        "keepLatestUrlas",
        "currency",
        "euro",
        "sek",
        "usd",
    ]);

    useEffect(() => {
        switch (locale) {
            case LOCALE.en:
                service.currency.set(CURRENCY.EUR);
                break;
            case LOCALE.sv:
                service.currency.set(CURRENCY.SEK);
            default:
                service.currency.set(CURRENCY.SEK);
                break;
        }
        if (downgrade) {
            service.keepLatestUrlas.set(true);
            service.downgrade.set(true);
        }
    }, []);

    service.plan = plan;
    service.open = open;

    let children: JSX.Element;
    const priceText: Array<any> = [];

    if (!initializing) {
        priceText.push(Translation.render("pay"));

        if (currencyValue && props.price) {
            switch (currencyValue) {
                case CURRENCY.EUR:
                case CURRENCY.USD:
                    priceText.push(...[" ", CURRENCY_SYMBOL[currencyValue], " ", props.price[currencyValue]]);
                    break;
                case CURRENCY.SEK:
                    priceText.push(...[" ", props.price[currencyValue], " ", CURRENCY_SYMBOL[currencyValue]]);
                default:
                    break;
            }
        }

        children = (
            <Paper className={classes.root}>
                <StripeProvider stripe={service.resolvedStripe}>
                    <Elements
                        ref={(elements) => {
                            service.elements = elements;
                        }}
                        fonts={[]}
                        locale={locale}
                    >
                        <Form service={service}>
                            <Typography children={props.title || t.render("upgradeSubscription")} className={classes.title} />
                            <TextField value={service.store.getValue("checkoutName") as any} fullWidth className={classes.name} />
                            <TextField value={service.store.getValue("billingEmail") as any} fullWidth className={classes.name} />
                            <FormLabel component="legend">{t.render("currency")}</FormLabel>
                            <RadioGroup
                                row
                                defaultValue="right"
                                aria-label="currency"
                                name="currency"
                                value={currencyValue}
                                onChange={(event, value) => {
                                    switch (value) {
                                        case CURRENCY.EUR:
                                            service.currency.set(CURRENCY.EUR);
                                            break;
                                        case CURRENCY.SEK:
                                            service.currency.set(CURRENCY.SEK);
                                            break;
                                        case CURRENCY.USD:
                                            service.currency.set(CURRENCY.USD);
                                            break;
                                        default:
                                            break;
                                    }
                                }}
                            >
                                <FormControlLabel value={CURRENCY.EUR} control={<Radio color="primary" />} label={t.render("euro")} />
                                <FormControlLabel value={CURRENCY.SEK} control={<Radio color="primary" />} label={t.render("sek")} />
                                {/* <FormControlLabel value={CURRENCY.USD}
                                control={<Radio color="primary" />}
                                label={t.render("usd")} /> */}
                            </RadioGroup>
                            <div className={classes.cardWrapper}>
                                <CardElement
                                    onChange={(value) => {
                                        service.card.set(value);
                                    }}
                                    className={classes.card}
                                    style={{
                                        base: {
                                            fontFamily: theme.typography.fontFamily,
                                            color: theme.palette.text.primary,
                                            fontSize: "16px",
                                        },
                                        empty: {
                                            color: theme.palette.text.primary,
                                        },
                                        invalid: {
                                            color: theme.palette.error.main,
                                        },
                                    }}
                                />
                            </div>
                            {downgrade ? (
                                <FormControlLabel
                                    className={classes.keepUrlas}
                                    control={
                                        <Checkbox
                                            checked={!!keepLatestUrlas}
                                            onChange={(_, checked) => service.keepLatestUrlas.set(checked)}
                                            color="primary"
                                        />
                                    }
                                    label={t.render("keepLatestUrlas")}
                                />
                            ) : null}
                            <Button
                                color={"primary"}
                                variant={"contained"}
                                className={classes.submit}
                                fullWidth
                                type={"submit"}
                                disabled={
                                    !submittable ||
                                    !!error ||
                                    loading ||
                                    initializing ||
                                    !cardValue ||
                                    !cardValue.complete ||
                                    cardValue.error
                                }
                                children={priceText}
                            />
                            <FormHelperText
                                error={!!error}
                                className={classes.status}
                                children={error || success || t.render("paymentsAreHandledByStripe")}
                            />
                        </Form>
                    </Elements>
                </StripeProvider>
            </Paper>
        );
    } else {
        children = <CircularProgress className={classes.spinner} color="primary" size="4rem" />;
    }

    return <animated.div style={{ transform: interpolations.transform }}>{children}</animated.div>;
}

const useClasses = makeStyles(
    (theme) => ({
        modal: {
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
        },
        spinner: {
            outline: "none",
        },
        root: {
            width: "30rem",
            maxWidth: "calc(100vw - 1rem)",
            padding: "3rem 2rem",
            outline: "none",
        },
        title: {
            fontWeight: 500,
            fontSize: "0.9rem",
            letterSpacing: 0.5,
            marginBottom: "1rem",
            paddingBottom: "0.25rem",
            color: theme.palette.grey[600],
            borderBottom: `1px solid ${theme.palette.grey[700]}`,
            textTransform: "uppercase",
        },
        name: {
            marginBottom: "1rem",

            "& .MuiFormLabel-root": {},

            "& input": {},
        },
        cardWrapper: {
            position: "relative",
            width: "100%",

            "& .StripeElement--empty": {},
            "& .StripeElement--focus": {
                "&:before": {
                    borderBottom: `2px solid ${theme.palette.text.primary}`,
                },
                "&:after": {
                    transform: "scaleX(1)",
                },
            },
            "& .StripeElement--error": {
                "&:after": {
                    transform: "scaleX(1)",
                    borderBottomColor: theme.palette.error.main,
                },
            },
        },
        card: {
            transition: "",
            padding: "0.75rem 0 0.6rem 0",
            cursor: "text",

            "&:hover": {
                "&:before": {
                    borderBottom: `2px solid ${theme.palette.text.primary}`,
                },
            },

            "&:before": {
                left: 0,
                right: 0,
                bottom: 0,
                content: '"\\00a0"',
                position: "absolute",
                transition: "border-bottom 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                borderBottom: `1px solid ${theme.palette.text.secondary}`,
                pointerEvents: "none",
            },

            "&:after": {
                left: 0,
                right: 0,
                bottom: 0,
                content: '""',
                position: "absolute",
                transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                borderBottom: `2px solid ${theme.palette.primary.main}`,
                pointerEvents: "none",
                transform: "scaleX(0)",
            },
        },

        submit: {
            marginTop: "1.5rem",
            fontWeight: 600,
            fontSize: "1rem",
        },

        status: {
            paddingTop: "0.5rem",
            lineHeight: 1.3,
        },
        keepUrlas: {
            marginTop: "1rem",
        },
    }),
    { name: "UpgradeCheckout" },
);
