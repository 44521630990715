/* eslint-disable no-invalid-this */
/* eslint-disable @typescript-eslint/camelcase */
import { Api, ENDPOINT, TemplateType, Abon, Message, ErrorAlert, LoadingStatus, Status, SnackStack, Translation, ServiceAsync } from "lib";

export class MessageService extends ServiceAsync {
    message = new Abon<Message>(null);
    initializing = new LoadingStatus();
    status = new Status();
    snacks: SnackStack;
    translations: { single: "messageThe"; the: "messageThe" };

    constructor(readonly props: MessageProps = {}) {
        super();

        this.snacks = this.require(SnackStack);
        const message: Message = {
            message: null,
            subject: null,
            id: props.msg_db_id,
            type: props.type,
        };
        this.message.set(message);
        this.translations = { single: "messageThe", the: "messageThe" };
    }

    fetchPromise?: Promise<void>;

    async constructorAsync() {
        const stopLoading = this.initializing.start();

        await this.fetch();

        stopLoading();
    }

    async fetch(forceHydrate?: boolean) {
        if (this.fetchPromise && !forceHydrate) {
            return this.fetchPromise;
        }

        this.fetchPromise = this.status.loading.while(
            Promise.resolve()
                .then(async () => {
                    try {
                        const Message: Message = await Api.requestAny<any, any>(ENDPOINT.getMessage, {
                            msg_db_id: this.message.current.id,
                        }).then((response) => {
                            return {
                                id: this.message.current.id,
                                type: this.message.current.type,
                                subject: response.subject || "",
                                message: response.message || "",
                            };
                        });

                        this.message.set(Message);
                    } catch (error) {
                        ErrorAlert.alert({
                            error,
                            when: Translation.result(
                                [Translation.render("fetching"), Translation.render(this.translations.single)].join(" "),
                            ),
                        });
                    }
                })
                .then(() => {
                    delete this.fetchPromise;
                }),
        );

        return this.fetchPromise;
    }
}

export interface MessageProps {
    msg_db_id?: number | string;
    type?: TemplateType;
}
