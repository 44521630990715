/* eslint-disable @typescript-eslint/camelcase */
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import MuiTableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "lib/material";

import { TableRowProps, TableCellComponentProps } from "./table.types";

export function TableRow<T extends KeyObject, I extends keyof T>(props: TableRowProps<T, I>) {
    const { service, id, columns, rowHeight, size } = props;

    const classes = useStyles();

    const selectedIds = service.selectedIds.use().current;
    const selected = selectedIds.includes(id);
    const row = service.items.use(id);

    const onSelectToggle = React.useMemo(
        () =>
            service.shouldManageSelected &&
            (() => {
                if (service.selectedIds.current.includes(props.id)) {
                    service.selectedIds.set(service.selectedIds.current.filter((_id) => _id !== props.id));
                } else {
                    service.selectedIds.set([...service.selectedIds.current, props.id]);
                }
            }),
        [service, service.shouldManageSelected],
    );

    if (!row) {
        return null;
    }

    const baseRowProps: Pick<TableCellComponentProps<T, I, keyof T>, keyof TableRowProps<T, I> | "selected" | "row"> = {
        ...props,
        service,
        id,
        selected,
        row,
    };

    return (
        <MuiTableRow
            aria-checked={selected}
            tabIndex={-1}
            selected={selected}
            className={classes.root}
            style={{ height: rowHeight }}
            key="_row"
        >
            {columns.map((column, i) => {
                let children: React.ReactNode = null;

                if (column.render || column.component || column.renderContent) {
                    const cellProps: TableCellComponentProps<T, I, keyof T> = {
                        ...baseRowProps,
                        ...column,
                        value: column.valueKey != null ? row[column.valueKey] : undefined,
                        index: i,
                        ["key" as never]: i,
                    };

                    if (column.render) {
                        children = React.createElement(React.Fragment, { key: i, children: column.render(cellProps) });
                    } else if (column.component) {
                        children = React.createElement(column.component, cellProps as any);
                    } else if (column.renderContent) {
                        children = (
                            <TableCell
                                className={classes.cell}
                                component={i === 0 ? "th" : undefined}
                                children={column.renderContent(cellProps)}
                                align={column.align}
                                key={i}
                                size={size}
                            />
                        );
                    }
                } else {
                    const value = column.valueKey ? row[column.valueKey] : undefined;

                    children = (
                        <TableCell
                            className={classes.cell}
                            component={i === 0 ? "th" : undefined}
                            children={value == null ? null : value}
                            align={column.align}
                            key={i}
                            size={size}
                        />
                    );
                }

                return children;
            })}

            {onSelectToggle && (
                <TableCell padding="checkbox" align="center" size={size} className={classes.checkbox}>
                    <Checkbox
                        checked={selected}
                        inputProps={{ "aria-labelledby": id }}
                        color="primary"
                        size={size}
                        onClick={onSelectToggle}
                    />
                </TableCell>
            )}
        </MuiTableRow>
    );
}

const useStyles = makeStyles(
    () => ({
        root: {},
        cell: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",

            "& > button": {
                marginLeft: "-0.75rem",
            },
        },
        checkbox: {
            "& > span": {},
        },
    }),
    { name: "TableRow" },
);
