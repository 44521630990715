import { IsEmail, IsString, MinLength } from "class-validator";

export class LogInProps {
    @IsEmail()
    email: string;

    @IsString()
    @MinLength(6)
    password: string;

    token: string;
}
