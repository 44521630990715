import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useService } from "lib";
import { AcceptInviteService } from "./accept-invite.service";

export default function AcceptInvite(props) {
    const params = useParams<{
        token: string;
    }>();
    const AIService = useService(AcceptInviteService);

    const checkValidToken = () => {
        if (params != null && params.token != null) {
            try {
                AIService.confirmInvite({ activation_token: params.token }).finally(() => {
                    props.history.push("/");
                });
            } catch (error) {
                props.history.push("/");
            }
        } else {
            props.history.push("/");
        }
    };

    useEffect(checkValidToken, []);

    return null;
}
