import "reflect-metadata";

import { Type } from "class-transformer";
import { IsString, IsUrl, IsOptional, IsNumber, IsDefined } from "class-validator";

export class CreateUrlaProps {
    @IsUrl()
    @IsOptional()
    link?: string;

    @IsOptional()
    @Type(() => Object)
    urlaFile?: File;

    @IsString()
    name: string;

    @IsNumber()
    @IsOptional()
    amount?: number;

    @IsString()
    @IsOptional()
    customUrla?: string;

    @IsOptional()
    @Type(() => Object)
    excelFile?: File;

    @IsOptional()
    projectId?: number | string;

    @IsString()
    @IsOptional()
    expirationDate?: string;
}

export class CreateUrlaExcelProps extends CreateUrlaProps {
    @IsDefined()
    @Type(() => Object)
    excelFile: File;

    @IsDefined()
    projectId?: number | string;
}
