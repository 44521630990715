import { Abon, AbonMap } from "lib/utils";
import { Translation } from "lib/store/translation";

export class MessageStatus extends Abon<boolean> {
    protected entities = new AbonMap<string, string>();
    message = new Abon<string>();

    constructor(initial?: boolean) {
        super(initial || false);

        this.entities.subscribe((entities) => {
            super.set(!!entities.size);

            const messageEntity = Array.from(entities.entries()).find((entity) => entity[1] != null);

            this.message.set(messageEntity ? messageEntity[1] : entities.size ? "" : null);
        });
    }

    set(error: boolean) {
        console.warn(`The "set" method of an MessageStatus should not be used.`);
        return super.set(error);
    }

    add(id: string, message?: string) {
        this.entities.set(id, message);
    }

    remove(id: string) {
        this.entities.delete(id);
    }

    has(id: string) {
        return this.entities.has(id);
    }

    clear() {
        this.entities.clear();
    }
}
