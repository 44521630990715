/* eslint-disable @typescript-eslint/camelcase */
import { Service, ValueStore, ValueMetaMap, FormService, Api, ENDPOINT, Auth, ServiceAsync, Status, LoadingStatus } from "lib";

import { AccountEmailProfileProps, AccountNameProfileProps, AccountPasswordProfileProps } from "./account-profile.types";

class AccountNameProfileService extends FormService<AccountNameProfileProps> {
    constructor() {
        super({
            store: new ValueStore<AccountNameProfileProps>({
                type: AccountNameProfileProps,
                metaMap: new ValueMetaMap<AccountNameProfileProps>({
                    meta: {
                        name: { label: "name", accept: "text", required: true, initial: "" },
                    },
                }),
            }),
            onSubmit: async (props) => {
                const stopLoading = this.store.loading.start();

                try {
                    const { message } = await Api.request<ENDPOINT.updateSelf>(ENDPOINT.updateSelf, {
                        name: props.name,
                    });

                    if (message && message.includes("updated successfully")) {
                        this.store.addSuccess("updatedSuccessfully");
                    }
                } catch (error) {
                    if (!error.response.data.message) {
                        return;
                    }

                    this.store.addError("unknownError");
                } finally {
                    stopLoading();
                }
            },
        });
    }
}

class AccountEmailProfileService extends FormService<AccountEmailProfileProps> {
    constructor() {
        super({
            store: new ValueStore<AccountEmailProfileProps>({
                type: AccountEmailProfileProps,
                metaMap: new ValueMetaMap<AccountEmailProfileProps>({
                    meta: {
                        email: { label: "email", accept: "email", required: true },
                    },
                }),
            }),

            onSubmit: async (props) => {
                const stopLoading = this.store.loading.start();

                try {
                    const { message } = await Api.request<ENDPOINT.updateUsername>(ENDPOINT.updateUsername, {
                        new_username: props.email,
                    });

                    if (message && message.includes("updated successfully")) {
                        this.store.addSuccess("updatedSuccessfully");
                    }
                } catch (error) {
                    if (!error.response.data.message) {
                        return;
                    }

                    this.store.addError("unknownError");
                } finally {
                    stopLoading();
                }
            },
        });
    }
}

class AccountPasswordProfileService extends FormService<AccountPasswordProfileProps> {
    constructor() {
        super({
            store: new ValueStore<AccountPasswordProfileProps>({
                type: AccountPasswordProfileProps,
                metaMap: new ValueMetaMap<AccountPasswordProfileProps>({
                    meta: {
                        newPassword: { label: "newPassword", accept: "password", required: true },
                        newPasswordVerification: {
                            label: "confirmNewPassword",
                            accept: "password",
                            required: true,
                        },
                    },
                }),
            }),

            onSubmit: async (props) => {
                if (props.newPassword !== props.newPasswordVerification) {
                    this.store.addCombinationError("newPasswordVerification", "doesntMatch");
                    return;
                }

                const stopLoading = this.store.loading.start();
                try {
                    const { message } = await Api.request<ENDPOINT.updatePassword>(ENDPOINT.updatePassword, {
                        password: props.newPassword,
                    });
                    if (message && message.includes("updated successfully")) {
                        this.clear();
                        this.store.addSuccess("updatedSuccessfully");
                    }
                } catch (error) {
                    if (!error.response.data.message) {
                        return;
                    }

                    this.store.addError("unknownError");
                } finally {
                    stopLoading();
                }
            },
        });
    }
}

export class AccountProfileService extends ServiceAsync {
    name: AccountNameProfileService;
    password: AccountPasswordProfileService;
    email: AccountEmailProfileService;
    status = new Status();
    initializing = new LoadingStatus();

    constructor() {
        super();
        this.name = this.require(AccountNameProfileService);
        this.password = this.require(AccountPasswordProfileService);
        this.email = this.require(AccountEmailProfileService);
    }

    async fetchUserProfile() {
        const stopLoading = this.name.loading.start();

        Api.request(ENDPOINT.getSelf, {})
            .then((response) => {
                if (response.username) {
                    this.name.store.setInitialValues({
                        name: response.name,
                    });
                    this.email.store.setInitialValues({
                        email: response.username,
                    });
                }
            })
            .catch((error) => {
                console.error({ invoices: error });

                if (!error.response || !error.response.data.message) {
                    return;
                }
            })
            .finally(() => {
                stopLoading();
            });
    }

    async constructorAsync() {
        const stopLoading = this.initializing.start();

        await this.fetchUserProfile();

        stopLoading();
    }
}
