import { FormService, ValueMetaMap } from "lib";

import { LogInProps } from "./log-in.props";
import { logIn } from "./log-in.utils";

export class LogInService extends FormService<LogInProps> {
    constructor() {
        super({
            type: LogInProps,
            metaMap: new ValueMetaMap<LogInProps>({
                meta: {
                    email: {
                        accept: "email",
                        label: "email",
                        initial: process.env.NODE_ENV === "development" ? "role_3@urla.se" : undefined,
                        required: true,
                    },
                    password: {
                        accept: "password",
                        label: "password",
                        initial: process.env.NODE_ENV === "development" ? "urla123" : undefined,
                        required: true,
                    },
                },
            }),

            onSubmit: async (props) => {
                const stopLoading = this.store.loading.start();

                try {
                    await logIn(props);
                } catch (error) {
                    console.error({ logIn: error });

                    if (!error.response || !error.response.data.message) {
                        return;
                    }

                    if (error.response.data.message.includes("Password")) {
                        this.store.addCombinationError("password", "incorrectPassword");
                    } else if (error.response.data.message.includes("Email")) {
                        this.store.addInputError("email", "userNotFound");
                    } else {
                        this.store.addError("unknownError");
                    }
                } finally {
                    stopLoading();
                }
            },
        });
    }
}
