import React from "react";
import { Helmet } from "react-helmet";
import { Switch, RouteProps, Route, Redirect } from "react-router-dom";
import { makeStyles, Container, useService, Translation } from "lib";

import { StatisticsActions } from "./actions";
import { StatisticsService } from "./service";
import { LinkStatisticsRoute } from "./link";
import { StatisticsTable } from "./statistics.table";

export default function Statistics() {
    const classes = useStyles();

    const t = Translation.useMap(["statistics"]);
    const service = useService(StatisticsService);
    const initializing = service.initializing.use().current;

    return (
        <>
            <Helmet title={[t.render("statistics"), "URLA"].join(" - ")} />

            <Container className={classes.root}>
                <StatisticsActions />
                <StatisticsTable />
            </Container>

            <Switch>
                <Route<RouteProps> exact path="/statistics/:urlaId" component={LinkStatisticsRoute} />

                <Redirect to="/statistics" />
            </Switch>
        </>
    );
}

const useStyles = makeStyles(
    () => ({
        root: {
            display: "flex",
            flexGrow: 1,
            flexShrink: 0,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            paddingTop: "2rem",
            paddingBottom: "2rem",
            position: "relative",
        },
    }),
    { name: "Statistics" },
);
