/* eslint-disable @typescript-eslint/camelcase */
import deburr from "lodash/deburr";
import moment from "moment";
import {
    FormService,
    Abon,
    ValueMetaMap,
    FileValue,
    TextValue,
    SliderValue,
    DateValue,
    Auth,
    CompletedTextOption,
    CompletedTextValue,
    ROLE,
    SnackStack,
    Translation,
} from "lib";
import { CreateUrlaService, CreateUrlaProps, CreateUrlaExcelProps } from "create-urla";

import { HomeService, ProjectService } from "../../service";

export class CreateUrlaActionService extends FormService<CreateUrlaProps> {
    open: Abon<boolean>;
    disabled: Abon<boolean>;

    createUrla: CreateUrlaService;
    home: HomeService;
    snacks: SnackStack;

    // link: TextValue;
    // file: FileValue;
    // name: TextValue;
    // linkAmount: SliderValue;
    // project: SelectValue<string>;

    constructor() {
        super({
            onSubmit: async (props) => {
                const stopLoading = this.loading.start();

                const created = await this.createUrla.create(props, this.store);

                if (created) {
                    this.open.set(false);

                    await Promise.all([this.require(ProjectService).refresh(), this.require(HomeService).refresh()]);
                    const amount = created["urla_ids"]?.split(",").length;
                    this.snacks.add({ message: Translation.render({ id: "createdXUrlas", values: { x: amount || props.amount || 1 } }) });
                }

                stopLoading();
            },

            types: [CreateUrlaProps, CreateUrlaExcelProps],
            metaMap: new ValueMetaMap<CreateUrlaProps>({
                meta: {
                    link: {
                        label: "link",
                        placeholder: "pasteLinkPlaceholder",
                        initial: process.env.NODE_ENV === "development" ? "https://google.com" : undefined,
                    },
                    urlaFile: {
                        type: "file",
                        label: "file",
                    },
                    name: {
                        label: "nameYourLink",
                        initial: process.env.NODE_ENV === "development" ? "Google" : undefined,
                    },
                    customUrla: {
                        type: "text",
                        label: "extension",
                        startAdornment: "/",
                        format(value) {
                            if (value == null) {
                                return value;
                            }

                            return deburr(value)
                                .split(" ")
                                .join("-");
                        },
                    },
                    amount: {
                        label: "numberOfLinks",
                        type: "slider",
                        fromZero: true,
                        min: 1,
                        initial: 1,
                        marks: true,
                    },
                    excelFile: {
                        type: "file",
                        placeholder: "excelList",
                    },
                    projectId: {
                        type: "completed-text",
                        placeholder: "project",
                        initial: 0 as any,
                        freeSolo: true,
                        useOptions: (): CompletedTextOption<number>[] => {
                            const projects = this.require(ProjectService).projects.use().current || [];

                            return projects.map((project) => {
                                return {
                                    value: Number(project.id),
                                    label: project.project_name,
                                };
                            });
                        },
                    },
                    expirationDate: {
                        label: "expires",
                        placeholder: "noExpiryDate",
                        type: "date",
                        readOnly: Auth.get("accessToken", "role_id") === ROLE.FREE,
                        initial:
                            Auth.get("accessToken", "role_id") === ROLE.FREE
                                ? moment()
                                      .add({ months: 1 })
                                      .format("YYYY-MM-DD")
                                : null,
                    },
                },
            }),
        });

        this.createUrla = this.require(CreateUrlaService);
        this.home = this.require(HomeService);
        this.snacks = this.require(SnackStack);

        this.open = new Abon(false);

        this.file.fileName.subscribe((fileName) => {
            this.name.set(fileName);
        });

        this.disabled = Abon.from(
            () => {
                const ids = this.home.ids.current;
                const userRole = Auth.get("accessToken", "role_id");

                if (userRole === ROLE.FREE) {
                    this.amount.sliderProps.max = 4 - ids.length;
                } else if (userRole === ROLE.PRO || userRole === ROLE.TRIAL) {
                    this.amount.sliderProps.max = 30 - Math.max(0, ids.length - 70);
                } else if (userRole === ROLE.ENTERPRISE) {
                    this.amount.sliderProps.max = 30;
                }

                return !this.amount.sliderProps.max;
            },
            (listener) => [Auth.subscribe("accessToken", "role_id", listener), this.home.ids.subscribe(listener)],
            this.deconstructFns,
        );

        this.store.subscribe("customUrla", (extension) => {
            if (extension) {
                this.amount.disabled.set(true);
                this.excelFile.disabled.set(true);

                if (this.amount.current > 1) {
                    this.amount.set(1);
                }
            } else {
                this.amount.disabled.set(false);
                this.excelFile.disabled.set(false);
            }
        });

        this.store.subscribe("link", (link) => {
            if (link) {
                this.file.disabled.set(true);
            } else {
                this.file.disabled.set(false);
            }
        });

        this.store.subscribe("excelFile", (excelFile) => {
            if (excelFile) {
                this.amount.disabled.set(true);
                this.extension.disabled.set(true);
                this.store.setValue("amount", 1);
                this.store.setValue("customUrla", undefined);
            } else {
                this.extension.disabled.set(false);

                if (!this.extension.current) {
                    this.amount.disabled.set(false);
                }
            }
        });

        this.onExited = this.onExited.bind(this);
    }

    onExited() {
        this.store.clear();
    }

    get link(): TextValue {
        return this.store.getValue("link") as any;
    }
    get file(): FileValue {
        return this.store.getValue("urlaFile") as any;
    }
    get name(): TextValue {
        return this.store.getValue("name") as any;
    }
    get extension(): TextValue {
        return this.store.getValue("customUrla") as any;
    }
    get amount(): SliderValue {
        return this.store.getValue("amount") as any;
    }
    get excelFile(): FileValue {
        return this.store.getValue("excelFile") as any;
    }
    get project(): CompletedTextValue<number> {
        return this.store.getValue("projectId") as any;
    }
    get expirationDate(): DateValue {
        return this.store.getValue("expirationDate") as any;
    }
}
