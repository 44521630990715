import React from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, Box, useTheme, TextField, useService } from "lib";
import { HomeService } from "home/service";

import { CreateUrlaAction } from "./create";
import { Projects } from "./projects";
import { NotificationSettings } from "./notification-settings";
import { SearchUrlas } from "./search";

export function HomeActions() {
    const classes = useStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));
    const service = useService(HomeService);

    return (
        <Box className={classes.root}>
            <ButtonGroup className={classes.group}>
                <CreateUrlaAction />
            </ButtonGroup>
            {!isMobile && !isTablet ? (
                <ButtonGroup className={classes.group}>
                    <SearchUrlas />
                    <Projects />
                    <NotificationSettings />
                </ButtonGroup>
            ) : (
                <ButtonGroup className={classes.group}>
                    <SearchUrlas />
                </ButtonGroup>
            )}
        </Box>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
        },
        group: {
            display: "flex",
            flexGrow: 1,
            flexShrink: 0,
            flexDirection: "row",
            alignItems: "center",
            padding: "1rem 0",
            position: "relative",

            "&:last-of-type": {
                justifyContent: "flex-end",

                [theme.breakpoints.down("sm")]: {
                    flexGrow: 0,
                    flexShrink: 1,
                },
            },

            // "& > button": {
            //     "&:not(:last-child)": {
            //         borderTopRightRadius: 0,
            //         borderBottomRightRadius: 0,
            //         borderRight: `1px solid ${theme.palette.primary.dark}`,
            //     },
            //     "&:not(:first-child)": {
            //         borderTopLeftRadius: 0,
            //         borderBottomLeftRadius: 0,
            //     },
            // },
        },
    }),
    { name: "HomeActions" },
);
