import React from "react";
import { hot } from "react-hot-loader/root";
import { BrowserRouter } from "react-router-dom";
import { RawIntlProvider } from "react-intl";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { Translation } from "../lib/store/translation";
import GA from "../lib/utils/GoogleAnalytics";

import { AppBody } from "./app.body";
import { AppErrorAlert } from "./app.error-alert";
import { AppLoading } from "./app.loading";
import { AppStyle } from "./app.style";
import { AppCardStack } from "./app.modal-stack";

export const App = hot(() => (
    <AppStyle>
        <BrowserRouter>
            {GA.init() && <GA.RouteTracker />}
            <RawIntlProvider value={Translation.intl}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <AppCardStack>
                        <AppBody />
                        <AppErrorAlert />
                        <AppLoading />
                    </AppCardStack>
                </MuiPickersUtilsProvider>
            </RawIntlProvider>
        </BrowserRouter>
    </AppStyle>
));
