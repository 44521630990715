/* eslint-disable @typescript-eslint/camelcase */
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import {
    useService,
    makeStyles,
    Api,
    ENDPOINT,
    ErrorAlert,
    SwitchValue,
    SwitchField,
    TableCellComponentProps,
    SnackStack,
    Translation,
    Auth,
    ROLE,
} from "lib";

import { HomeService } from "../service";
import { Urla } from "../shared";

export function HomeTableNotifyCell(props: TableCellComponentProps<Urla, "id">) {
    const classes = useStyles();

    const service = useService(HomeService);
    const snacks = useService(SnackStack);
    const roleId = Auth.use("accessToken", "role_id");
    const blockedTrialAccount = Auth.use("blockedTrialAccount");
    const value = service.values.use(props.id, "notifications");

    const receiver = React.useMemo(() => {
        const switchValue = new SwitchValue({
            async onChange() {
                if (switchValue.current === Boolean(Number(service.values.get(props.id, "notifications")))) {
                    return;
                }

                const stopLoading = switchValue.loading.start();

                try {
                    await Api.request(ENDPOINT.updateNotifications, {
                        urla_id: Number(props.id) as any,
                        notifications: switchValue.current ? 1 : 0,
                    });

                    service.values.set(props.id, "notifications", switchValue.current ? 1 : 0);

                    snacks.add({ duration: 1500, message: Translation.render("toggledMailNotify") });
                } catch (error) {
                    switchValue.set(!switchValue.current);
                    ErrorAlert.alert({
                        error,
                        when: "updatingTheNotificationStatus",
                    });
                } finally {
                    stopLoading();
                }
            },
        });

        return switchValue;
    }, []);

    React.useMemo(() => {
        receiver.valueProps.initial = Boolean(Number(value));
        receiver.set(receiver.valueProps.initial);
    }, [value]);

    return (
        <TableCell className={classes.root}>
            <SwitchField value={receiver} fullWidth disabled={roleId === ROLE.TRIAL && blockedTrialAccount} />
        </TableCell>
    );
}

const useStyles = makeStyles(
    () => ({
        root: {
            "& > div": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "-0.5rem",
            },
        },
    }),
    { name: "NotifyCell" },
);
