import React from "react";
import { Link, LinkProps } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import Button, { ButtonProps } from "@material-ui/core/Button";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";
import Tab, { TabProps } from "@material-ui/core/Tab";

export const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>((props, ref) => {
    return <Button {...(props as any)} ref={ref} component={Link} />;
});

export const IconButtonLink = React.forwardRef<HTMLAnchorElement, IconButtonLinkProps>((props, ref) => {
    return <IconButton {...(props as any)} ref={ref} component={Link} />;
});

export const MenuItemLink = React.forwardRef<HTMLAnchorElement, MenuItemLinkProps>((props, ref) => {
    return <MenuItem {...(props as any)} ref={ref} component={Link} />;
});

export const MenuItemAnchor = React.forwardRef<HTMLAnchorElement, MenuItemAnchorProps>((props, ref) => {
    return <MenuItem {...(props as any)} ref={ref} component={"a"} />;
});

export const TabLink = React.forwardRef<HTMLAnchorElement, TabLinkProps>((props, ref) => {
    const { tooltip, ...otherProps } = props;

    if (tooltip) {
        return (
            <Tab
                {...(otherProps as any)}
                ref={ref}
                component={!props.disabled ? Link : undefined}
                to={props.to || props.value}
                value={props.value || props.to}
                label={<Tooltip title={tooltip} children={props.label && <span children={props.label as any} />} />}
                style={{ pointerEvents: "auto" }}
            />
        );
    }

    return (
        <Tab
            {...(otherProps as any)}
            ref={ref}
            component={!props.disabled ? Link : undefined}
            to={props.to || props.value}
            value={props.value || props.to}
        />
    );
});

export const ButtonAnchor = React.forwardRef<
    HTMLAnchorElement,
    ButtonProps<"a", React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>>
>((props, ref) => {
    return <Button {...(props as any)} ref={ref} component={"a"} />;
});

export interface ButtonLinkProps extends ButtonProps<"a", LinkProps> {}

export interface IconButtonLinkProps extends IconButtonProps<"a", LinkProps> {}

export interface TabLinkProps extends TabProps<"a", Partial<LinkProps>> {
    tooltip?: string;
}
export interface MenuItemLinkProps extends MenuItemProps<"a", LinkProps> {}
export interface MenuItemAnchorProps extends MenuItemProps<"a", React.AnchorHTMLAttributes<HTMLAnchorElement>> {}
