import numeral from "numeral";

numeral.register("locale", "sv", {
    delimiters: {
        thousands: " ",
        decimal: ",",
    },
    abbreviations: {
        thousand: "k",
        million: "m",
        billion: "md",
        trillion: "bn",
    },
    ordinal: function(number) {
        const b = number % 10;
        return ~~((number % 100) / 10) === 1 ? ":a" : b === 1 ? ":a" : b === 2 ? ":a" : b === 3 ? ":e" : ":e";
    },
    currency: {
        symbol: "SEK ",
    },
});
