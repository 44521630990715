import { join } from "path";

import React from "react";
import { omit, pick, iterateLength } from "lib/utils";
import { Mark } from "@material-ui/core/Slider";

import { Value, ValueProps } from "../value";

import { SliderValueProps } from "./slider-value.types";

export class SliderValue extends Value<number> {
    static useSliderValue(props: SliderValueProps) {
        const sliderValue = React.useMemo(() => new SliderValue(props), []);

        React.useEffect(() => () => sliderValue.clear(), []);

        return sliderValue;
    }

    static selectSliderValueProps<PT extends SliderValueProps, T = any>(
        props: PT,
    ): Omit<PT, keyof SliderValueProps> & {
        sliderValueProps: SliderValueProps;
    } {
        const { valueProps, ...allProps } = Value.selectValueProps(props);

        return {
            ...omit(allProps, "min", "max", "step", "marks", "fromZero"),
            sliderValueProps: {
                ...valueProps,
                ...pick(allProps, "min", "max", "step", "marks", "fromZero"),
            },
        } as any;
    }

    sliderProps: Omit<SliderValueProps, keyof ValueProps<string>>;

    constructor(props: SliderValueProps) {
        if (!props.min) {
            props.min = 0;
        }

        if (!props.initial) {
            props.initial = props.min;
        }

        const { valueProps, ...sliderProps } = Value.selectValueProps(props);

        super(valueProps);

        this.sliderProps = sliderProps;

        this.onChange = this.onChange.bind(this);
    }

    use() {
        const usedValue = super.use();

        const current = usedValue.current;

        let marks: Mark[] | boolean;

        if (this.sliderProps.marks == null) {
            marks = (this.sliderProps.max || 100) < 25 ? true : undefined;
        } else if (this.sliderProps.marks === true) {
            const max = this.sliderProps.max || 100;

            if (max <= 10) {
                marks = iterateLength(
                    max,
                    (i): Mark => ({
                        value: i + 1,
                        label: i + 1,
                    }),
                );
            } else {
                let factor: number;

                if (max <= 25) {
                    factor = 5;
                } else if (max <= 50) {
                    factor = 10;
                } else if (max <= 100) {
                    factor = 25;
                } else if (max <= 250) {
                    factor = 50;
                } else if (max <= 500) {
                    factor = 100;
                } else if (max <= 1000) {
                    factor = 200;
                } else if (max <= 2000) {
                    factor = 400;
                } else if (max <= 5000) {
                    factor = 1000;
                }

                marks = iterateLength(
                    Math.floor(max / factor),
                    (i): Mark => ({
                        value: (i + 1) * factor,
                        label: (i + 1) * factor,
                    }),
                );
            }
        } else {
            marks = this.sliderProps.marks;
        }

        return {
            ...usedValue,
            current,
            step: this.sliderProps.step || 1,
            min: this.sliderProps.fromZero ? 0 : this.sliderProps.min || 0,
            max: this.sliderProps.max || 100,
            marks,
        };
    }

    onChange(event: React.ChangeEvent<{}>, value: number | number[]): void;
    onChange(_event: React.ChangeEvent<{}>, value: number) {
        if (value < (this.sliderProps.min || 0)) {
            return;
        }

        this.set(value);
    }
}
