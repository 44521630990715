import React from "react";
import MuiTableBody from "@material-ui/core/TableBody";
import { makeStyles, clsx } from "lib/material";
import { iterateLength } from "lib/utils";

import { TableParsedProps } from "./table.types";
import { TableRow } from "./table.row";
import { TableRowPlaceholder } from "./table.row-placeholder";

export function TableBody<T extends KeyObject, I extends keyof T, O extends keyof any>(props: TableParsedProps<T, I, O>) {
    const { service } = props;

    const classes = useStyles();

    const loading = service.status.loading.use().current;
    const initializing = service.initializing.use().current;
    const page = service.page.use().current;
    const perPage = service.perPage.use().current;
    const ids = service.ids.use().current;

    const pageIds = ids.slice(page * perPage, page * perPage + perPage);

    return (
        <MuiTableBody className={clsx(classes.root, initializing && classes.initializing, loading && classes.loading)}>
            {!initializing && pageIds.map((id) => <TableRow {...props} id={id} key={id} />)}

            {iterateLength(initializing ? perPage : perPage - pageIds.length, (index) => {
                return <TableRowPlaceholder {...props} key={index} initializing={initializing} />;
            })}
        </MuiTableBody>
    );
}

const useStyles = makeStyles(
    () => ({
        root: {
            transition: "opacity 0.25s ease-in-out",
        },
        initializing: {},
        loading: {
            opacity: 0.5,
        },
        placeholder: {
            height: 52,
        },
    }),
    { name: "StatisticsTableBody" },
);
