import { ValueMetaThunk } from "lib/form/value-meta/value-meta";

import { ValueMeta } from "../value-meta";
import { getValue, ValueType, ValueStateProps } from "../value";

import { ValueMetaMapProps, ValueMapProps } from "./value-meta-map.types";
import { ValueMap } from "./value-map";

export class ValueMetaMap<T extends KeyObject> {
    meta = new Map<keyof T, ValueMeta>();

    constructor(props: ValueMetaMapProps<T> = {}) {
        if (props.meta) {
            Object.keys(props.meta).forEach((key) => this.set(key, props.meta[key]));
        }
    }

    set<K extends keyof T>(key: K, metaThunk?: ValueMetaThunk<T[K]>) {
        const meta: ValueMeta<T[K]> = typeof metaThunk === "string" ? ({ type: metaThunk } as any) : metaThunk;

        if (!meta) {
            return;
        }

        if (meta.type === "checkbox") {
            if (meta.initial == null) {
                meta.initial = false;
            }

            if (meta.required) {
                delete meta.required;
            }
        }

        if (!meta.readOnly && meta.readOnly !== false && (meta.getFromParent || meta.getFromParentAsync || meta.getParentSubscription)) {
            meta.readOnly = true;
        }

        if (meta.required && meta.readOnly) {
            delete meta.required;
        }

        if (this.meta.has(key)) {
            this.meta.set(key, { ...this.meta.get(key), ...(meta as any) });
        } else {
            this.meta.set(key, meta as any);
        }
    }

    getValue<K extends keyof T>(key: K, props?: ValueStateProps<T[K]>): ValueType<T[K]> {
        if (!this.meta.has(key)) {
            throw new Error(`No value meta has been defined for key "${key}"`);
        }

        return getValue<T[K]>(this.meta.get(key) as any, props);
    }

    getValues(props: ValueMapProps<T>) {
        const values = new ValueMap<T>();

        const setValue = (key: keyof T) => {
            let value: ValueType;

            if (props.getValue) {
                value = props.getValue(key);
            } else if (props.getValueProps) {
                value = this.getValue(key, props.getValueProps(key));
            } else {
                value = this.getValue(key);
            }

            if (value != null) {
                values.set(key, value);
            }
        };

        if (props.group) {
            for (const [key, meta] of this.meta.entries()) {
                if (meta.group !== props.group || meta.tableOnly) {
                    continue;
                }

                setValue(key);
            }
        } else if (props.keys) {
            for (const [key, meta] of this.meta.entries()) {
                if (!props.keys.includes(key) || meta.tableOnly) {
                    continue;
                }

                setValue(key);
            }
        } else {
            for (const [key, meta] of this.meta.entries()) {
                if (meta.tableOnly) {
                    continue;
                }

                setValue(key);
            }
        }

        return values;
    }

    nest<_T extends T = T>(meta: ValueMetaMapProps<_T>["meta"]) {
        const metaMap = new ValueMetaMap<_T>({
            meta: {},
        });

        metaMap.meta = new Map<keyof _T, ValueMeta>([...this.meta.entries()]);

        Object.keys(meta).forEach((key) => metaMap.set(key, meta[key]));

        return metaMap;
    }

    nestReadOnly() {
        const metaMap = new ValueMetaMap<T>({
            meta: {},
        });

        metaMap.meta = new Map<keyof T, ValueMeta>([...this.meta.entries()].map(([key, meta]) => [key, { ...meta, readOnly: true }]));

        return metaMap;
    }
}
