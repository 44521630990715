/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Skeleton from "@material-ui/lab/Skeleton";
import { uniqueString } from "lib/utils";
import { makeStyles, clsx } from "lib/material";
import { Translation } from "lib/store";

import { FileFieldProps } from "./file-field.types";

export const FileField = React.forwardRef<HTMLInputElement, FileFieldProps>((props: FileFieldProps, ref) => {
    const {
        value,
        autoComplete,
        autoCorrect,
        spellCheck,
        error,
        helperText: explicitHelperText,
        skeleton,
        className,
        disabled: passedDisabled,
        ...otherProps
    } = props;

    if (!value) {
        throw new Error(`If no "value" is passed, you should use the text field exported directly from @material-ui/core/TextField.`);
    }

    const id = React.useMemo(() => `tf-${value.valueProps.name || uniqueString()}`, []);
    const classes = useStyles();
    const usedProps = value.use();
    const fileInputRef = React.useRef<HTMLInputElement>();

    const disabled = passedDisabled || usedProps.disabled;

    const onClick = React.useMemo(
        () => () => {
            if (disabled) {
                return;
            }

            value.onClick();
            fileInputRef.current.click();
        },
        [disabled],
    );

    if (skeleton) {
        return <Skeleton key={"_skeleton"} height={36} />;
    }

    const helperText =
        explicitHelperText ||
        (typeof usedProps.error === "string" && usedProps.error) ||
        (typeof usedProps.success === "string" && usedProps.success) ||
        undefined;

    const mustBeDefinedError =
        !explicitHelperText && usedProps.error && String(usedProps.error) === String(Translation.get("mustBeDefined"));

    let valueLabel = "";

    if (usedProps.fileName) {
        valueLabel = usedProps.fileName;
    } else if (usedProps.current) {
        valueLabel = "#" + usedProps.current;
    }

    return (
        <>
            {!disabled && (
                <input type="file" name={id + "-file"} onChange={value.uploadFromEvent} style={{ display: "none" }} ref={fileInputRef} />
            )}
            <FormControl
                className={clsx(!disabled && classes.root, className)}
                error={!!usedProps.error}
                disabled={disabled}
                required={mustBeDefinedError}
            >
                {usedProps.label && <InputLabel htmlFor={id} error={!!error} children={usedProps.label} />}

                <Input
                    {...otherProps.InputProps}
                    type={"text"}
                    value={usedProps.loading ? " " : valueLabel}
                    id={id}
                    onClick={onClick}
                    inputProps={{
                        name: value.valueProps.name,
                        id,
                        autoComplete: autoComplete || "off",
                        autoCorrect: autoCorrect || "off",
                        spellCheck: spellCheck || false,
                        "aria-readonly": usedProps.readOnly,
                        ...otherProps.inputProps,
                    }}
                    inputRef={ref}
                    name={value.valueProps.name}
                    placeholder={usedProps.placeholder}
                    endAdornment={
                        usedProps.fileSize && !usedProps.loading ? (
                            <InputAdornment className={classes.fileSize} position="end" children={usedProps.fileSize} />
                        ) : null
                    }
                    // endAdornment={
                    //     usedProps.endAdornment ? (
                    //         <InputAdornment position="end" children={usedProps.endAdornment} />
                    //     ) : null
                    // }
                />

                {usedProps.loading && <CircularProgress size="1rem" className={usedProps.label ? classes.loadingLabel : classes.loading} />}

                {helperText && !mustBeDefinedError && <FormHelperText children={helperText} />}
            </FormControl>
        </>
    );
});

const useStyles = makeStyles(() => ({
    root: {
        "& input": {
            cursor: "pointer",

            display: "flex",
            flexGrow: 1,
            flexShrink: 1,
        },

        "& .MuiInput-underline.Mui-disabled:before": {
            borderBottomStyle: "solid",
        },

        "& .MuiInput-underline:hover:before": {
            borderBottom: "2px solid #fff",
        },

        "& .MuiFormLabel-root.Mui-disabled": {
            color: "rgba(255, 255, 255, 0.7)",
        },
    },

    loading: {
        position: "absolute",
        top: "0.45rem",
        left: 0,
    },

    loadingLabel: {
        position: "absolute",
        top: "1.45rem",
        left: 0,
    },

    fileSize: {
        display: "flex",
        flexGrow: 0,
        flexShrink: 0,
    },
}));
