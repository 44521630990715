import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { Route } from "react-router-dom";
import { Auth } from "lib";

export function GoogleAnalytics(props) {
    const prevLocation = useRef(props.location);
    const userId = Auth.use("accessToken", "user_id");

    const logPageChange = ({ pathname, search = "", hash = "", key }) => {
        if (!key && !pathname) {
            return null;
        }
        if (pathname === "/") {
            pathname += "main-page";
        }
        const page = pathname + search + hash;
        const { location } = window;
        ReactGA.set({
            page,
            location: `${location.origin}${page}`,
            dimension1: process.env.NODE_ENV,
            userId: userId,
            ...props.options,
        });
        ReactGA.pageview(page);
    };

    useEffect(() => {
        logPageChange(props.location);
    }, []);

    useEffect(() => {
        const {
            location: { pathname, search },
        } = props;
        const isDifferentPathname = pathname !== prevLocation.current.pathname;
        const isDifferentSearch = search !== prevLocation.current.search;

        if (isDifferentPathname || isDifferentSearch) {
            logPageChange(props.location);
        }
    }, [props.location]);

    useEffect(() => {
        prevLocation.current = props.location;
    });

    return null;
}

GoogleAnalytics.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
    }).isRequired,
    options: PropTypes.object,
};

const RouteTracker = () => <Route component={GoogleAnalytics} />;

const init = () => {
    const isGAEnabled = true;

    if (isGAEnabled) {
        ReactGA.initialize("UA-174195464-2", {
            debug: process.env.NODE_ENV === "development" ? true : false,
            gaOptions: { cookieDomain: "auto" },
        });
    }

    return isGAEnabled;
};

export default {
    GoogleAnalytics,
    RouteTracker,
    init,
};
