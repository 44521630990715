import React from "react";
import { useTransition } from "react-spring";
import merge from "lodash/merge";
import { Abon } from "abon";

import { ParsedStackItem } from "../services/stack";
import { SnackStack, SnackPosition } from "../services";

import { StackProviderTransitionerProps, StackProviderItemProps } from "./stack-provider";
import { StackProviderSnackItem } from "./stack-provider.snack-item";

export function StackProviderSnackTransitioner(props: StackProviderTransitionerProps<SnackStack>) {
    return (
        <>
            <SnackStackProviderPositionTransitioner {...props} position="top-left" />
            <SnackStackProviderPositionTransitioner {...props} position="top-right" />
            <SnackStackProviderPositionTransitioner {...props} position="bottom-right" />
            <SnackStackProviderPositionTransitioner {...props} position="bottom-left" />
        </>
    );
}

function SnackStackProviderPositionTransitioner(props: StackProviderTransitionerProps<SnackStack> & { position: SnackPosition }) {
    const position = props.position;
    const stack: SnackStack = props.stack as any;

    const items = Abon.useComposedValue(
        () => stack.state.items.filter((item) => (!item.position && position === SnackStack.defaultPosition) || item.position === position),
        (listener) => [stack.state.subscribe("items", listener)],
        [stack],
    );

    const interpolations = {
        opacity: useTransition<ParsedStackItem<SnackStack>, { opacity: number }>(items, (item) => item.id, {
            from: { opacity: 0 },
            enter: { opacity: 1 },
            leave: { opacity: 0 },
            config: (item: ParsedStackItem<SnackStack>) =>
                merge({ tension: 450, friction: 35 }, item.interpolation, item.interpolations && item.interpolations.opacity),
        } as any),
        progress: useTransition<ParsedStackItem<SnackStack>, { progress: number }>(items, (item) => item.id, {
            from: { progress: -1 },
            enter: { progress: 0 },
            leave: { progress: 1 },
            config: (item: ParsedStackItem<SnackStack>) =>
                merge({ tension: 300, friction: 50 }, item.interpolation, item.interpolations && item.interpolations.progress),
        } as any),
        transform: useTransition<ParsedStackItem<SnackStack>, { transform: string }>(items, (item) => item.id, {
            from: { transform: "translateX(-200%) translateY(0px)" },
            enter: { transform: "translateX(0%) translateY(0px)" },
            update: (item: ParsedStackItem<SnackStack>) => {
                const before = items.slice(0, item.index);

                let beforeHeight = 0;

                before.forEach((beforeItem) => {
                    if (typeof beforeItem.height === "number") {
                        beforeHeight += beforeItem.height;
                    } else {
                        beforeHeight += SnackStack.defaultHeight;
                    }

                    beforeHeight += SnackStack.spacing;
                });

                if (beforeHeight) {
                    if (position.includes("top")) {
                        return { transform: "translateX(0%) translateY(" + beforeHeight + "px)" };
                    } else {
                        return { transform: "translateX(0%) translateY(" + -beforeHeight + "px)" };
                    }
                } else {
                    return { transform: "translateX(0%) translateY(0px)" };
                }
            },
            leave: { transform: "translateX(-200%) translateY(0px)" },
            config: (item: ParsedStackItem<SnackStack>) =>
                merge({ tension: 450, friction: 35, mass: 0.2 }, item.interpolation, item.interpolations && item.interpolations.transform),
        } as any),
    };

    const transitions: Omit<
        StackProviderItemProps<SnackStack>,
        keyof StackProviderTransitionerProps<SnackStack>
    >[] = interpolations.progress
        .map((progress) => {
            const transform = interpolations.transform.find((interpolation) => interpolation.item.id === progress.item.id);
            const opacity = interpolations.opacity.find((interpolation) => interpolation.item.id === progress.item.id);

            return {
                item: progress.item,
                key: progress.key,
                state: progress.state,
                progress,
                transform,
                opacity,
            };
        })
        .filter((itemInterpolations) =>
            [itemInterpolations.progress, itemInterpolations.transform, itemInterpolations.opacity].every((value) => !!value),
        )
        .map(
            ({
                progress,
                transform,
                opacity,
                item,
                state,
                key,
            }): Omit<StackProviderItemProps<SnackStack>, keyof StackProviderTransitionerProps<SnackStack>> => {
                return {
                    key,
                    state,
                    item,
                    interpolations: {
                        opacity: opacity ? opacity.props.opacity : undefined,
                        transform: transform ? transform.props.transform : undefined,
                        progress: progress.props.progress,
                        fraction: progress.props.progress.interpolate((progress: number) => (progress < 0 ? 1 - progress : progress)),
                    },
                };
            },
        );

    return (
        <>
            {transitions.map((transition) => (
                <StackProviderSnackItem {...transition} key={transition.key} {...props} />
            ))}
        </>
    );
}
