import React from "react";
import { animated } from "react-spring";
import { TemplateEditorService } from "../../services";
import { makeStyles, Translation, Abon, Tooltip, StackItemProps, CardStack, TemplateFile } from "lib";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import UploadIcon from "@material-ui/icons/CloudUpload";
import IconButton from "@material-ui/core/IconButton";
import Grow from "@material-ui/core/Grow";
import { Box, Grid, Typography } from "@material-ui/core";

export function ImageGallery(props: { open: Abon<boolean>; service: TemplateEditorService; onAdd(file_url: string): void }) {
    const { open = Abon.use(() => true), service, onAdd } = props;

    CardStack.use(
        open.current,
        () => ({
            onClose: () => open.set(false),
            renderer: (stackProps) => <ImageGalleryModal {...stackProps} {...props} open={open} onAdd={onAdd} service={service} />,
        }),
        [open, props.service],
    );

    return null;
}

function ImageGalleryModal(props: ImageGalleryModalProps) {
    const { service, onClose, onAdd, interpolations } = props;

    const t = Translation.useMap(["uploadImageTooltip", "uploadImage", "close", "ImageGalleryTitle", "ImageGallerySubtitle"]);
    const classes = useStyles();

    const page = service.images.page.use().current;
    const perPage = service.images.perPage.use().current;
    const ids = service.images.ids.use().current;
    const pageIds = ids.slice(page * perPage, page * perPage + perPage);

    return (
        <animated.div className={classes.paper} style={{ transform: interpolations.transform }}>
            <div className={classes.modalHeader}>
                <Typography variant="h5" className={classes.modalHeaderTitle}>
                    {t.render("ImageGalleryTitle")}
                </Typography>
            </div>
            <div className={classes.modalContent}>
                <Typography variant="body1">{t.render("ImageGallerySubtitle")}</Typography>
                <Grid container>
                    {pageIds.map((id) => (
                        <Grid item xs={4} sm={3} key={id}>
                            <TemplateEditorImage file_id={id} editor={service} onAdd={onAdd} />
                        </Grid>
                    ))}
                </Grid>
            </div>
            <div className={classes.modalFooter}>
                <Tooltip title={t.render("uploadImageTooltip")} key="_ub">
                    <Button
                        children={t.render("uploadImage")}
                        startIcon={<UploadIcon />}
                        onClick={service.images.onUpload}
                        className={classes.confirmButton}
                        variant="contained"
                        color="primary"
                    />
                </Tooltip>
                <Tooltip title={t.render("close")}>
                    <Button children={t.render("close")} onClick={onClose} className={classes.cancelButton} variant="outlined" />
                </Tooltip>
            </div>
        </animated.div>
    );
}

function TemplateEditorImage(props: Pick<TemplateFile, "file_id"> & { editor: TemplateEditorService; onAdd(file_url: string): void }) {
    const { file_id, editor, onAdd } = props;
    const file_url = editor.images.values.use(file_id, "file_url");
    const file_name = editor.images.values.use(file_id, "file_name");

    const c = useIconStyles();
    const t = Translation.useMap(["deleteImageTooltip"]);

    const hovered = Abon.use(() => false);
    const onMouseEnter = React.useCallback(() => hovered.set(true), [hovered]);
    const onMouseLeave = React.useCallback(() => hovered.set(false), [hovered]);
    const btnRef = React.useRef<HTMLButtonElement>();
    const onDelete = React.useCallback(() => editor.images.delete(file_id), [editor, file_id]);

    if (!file_url) {
        return null;
    }

    return (
        <div className={c.button} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <Grow in={hovered.current}>
                <Tooltip title={t.render("deleteImageTooltip")}>
                    <IconButton className={c.deleteButton} size="small" onClick={onDelete}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Grow>

            <Button
                className={c.root}
                ref={btnRef}
                onClick={() => {
                    onAdd(file_url);
                }}
            >
                <Box className={c.buttonWrapper}>
                    <img src={file_url} className={c.image} />
                    <Typography className={c.caption} variant="caption">
                        {file_name}
                    </Typography>
                </Box>
            </Button>
        </div>
    );
}

const useIconStyles = makeStyles(
    (theme) => ({
        root: {
            position: "relative",
        },
        button: {
            padding: 0,
            cursor: "pointer",
            position: "relative",
        },
        buttonWrapper: {
            display: "flex",
            flexDirection: "column",
        },
        image: {
            borderRadius: 3,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
        },
        caption: {
            marginTop: "0.5rem",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            width: "100%",
        },
        menu: {
            backgroundColor: "rgba(25, 36, 41, 1)",
        },
        deleteButton: {
            zIndex: 1,
            color: "white",
            position: "absolute",
            top: -4,
            right: -4,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            padding: ".5rem",

            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
        },
    }),
    { name: "TemplateEditorIcon" },
);

const useStyles = makeStyles(
    (theme) => ({
        paper: {
            minWidth: 300,
            maxWidth: 600,
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3, 4),
            outline: 0,
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
        },
        modalHeader: {
            marginBottom: "1rem",
            paddingBottom: "0.25rem",
            borderBottom: `1px solid ${theme.palette.grey[700]}`,
        },
        modalHeaderTitle: {
            fontWeight: 500,
            fontSize: "0.9rem",
            letterSpacing: 0.5,
            color: theme.palette.grey[600],
            textTransform: "uppercase",
        },
        modalContent: {
            flex: 1,
            marginBottom: "2rem",
            maxHeight: 500,
            overflow: "auto",
        },
        modalFooter: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "0 -0.5rem",
            flexWrap: "wrap",
        },
        confirmButton: {
            fontWeight: 600,
            margin: "0.5rem",
        },
        cancelButton: {
            fontWeight: 600,
            margin: "0.5rem",
        },
    }),
    { name: "ImageGalleryModal" },
);

interface ImageGalleryProps {
    onAdd(file_url: string): void;
    open: Abon<boolean>;
    service: TemplateEditorService;
}
interface ImageGalleryModalProps extends StackItemProps, ImageGalleryProps {}
