/* eslint-disable @typescript-eslint/camelcase */
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DateIcon from "@material-ui/icons/Today";
import ZeroIcon from "@material-ui/icons/Clear";
import { DatePicker } from "@material-ui/pickers";
import { useService, makeStyles, Auth, ROLE, TableCellComponentProps, Tooltip, Translation, Abon, Api, ENDPOINT, SnackStack } from "lib";
import moment from "moment";

import { HomeService } from "../service";
import { Urla } from "../shared";

export function HomeTableExpiresCell(props: TableCellComponentProps<Urla, "id">) {
    const classes = useStyles();

    const service = useService(HomeService);
    const t = Translation.useMap(["changeExpiryDate", "expiryDateUpgradeTooltip", "noExpiryDate"]);
    const snacks = useService(SnackStack);

    const roleId = Auth.use("accessToken", "role_id");
    const blockedTrialAccount = Auth.use("blockedTrialAccount");
    const expirationDate = service.values.use(props.id, "expiration_date");

    const expires = React.useMemo<{ date: moment.Moment; month: string; year: string; dayMonth: string; new: moment.Moment }>(() => {
        if (!expirationDate || expirationDate === "0000-00-00" || expirationDate === "2099-12-31") {
            return { date: null, month: "", year: "", dayMonth: "", new: null };
        }

        const date = moment(expirationDate);
        const year = date.format("YYYY");
        const month = date.format("MMMM");
        const day = date.format("Do");
        const dayMonth = date.format("DD/MM");

        return { date, month, year, dayMonth, day, new: null as typeof date };
    }, [expirationDate]);

    const buttonInner = (
        <>
            {expires.year && (
                <div className={classes.top}>
                    <DateIcon />
                    {expires.year}
                </div>
            )}

            <div className={classes.bottom}>{expires.dayMonth || "∞"}</div>
        </>
    );

    if (roleId !== ROLE.FREE && !blockedTrialAccount) {
        const open = Abon.use(() => false);
        const date = Abon.use(() => expires.date);

        const onChange = React.useMemo(() => {
            date.current = expires.date;

            return (newDate: moment.Moment) => {
                date.set(newDate);
            };
        }, [expires, service]);

        const onDelete = React.useCallback(() => {
            date.current = null;
            date.set(null);
            onClose();
        }, [service]);
        const onClick = React.useCallback(() => open.set(!open.current), [open]);
        const onClose = React.useCallback(async () => {
            open.set(false);

            const changed =
                Boolean(date.current) !== Boolean(expires.date) ||
                (date.current && expires.date && date.current.format("YYYY-MM-DD") !== expires.date.format("YYYY-MM-DD"));

            if (changed) {
                service.values.set(props.id, "expiration_date", date.current ? date.current.format("YYYY-MM-DD") : null);

                await Api.request(ENDPOINT.updateUrlaExpirationDate, {
                    urla_id: props.id,
                    expiration_date: service.values.get(props.id, "expiration_date"),
                });

                snacks.add({ duration: 1500, message: Translation.render("changedExpiryDate") });
            }
        }, [props.id, expires, open]);

        return (
            <TableCell className={classes.root}>
                <div className={classes.inner}>
                    <Tooltip title={t.render("changeExpiryDate")}>
                        <Button className={classes.button} onClick={onClick}>
                            {buttonInner}
                        </Button>
                    </Tooltip>
                    {date && date.current ? (
                        <Tooltip title={t.render("noExpiryDate")}>
                            <IconButton
                                onClick={onDelete}
                                disabled={!date || !date.current}
                                color="inherit"
                                size="small"
                                className={classes.button}
                            >
                                <ZeroIcon />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                </div>

                <DatePicker
                    minDate={Date()}
                    open={open.current}
                    onClose={onClose}
                    variant="inline"
                    value={date.current}
                    onChange={onChange}
                    tabIndex={-1}
                    InputProps={{ tabIndex: -1 }}
                />
            </TableCell>
        );
    }

    return (
        <TableCell className={classes.root}>
            <div className={classes.inner}>
                <Tooltip title={t.render("expiryDateUpgradeTooltip")}>
                    <Button className={classes.button}>{buttonInner}</Button>
                </Tooltip>
            </div>
        </TableCell>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            position: "relative",

            "& > .MuiFormControl-root": {
                pointerEvents: "none",
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0,
            },
        },
        inner: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
        },
        button: {
            "& > span": {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            },
            "& > span > svg": {
                fontSize: "1rem",
            },
        },
        top: {
            fontSize: 10,
            fontWeight: 500,
            opacity: 0.5,
            lineHeight: 1,
            display: "flex",
            flexDirection: "row",
            marginBottom: 4,
            alignItems: "center",
            justifyContent: "center",
            paddingRight: 3,

            "& > svg": {
                width: 10,
                height: 10,
                marginRight: 2,
                transform: "translateY(-0.5px)",
            },

            "& > span": {
                padding: 0,
            },
        },
        bottom: {
            display: "block",
            fontSize: 15,
            fontWeight: 600,
            opacity: 0.9,
            lineHeight: 1,
            textTransform: "none",
        },
    }),
    { name: "ExpiresCell" },
);
