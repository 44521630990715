import createMuiTheme, { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";

const themeOptions: ThemeOptions = {
    palette: {
        type: "dark",
        primary: {
            main: "#F76400",
            light: "#66afe9",
        },
        secondary: {
            main: "#103440",
        },
        background: {
            default: "#212121",
        },

        // error: {
        //     main: "#df0404",
        // },
    },

    overrides: {
        MuiButton: {
            root: {
                "& .MuiButton-label": {
                    paddingTop: "0.25rem",
                },
            },
        },
        MuiTooltip: {
            tooltipPlacementBottom: {
                marginTop: "-4px !important",
            },
            tooltipPlacementTop: {
                marginBottom: "-4px !important",
            },
            tooltip: {
                fontSize: 14,
                marginTop: -12,
                padding: "12px",
                paddingBottom: "8px",
                backgroundColor: "#212121",
            },
        },
    },
    zIndex: {
        modal: 1300,
        snackbar: 1200,
    },
    breakpoints: {
        values: {
            xl: 1360,
            lg: 1200,
            md: 750,
            sm: 450,
            xs: 400,
        },
    },

    typography: {
        h1: {
            fontSize: 52,
            fontWeight: 600,
        },

        fontFamily: [
            '"Azo Sans"',
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
};

export const theme = createMuiTheme(themeOptions);
