import React from "react";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles, useService, Class, Translation, TranslationThunk, Abon, clsx } from "lib";

import { HomeActionService } from "./home-action.service";

export function HomeAction(props: {
    service: Class<HomeActionService> | HomeActionService;
    label: TranslationThunk;
    primary?: boolean;
    disabled?: boolean;
    tooltip?: string;
    position?: "left" | "right";
    className?: string;
    children?: React.ReactNode | ((props: { open: boolean }) => JSX.Element);
    onClick?;
}) {
    const { service: serviceThunk, label, children, position, primary, disabled, tooltip, className, onClick } = props;

    const service = useService(serviceThunk);

    const classes = useStyles();

    const open = service.open.use();
    const anchorRef = Abon.use<HTMLButtonElement>();
    const toggleOpen = React.useMemo(() => () => !!open.set(!open.current), []);

    let button = (
        <Button
            className={classes.root}
            onClick={onClick ? onClick : toggleOpen}
            ref={React.useMemo(() => anchorRef.set.bind(anchorRef), [])}
            variant={primary ? "contained" : "text"}
            color={primary ? "primary" : "inherit"}
            key="_button"
            disabled={disabled}
        >
            {Translation.render(label)}
        </Button>
    );

    if (tooltip) {
        button = <Tooltip title={tooltip} children={<div children={button} />} key="_tooltip" />;
    }

    return (
        <>
            {button}

            <Popover
                anchorEl={anchorRef.current}
                open={open.current}
                onClose={toggleOpen}
                classes={{ paper: clsx(classes.menu, className) }}
                key="_popover"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: position || "center",
                }}
                transformOrigin={{
                    vertical: -8,
                    horizontal: position || "center",
                }}
                anchorPosition={{
                    top: 0,
                    left: 0,
                }}
                onExited={service.onExited}
            >
                {typeof children === "function" ? children({ open: open.current }) : children}
            </Popover>
        </>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            fontWeight: 600,
            padding: "0.4rem 1.2rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        menu: {
            padding: "1rem 1.5rem 2rem 1.5rem",
            backgroundColor: "rgba(42, 42, 42, 0.975)",
        },
    }),
    { name: "HomeAction" },
);
