import React from "react";
import {
    makeStyles,
    Table,
    useTheme,
    Auth,
    ROLE,
    ButtonLink,
    Translation,
    Paper,
    Typography,
    clsx,
    MessageActiviyStatus,
    Abon,
    Tooltip,
    IconButtonLink,
    RouterService,
    useService,
} from "lib";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grow from "@material-ui/core/Grow";
import { TableConfirmDelete } from "lib/components/table/table.confirm-delete";
import { Chip, Link } from "@material-ui/core";
import moment from "moment";
import TableCell from "@material-ui/core/TableCell";
import EyeIcon from "@material-ui/icons/RemoveRedEye";

export function CampaignsActivityTable(props) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const router = useService(RouterService);
    const roleId = Auth.use("accessToken", "role_id");
    const t = Translation.useMap([
        "upgradeRequired",
        "marketingActivityUpgradeTitle",
        "marketingActivityUpgradeBody",
        "upgrade",
        "delete",
        "viewMessage",
    ]);
    const { service } = props;
    const openDeleteMessage = Abon.use(() => false);
    const selectedMessageId = Abon.use(() => null);

    const openDeleteModal = (messageId) => {
        selectedMessageId.set(messageId);
        openDeleteMessage.set(true);
    };

    const onDelete = React.useCallback(() => {
        if (openDeleteMessage) {
            if (openDeleteMessage.current) {
                service.deleteScheduledMessage(selectedMessageId.current);
                openDeleteMessage.set(false);
                selectedMessageId.set(null);
            } else {
                openDeleteMessage.set(true);
            }
        } else {
            service.deleteScheduledMessage(selectedMessageId.current);
            selectedMessageId.set(null);
        }
    }, [service, openDeleteMessage]);

    return (
        <>
            <Table
                service={service}
                rowHeight={54}
                columns={[
                    {
                        valueKey: "receiver_name",
                        label: "receiver",
                        weight: 3,
                        renderContent: (props) => props.value || "—",
                    },
                    {
                        valueKey: "receiver_data",
                        label: "contact",
                        weight: 3,
                        renderContent: (props) => props.value || "—",
                    },
                    {
                        valueKey: "project_name",
                        label: "project",
                        weight: 2,
                        renderContent: (props) => props.value || "—",
                    },
                    {
                        valueKey: "status",
                        label: "status",
                        weight: 1.5,
                        renderContent: (props) => {
                            switch (props.value) {
                                case MessageActiviyStatus.unknown:
                                    return <Chip className={clsx(classes.chip, classes.chipGrey)} label={Translation.render("unknown")} />;
                                case MessageActiviyStatus.sent:
                                    return <Chip className={clsx(classes.chip, classes.chipOrange)} label={Translation.render("sent")} />;
                                case MessageActiviyStatus.delivered:
                                    return (
                                        <Chip className={clsx(classes.chip, classes.chipGreen)} label={Translation.render("delivered")} />
                                    );
                                case MessageActiviyStatus.queued:
                                    return <Chip className={clsx(classes.chip, classes.chipBlue)} label={Translation.render("queued")} />;
                                case MessageActiviyStatus.removed:
                                    return <Chip className={clsx(classes.chip, classes.chipGrey)} label={Translation.render("removed")} />;
                                case MessageActiviyStatus.rejected:
                                    return <Chip className={clsx(classes.chip, classes.chipRed)} label={Translation.render("rejected")} />;
                                case MessageActiviyStatus.failed:
                                    return <Chip className={clsx(classes.chip, classes.chipRed)} label={Translation.render("failed")} />;
                                case MessageActiviyStatus.expired:
                                    return <Chip className={clsx(classes.chip, classes.chipRed)} label={Translation.render("expired")} />;
                                case MessageActiviyStatus.invalid:
                                    return <Chip className={clsx(classes.chip, classes.chipRed)} label={Translation.render("invalid")} />;
                                case MessageActiviyStatus.retry:
                                    return <Chip className={clsx(classes.chip, classes.chipOrange)} label={Translation.render("retry")} />;
                                case MessageActiviyStatus.unsubscribed:
                                    return (
                                        <Chip className={clsx(classes.chip, classes.chipGrey)} label={Translation.render("unsubscribed")} />
                                    );
                                case MessageActiviyStatus.undelivered:
                                    return (
                                        <Chip className={clsx(classes.chip, classes.chipRed)} label={Translation.render("undelivered")} />
                                    );
                                case MessageActiviyStatus.blocked:
                                    return <Chip className={clsx(classes.chip, classes.chipRed)} label={Translation.render("blocked")} />;
                                default:
                                    return null;
                            }
                        },
                    },
                    {
                        valueKey: "send_timestamp",
                        label: "sent",
                        weight: 2.0,
                        renderContent: (props: any) => (props.value ? moment.unix(props.value).format("YYYY-MM-DD HH:mm") : "—"),
                    },
                    {
                        valueKey: "message_log_id",
                        align: "right",
                        weight: 2.2,
                        render: ({ row, value }) => (
                            <TableCell className={classes.viewCell} align="right">
                                {(() => {
                                    switch (row.status) {
                                        case MessageActiviyStatus.queued:
                                            return (
                                                <Link
                                                    className={classes.actionLink}
                                                    onClick={() => openDeleteModal(value)}
                                                    color={"primary"}
                                                    key="_button"
                                                >
                                                    {t.render("delete")}
                                                </Link>
                                            );
                                        default:
                                            null;
                                    }
                                })()}
                                <Tooltip title={t.render("viewMessage")}>
                                    <IconButtonLink
                                        to={`${router.location.pathname}/${row["msg_db_id"]}`}
                                        className={classes.view}
                                        children={<EyeIcon />}
                                    />
                                </Tooltip>
                            </TableCell>
                        ),
                    },
                ]}
                orderOptions={{}}
            >
                {roleId === ROLE.FREE && (
                    <Grow in>
                        <Paper className={classes.info}>
                            <Typography variant="overline" className={classes.infoOverline} children={t.render("upgradeRequired")} />
                            <Typography variant="h2" className={classes.infoTitle} children={t.render("marketingActivityUpgradeTitle")} />
                            <Typography variant="body1" className={classes.infoBody} children={t.render("marketingActivityUpgradeBody")} />
                            <ButtonLink to="/upgrade" color="primary" className={classes.infoLink} children={t.render("upgrade")} />
                        </Paper>
                    </Grow>
                )}
            </Table>
            <TableConfirmDelete service={service} open={openDeleteMessage} onDelete={onDelete} key="_confirmDelete" />
        </>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        viewCell: {},
        view: {
            padding: "0.5rem",
            lineHeight: 1,
            fontWeight: 600,
            backgroundColor: "",
            margin: "0 0.5rem",
        },
        info: {
            backgroundColor: theme.palette.grey[900],
            padding: "1rem 1.5rem",
            maxWidth: "calc(100% - 2rem)",
            overflow: "hidden",
            pointerEvents: "all",
        },
        infoOverline: {
            color: theme.palette.text.secondary,
            opacity: 0.5,
        },
        infoTitle: {
            color: theme.palette.text.primary,
            fontSize: 24,
        },
        infoBody: {
            color: theme.palette.text.secondary,
            fontSize: 16,
            marginTop: 8,
        },
        infoLink: {
            marginLeft: "-0.5rem",
            fontWeight: 600,
        },
        chip: {
            "& .MuiChip-label": {
                marginBottom: -2,
            },
        },
        chipGreen: {
            backgroundColor: theme.palette.success.main,
        },
        chipBlue: {
            backgroundColor: theme.palette.info.main,
        },
        chipOrange: {
            backgroundColor: theme.palette.warning.main,
        },
        chipRed: {
            backgroundColor: theme.palette.error.main,
        },
        chipGrey: {
            backgroundColor: theme.palette.grey[700],
        },
        actionLink: {
            padding: "0.5rem",
            cursor: "pointer",
            margin: "0 0.5rem",
        },
    }),
    { name: "CampaignsActivityTableRow" },
);
