import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Slider from "@material-ui/core/Slider";
import { uniqueString } from "lib/utils";
import { makeStyles, clsx } from "lib/material";
import { Translation } from "lib/store/translation";

import { SliderFieldProps } from "./slider-field.types";

export const SliderField = React.forwardRef<HTMLInputElement, SliderFieldProps>((props: SliderFieldProps, ref) => {
    const {
        value,
        skeleton,
        className,
        name = value.valueProps.name,
        helperText: explicitHelperText,
        id: passedId,
        disabled: passedDisabled,
        label,
        ...otherProps
    } = props;

    if (!value) {
        throw new Error(`If no "value" is passed, you should use the select field exported directly from @material-ui/core/Slider.`);
    }

    const id = React.useMemo(() => passedId || `sf-${value.valueProps.name || uniqueString()}`, [passedId]);
    const usedProps = value.use();
    const classes = useStyles();

    const helperText = explicitHelperText || usedProps.error || usedProps.success;

    if (skeleton) {
        return <Skeleton key={"_skeleton"} height={36} />;
    }

    const disabled = passedDisabled || (usedProps ? usedProps.disabled || usedProps.loading || usedProps.readOnly : undefined);

    return (
        <FormControl key={"_select"} className={clsx(classes.root, className)} error={!!usedProps.error} disabled={disabled}>
            {(label || usedProps.label) && <Typography className={classes.label} id={id} children={label || usedProps.label} />}

            <Slider
                step={usedProps.step}
                min={usedProps.min}
                max={usedProps.max}
                marks={usedProps.marks}
                {...otherProps}
                value={usedProps.current}
                onChange={value.onChange}
                name={name}
                placeholder={usedProps.placeholder}
                aria-labelledby={id}
                valueLabelDisplay="auto"
                disabled={disabled}
            />

            {helperText && <FormHelperText children={helperText} />}
        </FormControl>
    );
});

const useStyles = makeStyles(
    (theme) => ({
        root: {
            padding: "0.75rem 0 0.25rem 0",
        },
        label: { color: theme.palette.text.secondary },
    }),
    { name: "SliderField" },
);
