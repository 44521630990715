import React from "react";
import { makeStyles, Table, useTheme, Auth, ROLE, ButtonLink, Translation, Paper, Typography, useService } from "lib";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TableCell from "@material-ui/core/TableCell";
import Grow from "@material-ui/core/Grow";
import moment from "moment";

import { StatisticsService } from "./service";
import { AccountRemindersService } from "account/reminders/account-reminders-service";
import { Button } from "@material-ui/core";

export function StatisticsTable() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const roleId = Auth.use("accessToken", "role_id");
    const blockedTrialAccount = Auth.use("blockedTrialAccount");
    const t = Translation.useMap(["upgradeRequired", "statisticsUpgradeTitle", "statisticsUpgradeBody", "upgrade"]);
    const service = useService(StatisticsService);
    const reminders = useService(AccountRemindersService);

    function handleBlockedAction() {
        reminders.open.set(true);
    }

    return (
        <Table
            service={service}
            rowHeight={54}
            columns={[
                roleId === ROLE.ENTERPRISE && {
                    tooltip: "statisticsUserTooltip",
                    label: "user",
                    valueKey: "name",
                    weight: 2,
                    renderContent: (props) => (props.value ? props.value : props.row.username),
                },
                {
                    valueKey: "project_name",
                    label: "project",
                    weight: 2,
                    renderContent: (props) => (props.value !== "DEFAULT" && props.value) || "—",
                },
                {
                    valueKey: "url_name",
                    label: "linkName",
                    weight: 3,
                    renderContent: (props) => props.value || "—",
                },
                {
                    valueKey: "receiver_name",
                    label: "receiver",
                    weight: 2,
                    renderContent: (props) => props.value || "—",
                },
                {
                    valueKey: "number_of_views",
                    label: "views",
                    weight: 1.5,
                    renderContent: (props) => (props.value ? Translation.formatNumber(props.value, "0a") : "—"),
                },
                {
                    valueKey: "latest_view",
                    label: "lastView",
                    weight: 2,
                    renderContent: (props) => (props.value ? moment(props.value).format("YYYY-MM-DD") : "—"),
                },
                !isMobile && {
                    valueKey: "created_at",
                    label: "created",
                    width: 115,
                    renderContent: (props) => (props.value ? moment(props.value).format("YYYY-MM-DD") : "—"),
                },
                {
                    align: "right",
                    width: 140,
                    render: ({ row, id }) => (
                        <TableCell className={classes.viewCell} align="right">
                            {roleId === ROLE.TRIAL && blockedTrialAccount ? (
                                <Button
                                    onClick={handleBlockedAction}
                                    children={Translation.render("viewData")}
                                    className={classes.view}
                                    disabled={!row.latest_view || !Number(row.number_of_views)}
                                />
                            ) : (
                                <ButtonLink
                                    to={`/statistics/${id}`}
                                    children={Translation.render("viewData")}
                                    className={classes.view}
                                    disabled={!row.latest_view || !Number(row.number_of_views)}
                                />
                            )}
                        </TableCell>
                    ),
                },
            ]}
            orderOptions={{
                name: "name",
                project_name: "project_name",
                receiver_name: "receiver_name",
                url_name: "link_name",
                latest_view: "latest_view",
                created_at: "created_date",
                number_of_views: "num_views",
            }}
        >
            {(roleId === ROLE.FREE || (roleId === ROLE.TRIAL && blockedTrialAccount)) && (
                <Grow in>
                    <Paper className={classes.info}>
                        <Typography variant="overline" className={classes.infoOverline} children={t.render("upgradeRequired")} />
                        <Typography variant="h2" className={classes.infoTitle} children={t.render("statisticsUpgradeTitle")} />
                        <Typography variant="body1" className={classes.infoBody} children={t.render("statisticsUpgradeBody")} />
                        <ButtonLink to="/upgrade" color="primary" className={classes.infoLink} children={t.render("upgrade")} />
                    </Paper>
                </Grow>
            )}
        </Table>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        viewCell: {},
        view: {
            padding: "0.5rem 0",
            lineHeight: 1,
            fontWeight: 600,
            backgroundColor: "rgba(31, 31, 31, 0.5)",
            width: "100%",
        },
        info: {
            backgroundColor: theme.palette.grey[900],
            padding: "1rem 1.5rem",
            maxWidth: "calc(100% - 2rem)",
            overflow: "hidden",
            pointerEvents: "all",
        },
        infoOverline: {
            color: theme.palette.text.secondary,
            opacity: 0.5,
        },
        infoTitle: {
            color: theme.palette.text.primary,
            fontSize: 24,
        },
        infoBody: {
            color: theme.palette.text.secondary,
            fontSize: 16,
            marginTop: 8,
        },
        infoLink: {
            marginLeft: "-0.5rem",
            fontWeight: 600,
        },
    }),
    { name: "StatisticsTableRow" },
);
