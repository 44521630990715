/* eslint-disable @typescript-eslint/camelcase */
import { Abon, Service, TextValue, LoadingStatus, Api, ENDPOINT, ErrorAlert, SnackStack, Translation } from "lib";
import { ProjectService } from "home/service";

export class ProjectsActionService extends Service {
    open: Abon<boolean>;

    newProject = new TextValue({ placeholder: "newProject" });
    loadingNewProject = new LoadingStatus();
    snacks: SnackStack;

    constructor() {
        super();

        this.snacks = this.require(SnackStack);
        this.open = new Abon(false);
        this.onNewProjectCreate = this.onNewProjectCreate.bind(this);

        this.open.subscribe((open) => {
            if (!open) {
                this.newProject.set(undefined);
            }
        });
    }

    async onNewProjectCreate() {
        if (!this.newProject.current) {
            return;
        }

        const stopLoading = this.loadingNewProject.start();

        try {
            await Api.request(ENDPOINT.createProject, {
                project_name: this.newProject.current,
            });

            const projects = this.require(ProjectService);

            await projects.refresh();
            projects.page.set(projects.pagesCount.current);
            this.snacks.add({ message: Translation.render({ id: "createdProjectX", values: { x: this.newProject.current } }) });

            this.forgo(projects);
        } catch (error) {
            ErrorAlert.alert({ error });
        } finally {
            this.newProject.set(undefined);
            stopLoading();
        }
    }
}
