import React from "react";
import Table from "@material-ui/core/Table";
import Box from "@material-ui/core/Box";
import { makeStyles } from "lib";

import { AccountPaymentsTableHead } from "./account-payments.table-head";
import { AccountPaymentsTableBody } from "./account-payments.table-body";
import { AccountPaymentsTablePagination } from "./account-payments.table-pagination";

export function AccountPaymentsTable() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Table>
                <AccountPaymentsTableHead />
                <AccountPaymentsTableBody />
            </Table>

            <AccountPaymentsTablePagination />
        </Box>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: "80rem",
            maxWidth: "100%",
            backgroundColor: "rgba(42, 42, 42, 0)",
            padding: "1.5rem 1.5rem",
            position: "relative",

            "& .MuiTableCell-root": {
                borderColor: theme.palette.grey[800],
            },
        },
    }),
    { name: "AccountPaymentsTable" },
);
