import { MessageDescriptor } from "react-intl";

export interface TranslationQuery<I extends string = string> extends MessageDescriptor {
    id: I;
    values?: KeyObject;
}

export class TranslationResult extends String implements String {
    constructor(result: string) {
        super(result);
    }
}

export type TranslationThunk<I extends string = string> = I | TranslationQuery<I> | TranslationResult;

export enum LOCALE {
    en = "en",
    sv = "sv",
}

export const LOCALE_TRANSLATION: Partial<Record<LOCALE, string>> = {
    [LOCALE.en]: "english",
    [LOCALE.sv]: "swedish",
};

export const LOCALE_CODE: Partial<Record<LOCALE, string>> = {
    [LOCALE.en]: "en_GB",
    [LOCALE.sv]: "sv_SE",
};
