import axios from "axios";

import { Auth, LS_AUTH_NAMES } from "../auth";

import { API_URL, ENDPOINT, DECLARED_ENDPOINT, PARAMS_MAP, RESPONSE_MAP, STATIC_JWT } from "./api.types";
class Api {
    async request<E extends DECLARED_ENDPOINT>(endpoint: E, params: PARAMS_MAP[E]): Promise<RESPONSE_MAP[E]>;
    async request<E extends keyof PARAMS_MAP, T>(endpoint: E, params: PARAMS_MAP[E]): Promise<T>;
    async request(endpoint: ENDPOINT, params: any) {
        return this.requestAny(endpoint, params);
    }

    async requestAny<T, P>(endpoint: ENDPOINT, params: P): Promise<T> {
        const jwt = endpoint.includes("jwt_") ? await Auth.jwt : (params as any).jwt;

        let data: any;

        if (params instanceof FormData) {
            data = params;

            if (!params.has("jwt") && jwt) {
                params.append("jwt", jwt);
            }
        } else {
            data = {
                ...params,
                jwt,
            };
        }

        return axios({
            method: "post",
            url: `${API_URL}/${endpoint}`,
            data,
        }).then((response) => {
            const data = response.data as any;

            if (data != null && data.jwt != null) {
                Auth.setJwt(data.jwt);
            }

            return data;
        });
    }
}

const A = new Api();

export { A as Api };
