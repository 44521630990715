import { ServiceAsync } from "servido";

import { ValueStore } from "../value-store";

import { FormServiceProps } from "./form-service.types";

export class FormService<T extends KeyObject> extends ServiceAsync {
    static ID = "$form";

    constructor(readonly props: FormServiceProps<T>) {
        super();

        this.onSubmit = this.onSubmit.bind(this);
    }

    protected getStore() {
        if (!this.props.noSetStore) {
            if (this.props.store) {
                return this.props.store;
            } else if (this.props.metaMap) {
                return new ValueStore({
                    metaMap: this.props.metaMap,
                    type: this.props.type,
                    types: this.props.types,
                    edit: this.props.edit,
                    initial: this.props.initial,
                    validate: this.props.validate,
                });
            } else {
                throw new Error("Either a `store` or a `metaMap` must be passed to a FormService");
            }
        }
    }

    async onSubmit(): Promise<void | boolean> {
        await this.loading.onDone();
        const stopLoading = this.loading.start();

        const valid = await this.store.validate();

        if (valid) {
            if (this.props.onSubmit) {
                const submitted = await this.props.onSubmit(this.store.current);

                if (submitted) {
                    await this.store.setInitialValues(submitted);

                    if (this.props.onSuccess) {
                        await this.props.onSuccess(submitted);
                    }
                }
            } else {
                await this.store.setInitialValues(this.store.current);

                if (this.props.onSuccess) {
                    await this.props.onSuccess(this.store.current);
                }
            }

            stopLoading();

            return true;
        }

        stopLoading();

        return false;
    }

    clear() {
        this.store.clear();
    }

    get initializing() {
        return this.store.initializing;
    }

    get loading() {
        return this.props.loading || this.store.loading;
    }

    get error() {
        return this.store.error;
    }

    get success() {
        return this.store.success;
    }

    private _store?: ValueStore<T>;

    get store() {
        if (!this._store) {
            this._store = this.getStore();
        }

        return this._store;
    }
}
