/* eslint-disable @typescript-eslint/camelcase */
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles, Skeleton } from "lib/material";

import { TableRowPlaceholderProps } from "./table.types";

export function TableRowPlaceholder<T extends KeyObject, I extends keyof T>(props: Omit<TableRowPlaceholderProps<T, I>, "id">) {
    const { service, columns, initializing, rowHeight } = props;

    const classes = useStyles();

    return (
        <TableRow className={classes.root} style={{ height: rowHeight }}>
            {columns.map((_, i) => (
                <TableCell key={i} component={i === 0 ? "th" : undefined} children={initializing && <Skeleton />} />
            ))}

            {service.shouldManageSelected && <TableCell children={initializing && <Skeleton />} />}
        </TableRow>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            "& .MuiSkeleton-animate": {
                height: 64,
            },

            "& .MuiTableCell-root": {
                padding: "0.3rem 1rem",
            },

            "& .MuiSkeleton-text:empty:before": {
                color: "transparent",
            },
        },
    }),
    { name: "TableRowPlaceholder" },
);
