/* eslint-disable no-invalid-this */
/* eslint-disable @typescript-eslint/camelcase */
import { Api, ENDPOINT, AbonDeep, TableService, TemplateType, Abon, UnsubscribedUsers } from "lib";

export class MarketingUnsubscriptionsService extends TableService<UnsubscribedUsers, "id", UnsubscriptionsOrder> {
    unsubscriptions = new AbonDeep<UnsubscribedUsers[]>(null);
    type = new Abon<TemplateType>();

    constructor(props: UnsubscriptionsProps = {}) {
        super({
            id: "id",
            perPage: 6,
            perPageOptions: true,
            search: { placeholder: "searchForUnsubscriptions" },

            fetch: async (props) => {
                if (props.forceHydrate || !this.unsubscriptions.current) {
                    try {
                        const response = await Api.request(ENDPOINT.getUnsubscriptions, {
                            search_param: props.search || "",
                        });
                        this.unsubscriptions.set(response["response"] || []);
                    } catch (error) {
                        this.unsubscriptions.set([]);
                    }
                }

                return {
                    values: this.unsubscriptions.current,
                    totalCount: this.unsubscriptions.current.length,
                };
            },

            translations: {
                single: "unsubscriptionThe",
                many: "unsubscriptionMany",
                theMany: "unsubscriptionTheMany",
                the: "unsubscriptionThe",
            },
        });

        this.type.set(props.type);
    }
}

export type UnsubscriptionsOrder = "unsubscription_time" | "receiver_name" | "project_name";

export interface UnsubscriptionsProps {
    type?: TemplateType;
}
