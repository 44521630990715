import React from "react";
import { Helmet } from "react-helmet";
import LogInIcon from "@material-ui/icons/LockOpen";
import Button from "@material-ui/core/Button";
import { TextField, makeStyles, useTheme, useService, Translation, Form, Typography, Divider, Box, ButtonLink } from "lib";

import { AuthHead, AuthSubmit, AuthLinks } from "../shared";

import { ForgotPasswordService } from "./forgot-password.service";

export default function ForgotPassword() {
    const classes = useStyles();

    const service = useService(ForgotPasswordService);
    const t = Translation.useMap(["forgotPassword", "logIn", "signUpForUrla", "submit"]);

    const submittable = service.store.submittable.use().current;

    return (
        <>
            <Helmet>
                <title children={[t.get("forgotPassword"), "URLA"].join(" - ")} />
            </Helmet>

            <Form service={service}>
                <AuthHead title={t.get("forgotPassword")} />

                <TextField value={service.store.getValue("email") as any} fullWidth className={classes.input} />

                <AuthSubmit service={service} disabled={!submittable} children={t.get("submit")} />

                <AuthLinks>
                    <ButtonLink children={t.render("logIn")} to="/auth/log-in" />
                    <ButtonLink children={t.render("signUpForUrla")} to="/auth/sign-up" />
                </AuthLinks>
            </Form>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    input: {
        marginBottom: "1rem",
    },
}));
