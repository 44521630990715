import React from "react";
import debounce from "lodash/debounce";
import { makeStyles, TextField } from "lib";

export function SearchActivity(props) {
    const classes = useStyles();

    const { service } = props;

    const searching = React.useRef<symbol>();

    React.useEffect(() => {
        const fetchValues = async () => {
            const searchSymbol = Symbol();
            searching.current = searchSymbol;

            const stopLoading = service.status.loading.start();

            const { values } = await service.requestValues();

            if (searching.current === searchSymbol) {
                service.items.set(values);
                service.status.loading.remove("s");
            }

            stopLoading();
        };

        const trailingFetchValues = debounce(fetchValues, 750, { trailing: true });

        const unsubscribeSearch = service.search.value.subscribe(() => {
            service.status.loading.add("s");

            trailingFetchValues();
        });

        return () => {
            searching.current = null;
            trailingFetchValues.cancel();
            unsubscribeSearch();
        };
    }, []);

    return <TextField value={service.search} className={classes.root} variant="filled" />;
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            flexBasis: "18rem",
            flexGrow: 0,
            flexShrink: 1,
            marginLeft: "1rem",
            marginRight: "1rem",

            "& .MuiInputBase-input": {
                padding: "13px 12px 9px",
            },
        },
    }),
    { name: "SearchActivity" },
);
