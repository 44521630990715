import React from "react";
import {
    Service,
    Abon,
    Api,
    ENDPOINT,
    Template,
    TemplateType,
    TextValue,
    ErrorAlert,
    Translation,
    isEqual,
    CompletedTextValue,
    omit,
    SnackStack,
} from "lib";

import { TemplatesTable } from "./template-table";
import { TemplateImagesService } from "./template-images";

export class TemplateEditorService extends Service {
    templates: TemplatesTable;
    subjectEditor: Abon<any>;
    contentEditor: Abon<any>;

    name: TextValue;
    title: TextValue;
    id: CompletedTextValue<number>;
    canSave: Abon<boolean>;
    empty: Abon<boolean>;
    initialized: Abon<boolean>;
    snacks: SnackStack;
    images: TemplateImagesService;
    content: Abon<string>;
    contentLimit: Abon<boolean>;
    reset: Abon<boolean>;

    constructor(readonly props: TemplateEditorServiceProps = {}) {
        super();

        this.snacks = this.require(SnackStack);
        this.templates = this.require(TemplatesTable, this.type);
        this.initialized = new Abon(false);
        this.subjectEditor = new Abon(null);
        this.contentEditor = new Abon(null);
        this.reset = new Abon(false);
        this.contentLimit = new Abon(false);

        this.name = new TextValue({ label: "templateName", name: "template_name" });
        this.title = new TextValue({ placeholder: "emailTitle", name: "template_title" });
        this.id = new CompletedTextValue({
            placeholder: "selectTemplate",
            name: "template_id",
            useOptions: () => {
                this.templates.items.use();

                return this.templates.items.array.filter(Boolean).map((template) => {
                    return {
                        value: Number(template.id),
                        label: template.template_name,
                    };
                });
            },
        });
        this.content = new Abon("");

        this.onSubjectChange = this.onSubjectChange.bind(this);
        this.onContentChange = this.onContentChange.bind(this);
        this.save = this.save.bind(this);
        this.deleteSelected = this.deleteSelected.bind(this);
        this.onInitializing = this.onInitializing.bind(this);

        this.empty = Abon.from(
            () => !this.content.current,
            (listener) => [this.content.subscribe(listener)],
            () => {},
        );

        if (this.type === TemplateType.email) {
            this.images = this.require(TemplateImagesService, this);
        }

        this.canSave = Abon.from(
            () => {
                switch (this.type) {
                    case TemplateType.email:
                        if (this.empty.current || !this.name.current || !this.title.current) {
                            return false;
                        } else if (
                            this.id.selected.current &&
                            isEqual(this.templates.items.get(String(this.id.selected.current), "template_name"), this.name.current) &&
                            isEqual(this.templates.items.get(String(this.id.selected.current), "template_title"), this.title.current) &&
                            isEqual(this.templates.items.get(String(this.id.selected.current), "template_text"), this.content.current)
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    case TemplateType.sms:
                        if (this.empty.current || !this.name.current) {
                            return false;
                        } else if (
                            this.id.selected.current &&
                            isEqual(this.templates.items.get(String(this.id.selected.current), "template_name"), this.name.current) &&
                            isEqual(this.templates.items.get(String(this.id.selected.current), "template_text"), this.content.current)
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    default:
                        return false;
                }
            },
            (listener) => [
                this.title.value.subscribe(listener),
                this.name.value.subscribe(listener),
                this.id.selected.subscribe(listener),
                this.templates.items.subscribe(listener),
                this.empty.subscribe(listener),
                this.content.subscribe(listener),
            ],
            () => {},
        );

        this.id.selected.subscribe((id) => {
            this.select(id ? String(id) : undefined);
        });
    }

    async create(template: Omit<Template, "template_id" | "template_type">): Promise<Template | undefined> {
        const createTemplate: Omit<Template, "template_id"> = {
            template_type: this.type,
            ...template,
        };

        if (this.props.create) {
            return this.props.create(createTemplate);
        }

        const created = await Api.request(ENDPOINT.createTemplate, createTemplate);

        if (created && created.template_id) {
            await Api.request(ENDPOINT.updateTemplate, { ...createTemplate, template_id: created.template_id });

            await this.templates.refresh();

            const template = this.templates.items.get(String(created.template_id));

            if (template) {
                await this.select(template);
            }

            this.snacks.add({
                message: Translation.render({
                    id: this.type === TemplateType.sms ? "createdSMSTemplateX" : "createdMailTemplateX",
                    values: { x: createTemplate.template_name },
                }),
            });

            return template;
        }

        return undefined;
    }

    /** Saves the current template. If it has not yet been created, it will be. */
    async save() {
        if (!this.name.current) {
            this.name.addError("mustBeDefined");
            return;
        }

        const equalNameTemplate = this.templates.items.find((template) => template.template_name === this.name.current);
        if (equalNameTemplate && (!this.id.selected.current || String(this.id.selected.current) !== equalNameTemplate.id)) {
            this.name.addError("mustBeDefined");
            return;
        }

        const template: CurrentTemplate = {
            template_name: this.name.current,
            template_title: this.title.current,
            template_type: this.type,
            template_text: this.content.current,
            id: this.id.selected.current ? String(this.id.selected.current) : undefined,
        };

        try {
            if (template.id && equalNameTemplate) {
                await Api.request(ENDPOINT.updateTemplate, {
                    ...omit(template as Template, "id"),
                    template_id: template.id,
                });

                this.snacks.add({
                    message: Translation.render({
                        id: this.type === TemplateType.sms ? "savedSMSTemplateX" : "savedMailTemplateX",
                        values: { x: template.template_name },
                    }),
                });
            } else {
                await this.create(template as any);
            }
        } catch (error) {
            ErrorAlert.alert({ error });
        }
    }

    private selected: number;

    async select(id: string): Promise<void>;
    async select(template: CurrentTemplate): Promise<void>;
    async select(templateThunk: CurrentTemplate | string): Promise<void> {
        if (!templateThunk) {
            if (this.selected === undefined) {
                return;
            }
            this.selected = undefined;
            await this.id.set(undefined);
            await this.name.set(undefined);
            await this.title.set(undefined);
            await this.content.set(undefined);
            if (this.subjectEditor.current) {
                this.subjectEditor.current.resetContent();
            }
            if (this.contentEditor.current) {
                this.contentEditor.current.resetContent();
            }
            this.id.selected.set(undefined);
            return;
        }

        let template: CurrentTemplate;

        if (typeof templateThunk === "string") {
            if (this.selected === Number(templateThunk)) {
                return;
            }

            this.selected = Number(templateThunk);

            template = this.templates.items.get(templateThunk);

            if (!template) {
                await this.templates.fetch();
                template = this.templates.items.get(templateThunk);
            }
        } else if (templateThunk && typeof templateThunk === "object") {
            template = templateThunk;

            if (this.selected === Number(template.id)) {
                return;
            }
            this.selected = Number(template.id);
        }

        if (!template) {
            ErrorAlert.alert({ error: new Error(Translation.render("failedSelectingTemplate")) });
            return;
        } else if (template.template_type != null && template.template_type !== this.type) {
            ErrorAlert.alert({ error: new Error(Translation.render("incorrectTemplateType")) });
            return;
        }

        await this.name.set(template.template_name);
        await this.title.set(template.template_title);
        this.selected = Number(template.id);
        await this.id.set(template.id);
        await this.id.selected.set(this.selected);

        await Promise.resolve().then(() => {
            if (template.template_text) {
                this.content.set(template.template_text);
                if (this.subjectEditor.current) {
                    this.subjectEditor.current.setContent(template.template_title);
                }
                if (this.contentEditor.current) {
                    this.contentEditor.current.setContent(template.template_text);
                }
            } else {
                this.content.set(null);
                if (this.subjectEditor.current) {
                    this.subjectEditor.current.resetContent();
                }
                if (this.contentEditor.current) {
                    this.contentEditor.current.resetContent();
                }
            }
        });
    }

    async deleteSelected() {
        const selected = this.id.selected.current;

        if (!selected) {
            return;
        }

        try {
            await this.id.clear();
            this.select(undefined);

            await Api.request(ENDPOINT.deleteTemplate, { template_id: String(selected) });

            await this.templates.refresh();
        } catch (error) {
            ErrorAlert.alert({ error });
        }
    }

    onSubjectChange(title: string) {
        this.title.set(title);
    }

    onContentChange(content: string) {
        this.content.set(content);
    }

    onInitializing() {
        if (this.type === TemplateType.email) {
            if (
                this.contentEditor.current &&
                this.subjectEditor.current &&
                this.contentEditor.current.initialized &&
                this.subjectEditor.current.initialized
            ) {
                this.initialized.set(true);
            }
        } else if (this.type === TemplateType.sms) {
            if (this.contentEditor.current && this.contentEditor.current.initialized) {
                this.initialized.set(true);
            }
        }
    }

    get type() {
        return this.props.type || TemplateType.email;
    }
}

type CurrentTemplate = Omit<Template, "id"> & Partial<Pick<Template, "id">>;

export interface TemplateEditorServiceProps {
    type?: TemplateType;
    create?(template: Omit<Template, "id">): Promise<Template>;
}
