import React from "react";
import Button from "@material-ui/core/Button";
import { animated } from "react-spring";
import { TemplateEditorService } from "../../services";
import { StackItemProps, CardStack } from "lib/stack";
import SaveIcon from "@material-ui/icons/Save";
import { makeStyles, Translation, Abon, Typography } from "lib";

export function TemplateWithChanges(props: { onConfirm(): void; open: Abon<boolean>; service: TemplateEditorService }) {
    const { open = Abon.use(() => true), service } = props;

    CardStack.use(
        open.current,
        () => ({
            onClose: () => open.set(false),
            renderer: (stackProps) => <TemplateWithChangesModal {...stackProps} {...props} open={open} service={service} />,
        }),
        [open, props.onConfirm, props.service],
    );

    return null;
}

function TemplateWithChangesModal(props: TemplateWithChangesModalProps) {
    const { onConfirm, service, onClose, interpolations } = props;

    const t = Translation.useMap([
        "TemplateWithChangesModalTitle",
        "TemplateWithChangesModalDescription",
        "save",
        "dontSave",
        "cancel",
        "untitled",
    ]);
    const classes = useStyles();
    const canSave = service.canSave.use().current;

    const onSaveTemplateHandler = () => {
        onConfirm();
        service
            .save()
            .then((r) => {})
            .catch((e) => {});
    };

    return (
        <animated.div className={classes.paper} style={{ transform: interpolations.transform }}>
            <div className={classes.modalHeader}>
                <Typography variant="h5" className={classes.modalHeaderTitle}>
                    {t.render("TemplateWithChangesModalTitle")} &quot;{service.name.current || t.render("untitled")}&quot;?
                </Typography>
            </div>
            <div className={classes.modalContent}>
                <Typography variant="body1">{t.render("TemplateWithChangesModalDescription")}</Typography>
            </div>
            <div className={classes.modalFooter}>
                <Button
                    onClick={onSaveTemplateHandler}
                    disabled={!canSave}
                    startIcon={<SaveIcon />}
                    color={"primary"}
                    variant={"contained"}
                    className={classes.confirmButton}
                >
                    {t.render("save")}
                </Button>

                <Button onClick={onConfirm} color={"primary"} variant="outlined" className={classes.cancelButton}>
                    {t.render("dontSave")}
                </Button>
                <Button onClick={onClose} variant="outlined" className={classes.cancelButton}>
                    {t.render("cancel")}
                </Button>
            </div>
        </animated.div>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        paper: {
            width: "auto",
            maxWidth: 450,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3, 4),
            outline: 0,
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
        },
        modalHeader: {
            marginBottom: "1rem",
            paddingBottom: "0.25rem",
            borderBottom: `1px solid ${theme.palette.grey[700]}`,
        },
        modalHeaderTitle: {
            fontWeight: 500,
            fontSize: "0.9rem",
            letterSpacing: 0.5,
            color: theme.palette.grey[600],
            textTransform: "uppercase",
        },
        modalContent: {
            flex: 1,
            marginBottom: "2rem",
        },
        modalFooter: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "0 -0.5rem",
        },
        nameField: {
            flex: "1 1 auto",

            "& .MuiFilledInput-root": {
                borderRadius: 0,
                backgroundColor: "rgba(255, 255, 255, 0)",
            },

            "& .MuiFilledInput-underline:before": {
                borderBottom: "1px solid rgba(255, 255, 255, 1)",
            },
        },
        confirmButton: {
            fontWeight: 600,
            margin: "0.5rem",
        },
        cancelButton: {
            fontWeight: 600,
            margin: "0.5rem",
        },
    }),
    { name: "TemplateWithChangesModal" },
);

interface TemplateWithChangesProps {
    onConfirm(): void;
    open: Abon<boolean>;
    service: TemplateEditorService;
}
interface TemplateWithChangesModalProps extends StackItemProps, TemplateWithChangesProps {}
