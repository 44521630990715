import React from "react";
import { useService, Service } from "lib/utils";
import { Translation } from "lib/store";

import { StackItem } from "../types";

import { Stack, StackRemoveFn } from "./stack";

export class SnackStack extends Stack<Snack> {
    constructor() {
        super({
            unshift: true,
            variant: "snack",
            parse: (snack) => {
                if (snack.error) {
                    if (!snack.type) {
                        snack.type = "error";
                    }

                    if (!snack.errorStack) {
                        snack.errorStack = snack.error.stack;
                    }

                    if ((snack.error as any).crossDomain) {
                        snack.httpStatus = "CORS";
                    }

                    if (!snack.httpStatus && (snack.error as any).networkError) {
                        snack.httpStatus = 404;
                    }

                    if (!snack.message) {
                        if (snack.httpStatus) {
                            switch (snack.httpStatus) {
                                case 404: {
                                    snack.message = Translation.render("http404Error");
                                    break;
                                }
                                default: {
                                    break;
                                }
                            }
                        }

                        if (!snack.message) {
                            snack.message = snack.error.message;

                            if (typeof snack.message === "string") {
                                if (snack.message.length > SnackStack.messageMaxLength) {
                                    if (snack.message.includes("\n")) {
                                        const lines = snack.message.split("\n");
                                        let slices = "";

                                        let i = 0;
                                        let done = false;

                                        while (i !== lines.length && !done) {
                                            if (slices.length + lines[i].length <= SnackStack.messageMaxLength) {
                                                if (slices.length) {
                                                    slices = [slices, lines[i]].join("\n");
                                                } else {
                                                    slices = lines[i];
                                                }
                                            } else {
                                                done = true;
                                            }

                                            i++;
                                        }

                                        snack.message = slices;
                                    } else {
                                        snack.message =
                                            snack.message.substr(SnackStack.messageMaxLength, SnackStack.messageMaxLength - 3) + "...";
                                    }
                                }
                            }
                        }
                    }
                }
            },
        });
    }

    addError(error: Error) {
        console.error({ ...error });
        return this.add({ error });
    }

    usePusher(getItem: () => Snack, deps: readonly any[] = []): SnackPushFn {
        const pusher = React.useMemo(() => {
            let id = String(this.$idIndex++);

            const pushSnack: SnackPushFn = (() => {
                let item = getItem();

                if (item.id !== undefined) {
                    id = item.id;
                } else {
                    item = { ...item, id };
                }

                this.add(item);

                return item;
            }) as any;

            pushSnack.remove = () => this.remove(id);

            return pushSnack;
        }, [this, ...deps]);

        return pusher;
    }

    static usePusher(getItem: () => Snack, deps: readonly any[] = []): SnackPushFn {
        return useService(this).usePusher(getItem, deps);
    }

    static handle(service: Service, handler: (snacks: SnackStack) => void) {
        const snacks = service["require"](this);
        handler(snacks);
        service["forgo"](snacks);
    }

    static defaultPosition: SnackPosition = "bottom-left";
    static defaultHeight = 42;
    static spacing = 8;
    static defaultDuration = 3000;
    static messageMaxLength = 100;
}

export type SnackPosition = "top-left" | "top-right" | "bottom-right" | "bottom-left";

export interface Snack extends StackItem {
    /** The position to display the snack. Defaults to `bottom-left`. */
    position?: SnackPosition;
    /** The height of the the snack. Defaults to `46`. */
    height?: number | "auto";
    /** The width of the the snack. Defaults to `auto`. */
    width?: number | "auto";
    /** The total amount of time in milliseconds that the snack should be visible (excluding transitions). If `undefined`, default is `3000`. If `null`, it can only be closed manually. */
    duration?: number;
    /** If a symbol for an http status should be rendered. */
    httpStatus?: number | string;
    /** If there is an error stack. */
    errorStack?: string;
    /** Automatic parsing of an error. */
    error?: Error;
}

export interface SnackPushFn {
    (): StackRemoveFn;

    /** Removes the item from the stack if it has been pushed. */
    remove(): void;
}
