import { IsNumber, Max, Min, IsMilitaryTime } from "class-validator";
import { NOTIFICATION_DAY } from "lib";

export class NotificationSettingsProps {
    @IsNumber()
    @Min(0)
    @Max(9)
    notificationDay: NOTIFICATION_DAY;

    @IsMilitaryTime()
    notificationTime: string;
}
