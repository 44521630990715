import axios from "axios";
import { load as loadRecaptcha } from "recaptcha-v3";
import { FormService, ValueMetaMap, Api, ENDPOINT, ValueStore, Auth, RouterService, CheckboxValue, ROLE, Abon } from "lib";
import { logIn } from "auth/log-in/log-in.utils";

import { SignUpProps } from "./sign-up.props";

export class SignUpService extends FormService<SignUpProps> {
    static SUBMIT_ERROR = "$submit";

    router: RouterService;
    executeRecaptcha?: (action?: string) => Promise<string>;

    acceptTerms = new CheckboxValue({
        required: true,
    });

    role = new Abon<ROLE>();

    constructor(props: { role: ROLE }) {
        super({
            store: new ValueStore<SignUpProps>({
                type: SignUpProps,
                metaMap: new ValueMetaMap<SignUpProps>({
                    meta: {
                        name: {
                            label: "name",
                            required: true,
                        },
                        email: {
                            accept: "email",
                            label: "email",
                            required: true,
                        },
                        password: {
                            accept: "password",
                            label: "password",
                            required: true,
                        },
                        passwordVerification: {
                            accept: "password",
                            label: "passwordVerification",
                            required: true,
                        },
                    },
                }),
            }),

            onSubmit: async (props) => {
                if (props.password !== props.passwordVerification) {
                    this.store.addCombinationError("passwordVerification", "doesntMatch");
                    return;
                }

                const stopLoading = this.loading.start();

                let recaptchaValid: boolean;

                try {
                    const token = await loadRecaptcha("6LeP3sIUAAAAAG305-C04OZlnHrCWaGgCHG9pUSK").then((recaptcha) =>
                        recaptcha.execute("sign_up"),
                    );

                    const response = await axios({
                        method: "post",
                        url: "https://www.google.com/recaptcha/api/siteverify",
                        data: {
                            response: token,
                            /** This is obviously not safe — the secret should not be exposed in the client-side.
                             * To reduce load on the back-end developer, this is how it's done, for now.
                             * In the future, it would be better to do this whole request through the back-end. */
                            secret: "6LeP3sIUAAAAAMQluYjI0PyssHzVUqHp4aizYzcL",
                        },
                    });

                    recaptchaValid = !!response.data.success;
                } catch (error) {
                    console.error("Failed using ReCaptcha", error);
                    recaptchaValid = true;
                }

                if (recaptchaValid) {
                    try {
                        const response = await Api.request(ENDPOINT.createUser, {
                            name: props.name,
                            username: props.email,
                            password: props.password,
                            role: this.role.current,
                        });

                        if (response.user_id) {
                            this.store.addSuccess("successfullySignedUp", true);

                            await logIn({ email: props.email, password: props.password, token: null });
                        } else {
                            this.store.addError("unknownError");
                        }
                    } catch (error) {
                        if (error.response.data.error) {
                            if (error.response.data.error.includes("already")) {
                                this.store.addCombinationError("email", "userAlreadyExists");
                            } else {
                                this.store.addError("unknownError");
                            }
                        } else {
                            this.store.addError("unknownError");
                        }
                    }
                } else {
                    this.store.addError("failedHumanVerification");
                }

                stopLoading();
            },
        });

        this.role.set(props.role);

        this.router = this.require(RouterService);
    }
}
