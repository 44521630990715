import { AbonDeep, Abon, isEqual, UnsubscribeFn, get, Class } from "lib/utils";
import { validate, ValidationError } from "class-validator";
import { plainToClass } from "class-transformer";
import { Translation, TranslationThunk } from "lib/store";

import { ValueStateProps, ValueType } from "../value";
import { ValueMeta, ValueMetaThunk } from "../value-meta";
import { ValueMapProps, ValueMap, ValueMetaMap } from "../value-meta-map";
import { LoadingStatus, MessageStatus, Status } from "../status";

import { ValueStoreProps } from "./value-store.types";

export class ValueStore<T extends KeyObject> extends AbonDeep<T> {
    /** Describes an error that happened because of an incorrect input of a value. */
    static INPUT_ERROR_ID = "$input";
    /** Describes an error that happened because of an incorrect combination of values. */
    static COMBINATION_ERROR_ID = "$combination";

    values = new ValueMap<T>();

    status: Status;

    valueError: MessageStatus = new MessageStatus();
    initializing: LoadingStatus;
    submittable: Abon<boolean> = new Abon(false);
    missingRequired: Abon<boolean> = new Abon(true);
    missingChanges: Abon<boolean>;
    externalSubmittable: Abon<boolean> = new Abon(undefined);

    constructor(readonly props: ValueStoreProps<T>) {
        super(props.initial ? { ...props.initial } : ({} as any));

        this.props.initial = { ...props.initial };

        this.missingChanges = new Abon(!props.edit);
        this.status = props.status || new Status();
        this.initializing = props.initializing || new LoadingStatus();

        const setSubmittable = () => {
            this.submittable.set(
                this.externalSubmittable.current == null
                    ? !this.error.current &&
                          !this.valueError.current &&
                          !this.loading.current &&
                          !this.initializing.current &&
                          (!this.success.current || !this.finalSuccess) &&
                          !this.missingRequired.current &&
                          (!this.props.edit || !this.missingChanges.current)
                    : this.externalSubmittable.current,
            );
        };

        this.missingRequired.subscribe(setSubmittable);
        this.missingChanges.subscribe(setSubmittable);
        this.error.subscribe(setSubmittable);
        this.valueError.subscribe(setSubmittable);
        this.loading.subscribe(setSubmittable);
        this.success.subscribe(setSubmittable);
        this.externalSubmittable.subscribe(setSubmittable);

        this.initializing.subscribe((initializing) => {
            setSubmittable();

            if (initializing) {
                this.loading.add("$initializing");
            } else {
                this.loading.remove("$initializing");
            }
        });

        this.setInitialInput().then(() => {
            this.setValues(this.props.initial);
        });
    }

    setValueMeta<K extends keyof T>(key: K, metaThunk?: ValueMetaThunk<T[K]>) {
        this.props.metaMap.set(key, metaThunk);
        this.values.delete(key);
        return this.getValue(key);
    }

    async setMetaMap(metaMap: ValueMetaMap<T>) {
        if (this.props.metaMap === metaMap) {
            return;
        }

        this.props.metaMap = metaMap;

        const valueKeys = [...this.values.keys()];

        this.values.clear();

        valueKeys.forEach((key) => this.getValue(key));

        await this.setInitialValues(this.props.initial);
    }

    getValue<K extends keyof T>(key: K): ValueType<T[K]> {
        if (!this.values.has(key)) {
            if (!this.props.metaMap.meta.has(key)) {
                return;
            }

            const meta = this.props.metaMap.meta.get(key);

            if (meta && meta.initial !== undefined) {
                if (!this.props.initial) {
                    this.props.initial = {} as any;
                }

                if (this.props.initial[key] == null) {
                    this.props.initial = {
                        ...this.props.initial,
                        [key]: meta.initial,
                    };
                }
            }

            const value = this.props.metaMap.getValue(key, this.getValueProps(key));

            if (value) {
                this.values.set(key, value);
            }
        }

        return this.values.get(key) as ValueType<T[K]>;
    }

    getValues(props: Omit<ValueMapProps<T>, "getValueProps" | "getValue"> = {}) {
        return this.props.metaMap.getValues({
            ...props,
            getValue: this.getValue.bind(this),
        });
    }

    async setValue<K extends keyof T>(key: K, value: T[K]) {
        const valueInstance = this.getValue(key);

        if (valueInstance && valueInstance.external) {
            // some types of values get parsed and then set here
            await valueInstance.set(value as never);
        } else {
            this.set(key, value);
        }

        this.missingChanges.set(this.checkMissingChanges());
        this.missingRequired.set(await this.checkMissingRequired());
    }

    async setValues(values: { [K in keyof T]?: T[K] }) {
        const nonValues: (keyof T)[] = [];

        await Promise.all([
            Object.keys(values).map((key) => {
                const value = this.getValue(key);

                if (value && value.external) {
                    return value.set(values[key] as never);
                } else {
                    nonValues.push(key);
                }
            }),
        ]);

        if (nonValues.length) {
            const setValues = { ...this.current };

            nonValues.forEach((key) => {
                setValues[key] = values[key];
            });

            this.set(setValues);
        }

        this.missingChanges.set(this.checkMissingChanges());
        this.missingRequired.set(await this.checkMissingRequired());
    }

    protected getValueProps<K extends keyof T>(key: K) {
        const props: ValueStateProps<T[K]> = {
            name: String(key),
            getCurrent: () => this.get(key),
            useCurrent: () => this.use(key),
            setCurrent: (value) => {
                const checkMissingRequired = this.get(key) == null || value == null;

                if (this.missingErrorSubscriptions.has(key)) {
                    if (!isEqual(this.get(key), value)) {
                        this.missingErrorSubscriptions.get(key)();
                        this.missingErrorSubscriptions.delete(key);
                        this.values.get(key).error.remove(ValueStore.INPUT_ERROR_ID);
                    }
                }

                this.set(key, value);

                if (checkMissingRequired) {
                    this.checkMissingRequired().then((missingRequired) => this.missingRequired.set(missingRequired));
                }

                if (this.missingChanges.current) {
                    this.missingChanges.set(this.checkMissingChanges());
                }
            },
            onBlur: async () => {
                this.validateValue(key);

                this.missingChanges.set(this.checkMissingChanges());
                this.missingRequired.set(await this.checkMissingRequired());
            },
            parentContext: this as any,
        };

        const meta = this.props.metaMap.meta.get(key) as ValueMeta<T[K]>;

        if (!meta) {
            return props;
        }

        if (meta.type === "select" && meta.nullValue != null) {
            props.useCurrent = () => {
                const current = this.use(key);
                return current != null ? current : meta.nullValue;
            };

            props.getCurrent = () => {
                const current = this.get(key);
                return current != null ? current : meta.nullValue;
            };
        }

        return props;
    }

    private missingErrorSubscriptions = new Map<keyof T, UnsubscribeFn>();
    private combinationErrorSubscriptions = new Map<keyof T, UnsubscribeFn>();

    /** Validate the current state of the store. If the state is valid, `true` will be returned. */
    async validate() {
        if (this.checkMissingChanges() || (await this.checkMissingRequired())) {
            return false;
        }

        const types = this.types;

        if (types.length) {
            for (const errorSubsciption of this.missingErrorSubscriptions.values()) {
                errorSubsciption();
            }

            this.missingErrorSubscriptions.clear();

            const typeErrors = await this.typeErrors();

            // const errors = await validate(plainToClass(this.props.type, this.current || {}));

            // const parsedErrors = errors
            //     .map((error) => {
            //         const value = this.values.get(error.property);

            //         return {
            //             error,
            //             value,
            //         };
            //     })
            //     .filter((props) =>
            //         !props.value ? false : props.value.valueProps.readOnly ? false : true,
            //     );

            const errorValues = typeErrors.map((props) => props.value);

            for (const value of this.values.values()) {
                if (!errorValues.includes(value)) {
                    value.error.remove(ValueStore.INPUT_ERROR_ID);
                }
            }

            typeErrors.forEach(({ error, value }) => {
                this.addValidationError(value, error);
            });

            if (typeErrors.length) {
                return false;
            }
        }

        if (this.props.validate) {
            const valid = await this.props.validate(this.current || ({} as any), this);

            if (valid !== true) {
                return false;
            }
        }

        return true;
    }

    async validateValue<K extends keyof T>(key: K) {
        const types = this.types;

        if (types.length) {
            if (this.missingErrorSubscriptions.has(key)) {
                this.missingErrorSubscriptions.get(key)();
                this.missingErrorSubscriptions.delete(key);
            }

            const typeErrors = await this.typeErrors();
            const typeError = typeErrors.find(({ error: _error }) => _error.property === key);

            if (typeError) {
                this.addValidationError(typeError.value, typeError.error);
                return false;
            } else {
                const value = this.getValue(key);
                value.error.remove(ValueStore.INPUT_ERROR_ID);
                return true;
            }
        }

        return true;
    }

    addValidationError<K extends keyof T>(value: ValueType<T[K]>, error: ValidationError) {
        if (value.current == null) {
            this.addInputError(value.valueProps.name, Translation.get("mustBeDefined"));
        } else if (error.constraints.isNumber) {
            this.addInputError(value.valueProps.name, Translation.get("mustBeANumber"));
        } else if (error.constraints.isEmail) {
            this.addInputError(value.valueProps.name, Translation.get("invalidEmail"));
        } else if (error.constraints.minLength) {
            this.addInputError(
                value.valueProps.name,
                Translation.intl.formatMessage(
                    {
                        id: "mustBeAtLeastXCharacters",
                    },
                    {
                        x: error.constraints.minLength.split("to ")[1].split(" ")[0],
                    },
                ),
            );
        } else if (error.constraints.maxLength) {
            this.addInputError(
                value.valueProps.name,
                Translation.intl.formatMessage(
                    {
                        id: "mustBeLessThanXCharacters",
                    },
                    {
                        x: error.constraints.maxLength.split("to ")[1].split(" ")[0],
                    },
                ),
            );
        } else {
            const foundTranslation = Object.keys(error.constraints)
                .map((constraint) => error.constraints[constraint])
                .find((message) => Translation.has(message));

            if (foundTranslation) {
                this.addInputError(value.valueProps.name, Translation.get(foundTranslation));
            } else {
                console.warn("Unknown validation error", { error, value });

                this.addInputError(value.valueProps.name);
            }
        }
    }

    addCombinationError<K extends keyof T>(key: K, error: TranslationThunk) {
        if (this.values.has(key)) {
            const value = this.values.get(key);

            value.error.add(ValueStore.COMBINATION_ERROR_ID, error ? Translation.get(error) : undefined);

            if (!this.combinationErrorSubscriptions.has(key)) {
                const propsSubscription = this.subscribe(() => {
                    value.error.remove(ValueStore.COMBINATION_ERROR_ID);
                    this.combinationErrorSubscriptions.delete(key);
                    propsSubscription();
                });

                this.combinationErrorSubscriptions.set(key, propsSubscription);
            }
        }
    }

    addInputError<K extends keyof T>(key: K, error?: TranslationThunk) {
        if (this.values.has(key)) {
            const value = this.values.get(key);

            value.error.add(ValueStore.INPUT_ERROR_ID, error ? Translation.get(error) : undefined);

            if (!this.missingErrorSubscriptions.has(value.valueProps.name)) {
                // if the state of the value changes, remove the trace of the error pushed from this execution
                const unsubscribeValueError = value.value.subscribe(() => {
                    value.error.remove(ValueStore.INPUT_ERROR_ID);
                    this.missingErrorSubscriptions.delete(value.valueProps.name);
                    unsubscribeValueError();
                });

                this.missingErrorSubscriptions.set(value.valueProps.name, unsubscribeValueError);
            }
        }
    }

    private globalErrorSubscription: UnsubscribeFn;

    addError(error: TranslationThunk) {
        this.error.add(ValueStore.COMBINATION_ERROR_ID, error ? Translation.get(error) : undefined);

        if (!this.globalErrorSubscription) {
            this.globalErrorSubscription = this.subscribe(() => {
                this.error.remove(ValueStore.COMBINATION_ERROR_ID);
                this.globalErrorSubscription();
            });
        }
    }

    private globalSuccessSubscription: UnsubscribeFn;
    private finalSuccess: boolean;

    addSuccess(message?: TranslationThunk, final?: boolean) {
        this.finalSuccess = final;

        this.success.add(ValueStore.COMBINATION_ERROR_ID, message ? Translation.get(message) : undefined);

        if (!this.globalSuccessSubscription) {
            this.globalSuccessSubscription = this.subscribe(() => {
                this.success.remove(ValueStore.COMBINATION_ERROR_ID);
                this.globalSuccessSubscription();
            });
        }

        this.checkMissingRequired().then((missingRequired) => this.missingRequired.set(missingRequired));
    }

    /** Validate the current state of the store. If the state is valid, `true` will be returned. */
    async checkMissingRequired() {
        const types = this.types;

        if (types.length) {
            const typeErrors = await this.typeErrors((props) =>
                !props.value ? false : !(props.value.valueProps.name.includes("$") && props.value.valueProps.ignoreChange !== false),
            );

            const missingValues = typeErrors.map((props) => props.value).filter((value) => value.current == null);

            return !!missingValues.length;
        }

        return false;
    }

    // protected async checkMissingRequiredOfType(type = this.props.type): Promise<boolean> {
    //     if (!type) {
    //         return false;
    //     }

    //     const errors = await validate(plainToClass(type, this.current || {}));

    //     const parsedErrors = errors
    //         .map((error) => {
    //             const value = this.values.get(error.property);

    //             return {
    //                 error,
    //                 value,
    //             };
    //         })
    //         .filter((props) =>
    //             !props.value
    //                 ? false
    //                 : !(
    //                       props.value.valueProps.name.includes("$") &&
    //                       props.value.valueProps.ignoreChange !== false
    //                   ),
    //         );

    //     const missingValues = parsedErrors
    //         .map((props) => props.value)
    //         .filter((value) => value.current == null);

    //     return !!missingValues.length;
    // }

    checkMissingChanges() {
        if (!this.props.edit) {
            return false;
        }

        const changed = [...this.values.keys()].filter(
            (key) =>
                !(
                    ((key as any).includes("$") && this.values.get(key).valueProps.ignoreChange !== false) ||
                    this.values.get(key).valueProps.readOnly
                ) && !isEqual(get(this.props.initial, key), this.get(key)),
        );

        this.missingChanges.set(!changed.length);

        return !changed.length;
    }

    get error() {
        return this.status.error;
    }

    get loading() {
        return this.status.loading;
    }

    get success() {
        return this.status.success;
    }

    clear() {
        super.set(this.props.initial);
        this.loading.clear();
        this.initializing.clear();
        this.valueError.clear();
        this.setInitialInput();
    }

    async setInitialValues(initial: T) {
        this.setValues(initial);
        await this.setInitialInput(this.current);
    }

    async setInitialInput(initial?: T) {
        if (initial) {
            this.props.initial = { ...initial };
        }

        if (this.props.edit) {
            this.missingChanges.set(true);
        }

        // this is done to set the `input` value to `true` if there are no
        // required values that are undefined
        let foundMissingInput: boolean;

        if (!this.props.initial) {
            this.props.initial = {} as any;
        }

        for (const [key, meta] of this.props.metaMap.meta.entries()) {
            const value = this.getValue(key);

            if (value != null) {
                this.values.set(key, value);

                if (value.current !== undefined) {
                    if (this.props.initial[key] == null) {
                        this.props.initial = {
                            ...this.props.initial,
                            [key]: value.current as any,
                        };
                    }

                    this.current = { ...this.props.initial };
                }

                value.error.message.subscribe((error) => {
                    if (error != null) {
                        this.valueError.add(key as any, error);
                    } else {
                        this.valueError.remove(key as any);
                    }
                });
            } else if (meta.initial != null) {
                if (this.props.initial[key] == null) {
                    this.props.initial = {
                        ...this.props.initial,
                        [key]: meta.initial,
                    };
                }

                this.current = { ...this.props.initial };
            }

            if (meta.required && (this.current == null || this.current[key] == null)) {
                foundMissingInput = true;
            }
        }

        this.missingRequired.set(!!foundMissingInput);

        return this.checkMissingRequired().then((missingRequired) => this.missingRequired.set(missingRequired).current);
    }

    async typeErrors(filter?: (parsedError: { error: ValidationError; value: ValueType<T[keyof T]> }) => boolean) {
        const types = this.types;

        const errorsList = await Promise.all(
            types.map(async (type: Class<T>) => {
                const errors = await validate(plainToClass(type, this.current || {}));

                let parsedErrors = errors
                    .map(
                        (error): ParsedError<T> => {
                            const value = this.values.get(error.property);

                            return {
                                error,
                                value,
                            };
                        },
                    )
                    .filter((props) => (!props.value ? false : props.value.valueProps.readOnly ? false : true));

                if (filter) {
                    parsedErrors = parsedErrors.filter(filter as any);
                }

                return {
                    errors: parsedErrors,
                    type,
                };
            }),
        );

        if (errorsList.some(({ errors }) => errors.length === 0)) {
            return [];
        }

        const errorsListSorted = errorsList.sort((a, b) => {
            if (types.indexOf(b.type) > types.indexOf(a.type)) {
                return 1;
            } else {
                return -1;
            }
        });

        return errorsListSorted[0].errors;
    }

    subscribeValueBlurChange<K extends keyof T>(key: K, callback: (value: T[K]) => any): UnsubscribeFn {
        const value = this.getValue(key);

        const initialOnBlur = value.valueProps.onBlur;
        let previous: T[K];

        value.valueProps.onBlur = () => {
            if (!isEqual(this.props.initial[key], value.current) && !isEqual(previous, value.current)) {
                previous = value.current as any;
                callback(previous);
            }

            return initialOnBlur();
        };

        return () => {
            value.valueProps.onBlur = initialOnBlur;
            return true;
        };
    }

    get types(): Class<T>[] {
        const types = this.props.types || [];

        if (this.props.type && !types.includes(this.props.type)) {
            types.push(this.props.type);
        }

        return types as any;
    }
}

interface ParsedError<T extends KeyObject> {
    error: ValidationError;
    value: ValueType<T[keyof T]>;
}
