import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Box, makeStyles, Translation, Tooltip, Abon } from "lib";

import { TemplateReceiversService } from "../../services";

export function TemplateReceiversMover(props: TemplateReceiversMoverProps) {
    const { receivers } = props;

    const c = useStyles();
    const t = Translation.useMap(["addAll", "addSelected", "removeAll", "removeSelected"]);

    const hasAnySelectable = Abon.useComposedValue(
        () => !!receivers.selectable.ids.current.length,
        (listener) => [receivers.selectable.ids.subscribe(listener)],
        [receivers],
    );
    const selectedAnySelectable = Abon.useComposedValue(
        () => !!receivers.selectable.selectedIds.current.length,
        (listener) => [receivers.selectable.selectedIds.subscribe(listener)],
        [receivers],
    );
    const selectedAnySelected = Abon.useComposedValue(
        () => !!receivers.selected.selectedIds.current.length,
        (listener) => [receivers.selected.selectedIds.subscribe(listener)],
        [receivers],
    );
    const hasAnySelected = Abon.useComposedValue(
        () => !!receivers.selected.ids.current.length,
        (listener) => [receivers.selected.ids.subscribe(listener)],
        [receivers],
    );

    const handleAllRight = React.useCallback(() => {
        receivers.selected.unshift(...receivers.selectable.array);
        receivers.selectable.set([]);
    }, [receivers]);

    const handleCheckedRight = React.useCallback(() => {
        const selectedIds = receivers.selectable.selectedIds.current;
        const selected = selectedIds.map((id) => receivers.selectable.get(id));
        receivers.selectable.delete(...selectedIds);
        receivers.selected.unshift(...selected);
    }, [receivers]);

    const handleCheckedLeft = React.useCallback(() => {
        const selectedIds = receivers.selected.selectedIds.current;
        const selected = selectedIds.map((id) => receivers.selected.get(id));
        receivers.selected.delete(...selectedIds);
        receivers.selectable.unshift(...selected);
    }, [receivers]);

    const handleAllLeft = React.useCallback(() => {
        receivers.selectable.push(...receivers.selected.array);
        receivers.selected.set([]);
    }, [receivers]);

    return (
        <Box className={c.root}>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Tooltip title={t.render("addAll")}>
                        <span>
                            <Button
                                variant="outlined"
                                size="small"
                                className={c.button}
                                onClick={handleAllRight}
                                disabled={!hasAnySelectable}
                                aria-label={t.render("addAll")}
                            >
                                ≫
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip title={t.render("addSelected")}>
                        <span>
                            <Button
                                variant="outlined"
                                size="small"
                                className={c.button}
                                onClick={handleCheckedRight}
                                disabled={!selectedAnySelectable}
                                aria-label={t.render("addSelected")}
                            >
                                &gt;
                            </Button>
                        </span>
                    </Tooltip>
                    <div className={c.divider} />
                    <Tooltip title={t.render("removeSelected")}>
                        <span>
                            <Button
                                variant="outlined"
                                size="small"
                                className={c.button}
                                onClick={handleCheckedLeft}
                                disabled={!selectedAnySelected}
                                aria-label={t.render("removeSelected")}
                            >
                                &lt;
                            </Button>
                        </span>
                    </Tooltip>
                    <Tooltip title={t.render("removeAll")}>
                        <span>
                            <Button
                                variant="outlined"
                                size="small"
                                className={c.button}
                                onClick={handleAllLeft}
                                disabled={!hasAnySelected}
                                aria-label={t.render("removeAll")}
                            >
                                ≪
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
        </Box>
    );
}
export interface TemplateReceiversMoverProps {
    receivers: TemplateReceiversService;
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            flex: "0 0auto",
            width: "10rem",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            overflow: "hidden",
        },
        button: {
            margin: theme.spacing(0.5, 0),
            padding: "1px 0 0 0",
            fontSize: 20,
            fontWeight: 900,
        },
        divider: {
            margin: theme.spacing(1, 0),
        },
    }),
    { name: "TemplateReceiversMover" },
);

export interface TemplateReceiversProps {
    receivers: TemplateReceiversService;
}
