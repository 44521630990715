import { FormService, ValueMetaMap, Translation, Api, ENDPOINT, ValueStore } from "lib";
import "reflect-metadata";
import { IsEmail } from "class-validator";

export class InviteUserService extends FormService<InviteUserProps> {
    constructor() {
        super({
            store: new ValueStore<InviteUserProps>({
                type: InviteUserProps,
                metaMap: new ValueMetaMap<InviteUserProps>({
                    meta: {
                        email: { label: "email", accept: "email", required: true },
                    },
                }),
            }),

            onSubmit: async (props) => {
                const stopLoading = this.loading.start();

                Api.request<ENDPOINT.createUserInvitation>(ENDPOINT.createUserInvitation, {
                    receiver_email: props.email,
                })
                    .then((response) => {
                        this.store.addSuccess("userInvited");
                    })
                    .catch((error) => {
                        this.store.addError("unknownError");
                    })
                    .finally(() => {
                        stopLoading();
                    });
            },
        });
    }
}

export class InviteUserProps {
    @IsEmail()
    email: string;
}
