import { FormService, ValueMetaMap } from "lib";
import { Api, ENDPOINT } from "lib/store";

import { ResetPasswordProps } from "./reset-password.props";

export class ResetPasswordService extends FormService<ResetPasswordProps> {
    constructor() {
        super({
            type: ResetPasswordProps,
            metaMap: new ValueMetaMap<ResetPasswordProps>({
                meta: {
                    password: {
                        accept: "password",
                        label: "password",
                        required: true,
                    },
                    passwordVerification: {
                        accept: "password",
                        label: "passwordVerification",
                        required: true,
                    },
                },
            }),

            onSubmit: async (props) => {
                if (props.password !== props.passwordVerification) {
                    this.store.addCombinationError("passwordVerification", "doesntMatch");
                    return;
                }

                this.store.success.remove("submit");
                const stopLoading = this.store.loading.start();

                try {
                    const response = await Api.request(ENDPOINT.confirmResetPassword, {
                        user_id: props.user_id,
                        password: props.password,
                        token: props.token,
                    });

                    if (response.success) {
                        this.store.set(this.store.props.initial);

                        this.store.addSuccess("newPasswordSuccess");
                    } else {
                        this.store.addError("unknownError");
                    }
                } catch (error) {
                    if (!error.response.data.message) {
                        this.store.addError("unknownError");
                    } else if (error.response.data.message.includes("not registered")) {
                    } else {
                        this.store.addError("unknownError");
                    }
                } finally {
                    stopLoading();
                }
            },
        });
    }
}
