import React from "react";
import { Abon, uniqueString, pick, omit } from "lib/utils";
import { Loading } from "lib/store/loading";

import { StatusProps } from "./status.types";
import { MessageStatus } from "./status.message";
import { LoadingStatus } from "./status.loading";

export class Status {
    static useStatus(props: StatusProps) {
        const status = React.useMemo(() => new Status(props), []);

        React.useEffect(() => () => status.clear(), []);

        return status;
    }

    static selectStatusProps<T extends StatusProps>(
        props: T,
    ): Omit<T, keyof StatusProps> & {
        statusProps: StatusProps;
    } {
        return {
            ...omit(props, "loading", "id"),
            statusProps: pick(props, "loading", "id"),
        };
    }

    protected statusProps: StatusProps;

    error = new MessageStatus();
    silentError = new MessageStatus();
    success = new MessageStatus();
    loading: LoadingStatus;

    constructor(props: StatusProps = {}) {
        this.statusProps = props;

        this.statusProps.id = this.statusProps.id || uniqueString();

        if (this.statusProps.loading instanceof LoadingStatus) {
            this.loading = this.statusProps.loading;
        } else {
            this.loading = new LoadingStatus();

            this.loading.subscribe((loading) => {
                if (loading) {
                    Loading.add(this.statusProps.id, (this.statusProps.loading as any) || { linear: "query" });
                } else {
                    Loading.delete(this.statusProps.id);
                }
            });
        }
    }

    use() {
        const loading = this.loading.use().current;
        const success = this.success.message.use().current;
        const error = this.error.message.use().current;
        const errorBoolean = this.error.use().current;
        const silentError = this.silentError.message.use().current;

        return {
            loading,
            success,
            error: error,
            invalid: !!error || !!silentError || errorBoolean,
        };
    }

    clear() {
        this.error.clear();
        this.success.clear();
        this.loading.clear();
    }
}
