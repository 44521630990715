import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import TimeIcon from "@material-ui/icons/AccessTimeTwoTone";
import EyeIcon from "@material-ui/icons/RemoveRedEye";
import LocationIcon from "@material-ui/icons/LocationOn";
import { makeStyles, Translation } from "lib";

export function LinkIpSymbol() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <p className={classes.label}>IP</p>
            <p className={classes.detail}>http://193.148.0.1</p>
            <EyeIcon className={classes.icon} />
        </div>
    );
}

export function LinkTimeSymbol() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <p className={classes.label}>{Translation.render("time")}</p>
            <TimeIcon className={classes.icon} />
        </div>
    );
}

export function LinkReferrerSymbol() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <p className={classes.label}>{Translation.render("referrer")}</p>
            <div className={classes.iconRow}>
                <SvgIcon viewBox="0 0 18 18">
                    <path
                        d={
                            "M8.546 16V9.804H6.46V7.39h2.086V5.607c0-2.066 1.262-3.19 3.106-3.19.883 " +
                            "0 1.642.064 1.863.094v2.16h-1.28c-1 0-1.195.48-1.195 1.18v1.54h2.39l-.31 2.42" +
                            "h-2.08V16"
                        }
                    />
                </SvgIcon>
                <SvgIcon viewBox="0 0 20 20">
                    <path
                        style={{ transform: "translate(1.5px, 3px)" }}
                        d={
                            "M16 3.038c-.59.26-1.22.437-1.885.517.677-.407 1.198-1.05 1.443-1.816-.634.37-1.337.64" +
                            "-2.085.79-.598-.64-1.45-1.04-2.396-1.04-1.812 0-3.282 1.47-3.282 3.28 0 .26.03.51.085.75-2.728" +
                            "-.13-5.147-1.44-6.766-3.42C.83 2.58.67 3.14.67 3.75c0 1.14.58 2.143 1.46 2.732-.538-.017-1.045" +
                            "-.165-1.487-.41v.04c0 1.59 1.13 2.918 2.633 3.22-.276.074-.566.114-.865.114-.21 0-.41-.02-.61" +
                            "-.058.42 1.304 1.63 2.253 3.07 2.28-1.12.88-2.54 1.404-4.07 1.404-.26 0-.52-.015-.78-.045 1.46" +
                            ".93 3.18 1.474 5.04 1.474 6.04 0 9.34-5 9.34-9.33 0-.14 0-.28-.01-.42.64-.46 1.2-1.04 1.64-1.7z"
                        }
                    />
                </SvgIcon>
                <SvgIcon viewBox="0 0 16 16">
                    <path
                        d={
                            "M13.632 13.635h-2.37V9.922c0-.886-.018-2.025-1.234-2.025-1.235 0-1.424.964" +
                            "-1.424 1.96v3.778h-2.37V6H8.51v1.04h.03c.318-.6 1.092-1.233 2.247-1.233 2.4 0 " +
                            "2.845 1.58 2.845 3.637v4.188zM3.558 4.955c-.762 0-1.376-.617-1.376-1.377 0-.758" +
                            ".614-1.375 1.376-1.375.76 0 1.376.617 1.376 1.375 0 .76-.617 1.377-1.376 1.377z" +
                            "m1.188 8.68H2.37V6h2.376v7.635z"
                        }
                    />
                </SvgIcon>
            </div>
        </div>
    );
}

export function LinkLocationSymbol() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <p className={classes.label}>{Translation.render("location")}</p>
            <LocationIcon className={classes.icon} />
        </div>
    );
}

const useStyles = makeStyles(
    {
        root: {
            backgroundColor: "rgba(0,0,0,.1)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            float: "left",
            width: 80,
            height: 80,
            overflow: "hidden",
            borderRadius: "100%",
            position: "relative",
            marginLeft: -12,
            marginRight: -32,

            "& > p": {
                marginBlockStart: 0,
                marginBlockEnd: 0,
                textAlign: "center",
                lineHeight: 1,
            },
        },

        label: {
            fontWeight: 600,
            fontSize: 13,
            letterSpacing: 0,
            paddingTop: 4,
        },
        detail: {
            paddingTop: 4,
            fontSize: 8,
            opacity: 0.6,
        },
        icon: {
            width: 72,
            height: 72,
            opacity: 0.05,
            position: "absolute",
        },
        iconRow: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 3,
            marginBottom: -6,

            "& > svg": {
                width: 12,
                height: 12,
                opacity: 0.2,
            },
        },
    },
    { name: "LinkStatisticsSymbol" },
);
