import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { KeyboardTimePicker, TimePicker } from "@material-ui/pickers";
import { uniqueString } from "lib/utils";
import { makeStyles, clsx } from "lib/material";

import { TimeFieldProps } from "./time-field.types";

export const TimeField = React.forwardRef<HTMLInputElement, TimeFieldProps>((props: TimeFieldProps, ref) => {
    const {
        value,
        autoComplete,
        autoCorrect,
        spellCheck,
        error,
        skeleton,
        className,
        name = value.valueProps.name,
        helperText: explicitHelperText,
        id: passedId,
        fullWidth,
        disabled,
        placeholder,
        ...otherProps
    } = props;

    if (!value) {
        throw new Error(`If no "value" is passed, you should use the select field exported directly from @material-ui/core/Select.`);
    }

    const id = React.useMemo(() => passedId || `sf-${value.valueProps.name || uniqueString()}`, [passedId]);
    const c = useStyles();
    const usedProps = value.use();

    const helperText = usedProps.error || usedProps.success || explicitHelperText;

    if (skeleton) {
        return <Skeleton key={"_skeleton"} height={36} />;
    }

    return (
        <KeyboardTimePicker
            {...otherProps}
            className={clsx(c.field, className)}
            readOnly={usedProps.readOnly}
            variant="inline"
            inputRef={ref}
            id={id}
            onChange={value.onChange}
            format={value.format}
            ampm={false}
            // views={
            //     value.format && value.format.includes("ss")
            //         ? ["hours", "minutes", "seconds"]
            //         : undefined
            // }
            label={usedProps.label}
            placeholder={placeholder}
            error={!!error}
            disabled={disabled || (usedProps ? usedProps.loading || usedProps.readOnly : undefined)}
            value={usedProps.formatted}
            helperText={helperText}
            fullWidth={fullWidth}
        />
    );
});

const useStyles = makeStyles(() => ({
    field: {
        flexGrow: 1,
        caretColor: "black",
        marginBottom: -5,
    },
}));
