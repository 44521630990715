import React from "react";
import Tabs from "@material-ui/core/Tabs";
import { Switch, Route, RouteProps, Redirect } from "react-router-dom";
import { useTheme, makeStyles, Translation, useLocation, TabLink, Container, Paper, Box } from "lib";
import { MarketingEmail } from "./marketing-email";
import { MarketingSMS } from "./marketing-sms";
import { MarketingEmailActivity } from "./marketing-email-activity";
import { MarketingSMSActivity } from "./marketing-sms-activity";
import { MarketingUnsubscriptions } from "./marketing-unsubscriptions";

export default function Marketing() {
    const theme = useTheme();
    const classes = useStyles();
    const t = Translation.useMap([
        "mailCampaigns",
        "smsCampaigns",
        "campaignsEmailActivity",
        "campaignsSMSActivity",
        "campaignsUnsubscriptions",
    ]);

    const {
        location: { pathname },
    } = useLocation();

    const value = "/" + pathname.split("/")[2];
    return (
        <Container className={classes.root}>
            <Paper className={classes.paper}>
                <Tabs
                    value={["/email-activity", "/sms-activity", "/email", "/sms", "/unsubscriptions"].includes(value) ? value : false}
                    onChange={() => {}}
                    scrollButtons="on"
                    indicatorColor="primary"
                    className={classes.tabs}
                >
                    <TabLink value="/email" to="/marketing/email" label={t.render("mailCampaigns")} />
                    <TabLink value="/sms" to="/marketing/sms" label={t.render("smsCampaigns")} />
                    <TabLink value="/email-activity" to="/marketing/email-activity" label={t.render("campaignsEmailActivity")} />
                    <TabLink value="/sms-activity" to="/marketing/sms-activity" label={t.render("campaignsSMSActivity")} />
                    <TabLink value="/unsubscriptions" to="/marketing/unsubscriptions" label={t.render("campaignsUnsubscriptions")} />
                </Tabs>

                <Box className={classes.children}>
                    <Switch>
                        <Route<RouteProps> exact path="/marketing/email" component={MarketingEmail} />
                        <Route<RouteProps> exact path="/marketing/sms" component={MarketingSMS} />
                        <Route<RouteProps> exact path="/marketing/email-activity" component={MarketingEmailActivity} />
                        <Route<RouteProps> exact path="/marketing/email-activity/:messageId" component={MarketingEmailActivity} />
                        <Route<RouteProps> exact path="/marketing/sms-activity" component={MarketingSMSActivity} />
                        <Route<RouteProps> exact path="/marketing/sms-activity/:messageId" component={MarketingSMSActivity} />
                        <Route<RouteProps> exact path="/marketing/unsubscriptions" component={MarketingUnsubscriptions} />

                        <Redirect to="/marketing/email" />
                    </Switch>
                </Box>
            </Paper>
        </Container>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: "flex",
            flexGrow: 1,
            flexShrink: 0,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            paddingTop: "3rem",
            paddingBottom: "3rem",

            [theme.breakpoints.down("sm")]: {
                paddingTop: "2rem",
                paddingBottom: "2rem",
            },
        },

        paper: {
            backgroundColor: "rgba(42, 42, 42, 0.85)",
            width: "100%",
            maxWidth: "100%",

            [theme.breakpoints.down("md")]: {
                height: "auto",
            },
        },
        tabs: {
            flexGrow: 0,
            flexShrink: 0,
            "& .MuiTab-root, a": {
                paddingTop: "0.6rem",
            },

            borderBottom: `1px solid ${theme.palette.grey[800]}`,
        },
        row: {
            width: "100%",
            display: "flex",
            alignSelf: "flex-start",
            marginBottom: "1.5rem",
        },
        children: {},
    }),
    { name: "Marketing" },
);
