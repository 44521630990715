import React from "react";
import { useTransition } from "react-spring";
import merge from "lodash/merge";

import { ParsedStackItem } from "../services/stack";
import { CardStack } from "../services";

import { StackProviderTransitionerProps, StackProviderItemProps } from "./stack-provider";
import { StackProviderCardItem } from "./stack-provider.card-item";

export function StackProviderCardTransitioner(props: StackProviderTransitionerProps<CardStack>) {
    const { stack } = props;

    const items = stack.state.use().items;

    const interpolations = {
        opacity: useTransition<ParsedStackItem<CardStack>, { opacity: number }>(items, (item) => item.id, {
            from: { opacity: 0 },
            enter: { opacity: 1 },
            leave: { opacity: 0 },
            config: (item: ParsedStackItem<CardStack>) =>
                merge({ tension: 450, friction: 35 }, item.interpolation, item.interpolations && item.interpolations.opacity),
        } as any),
        progress: useTransition<ParsedStackItem<CardStack>, { progress: number }>(items, (item) => item.id, {
            from: { progress: -1 },
            enter: { progress: 0 },
            leave: { progress: 1 },
            config: (item: ParsedStackItem<CardStack>) =>
                merge({ tension: 300, friction: 50 }, item.interpolation, item.interpolations && item.interpolations.progress),
        } as any),
        transform: useTransition<ParsedStackItem<CardStack>, { transform: string }>(items, (item) => item.id, {
            from: { transform: "translateY(-100vh)" },
            enter: { transform: "translateY(0vh)" },
            leave: { transform: "translateY(100vh)" },
            config: (item: ParsedStackItem<CardStack>) =>
                merge({ tension: 450, friction: 35, mass: 0.2 }, item.interpolation, item.interpolations && item.interpolations.transform),
        } as any),
    };

    const transitions: Omit<StackProviderItemProps<CardStack>, keyof StackProviderTransitionerProps<CardStack>>[] = interpolations.progress
        .map((progress) => {
            const transform = interpolations.transform.find((interpolation) => interpolation.item.id === progress.item.id);
            const opacity = interpolations.opacity.find((interpolation) => interpolation.item.id === progress.item.id);

            return {
                item: progress.item,
                key: progress.key,
                state: progress.state,
                progress,
                transform,
                opacity,
            };
        })
        .filter((itemInterpolations) =>
            [itemInterpolations.progress, itemInterpolations.transform, itemInterpolations.opacity].every((value) => !!value),
        )
        .map(
            ({
                progress,
                transform,
                opacity,
                item,
                state,
                key,
            }): Omit<StackProviderItemProps<CardStack>, keyof StackProviderTransitionerProps<CardStack>> => {
                return {
                    key,
                    state,
                    item,
                    interpolations: {
                        opacity: opacity ? opacity.props.opacity : undefined,
                        transform: transform ? transform.props.transform : undefined,
                        progress: progress.props.progress,
                        fraction: progress.props.progress.interpolate((progress: number) => (progress < 0 ? 1 - progress : progress)),
                    },
                };
            },
        );

    return (
        <>
            {transitions.map((transition) => (
                <StackProviderCardItem {...transition} key={transition.key} {...props} />
            ))}
        </>
    );
}
