import React from "react";
import FilterIcon from "@material-ui/icons/FilterList";
import { useService, makeStyles, Translation, TableCellComponentProps, Tooltip, Abon } from "lib";
import { HomeService } from "home/service";
import { Project } from "home/shared";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";

export function ProjectFilterCell(props: TableCellComponentProps<Project, "id", "id" | "project_name">) {
    const classes = useStyles();
    const t = Translation.useMap(["filterUrlasByProject", "stopFilteringUrlasByProject"]);
    const home = useService(HomeService);

    const checked = Abon.useComposedValue(
        () => home.projectIds.has(props.id),
        (listener) => [home.projectIds.subscribe(listener)],
        [home, props.id],
    );

    const onClick = React.useCallback(() => {
        home.projectIds.has(props.id) ? home.projectIds.delete(props.id) : home.projectIds.add(props.id);
    }, [home, props.id]);

    return (
        <TableCell className={classes.root}>
            <Tooltip title={t.render(checked ? "stopFilteringUrlasByProject" : "filterUrlasByProject")}>
                <Checkbox
                    checked={checked}
                    color="primary"
                    size={"medium"}
                    icon={<FilterIcon />}
                    checkedIcon={<FilterIcon />}
                    onClick={onClick}
                />
            </Tooltip>
        </TableCell>
    );
}
{
    /* <IconButton children={<FilterIcon />} className={classes.clipboard} onClick={onClick} /> */
}

const useStyles = makeStyles(
    () => ({
        root: {},
    }),
    { name: "ProjectFilterCell" },
);
