import React from "react";
import { Api, ENDPOINT, TableService, TemplateFile, ErrorAlert } from "lib";

import { TemplateEditorService } from "./template-editor";

export class TemplateImagesService extends TableService<TemplateFile, "file_id"> {
    readonly editor: TemplateEditorService;

    constructor(editor: TemplateEditorService) {
        super({
            id: "file_id",
            fetch: async () => {
                let images: TemplateFile[] = [];
                images = await Api.request(ENDPOINT.getTemplateFiles, {});
                return { values: (Array.isArray(images) && images.filter(Boolean)) || [], totalCount: images.length };
            },
            deleteNoHydrate: true,
            delete: async (props) => {
                let error: Error;

                const deleted = await Promise.all(
                    props.ids
                        .filter((id) => Number(id) > 0)
                        .map((file_id) =>
                            Api.request(ENDPOINT.deleteTemplateFile, { file_id }).catch((err) => {
                                error = err;
                            }),
                        ),
                );

                this.totalCount.set(Math.max(0, this.totalCount.current - deleted.filter(Boolean).length));

                if (error) {
                    throw error;
                }
            },
            perPage: 9999999,
            translations: {
                single: "imageSingle",
                many: "imageMany",
                theMany: "imageTheMany",
                the: "imageThe",
            },
        });

        this.editor = editor;

        this.onUpload = this.onUpload.bind(this);
    }

    add(id: TemplateFile["file_id"], extraData: { [key: string]: any } = {}) {
        if (!this.values.get(id, "file_url")) {
            return;
        }
    }

    /** Upload an image from a change event on a `<input type="file" />` and set the external source to the value. */
    async onUpload() {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.setAttribute("style", "display:none;");
        fileInput.setAttribute("multiple", "");
        fileInput.setAttribute("accept", "image/*");
        document.getElementById("root").appendChild(fileInput);

        return new Promise<void>((resolve) => {
            fileInput.onchange = async (evt: any) => resolve(this.uploadFromEvent(evt));
            fileInput.click();
        }).finally(() => {
            document.getElementById("root").removeChild(fileInput);
        });
    }

    /** Upload an image from a change event on a `<input type="file" />` and set the external source to the value. */
    uploadFromEvent(evt: React.ChangeEvent<HTMLInputElement>) {
        evt.preventDefault();
        return this.upload(...Array.from(evt.target.files));
    }

    async upload(...files: File[]) {
        if (!files.length) {
            return;
        }

        await Promise.all(
            files.map(async (file) => {
                if (!file) {
                    return file;
                }
                try {
                    const data = new FormData();
                    data.append("file_name", file);
                    return Api.request(ENDPOINT.uploadTemplateFile, data);
                } catch (error) {
                    ErrorAlert.alert({
                        error,
                    });
                }
            }),
        );

        await this.fetch(true);
    }
}
