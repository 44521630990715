import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { uniqueString } from "lib/utils";
import { makeStyles, clsx } from "lib/material";

import { DateFieldProps } from "./date-field.types";

export const DateField = React.forwardRef<HTMLInputElement, DateFieldProps>((props: DateFieldProps, ref) => {
    const {
        value,
        autoComplete,
        autoCorrect,
        spellCheck,
        error,
        skeleton,
        className,
        name = value.valueProps.name,
        helperText: explicitHelperText,
        id: passedId,
        fullWidth,
        disabled,
        placeholder,
        ...otherProps
    } = props;

    if (!value) {
        throw new Error(`If no "value" is passed, you should use the select field exported directly from @material-ui/core/Select.`);
    }

    const id = React.useMemo(() => passedId || `sf-${value.valueProps.name || uniqueString()}`, [passedId]);
    const c = useStyles();
    const usedProps = value.use();

    const helperText = explicitHelperText || usedProps.error || usedProps.success;

    if (skeleton) {
        return <Skeleton key={"_skeleton"} height={36} />;
    }

    return (
        <KeyboardDatePicker
            {...otherProps}
            className={clsx(c.field, className)}
            variant="inline"
            readOnly={usedProps.readOnly}
            inputRef={ref}
            id={id}
            onChange={value.onChange}
            InputLabelProps={{ shrink: Boolean(placeholder || usedProps.placeholder) }}
            label={usedProps.label}
            format={value.format}
            placeholder={placeholder || usedProps.placeholder}
            error={!!error}
            disabled={disabled || (usedProps ? usedProps.loading || usedProps.readOnly : undefined)}
            value={usedProps.formatted}
            helperText={helperText}
            fullWidth={fullWidth}
        />
    );
});

const useStyles = makeStyles(() => ({
    field: {
        flexGrow: 1,
        caretColor: "black",
        marginBottom: -5,
    },
}));
