import React from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles, Box, Translation } from "lib";

export function AuthContainer(props: React.PropsWithChildren<{}>) {
    const classes = useStyles();
    const t = Translation.useMap(["recaptchaDetails", "andTheGoogle", "privacyPolicy", "and", "termsOfService", "apply"]);

    return (
        <Box className={classes.root}>
            <Paper className={classes.paper}>{props.children}</Paper>

            <p className={classes.recaptchaDetails}>
                {t.render("recaptchaDetails")} {t.render("andTheGoogle")}{" "}
                <a href="https://www.urla.se/privacy.html" target="_blank" rel="noopener noreferrer" children={t.render("privacyPolicy")} />{" "}
                {t.render("and")}{" "}
                <a href="https://www.urla.se/terms.html" target="_blank" rel="noopener noreferrer" children={t.render("termsOfService")} />{" "}
                {t.render("apply")}.
            </p>
        </Box>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
            flexShrink: 0,
            padding: "5vh 0 2vh 0",

            [theme.breakpoints.down("sm")]: {
                padding: 0,
            },
        },

        paper: {
            padding: "2rem 1.5rem",
            borderRadius: 3,
            backgroundColor: "rgba(42, 42, 42, 0.75)",
            width: "20rem",
            color: "white",

            [theme.breakpoints.down("sm")]: {
                display: "flex",
                flexGrow: 1,
                flexShrink: 0,
                borderRadius: 0,
                justifyContent: "center",
                flexDirection: "column",
                paddingBottom: "1rem",
                width: "100%",
            },
        },

        recaptchaDetails: {
            marginTop: "1rem",
            padding: "0.5rem 1.5rem",
            fontSize: 11,
            color: theme.palette.text.secondary,
            width: "20rem",
            textAlign: "left",

            "& a": {
                color: theme.palette.primary.dark,
            },

            [theme.breakpoints.down("sm")]: {
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                textAlign: "left",
            },
        },
    }),
    { name: "Auth" },
);
