import React from "react";
import { Helmet } from "react-helmet";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { TextField, makeStyles, useService, Translation, Form, ButtonLink, Tooltip, ROLE } from "lib";

import { AuthHead, AuthSubmit, AuthLinks } from "../shared";

import { SignUpService } from "./sign-up.service";
import { RouteComponentProps } from "react-router";

function SignUp(props: signUpParamTypes) {
    const classes = useStyles();

    const service = useService(SignUpService, { role: props.role });
    const t = Translation.useMap([
        "signUp",
        "signUpForFree",
        "signUpForProTrial",
        "logIn",
        "iAcceptThe",
        "termsOfService",
        "and",
        "privacyPolicy",
        "signUpEmailTooltip",
    ]);

    const { executeRecaptcha } = useGoogleReCaptcha();
    service.executeRecaptcha = executeRecaptcha;

    const submittable = service.store.submittable.use().current;
    const acceptTerms = service.acceptTerms.useCurrent();

    let signupTitle;
    let signupDescription;

    switch (props.role) {
        case ROLE.TRIAL:
            signupTitle = "signUpForProTrial";
            signupDescription = "signUpProTrialDescription";
            break;
        case ROLE.FREE:
        default:
            signupTitle = "signUpForFree";
            break;
    }

    return (
        <>
            <Helmet title={[t.get("signUp"), "URLA"].join(" - ")} />

            <Form service={service}>
                <AuthHead title={t.get(signupTitle)} description={signupDescription} />
                <TextField value={service.store.getValue("name") as any} fullWidth className={classes.input} />
                <Tooltip title={t.get("signUpEmailTooltip")}>
                    <TextField value={service.store.getValue("email") as any} fullWidth className={classes.input} />
                </Tooltip>
                <TextField value={service.store.getValue("password") as any} fullWidth className={classes.input} />
                <TextField value={service.store.getValue("passwordVerification") as any} fullWidth className={classes.input} />

                <FormControlLabel
                    className={classes.terms}
                    control={
                        <Checkbox
                            checked={!!acceptTerms}
                            onChange={(_, checked) => service.acceptTerms.set(checked)}
                            value="acceptTerms"
                            color="primary"
                        />
                    }
                    label={
                        <>
                            {t.render("iAcceptThe")}{" "}
                            <a
                                href="https://www.urla.se/privacy.html"
                                target="_blank"
                                rel="noopener noreferrer"
                                children={t.render("privacyPolicy")}
                            />{" "}
                            {t.render("and")}{" "}
                            <a
                                href="https://www.urla.se/terms.html"
                                target="_blank"
                                rel="noopener noreferrer"
                                children={t.render("termsOfService")}
                            />
                            .
                        </>
                    }
                />

                <AuthSubmit service={service} disabled={!submittable || !acceptTerms} children={t.get("signUp")} />

                <AuthLinks>
                    <div />
                    <ButtonLink children={t.render("logIn")} to="/auth/log-in" />
                </AuthLinks>
            </Form>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    input: {
        marginBottom: "1rem",
    },

    terms: {
        margin: "0.5rem 1rem 0.75rem -11px",
        alignItems: "flex-start",
        fontWeight: 100,
        "& span": {
            fontSize: 15,
        },
        "& a": {
            color: theme.palette.primary.main,
        },
    },
}));

// the provider is necessary for use of the hook inside of the SignUp component
export default function SignUpRoot(props: RouteComponentProps<{ role?: string }>) {
    let role = ROLE.FREE;
    if (props.match.params.role && props.match.params.role == "pro-trial") {
        role = ROLE.TRIAL;
    }
    return (
        <GoogleReCaptchaProvider reCaptchaKey={"6LeP3sIUAAAAAG305-C04OZlnHrCWaGgCHG9pUSK"}>
            <SignUp role={role} />
        </GoogleReCaptchaProvider>
    );
}
interface signUpParamTypes {
    role: ROLE;
}
