import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles, Box, Auth, Translation, ROLE, SUBSCRIPTION_PLAN, Abon } from "lib";
import { UpgradePlan } from "upgrade/plan";

export function AccountSubscription() {
    const classes = useStyles();

    const t = Translation.useMap(["free", "links", "subscription", "account"]);
    const roleId = Auth.use("accessToken", "role_id");
    const openDowngradeSubscription = Abon.use(() => false);

    return (
        <>
            <Helmet title={[t.get("subscription"), t.get("account"), "URLA"].join(" - ")} />

            <Box className={classes.root}>
                <Box className={classes.plans}>
                    <UpgradePlan
                        name={"Pro"}
                        active={roleId === ROLE.PRO}
                        plan={SUBSCRIPTION_PLAN.PRO}
                        openDowngradeSubscription={openDowngradeSubscription}
                    />
                    <UpgradePlan
                        name={"Enterprise"}
                        active={roleId === ROLE.ENTERPRISE}
                        plan={SUBSCRIPTION_PLAN.ENTERPRISE}
                        openDowngradeSubscription={openDowngradeSubscription}
                    />
                </Box>
            </Box>
        </>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            padding: "2rem",
            position: "relative",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            flexShrink: 1,
        },
        plans: {
            display: "flex",
            flexGrow: 1,
            flexShrink: 1,
            flexDirection: "row",
            alignItems: "stretch",
            justifyContent: "space-between",
            margin: "0 -1vw",
            position: "relative",
            padding: "0 15%",

            "& > div": {
                margin: "0 1vw",
                width: 300,
            },

            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                justifyContent: "flex-start",
                padding: "0",
                margin: "1rem 0",

                "& > div": {
                    margin: "1rem 0",
                    width: "100%",
                },
            },
        },
    }),
    { name: "AccountSubscription" },
);
