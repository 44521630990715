import React from "react";
import { omit, pick } from "lib/utils";
import { Translation, TranslationThunk } from "lib/store/translation";

import { Value, ValueProps } from "../value";

import { TextValueProps } from "./text-value.types";

export class TextValue extends Value<string> {
    static VALUE_ERROR_ID = "$value";

    static useTextValue(props: TextValueProps) {
        const textValue = React.useMemo(() => new TextValue(props), []);

        React.useEffect(() => () => textValue.clear(), []);

        return textValue;
    }

    static selectTextValueProps<PT extends TextValueProps, T = any>(
        props: PT,
    ): Omit<PT, keyof TextValueProps> & {
        textValueProps: TextValueProps;
    } {
        const { valueProps, ...allProps } = Value.selectValueProps(props);

        return {
            ...omit(allProps, "onBlur", "onChange", "format", "type"),
            textValueProps: {
                ...valueProps,
                ...pick(allProps, "onBlur", "onChange", "format", "type"),
            },
        } as any;
    }

    textProps: Omit<TextValueProps, keyof ValueProps<string>>;

    constructor(props: TextValueProps = {}) {
        const { valueProps, ...textProps } = Value.selectValueProps(props);

        super(valueProps);

        this.textProps = textProps;

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
    }

    use() {
        const usedValue = super.use();

        let current = usedValue.current;

        if (this.textProps.accept === "percent") {
            if (current != null) {
                current = String((Number(usedValue.current) * 100) as any);
            } else {
                current = "";
            }
        } else if (current == null) {
            current = "";
        }

        return {
            ...usedValue,
            current,
            startAdornment: this.textProps.startAdornment || (this.textProps.accept === "percent" ? "%" : undefined),
            endAdornment: this.textProps.endAdornment,
            maxLength: this.textProps.maxLength,
            multiline: this.textProps.multiline,
            type: this.textProps.accept === "percent" ? "number" : this.textProps.accept || "text",
        };
    }

    onChange(evt: React.ChangeEvent<HTMLInputElement>, _value?: any) {
        this.error.remove(TextValue.VALUE_ERROR_ID);

        let value: any = evt ? evt.target.value || _value : _value;

        if (this.textProps.accept === "number" && typeof value !== "number") {
            if (value) {
                value = Number(value);
            }
        } else if (this.textProps.accept === "percent") {
            if (value) {
                value = Number(value);

                if (value !== 0) {
                    value = value / 100;
                }
            }
        }

        if (evt) {
            evt.target.value = value;
        }

        if (this.textProps.format) {
            this.set(this.textProps.format(value));
        } else {
            this.set(value);
        }

        if (this.textProps.onChange) {
            this.textProps.onChange(evt);
        }
    }

    addError(message?: TranslationThunk) {
        this.error.add(TextValue.VALUE_ERROR_ID, Translation.render(message));
    }

    async onBlur(_evt: React.FocusEvent<HTMLInputElement>) {
        if (this.value.current !== undefined) {
            if (this.current != null) {
                if (this.textProps.accept === "url") {
                    if (!isUrlValid(this.current)) {
                        this.addError("invalidUrl");
                        return;
                    }
                } else if (this.textProps.accept === "email") {
                    if (!isEmailValid(this.current)) {
                        this.addError("invalidEmail");
                        return;
                    }
                }
            }
        }

        if (this.valueProps.onBlur) {
            await this.valueProps.onBlur();
        }
    }

    async onFocus(_evt: React.FocusEvent<HTMLInputElement>) {
        if (this.valueProps.onFocus) {
            await this.valueProps.onFocus();
        }
    }
}

function isUrlValid(userInput: string) {
    const res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);

    if (res == null) {
        return false;
    } else {
        return true;
    }
}

function isEmailValid(userInput: string) {
    // eslint-disable-next-line
	const emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    return emailPattern.test(userInput);
}
