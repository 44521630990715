/* eslint-disable no-invalid-this */
/* eslint-disable @typescript-eslint/camelcase */
import {
    Api,
    ENDPOINT,
    AbonDeep,
    TableService,
    TABLE_DIRECTION,
    ConnectedUser,
    requireService,
    SnackStack,
    Translation,
    forgoService,
} from "lib";

export class UserRelationsService extends TableService<ConnectedUser, "id", UserRelationsOrder> {
    users = new AbonDeep<ConnectedUser[]>([]);

    constructor() {
        super({
            id: "id",
            initialOrderBy: "created_at",
            perPage: 6,
            perPageOptions: true,

            fetch: async (props) => {
                if (props.forceHydrate || !this.users.current) {
                    const response = await Api.request(ENDPOINT.getUserRelations, {
                        order_by: props.orderBy,
                        asc_desc: props.direction === TABLE_DIRECTION.ASC ? "DESC" : "ASC",
                        pagination: 1,
                        per_page: 99999,
                    });
                    if (response && Array.isArray(response)) {
                        this.users.set(response);
                    }
                }

                return {
                    values: this.users.current,
                    totalCount: this.users.current.length,
                };
            },

            translations: { single: "userThe", many: "userMany", theMany: "userTheMany", the: "userThe" },
        });
    }

    revokeUser = async (activation_token: string) => {
        await Api.request(ENDPOINT.deleteUserRelations, {
            activation_token: activation_token,
        });
        // eslint-disable-next-line no-invalid-this
        this.refresh();
    };

    resendInvite = async (username: string) => {
        await Api.request<ENDPOINT.createUserInvitation>(ENDPOINT.createUserInvitation, {
            receiver_email: username,
        })
            .then((response) => {
                const snacks = requireService({ service: SnackStack, dependent: this });
                snacks.add({ message: Translation.render("userInvited") });
                forgoService({ service: snacks, dependent: this });
            })
            .catch((error) => {
                const snacks = requireService({ service: SnackStack, dependent: this });
                snacks.add({ message: Translation.render("unknownError") });
                forgoService({ service: snacks, dependent: this });
            })
            .finally(() => {
                this.refresh();
            });
    };
}

export type UserRelationsOrder = "id" | "name" | "email" | "status" | "created_at" | "activation_token";
