import React from "react";
import { animated } from "react-spring";
import { RouteComponentProps, Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExportIcon from "@material-ui/icons/TableChart";
import Grow from "@material-ui/core/Grow";
import Fade from "@material-ui/core/Fade";
import {
    Paper,
    useService,
    Abon,
    MaterialButton,
    makeStyles,
    Table,
    clsx,
    Box,
    Translation,
    ButtonLink,
    Typography,
    Auth,
    ROLE,
    StackItemProps,
    CardStack,
} from "lib";
import moment from "moment";

import { LinkStatisticsService } from "./link-statistics.service";
import { LinkIpSymbol, LinkTimeSymbol, LinkReferrerSymbol, LinkLocationSymbol } from "./link-statistics.symbols";

export function LinkStatistics(props: LinkStatisticsProps) {
    const { open = Abon.use(() => true) } = props;

    CardStack.use(
        open.current,
        () => ({
            renderer: (stackProps) => <LinkStatisticsModal {...props} {...stackProps} open={open} />,
            onClose: () => open.set(false),
            transitionDuration: 2000,
        }),
        [props.urlaId, open],
    );

    return null;
}

function LinkStatisticsModal(props: LinkStatisticsModalProps) {
    const { urlaId } = props;
    const c = useStyles();

    const classes = useStyles();
    const service = useService(LinkStatisticsService, urlaId);
    const roleId = Auth.use("accessToken", "role_id");
    const t = Translation.useMap(["upgradeRequired", "statisticsUpgradeTitle", "statisticsUpgradeBody", "upgrade", "to"]);

    const initializing = service.initializing.use().current;

    const urla = service.urla.use().current;

    let label = urla.url_name || null;
    if (urla.receiver_name) {
        if (label) {
            label = label + " " + t.render("to") + " " + urla.receiver_name;
        } else {
            label = urla.receiver_name;
        }
    }

    return (
        <animated.div style={{ transform: props.interpolations.transform }} className={c.inner}>
            <Grow in={!initializing} timeout={200}>
                <Paper className={c.root}>
                    <Box className={classes.topDetails}>
                        <Box>
                            {label}

                            {urla.uniqid && (
                                <a
                                    href={"https://urla.se/" + urla.uniqid}
                                    children={"urla.se/" + urla.uniqid}
                                    className={classes.anchor}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                />
                            )}
                        </Box>

                        <MaterialButton
                            className={classes.exportList}
                            variant="contained"
                            color="primary"
                            endIcon={<ExportIcon />}
                            disabled
                        >
                            {Translation.render("exportList")}
                        </MaterialButton>
                    </Box>
                    <Table
                        service={(service as any) as typeof LinkStatisticsService}
                        rowHeight={40}
                        columns={[
                            {
                                valueKey: "user_ip",
                                labelChildren: <LinkIpSymbol />,
                                tooltip: "linkIpTooltip",
                                width: 120,
                                renderContent: (props) => props.value || "—",
                            },
                            {
                                valueKey: "reg_time",
                                labelChildren: <LinkTimeSymbol />,
                                tooltip: "linkTimeTooltip",
                                width: 160,
                                renderContent: (props) => (props.value ? moment(props.value).format("YYYY-MM-DD HH:mm") : "—"),
                            },
                            {
                                valueKey: "user_referer_name",
                                labelChildren: <LinkReferrerSymbol />,
                                tooltip: "linkReferrerTooltip",
                                width: 130,
                                renderContent: ({ row }) => row.user_referer_name || row.user_referer || "—",
                            },
                            {
                                labelChildren: <LinkLocationSymbol />,
                                tooltip: "linkLocationTooltip",
                                weight: 1,
                                renderContent: ({ row }) => (
                                    <>
                                        {row.country_code && (
                                            <span
                                                className={clsx("flag-icon", "flag-icon-" + row.country_code.toLowerCase(), classes.flag)}
                                            ></span>
                                        )}
                                        <span
                                            className={classes.country}
                                            children={[row.user_city, row.user_country].filter(Boolean).join(", ") || "—"}
                                        />
                                    </>
                                ),
                            },
                        ]}
                        noPaper
                    >
                        {roleId === ROLE.FREE && (
                            <Paper className={classes.info}>
                                <Typography variant="overline" className={classes.infoOverline} children={t.render("upgradeRequired")} />
                                <Typography variant="h2" className={classes.infoTitle} children={t.render("statisticsUpgradeTitle")} />
                                <Typography variant="body1" className={classes.infoBody} children={t.render("statisticsUpgradeBody")} />
                                <ButtonLink to="/upgrade" color="primary" className={classes.infoLink} children={t.render("upgrade")} />
                            </Paper>
                        )}
                    </Table>
                </Paper>
            </Grow>

            <Fade in={initializing} timeout={200}>
                <CircularProgress className={c.spinner} color="primary" size="4rem" />
            </Fade>
        </animated.div>
    );
}

interface LinkStatisticsProps {
    urlaId?: string;
    open?: Abon<boolean>;
}

interface LinkStatisticsModalProps extends StackItemProps {
    urlaId?: string;
    open: Abon<boolean>;
}

const useStyles = makeStyles(
    (theme) => ({
        modal: {
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
        },
        inner: {
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            outline: "none",

            "& > *": {
                position: "absolute",
            },
        },
        spinner: {
            outline: "none",
        },
        root: {
            width: "46rem",
            maxWidth: "calc(100vw - 1rem)",
            maxHeight: "70vh",
            overflow: "auto",
            padding: "1rem 2rem",
            outline: "none",
            position: "relative",
        },
        topDetails: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "-1rem",
            marginBottom: "0.5rem",

            "& > *": {
                padding: "9px 16px 5px 16px",
                minHeight: 40,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                backgroundColor: "rgba(0,0,0,.2) !important",
                borderBottomLeftRadius: 3,
                borderBottomRightRadius: 3,
                fontSize: 14,
                lineHeight: 1,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                textTransform: "none",
            },
        },
        anchor: {
            color: theme.palette.primary.main,
            marginLeft: 6,
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
        exportList: {
            padding: "6px 16px",
            opacity: 0.6,

            "& svg": {
                marginTop: -5,
            },
        },
        flag: {
            paddingTop: 4,
            marginRight: 8,
        },
        country: {},
        info: {
            backgroundColor: "rgba(46,46,46,1)",
            padding: "0.75rem 1.25rem",
            marginTop: "5rem",
            maxWidth: "calc(100% - 2rem)",
            overflow: "hidden",
            pointerEvents: "all",
        },
        infoOverline: {
            color: theme.palette.text.secondary,
            opacity: 0.5,
        },
        infoTitle: {
            color: theme.palette.text.primary,
            fontSize: 20,
        },
        infoBody: {
            color: theme.palette.text.secondary,
            fontSize: 14,
            marginTop: 6,
        },
        infoLink: {
            marginLeft: "-0.5rem",
            fontWeight: 600,
            fontSize: 13,
        },
    }),
    { name: "LinkStatistics" },
);

export function LinkStatisticsRoute(props: RouteComponentProps<{ urlaId?: string }>) {
    if (isNaN(Number(props.match.params.urlaId))) {
        return <Redirect to={props.match.path.split("/:urlaId")[0]} />;
    }

    const open = Abon.use(() => true);

    if (!open.current) {
        return <Redirect to={props.match.path.split("/:urlaId")[0]} />;
    }

    return <LinkStatistics urlaId={props.match.params.urlaId} key={props.match.params.urlaId} open={open} />;
}
