import React from "react";
import { Helmet } from "react-helmet";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ButtonLink, Container, makeStyles, Typography, Translation, useTheme } from "lib";

export default function Welcome() {
    const c = useStyles();
    const t = Translation.useMap(["welcome", "welcomeToUrla", "welcomeCaption", "pasteLink", "chooseName", "selectAmount", "getStarted"]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <>
            <Helmet title={[t.render("welcome"), "URLA"].join(" - ")} />

            <Container className={c.root}>
                <Typography children={t.render("welcomeToUrla")} variant="h4" />
                <Typography children={t.render("welcomeCaption")} variant="h6" />

                <Stepper activeStep={-1} orientation={isMobile ? "vertical" : undefined} className={c.stepper}>
                    <Step key={0}>
                        <StepLabel children={t.render("pasteLink")} />
                    </Step>
                    <Step key={1}>
                        <StepLabel children={t.render("chooseName")} />
                    </Step>
                    <Step key={2}>
                        <StepLabel children={t.render("selectAmount")} />
                    </Step>
                </Stepper>

                <ButtonLink
                    color={"primary"}
                    variant={"contained"}
                    to="/create"
                    className={c.cta}
                    fullWidth
                    children={t.render("getStarted")}
                />
            </Container>
        </>
    );
}

const useStyles = makeStyles(
    () => ({
        root: {
            paddingTop: "calc(8vh + 3rem)",
            paddingBottom: "calc(8vh + 3rem)",
            textAlign: "center",
            display: "flex",
            flexGrow: 1,
            flexShrink: 0,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",

            "& h6": {
                marginTop: "0.85rem",
                lineHeight: 1.3,
                opacity: 0.85,
            },
        },
        stepper: {
            marginTop: "calc(2vh + 1rem)",
            textAlign: "left",
            backgroundColor: "rgba(42, 42, 42, 0.75)",
            borderRadius: 3,
            width: "45rem",
            maxWidth: "100%",

            "& .MuiStepLabel-label": {
                marginTop: 2,
            },
        },
        cta: {
            display: "flex",
            flexGrow: 0,
            flexShrink: 0,
            fontWeight: 600,
            marginTop: "calc(2vh + 1rem)",
            width: "10rem",
        },
    }),
    { name: "Welcome" },
);
