import React from "react";
import { makeStyles, Box, Typography } from "lib";

export function TemplateHeader(props: TemplateHeaderProps) {
    const c = useStyles();

    return (
        <Box className={c.root}>
            {props.step && <Box className={c.step} children={props.step} />}

            <Box className={c.content}>
                <Box className={c.text}>
                    <Typography children={props.title} className={c.title} />

                    {props.subhead && <Typography children={props.subhead} className={c.subhead} />}
                </Box>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "1.5rem 1.5rem 0 1.5rem",

            [theme.breakpoints.down("lg")]: {
                width: "100%",
                marginLeft: 0,
            },
        },
        step: {
            backgroundColor: "#F76400",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: 48,
            height: 48,
            overflow: "hidden",
            borderRadius: "100%",
            position: "relative",
            flex: "0 0 auto",
            fontSize: 24,
            lineHeight: 1,
            paddingTop: 5,
            fontWeight: 600,
            marginRight: 16,
        },
        content: {
            flex: "1 1 auto",
        },
        text: {},
        title: {
            fontSize: 22,
            paddingBottom: 1,
            width: "100%",
            borderBottom: "1px solid rgba(255,255,255,0.1)",
        },
        subhead: {
            fontSize: 15,
            fontWeight: 100,
            marginTop: 8,
            color: "rgba(255,255,255,0.85)",
        },
    }),
    { name: "TemplatesHeader" },
);

export interface TemplateHeaderProps {
    title: string;
    subhead?: string;
    step?: number;
}
