/* eslint-disable @typescript-eslint/camelcase */
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import DateIcon from "@material-ui/icons/Today";
import { useService, makeStyles, Auth, ROLE, TableCellComponentProps, Tooltip, Translation, Abon, Api, ENDPOINT, SnackStack } from "lib";
import moment from "moment";

import { HomeService } from "../service";
import { Urla } from "../shared";

export function HomeTableCreatedCell(props: TableCellComponentProps<Urla, "id">) {
    const classes = useStyles();

    const service = useService(HomeService);

    const createdDate = service.values.use(props.id, "created_date");

    const created = React.useMemo<{ date: moment.Moment; month: string; year: string; dayMonth: string; new: moment.Moment }>(() => {
        if (!createdDate || createdDate === "0000-00-00") {
            return { date: null, month: "", year: "", dayMonth: "", new: null };
        }

        const date = moment(createdDate);
        const year = date.format("YYYY");
        const month = date.format("MMMM");
        const day = date.format("Do");
        const dayMonth = date.format("DD/MM");

        return { date, month, year, dayMonth, day, new: null as typeof date };
    }, [createdDate]);

    const buttonInner = (
        <>
            {created.year && (
                <div className={classes.top}>
                    <DateIcon />
                    {created.year}
                </div>
            )}

            <div className={classes.bottom}>{created.dayMonth || "—"}</div>
        </>
    );

    return (
        <TableCell className={classes.root}>
            <div className={classes.inner}>{buttonInner}</div>
        </TableCell>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            position: "relative",

            "& > .MuiFormControl-root": {
                pointerEvents: "none",
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0,
            },
        },
        inner: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },
        top: {
            fontSize: 10,
            fontWeight: 500,
            opacity: 0.5,
            lineHeight: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingRight: 3,

            "& > svg": {
                width: 10,
                height: 10,
                marginRight: 2,
                transform: "translateY(-0.5px)",
            },
        },
        bottom: {
            display: "block",
            fontSize: 15,
            fontWeight: 600,
            opacity: 0.9,
            lineHeight: 1,
            marginTop: 4,
            textTransform: "none",
        },
    }),
    { name: "CreatedCell" },
);
