import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles, Box, Translation, TemplateType, useService } from "lib";
import { TemplatesScreen } from "templates/components";
import { TemplateService } from "templates/services";

export function MarketingSMS() {
    const classes = useStyles();
    const service = useService(TemplateService, { type: TemplateType.sms });
    const t = Translation.useMap([
        "marketing",
        "smsCampaigns",
        "smsTemplateTitle",
        "smsTemplateSubhead",
        "smsReceiversTitle",
        "smsReceiversSubhead",
        "sendSMSTitle",
        "sendSMSSubhead",
        "sendSMSAction",
    ]);
    service.editor.contentLimit.set(true);

    return (
        <>
            <Helmet title={[t.get("smsCampaigns"), t.get("marketing"), "URLA"].join(" - ")} />

            <Box className={classes.root}>
                <TemplatesScreen
                    service={service}
                    editorTitle={t.render("smsTemplateTitle")}
                    editorSubhead={t.render("smsTemplateSubhead")}
                    receiversTitle={t.render("smsReceiversTitle")}
                    receiversSubhead={t.render("smsReceiversSubhead")}
                    sendTitle={t.render("sendSMSTitle")}
                    sendSubhead={t.render("sendSMSSubhead")}
                    sendAction={t.render("sendSMSAction")}
                />
            </Box>
        </>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            padding: "2rem 0",
            position: "relative",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            flexShrink: 1,
        },
        plans: {
            display: "flex",
            flexGrow: 1,
            flexShrink: 1,
            flexDirection: "row",
            alignItems: "stretch",
            justifyContent: "space-between",
            margin: "0 -1vw",
            position: "relative",
            padding: "0 15%",

            "& > div": {
                margin: "0 1vw",
                width: 300,
            },

            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                justifyContent: "flex-start",
                padding: "0",
                margin: "1rem 0",

                "& > div": {
                    margin: "1rem 0",
                    width: "100%",
                },
            },
        },
    }),
    { name: "MarketingSMS" },
);
