import React from "react";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import { animated } from "react-spring";
import { makeStyles, Typography } from "lib/material";
import { Translation } from "lib/store";
import { Abon } from "lib/utils";
import { TableService } from "lib/services";
import { StackItemProps, CardStack } from "lib/stack";

export function TableConfirmDelete(props: { onDelete(): void; open: Abon<boolean>; service: TableService<any, any, any> }) {
    const { open, service } = props;

    CardStack.use(
        open.current,
        () => ({
            onClose: () => open.set(false),
            renderer: (stackProps) => <TableConfirmDeleteModal {...stackProps} {...props} />,
            interpolations: { transform: { tension: 600 } },
        }),
        [open, props.onDelete, props.service],
    );

    return null;
}

function TableConfirmDeleteModal(props: TableConfirmDeleteModalProps) {
    const { onDelete, service, onClose, interpolations } = props;

    const valueTheMany = Translation.useRender(service.props.translations.theMany);
    const valueThe = Translation.useRender(service.props.translations.the);
    const t = Translation.useMap([
        { id: "deleteSelectedValuesConfirmation", values: { valueTheMany } },
        { id: "deleteSelectedValueConfirmation", values: { valueThe } },
        "deleteSelectedValuedDescription",
        "delete",
        "cancel",
    ]);
    const classes = useStyles();
    const selectedMany = Abon.useComposedValue(
        () => service.selectedIds.current.length > 1,
        (listener) => [service.selectedIds.subscribe(listener)],
        [service],
    );

    return (
        <animated.div className={classes.paper} style={{ transform: interpolations.transform }}>
            <div className={classes.modalHeader}>
                <Typography variant="h5" className={classes.modalHeaderTitle}>
                    {t.render(selectedMany ? "deleteSelectedValuesConfirmation" : "deleteSelectedValueConfirmation")}
                </Typography>
            </div>
            <div className={classes.modalContent}>
                <Typography variant="body1">{t.render("deleteSelectedValuedDescription")}</Typography>
            </div>
            <div className={classes.modalFooter}>
                <Button
                    onClick={onDelete}
                    startIcon={<DeleteIcon />}
                    color={"primary"}
                    variant={"contained"}
                    className={classes.confirmButton}
                >
                    {t.render("delete")}
                </Button>
                <Button onClick={onClose} variant="outlined" className={classes.cancelButton}>
                    {t.render("cancel")}
                </Button>
            </div>
        </animated.div>
    );
}

interface TableConfirmDeleteProps {
    onDelete(): void;
    open: Abon<boolean>;
    service: TableService<any, any, any>;
}
interface TableConfirmDeleteModalProps extends StackItemProps, TableConfirmDeleteProps {}

const useStyles = makeStyles(
    (theme) => ({
        paper: {
            width: "auto",
            maxWidth: 450,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3, 4),
            outline: 0,
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
        },
        modalHeader: {
            marginBottom: "1rem",
            paddingBottom: "0.25rem",
            borderBottom: `1px solid ${theme.palette.grey[700]}`,
        },
        modalHeaderTitle: {
            fontWeight: 500,
            fontSize: "0.9rem",
            letterSpacing: 0.5,
            color: theme.palette.grey[600],
            textTransform: "uppercase",
        },
        modalContent: {
            flex: 1,
            marginBottom: "2rem",
        },
        modalFooter: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "0 -0.5rem",
        },
        confirmButton: {
            fontWeight: 600,
            margin: "0.5rem",
        },
        cancelButton: {
            fontWeight: 600,
            margin: "0.5rem",
        },
    }),
    { name: "TableConfirmDelete" },
);
