import React from "react";
import moment from "moment";
import { omit, pick } from "lib/utils";
import { TIME_FORMAT } from "lib/form/value-meta";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";

import { Value, ValueProps } from "../value";

import { TimeValueProps } from "./time-value.types";

export class TimeValue extends Value<string> {
    static useTimeValue(props: TimeValueProps) {
        const timeValue = React.useMemo(() => new TimeValue(props), []);

        React.useEffect(() => () => timeValue.clear(), []);

        return timeValue;
    }

    static selectTimeValueProps<PT extends TimeValueProps, T = any>(
        props: PT,
    ): Omit<PT, keyof TimeValueProps> & {
        timeValueProps: TimeValueProps;
    } {
        const { valueProps, ...allProps } = Value.selectValueProps(props);

        return {
            ...omit(allProps, "onChange", "format"),
            timeValueProps: {
                ...valueProps,
                ...pick(allProps, "onChange", "format"),
            },
        } as any;
    }

    formatted: ParsableDate;
    format: string;

    timeProps: Omit<TimeValueProps, keyof ValueProps<string>>;

    constructor(props: TimeValueProps = {}) {
        const { valueProps, ...timeProps } = Value.selectValueProps(props);

        super(valueProps);

        this.timeProps = timeProps;

        this.onChange = this.onChange.bind(this);

        const formatCurrentTime = (current: string) => {
            if (current == null) {
                this.formatted = current;
            } else if (!this.timeProps.format || this.timeProps.format === TIME_FORMAT.MILITARY) {
                this.format = "HH:mm";
                const formatted = moment(current, this.format);
                this.formatted = formatted;
            }
        };

        formatCurrentTime(this.current);
        this.value.subscribe(formatCurrentTime);
    }

    use() {
        const usedValue = super.use();

        const current = usedValue.current;

        let formatted: ParsableDate;

        if (current == null) {
            formatted = current;
        } else if (!this.timeProps.format || this.timeProps.format === TIME_FORMAT.MILITARY) {
            this.format = "HH:mm";
            formatted = moment(current, this.format);
        }

        return {
            ...usedValue,
            current,
            formatted,
        };
    }

    set(value: string) {
        return super.set(value);
    }

    onChange(_moment: moment.Moment) {
        this.set(_moment != null ? moment(_moment, this.format).format(this.format) : null);

        if (this.current !== undefined) {
            if (this.timeProps.onChange) {
                return this.timeProps.onChange(this.current);
            }
        }
    }
}
