/* eslint-disable @typescript-eslint/camelcase */
import {
    FormService,
    ValueMetaMap,
    FileValue,
    TextValue,
    RouterService,
    Abon,
    SliderValue,
    Auth,
    Api,
    ENDPOINT,
    ErrorAlert,
    ROLE,
    Translation,
    SnackStack,
} from "lib";
import { ProjectService, HomeService } from "home/service";

import { CreateUrlaProps, CreateUrlaExcelProps } from "./create-urla.type";

export class CreateUrlaService extends FormService<CreateUrlaProps> {
    router: RouterService;
    home: HomeService;
    snacks: SnackStack;

    step = new Abon(0);
    disabled: Abon<boolean>;

    constructor() {
        super({
            onSubmit: async (props) => {
                const stopLoading = this.loading.start();

                const created = await this.create(props, this.store);

                if (created) {
                    await Promise.all([this.require(ProjectService).refresh(), this.require(HomeService).refresh()]);

                    this.snacks.add({ message: Translation.render({ id: "createdXUrlas", values: { x: props.amount || 1 } }) });

                    this.router.navigate("/");
                }

                stopLoading();
            },

            types: [CreateUrlaProps, CreateUrlaExcelProps],
            metaMap: new ValueMetaMap<CreateUrlaProps>({
                meta: {
                    link: {
                        label: "link",
                        placeholder: "pasteLinkPlaceholder",
                    },
                    urlaFile: {
                        type: "file",
                        label: "file",
                    },
                    name: {
                        label: "nameYourLink",
                    },
                    amount: {
                        label: "numberOfLinks",
                        type: "slider",
                        fromZero: true,
                        min: 1,
                        initial: 1,
                        marks: true,
                    },
                },
            }),
        });

        this.snacks = this.require(SnackStack);
        this.router = this.require(RouterService);
        this.home = this.require(HomeService);

        this.onExited = this.onExited.bind(this);

        this.disabled = Abon.from(
            () => {
                const ids = this.home.ids.current;
                const userRole = Auth.get("accessToken", "role_id");

                if (userRole === ROLE.FREE) {
                    this.amount.sliderProps.max = 4 - ids.length;
                } else if (userRole === ROLE.PRO || userRole === ROLE.TRIAL) {
                    this.amount.sliderProps.max = 30 - Math.max(0, ids.length - 70);
                } else if (userRole === ROLE.ENTERPRISE) {
                    this.amount.sliderProps.max = 30;
                }

                return !this.amount.sliderProps.max;
            },
            (listener) => [Auth.subscribe("accessToken", "role_id", listener), this.home.ids.subscribe(listener)],
            this.deconstructFns,
        );

        this.file.fileName.subscribe((fileName) => {
            if (fileName) {
                this.name.set(fileName);
                this.step.set(1);
            }
        });

        this.store.subscribe("link", (link) => {
            if (link) {
                this.file.disabled.set(true);
            } else {
                this.file.disabled.set(false);
            }
        });
    }

    async create(props: CreateUrlaProps, store = this.store) {
        if (!props.urlaFile && !props.link) {
            store.addCombinationError("link", "aLinkOrFileIsRequired");
            store.addCombinationError("urlaFile", null);
            return false;
        }

        try {
            let projectId: number | string;

            if (props.projectId != null) {
                const project = this.require(ProjectService).projects.current.find(
                    (_project) => _project.project_name === (props.projectId as any),
                );

                if (project) {
                    projectId = Number(project.id);
                } else {
                    projectId = props.projectId;
                }
            } else {
                projectId = 0;
            }

            const data = new FormData();

            data.append("jwt", await Auth.jwt);
            data.append("project_id", String(typeof projectId === "number" ? projectId : 0));
            data.append("project_name", typeof projectId === "string" ? projectId : "");
            data.append("expiration_date", props.expirationDate || "");
            data.append("url_name", props.name);

            if (props.excelFile) {
                if (!props.projectId) {
                    store.addInputError("projectId", "mustBeDefined");
                    return;
                }

                // yup, file_name, that's still the back-end
                data.append("file_name", props.excelFile);

                if (props.urlaFile) {
                    data.append("url_orig", "");

                    const urlData = new FormData();
                    urlData.append("file_name", props.urlaFile);
                    const urlId = await Api.requestAny(ENDPOINT.fileToUrl, urlData).then((d: any) => d.url_id);

                    if (!urlId) {
                        store.addInputError("urlaFile");
                        return;
                    }

                    data.append("url_id", urlId);
                } else {
                    data.append("url_orig", props.link);
                    data.append("url_id", "0");
                }

                return await Api.requestAny(ENDPOINT.excelFileToUrlas, data);
            } else {
                if (props.urlaFile) {
                    data.append("file_name", props.urlaFile);
                    data.append("is_file", "1");
                } else {
                    data.append("url_orig", props.link);
                    data.append("is_file", "0");
                }

                data.append("is_custom", String(props.customUrla ? 1 : 0));
                data.append("custom_urla", props.customUrla || "");
                data.append("url_amount", String(props.amount));

                return await Api.requestAny(ENDPOINT.createUrlas, data);
            }

            return true;
        } catch (error) {
            ErrorAlert.alert({
                error,
            });
            return false;
        }
    }

    onExited() {
        this.store.clear();
    }

    get link(): TextValue {
        return this.store.getValue("link") as any;
    }
    get file(): FileValue {
        return this.store.getValue("urlaFile") as any;
    }
    get name(): TextValue {
        return this.store.getValue("name") as any;
    }
    get amount(): SliderValue {
        return this.store.getValue("amount") as any;
    }
}
