import React, { useEffect } from "react";
import { animated } from "react-spring";
import { RouteComponentProps, Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grow from "@material-ui/core/Grow";
import Fade from "@material-ui/core/Fade";
import {
    Paper,
    useService,
    Abon,
    makeStyles,
    Box,
    Translation,
    StackItemProps,
    CardStack,
    TemplateType,
    Typography,
    Message as Msg,
} from "lib";
import { MessageService } from "../service/message-service";
import { Button } from "@material-ui/core";
import DOMPurify from "dompurify";

export function Message(props: MessageProps) {
    const { open = Abon.use(() => true), messageId, type } = props;
    const service = useService(MessageService, { msg_db_id: messageId, type: type });
    const message = service.message.use().current;
    const initializing = service.initializing.use().current;

    CardStack.use(
        open.current,
        () => ({
            renderer: (stackProps) => <MessageModal {...props} {...stackProps} open={open} message={message} initializing={initializing} />,
            onClose: () => open.set(false),
            interpolations: { transform: { tension: 600 } },
        }),
        [props.messageId, open, service, message, initializing],
    );

    return null;
}

const Iframe = (props) => {
    let iframe_ref = null;
    const writeHTML = (frame) => {
        if (!frame) {
            return;
        }
        iframe_ref = frame;
        const doc = frame.contentDocument;
        doc.open();
        doc.write(DOMPurify.sanitize(`<body style="margin: 0">${props.content}</body>`));
        doc.close();
        frame.style.width = "100%";
        frame.style.height = `${frame.contentWindow.document.body.scrollHeight}px`;
        frame.style.maxWidth = `${frame.contentWindow.document.body.scrollWidth}px`;
        frame.style.minWidth = "640px";
        frame.style.backgroundColor = "white";
    };
    return <iframe src="about:blank" scrolling="yes" frameBorder="0" ref={writeHTML} />;
};

function MessageModal(props: MessageModalProps) {
    const { onClose, interpolations, message, initializing } = props;
    const classes = useStyles();
    const t = Translation.useMap(["close", "message"]);

    return (
        <animated.div style={{ transform: interpolations.transform }} className={classes.inner}>
            <Grow in={!initializing} timeout={200}>
                <Paper className={classes.paper}>
                    {message?.subject && message?.type == TemplateType.email && (
                        <div className={classes.modalHeader}>
                            <Typography variant="h5" className={classes.modalHeaderTitle}>
                                {message?.subject}
                            </Typography>
                        </div>
                    )}
                    {message?.message && <Iframe className={classes.modalContent} content={message?.message} />}
                    <div className={classes.modalFooter}>
                        <Button onClick={onClose} variant="outlined" className={classes.cancelButton}>
                            {t.render("close")}
                        </Button>
                    </div>
                </Paper>
            </Grow>

            <Fade in={initializing} timeout={200}>
                <CircularProgress className={classes.spinner} color="primary" size="4rem" />
            </Fade>
        </animated.div>
    );
}

interface MessageProps {
    messageId?: string;
    type?: TemplateType;
    open?: Abon<boolean>;
}

interface MessageModalProps extends StackItemProps {
    initializing?: boolean;
    message: Msg;
    open: Abon<boolean>;
}

const useStyles = makeStyles(
    (theme) => ({
        inner: {
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            outline: "none",

            "& > *": {
                position: "absolute",
            },
        },
        paper: {
            minWidth: 400,
            maxWidth: 700,
            width: "auto",
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3, 4),
            outline: 0,
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
        },
        modalHeader: {
            marginBottom: "1rem",
            paddingBottom: "0.25rem",
            borderBottom: `1px solid ${theme.palette.grey[700]}`,
        },
        modalHeaderTitle: {
            fontWeight: 500,
            fontSize: "0.9rem",
            letterSpacing: 0.5,
            color: theme.palette.grey[600],
            textTransform: "uppercase",
        },
        modalContent: {
            flex: 1,
            marginBottom: "2rem",
        },
        modalFooter: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "0 -0.5rem",
            flexWrap: "wrap",
        },
        form: {},
        dateField: {
            marginTop: "1rem",
        },
        status: { marginTop: 0.5, width: "100%" },
        confirmButton: {
            fontWeight: 600,
            margin: "0.5rem",
        },
        cancelButton: {
            fontWeight: 600,
            margin: "0.5rem",
        },
        spinner: {
            outline: "none",
        },
    }),
    { name: "Message" },
);

export function MessageRoute(props: RouteComponentProps<{ messageId?: string }>) {
    // if (isNaN(Number(props.match.params.messageId))) {
    //     return <Redirect to={props.match.path.split("/:messageId")[0]} />;
    // }

    const open = Abon.use(() => true);
    let type = undefined;
    if (props.match.path.includes("sms")) {
        type = TemplateType.sms;
    } else if (props.match.path.includes("email")) {
        type = TemplateType.email;
    } else {
        type = undefined;
    }

    if (!open.current || !type) {
        return <Redirect to={props.match.path.split("/:messageId")[0]} />;
    }

    return <Message messageId={props.match.params.messageId} type={type} key={props.match.params.messageId} open={open} />;
}
