import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import { makeStyles, Form, Translation, useService, TextField, Box } from "lib";

import { AccountProfileService } from "./account-profile.service";

export function AccountProfile() {
    const t = Translation.useMap(["account", "profile"]);
    const classes = useStyles();
    return (
        <>
            <Helmet title={[t.get("profile"), t.get("account"), "URLA"].join(" - ")} />

            <Box className={classes.root}>
                <AccountNameProfile />
                <hr className={classes.hr} />
                <AccountEmailProfile />
                <hr className={classes.hr} />
                <AccountPasswordProfile />
            </Box>
        </>
    );
}

export function AccountNameProfile() {
    const t = Translation.useMap(["update"]);
    const classes = useStyles();

    const profileService = useService(AccountProfileService);
    const service = profileService.name;

    const error = service.error.message.use().current;
    const success = service.success.message.use().current;
    const initializing = service.initializing.use().current;
    const loading = service.loading.use().current;
    const submittable = service.store.submittable.use();
    const nameValue = service.store.getValue("name").use().current;
    const [initialName, setInitalName] = useState(service.store.getValue("name").use().current);

    const checkFirstLoadAfterFetch = () => {
        if (!service.initializing.current && !service.loading.current) {
            setInitalName(service.store.getValue("name").current);
        }
    };

    const submittableHandle = () => {
        if (nameValue !== initialName) {
            service.store.submittable.set(true);
        } else {
            service.store.submittable.set(false);
        }
    };

    useEffect(checkFirstLoadAfterFetch, [initializing, loading]);
    useEffect(submittableHandle, [nameValue, initialName]);

    return (
        <Form service={service} className={classes.form}>
            <TextField fullWidth value={service.store.getValue("name") as any} />

            <Button
                color={"primary"}
                variant={"contained"}
                className={classes.submit}
                type={"submit"}
                children={t.get("update")}
                disabled={!submittable.current}
            />

            {(error || success) && <FormHelperText error={!!error} className={classes.status} children={error || success} />}
        </Form>
    );
}

export function AccountEmailProfile() {
    const t = Translation.useMap(["updateEmail"]);
    const classes = useStyles();

    const profileService = useService(AccountProfileService);
    const service = profileService.email;

    const initializing = service.initializing.use().current;
    const loading = service.loading.use().current;
    const emailValue = service.store.getValue("email").use().current;
    const [initialName, setInitalName] = useState(service.store.getValue("email").use().current);

    const checkFirstLoadAfterFetch = () => {
        if (!service.initializing.current && !service.loading.current) {
            setInitalName(service.store.getValue("email").current);
        }
    };

    useEffect(checkFirstLoadAfterFetch, [initializing, loading]);

    return (
        <Form service={service} className={classes.form}>
            <TextField fullWidth value={service.store.getValue("email") as any} disabled={true} />
        </Form>
    );
}

export function AccountPasswordProfile() {
    const t = Translation.useMap(["updatePassword"]);
    const classes = useStyles();

    const profileService = useService(AccountProfileService);
    const service = profileService.password;

    const error = service.error.message.use().current;
    const success = service.success.message.use().current;
    const submittable = service.store.submittable.use().current;

    return (
        <Form service={service} className={classes.form} autoComplete="off">
            <TextField fullWidth value={service.store.getValue("newPassword") as any} autoComplete="new-password" />
            <TextField fullWidth value={service.store.getValue("newPasswordVerification") as any} autoComplete="new-password" />

            <Button
                color={"primary"}
                variant={"contained"}
                className={classes.submit}
                type={"submit"}
                children={t.get("updatePassword")}
                disabled={!submittable}
            />

            {(error || success) && <FormHelperText error={!!error} className={classes.status} children={error || success} />}
        </Form>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            padding: "0rem 2rem 2rem 2rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "stretch",

            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                paddingBottom: "2rem",
            },
        },

        hr: {
            width: "100%",
        },

        form: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flexGrow: 1,
            flexShrink: 1,
            margin: "1rem 0",
            maxWidth: "50%",

            "& .MuiTextField-root": {
                marginTop: "1rem",
            },

            [theme.breakpoints.down("sm")]: {
                margin: 0,
                width: "100%",

                "&:last-of-type": {
                    borderBottom: "none",
                },
            },
        },

        submit: {
            marginTop: "1.5rem",
            fontWeight: 600,
            fontSize: "1rem",
        },

        status: {
            paddingTop: "0.5rem",
        },
    }),
    { name: "AccountProfile" },
);
