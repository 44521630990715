import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useService, makeStyles, Translation, Tooltip, useTheme } from "lib";

import { AccountPaymentsService } from "../service";

export function AccountPaymentsTableHead() {
    const service = useService(AccountPaymentsService);

    const t = Translation.useMap(["status", "invoiceId", "paidAmount", "remainingAmount", "pdf"]);
    const classes = useStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>
            <colgroup>
                <col className={classes.status} />
                {!isMobile && !isTablet && <col className={classes.invoice} />}
                {!isMobile && !isTablet && <col className={classes.paidAmount} />}
                {!isMobile && !isTablet && <col className={classes.remainingAmount} />}
                <col className={classes.pdf} />
            </colgroup>

            <TableHead>
                <TableRow>
                    <TableCell children={t.render("status")} />
                    {!isMobile && !isTablet && <TableCell children={t.render("invoiceId")} />}
                    {!isMobile && !isTablet && <TableCell children={t.render("paidAmount")} />}
                    {!isMobile && !isTablet && <TableCell children={t.render("remainingAmount")} />}
                    <TableCell children={" "} />
                </TableRow>
            </TableHead>
        </>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {},
        status: {
            width: "15%",
            maxWidth: "32px",

            [theme.breakpoints.down("sm")]: {
                width: "100%",
            },
        },
        invoice: {
            width: "30%",
        },
        paidAmount: {
            width: "20%",
        },
        remainingAmount: {
            width: "20%",
        },
        pdf: {
            width: "1rem",
        },
    }),
    { name: "AccountPaymentsTable" },
);
