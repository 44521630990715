/* eslint-disable @typescript-eslint/camelcase */
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, Skeleton, useTheme } from "lib";

export function AccountPaymentsTableRowPlaceholder(props: { initializing?: boolean; isMobile?: boolean; isTablet?: boolean }) {
    const classes = useStyles();

    const isMobile = props.isMobile != null ? props.isMobile : useMediaQuery(useTheme().breakpoints.down("sm"));
    const isTablet = props.isTablet != null ? props.isTablet : useMediaQuery(useTheme().breakpoints.down("md"));

    return (
        <TableRow className={classes.root}>
            <TableCell children={props.initializing && <Skeleton children={""} />} />
            <TableCell children={props.initializing && <Skeleton children={""} />} />

            {!isMobile && !isTablet && (
                <>
                    <TableCell children={props.initializing && <Skeleton children={""} />} />
                    <TableCell children={props.initializing && <Skeleton children={""} />} />
                    <TableCell children={props.initializing && <Skeleton children={""} />} />
                </>
            )}
        </TableRow>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            height: 53,
            maxHeight: 53,

            "& .MuiSkeleton-animate": {
                margin: 0,
            },

            "& .MuiTableCell-root": {
                padding: "0.25rem 1rem",
            },

            "& .MuiSkeleton-text:empty:before": {
                color: "transparent",
            },
        },
        urla: {},
    }),
    { name: "RowPlaceholder" },
);
