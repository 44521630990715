import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles, Box, Translation, useService, TemplateType } from "lib";
import { CampaignsActivityService } from "../marketing-activity/service";
import { CampaignsActivityTable } from "../marketing-activity/marketing-activity.table";
import { MarketingActivityActions } from "marketing/marketing-activity/actions";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import { MessageRoute } from "marketing/marketing-activity/message/message";

export function MarketingEmailActivity() {
    const classes = useStyles();
    const t = Translation.useMap(["marketing", "campaignsEmailActivity"]);
    const service = useService(CampaignsActivityService, { type: TemplateType.email });

    return (
        <>
            <Helmet title={[t.get("campaignsEmailActivity"), t.get("marketing"), "URLA"].join(" - ")} />

            <Box className={classes.root}>
                <MarketingActivityActions service={service} />
                <CampaignsActivityTable service={service} />
            </Box>

            <Switch>
                <Route<RouteProps> exact path="/marketing/email-activity/:messageId" component={MessageRoute} />

                <Redirect to="/marketing/email-activity" />
            </Switch>
        </>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            padding: 0,
            position: "relative",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            flexShrink: 1,
        },
    }),
    { name: "MarketingEmailActivity" },
);
