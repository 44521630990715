import React from "react";
import { omit, pick, Abon } from "lib/utils";
import { CompletedTextOption } from "lib/form/value-meta";
import uniqBy from "lodash/uniqBy";

import { Value, ValueProps } from "../value";
import { TextValue } from "../text";

import { CompletedTextValueProps } from "./completed-text-value.types";

export class CompletedTextValue<T> extends TextValue {
    static COMPLETED_TEXT_VALUE_KEYS: (keyof CompletedTextValueProps<any>)[] = ["options", "fetchOptions", "getOptions", "freeSolo"];

    static useCompletedTextValue<T>(props: CompletedTextValueProps<T>) {
        const completedTextValue = React.useMemo(() => new CompletedTextValue(props), []);

        React.useEffect(() => () => completedTextValue.clear(), []);

        return completedTextValue;
    }

    static selectCompletedTextValueProps<PT extends CompletedTextValueProps<T>, T = any>(
        props: PT,
    ): Omit<PT, keyof CompletedTextValueProps<T>> & {
        completedTextValueProps: CompletedTextValueProps<T>;
    } {
        const { textValueProps, ...allProps } = TextValue.selectTextValueProps(props);

        return {
            ...omit(allProps, CompletedTextValue.COMPLETED_TEXT_VALUE_KEYS),
            completedTextValueProps: {
                ...textValueProps,
                ...pick(allProps, CompletedTextValue.COMPLETED_TEXT_VALUE_KEYS),
            },
        } as any;
    }

    completedTextProps: Omit<CompletedTextValueProps<T>, keyof ValueProps<string>>;
    options: Abon<CompletedTextOption<T>[]>;
    selected: Abon<T>;
    selectedOption: Abon<CompletedTextOption<T>>;

    constructor(props: CompletedTextValueProps<T> = {}) {
        const { textValueProps, ...completedTextProps } = TextValue.selectTextValueProps(props);

        super(textValueProps);

        this.completedTextProps = completedTextProps;
        this.options = new Abon(props.getOptions ? props.getOptions() : props.options || []);
        this.selected = new Abon();
        this.selectedOption = new Abon();
    }

    use() {
        const usedValue = super.use();

        if (!this.completedTextProps.useOptions && (this.completedTextProps.getOptions || this.completedTextProps.fetchOptions)) {
            React.useMemo(() => {
                if (this.completedTextProps.fetchOptions) {
                    this.completedTextProps.fetchOptions().then((options) => {
                        this.options.set(options);
                    });
                } else {
                    this.options.set(this.completedTextProps.getOptions());
                }
            }, []);
        }

        let options = this.completedTextProps.useOptions ? this.completedTextProps.useOptions() : this.options.use().current;

        options = uniqBy(options, (option) => option.label || option.value);

        React.useMemo(() => {
            const selected = options.find((option) => (option.label || option.value) === usedValue.current);

            if (selected) {
                this.selected.set(selected.value);
            } else {
                this.selected.set(undefined);
            }

            this.selectedOption.set(selected);
        }, [options, usedValue.current]);

        return {
            ...usedValue,
            options,
        };
    }

    async onBlur(evt: React.FocusEvent<HTMLInputElement>) {
        await super.onBlur(evt);

        if (this.completedTextProps.freeSolo) {
            return;
        }

        if (!this.selected.current) {
            await this.set(undefined);
        } else {
            this.value.notify();
        }
    }
}
