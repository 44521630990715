import "./locale-config";

import React from "react";
import moment from "moment";
import numeral from "numeral";
import { createIntl, createIntlCache, IntlShape, IntlCache, FormatNumberOptions } from "react-intl";
import { Abon } from "lib/utils";
import translations from "translations";

import { TranslationThunk, TranslationResult, TranslationQuery, LOCALE } from "./translation.types";

const LS_LOCALE_KEY = "urla-locale";
const DEFAULT_LOCALE = LOCALE.en;

let initialLocale: LOCALE = LOCALE[localStorage.getItem(LS_LOCALE_KEY)];

if (!initialLocale) {
    const userLang: string = navigator.language || (navigator as any).userLanguage;

    if (userLang && LOCALE[userLang]) {
        initialLocale = LOCALE[userLang];
    } else {
        initialLocale = DEFAULT_LOCALE;
    }
}

class Translation {
    static translations = translations;

    locale = new Abon<LOCALE>(initialLocale);

    intl: IntlShape;
    cache: IntlCache;

    constructor() {
        this.cache = createIntlCache();

        this.setLocale(this.locale.current);

        this.locale.subscribe(this.setLocale.bind(this));

        numeral.locale(this.locale.current);

        this.locale.subscribe((locale) => {
            numeral.locale(locale);
            localStorage.setItem(LS_LOCALE_KEY, locale);
        });
    }

    result(message: string) {
        return new TranslationResult(message);
    }

    use<I extends string>(thunk: TranslationThunk<I>) {
        this.locale.use();

        return this.get(thunk);
    }

    useRender<I extends string>(thunk: TranslationThunk<I>) {
        const used = this.use(thunk);

        return used != null ? String(used) : undefined;
    }

    get<I extends string>(thunk: TranslationThunk<I>): string {
        if (!thunk) {
            return null;
        }

        if (thunk instanceof TranslationResult) {
            return thunk as string;
        } else if (typeof thunk === "string") {
            if (this.checkMissing(thunk)) {
                return thunk;
            }

            return this.result(this.intl.formatMessage({ id: thunk })) as string;
        }

        if (this.checkMissing(thunk.id)) {
            return thunk.id;
        }

        const { values, ...descriptor } = thunk;

        return this.result(this.intl.formatMessage(descriptor, values)) as string;
    }

    render<I extends string>(thunk: TranslationThunk<I>): string {
        const gotten = this.get(thunk);

        if (gotten == null) {
            return gotten;
        }

        return String(gotten);
    }

    useMap<I extends string>(queries: (I | TranslationQuery<I>)[]): TranslationMap<I> {
        this.locale.use();

        const map = React.useMemo(() => new TranslationMap<I>(), []);

        queries.forEach((query) => {
            if (!query) {
                return;
            }

            if (typeof query === "string") {
                map.set(query);
            } else {
                map.set(query.id, query);
            }
        });

        return map;
    }

    has(id: string) {
        return !!this.intl.messages[id];
    }

    formatNumber(num: number | string, opts?: FormatNumberOptions | string): string {
        if (num == null) {
            return num as any;
        }

        if (typeof opts === "string") {
            return numeral(num).format(opts);
        }

        return this.intl.formatNumber(this.parseNumber(num), opts);
    }

    parseNumber(num: number | string): number {
        return numeral(num).value();
    }

    setLocale(locale: LOCALE) {
        if (!Translation.translations[locale]) {
            locale = DEFAULT_LOCALE;
        }

        this.intl = createIntl(
            {
                locale,
                messages: Translation.translations[locale],
            },
            this.cache,
        );

        this.locale.set(locale);
        moment.locale(locale);
    }

    private checkMissing(id: string) {
        if (!this.intl.messages[id]) {
            console.warn(`Missing message "${id}" for locale "${this.locale.current}"`);
            return true;
        }

        return false;
    }
}

export class TranslationMap<I extends string> {
    protected map = new Map<I, TranslationResult>();

    set(id: I, query?: Omit<TranslationQuery<I>, "id">) {
        if (query) {
            this.map.set(id, new TranslationResult(T.get({ ...query, id })));
        } else {
            this.map.set(id, new TranslationResult(T.get(id)));
        }
    }

    get(id: I): string | (string & TranslationResult) {
        const result = this.map.get(id);

        if (result) {
            return result as any;
        }

        return id;
    }

    render(id: I): string {
        return String(this.get(id));
    }
}

const T = new Translation();

if ((module as any).hot) {
    (module as any).hot.accept("translations", function() {
        try {
            Translation.translations = require("translations").default;
            (T.locale as any).notify();
        } catch (error) {
            console.error("Failed reloading translations", error);
        }
        // Do something with the updated library module...
    });
}

export { T as Translation };
