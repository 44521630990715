import React from "react";
import Button from "@material-ui/core/Button";
import SelectedIcon from "@material-ui/icons/Check";
import { Box, makeStyles, Paper, clsx, Abon, Typography, ItemRecordKey } from "lib";

import { TemplateReceiversService, SelectableItems } from "../../services";

export function TemplateReceiversList<T extends object, I extends keyof T>(props: TemplateReceiversListProps<T, I>) {
    const { list, title, header, getLabel } = props;

    const c = useStyles();

    const ids = list.ids.use().current;

    return (
        <Paper className={c.root}>
            <Box className={clsx(c.header, !header && c.headerWithTitle)}>
                {header || <Typography className={c.title} children={title} />}
            </Box>

            <Box className={c.items}>
                {ids.map((id) => (
                    <TemplateReceiversListItem key={String(id)} list={list} id={id} c={c} getLabel={getLabel} />
                ))}
            </Box>
        </Paper>
    );
}

function TemplateReceiversListItem<T extends object, I extends keyof T>(
    props: Pick<TemplateReceiversListProps<T, I>, "list" | "getLabel"> & { id: T[I]; c: ReturnType<typeof useStyles> },
) {
    const { list, getLabel, id, c } = props;

    const label = Abon.useComposedValue(
        () => (list.indexOf(id) >= 0 ? getLabel(list.get(id as ItemRecordKey<T, I>)) : undefined),
        (listener) => [list.indexOf(id) >= 0 && list.subscribe(id, listener)],
        [list, id, list.indexOf(id) >= 0],
    );
    const selected = Abon.useComposedValue(
        () => list.selectedIds.current.includes(id),
        (listener) => [list.selectedIds.subscribe(listener)],
        [list, id],
    );
    const onClick = React.useCallback(() => {
        if (list.selectedIds.current.includes(id)) {
            list.selectedIds.set(list.selectedIds.current.filter((_id) => _id !== id));
        } else {
            list.selectedIds.set(list.selectedIds.current.concat(id));
        }
    }, [id, list]);

    return (
        <Button className={clsx(c.item, selected && c.itemSelected)} onClick={onClick} endIcon={selected ? <SelectedIcon /> : " "}>
            {label}
        </Button>
    );
}

export interface TemplateReceiversListProps<T extends object, I extends keyof T> {
    list: SelectableItems<T, I>;
    getLabel(value: T): string;
    title?: React.ReactNode;
    header?: JSX.Element;
}

const useStyles = makeStyles(
    () => ({
        root: {
            flex: "1 0 10rem",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#e3e5e5",
            height: "40rem",
            overflow: "hidden",
        },
        header: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flex: "0 0 auto",
            backgroundColor: "#212121",
            alignItems: "center",
        },
        headerWithTitle: {
            padding: "13px 16px 10px 16px",
        },
        title: {},
        items: {
            display: "flex",
            flexDirection: "column",
            flex: "1 1 auto",
            overflowY: "scroll",
            overflowX: "hidden",
            position: "relative",
            padding: "16px 0",
            width: "100%",
        },
        item: {
            padding: "6px 24px",
            fontSize: 15,
            cursor: "pointer",
            textAlign: "left",
            width: "100%",
            alignItems: "flex-start",
            borderRadius: 0,
            textTransform: "none",
            color: "rgba(45, 57, 61, 1)",

            "& .MuiButton-endIcon": {
                flex: "1 0 24px",
                justifyContent: "flex-end",
            },

            "&:hover": {
                backgroundColor: "rgba(45, 57, 61, 0.15)",
            },
        },
        itemSelected: {
            // backgroundColor: "rgba(255,255,255,.05)",
            backgroundColor: "rgba(45, 57, 61, 0.1)",
        },
    }),
    { name: "TemplateReceiversList" },
);

export interface TemplateReceiversProps {
    receivers: TemplateReceiversService;
}
