import React from "react";
import { omit, pick } from "lib/utils";

import { Value, ValueProps } from "../value";

import { CheckboxValueProps } from "./checkbox-value.types";

export class CheckboxValue extends Value<boolean> {
    static useCheckboxValue(props: CheckboxValueProps) {
        const checkboxValue = React.useMemo(() => new CheckboxValue(props), []);

        React.useEffect(() => () => checkboxValue.clear(), []);

        return checkboxValue;
    }

    static selectCheckboxValueProps<PT extends CheckboxValueProps, T = any>(
        props: PT,
    ): Omit<PT, keyof CheckboxValueProps> & {
        checkboxValueProps: CheckboxValueProps;
    } {
        const { valueProps, ...allProps } = Value.selectValueProps(props);

        return {
            ...omit(allProps, "onChange"),
            checkboxValueProps: {
                ...valueProps,
                ...pick(allProps, "onChange"),
            },
        } as any;
    }

    checkboxProps: Omit<CheckboxValueProps, keyof ValueProps<string>>;

    constructor(props: CheckboxValueProps = {}) {
        const { valueProps, ...checkboxProps } = Value.selectValueProps(props);

        super(valueProps);

        this.checkboxProps = checkboxProps;

        this.onSwitch = this.onSwitch.bind(this);
    }

    use() {
        const usedValue = super.use();

        return {
            ...usedValue,
            current: usedValue.current || false,
        };
    }

    onSwitch() {
        this.set(!this.current);

        if (this.checkboxProps.onChange) {
            this.checkboxProps.onChange(this.current);
        }
    }
}
