/* eslint-disable @typescript-eslint/camelcase */
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import ZeroIcon from "@material-ui/icons/Clear";
import { animated } from "react-spring";
import Button from "@material-ui/core/Button";
import {
    makeStyles,
    Tooltip,
    TableCellComponentProps,
    Translation,
    Api,
    ENDPOINT,
    Abon,
    CardStack,
    StackItemProps,
    Auth,
    ROLE,
    useService,
    useTheme,
} from "lib";

import { Urla } from "../shared";
import { AccountRemindersService } from "account/reminders/account-reminders-service";
import { useMediaQuery } from "@material-ui/core";

export function HomeTableViewsCell(props: TableCellComponentProps<Urla, "id">) {
    const classes = useStyles();
    const theme = useTheme();
    const roleId = Auth.use("accessToken", "role_id");
    const blockedTrialAccount = Auth.use("blockedTrialAccount");
    const reminders = useService(AccountRemindersService);
    const t = Translation.useMap(["resetViews"]);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const value: number = props.value ? Number(props.value) : 0;

    let formatted: string;

    if (roleId === ROLE.TRIAL && blockedTrialAccount) {
        formatted = "?";
    } else if (value > 10000) {
        formatted = Translation.formatNumber(value, "0.00a");
    } else if (value > 1000) {
        formatted = Translation.formatNumber(value, "0.0a");
    } else if (value > 100) {
        formatted = Translation.formatNumber(value, "0a");
    } else {
        formatted = Translation.formatNumber(value);
    }

    const confirmingReset = Abon.use(() => false);

    const onReset = React.useCallback(() => {
        confirmingReset.set(true);
    }, [confirmingReset]);

    function handleBlockedAction() {
        reminders.open.set(true);
    }

    CardStack.use(
        confirmingReset.current,
        () => ({
            renderer: (stackProps) => <ConfirmResetViewsModal {...stackProps} {...props} />,
            onClose: () => confirmingReset.set(false),
            interpolations: { transform: { tension: 600 } },
        }),
        [confirmingReset, props.service, props.id],
    );

    return (
        <TableCell className={classes.root}>
            <div className={classes.inner}>
                <div className={classes.number} children={formatted} />
                {!isMobile && (
                    <Tooltip title={t.render("resetViews")}>
                        <span>
                            <IconButton
                                onClick={roleId === ROLE.TRIAL && blockedTrialAccount ? handleBlockedAction : onReset}
                                disabled={!value}
                                color="inherit"
                                size="small"
                                className={classes.button}
                            >
                                <ZeroIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}
            </div>
        </TableCell>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {},
        inner: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
        },
        number: {
            color: theme.palette.text.primary,
            backgroundColor: "rgba(0,0,0,0.1)",
            padding: "0.5rem 0.6rem 0.35rem 0.6rem",
            borderRadius: 3,
            marginLeft: -6,
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontWeight: 600,
        },
        button: {
            color: theme.palette.text.secondary,
            "& > span": {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            },

            "& > span > svg": {
                fontSize: "1rem",
            },
        },
    }),
    { name: "ViewsCell" },
);

function ConfirmResetViewsModal(props: StackItemProps & TableCellComponentProps<Urla, "id">) {
    const { service, id, onClose, interpolations } = props;

    const t = Translation.useMap(["resetUrlaViewsConfirmation", "resetViews", "cancel"]);
    const classes = useConfirmStyles();

    const onReset = React.useCallback(async () => {
        service.values.set(id, "number_of_views", "0");
        onClose();
        Api.request(ENDPOINT.resetNumOfViews, { urla_id: id });
    }, [service, id, onClose]);

    return (
        <animated.div className={classes.paper} style={{ transform: interpolations.transform }}>
            <p className={classes.description}>{t.render("resetUrlaViewsConfirmation")}</p>

            <Button onClick={onReset} variant="outlined" className={classes.resetButton}>
                {t.render("resetViews")}
            </Button>

            <Button onClick={onClose} className={classes.cancelButton}>
                {t.render("cancel")}
            </Button>
        </animated.div>
    );
}

const useConfirmStyles = makeStyles(
    (theme) => ({
        paper: {
            width: 350,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3, 4),
            outline: 0,
            borderRadius: 4,
        },
        description: {
            flex: "1 1 100%",
            color: theme.palette.text.primary,
            fontSize: 16,
        },
        resetButton: {
            fontWeight: 600,
            color: theme.palette.text.primary,
        },
        cancelButton: {
            color: theme.palette.text.secondary,
            marginLeft: "0.5rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            fontWeight: 400,
        },
    }),
    { name: "ConfirmResetViewsModal" },
);
