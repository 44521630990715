import React from "react";
import { animated } from "react-spring";
import { makeStyles, clsx } from "lib/material";
import { Abon } from "abon";

import { StackItemProps } from "../types";
import { SnackStack } from "../services";

import { StackProviderItemProps } from "./stack-provider";

export function StackProviderSnackItem(props: StackProviderItemProps<SnackStack>) {
    let children: React.ReactNode;

    const onClose = React.useCallback(() => {
        if (props.item.onClose) {
            props.item.onClose("manual");
        }

        props.stack.remove(props.item.id);
    }, [props.stack, props.item.onClose, props.item.id]);

    const onTimeout = React.useCallback(() => {
        if (props.item.onClose) {
            props.item.onClose("timeout");
        }

        props.stack.remove(props.item.id);
    }, [props.stack, props.item.onClose, props.item.id]);

    const rootRef = React.useRef<HTMLDivElement>();

    const itemProps: StackItemProps = { ...props, onClose };

    if (props.item.component) {
        children = React.createElement(props.item.component, { ...itemProps, key: "_c" });
    } else if (props.item.renderer) {
        children = props.item.renderer(itemProps);
    } else {
        const childrenArray: React.ReactNodeArray = [];

        const contentClasses = useContentStyles();

        let symbolChildren: React.ReactNode;

        if (props.item.httpStatus) {
            symbolChildren = (
                <div key="_http" className={clsx("Snack-symbol", contentClasses.symbol, contentClasses.httpSymbol)}>
                    <span children={["HTTP", props.item.httpStatus].filter(Boolean).join(" ")} />
                </div>
            );
        }

        if (symbolChildren !== undefined) {
            childrenArray.push(symbolChildren);
        }

        let messageChildren: React.ReactNode;

        if (props.item.messageComponent) {
            messageChildren = React.createElement(props.item.messageComponent, { ...itemProps, key: "_mc" });
        } else if (props.item.messageRenderer) {
            messageChildren = props.item.messageRenderer(itemProps);
        } else {
            messageChildren = props.item.message;
        }

        if (messageChildren != null && (props.item.wrapMessage || (props.item.wrapMessage !== false && !props.item.messageComponent))) {
            messageChildren = <div key="_mw" className={clsx("Snack-message", contentClasses.message)} children={messageChildren} />;
        }

        if (messageChildren !== undefined) {
            childrenArray.push(messageChildren);
        }

        if (childrenArray.length) {
            children = childrenArray;
        }
    }

    const classes = useWrapperStyles();

    if (props.item.wrap !== false && ((!props.item.component && !props.item.renderer) || props.item.wrap === true)) {
        children = (
            <animated.div
                style={{ transform: props.interpolations.transform }}
                className={clsx("Snack-snack", classes.snack, props.item.type === "error" && classes.error)}
                children={children}
                key="_w"
            />
        );
    }

    const zIndex: number = Abon.useComposedValue(
        () => {
            const state = props.stack.state.current;
            const index = state.ids.indexOf(props.item.id);
            const zIndex = state.amount - index;

            return zIndex;
        },
        (listener) => [props.stack.state.subscribe(listener)],
        [props.item.id, props.zIndex],
    );

    const timeout = React.useRef<NodeJS.Timeout>();

    React.useEffect(() => {
        if (props.state === "enter" || props.state === "update") {
            timeout.current = setTimeout(onTimeout, props.item.duration || SnackStack.defaultDuration);
        } else if (timeout.current !== undefined) {
            clearTimeout(timeout.current);
        }

        return () => {
            if (timeout.current !== undefined) {
                clearTimeout(timeout.current);
            }
        };
    }, [onTimeout, props.item.duration]);

    children = (
        <animated.div
            style={{ zIndex, pointerEvents: props.state === "leave" ? "none" : undefined, opacity: props.interpolations.opacity }}
            className={clsx(
                "Snack-root",
                classes.root,
                classes[props.item.position || SnackStack.defaultPosition],
                props.item.dim === "dark" ? classes.dimDark : undefined,
            )}
            children={children}
            ref={rootRef}
        />
    );

    if (children === undefined) {
        children = null;
    }

    return children as any;
}

const useContentStyles = makeStyles(
    (theme) => ({
        message: { color: "rgba(255,255,255,1)", fontSize: 15 },
        symbol: {
            backgroundColor: "rgba(0,0,0,.1)",
            padding: theme.spacing(0.5, 1, 0, 1),
            marginRight: theme.spacing(1.25),
            marginLeft: theme.spacing(-1),
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            pointerEvents: "none",
            userSelect: "none",
            borderRadius: theme.shape.borderRadius,
        },
        httpSymbol: {
            "& span": {
                marginLeft: theme.spacing(0.35),
                fontSize: 11,
                fontWeight: 900,
                padding: theme.spacing(0.5, 0),
            },
        },
    }),
    { name: "Snack" },
);

const useWrapperStyles = makeStyles(
    (theme) => ({
        snack: {
            padding: theme.spacing(1.5, 2.5, 0.75, 2.5),
            backgroundColor: theme.palette.primary.main,
            borderBottom: "3px solid rgba(0,0,0,0.1)",
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[5],
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            maxWidth: "20rem",
        },
        error: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText,

            "& .Snack-message": {
                color: theme.palette.error.contrastText,
            },
        },

        root: {
            position: "fixed",
            flex: "0 0 auto",
        },

        "top-left": {
            top: theme.spacing(3),
            left: theme.spacing(),
        },
        "top-right": {
            top: theme.spacing(),
            right: theme.spacing(),
        },
        "bottom-right": {
            bottom: theme.spacing(),
            right: theme.spacing(),
        },
        "bottom-left": {
            bottom: theme.spacing(2.5),
            left: theme.spacing(3),
        },

        dimDark: { backgroundColor: "rgba(0,0,0,0.35)" },
    }),
    { name: "Snack" },
);
