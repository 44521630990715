import { FormService, ValueMetaMap, Api, ENDPOINT, ValueStore, DateValue } from "lib";
import "reflect-metadata";
import { IsString } from "class-validator";
import moment from "moment";

export class ExportStatisticsService extends FormService<ExportStatisticsProps> {
    constructor() {
        super({
            store: new ValueStore<ExportStatisticsProps>({
                type: ExportStatisticsProps,
                metaMap: new ValueMetaMap<ExportStatisticsProps>({
                    meta: {
                        dateFrom: {
                            label: "fromDate",
                            placeholder: " ",
                            type: "date",
                            initial: moment()
                                .subtract({ days: 1 })
                                .format("YYYY-MM-DD"),
                            required: true,
                        },
                        dateTo: {
                            label: "toDate",
                            type: "date",
                            placeholder: " ",
                            initial: moment().format("YYYY-MM-DD"),
                            required: true,
                        },
                    },
                }),
            }),

            onSubmit: async (props) => {
                const stopLoading = this.loading.start();

                Api.request<ENDPOINT.exportUrlaStatistics>(ENDPOINT.exportUrlaStatistics, {
                    date_from: props.dateFrom,
                    date_to: props.dateTo,
                })
                    .then((response) => {
                        const a = document.createElement("a");
                        document.body.appendChild(a);
                        const blob = new Blob([response], { type: "octet/stream" }),
                            url = window.URL.createObjectURL(blob);
                        a.href = url;
                        // a.href = response.url;
                        a.download = `urla_statistics_${props.dateFrom}_${props.dateTo}.csv`;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        this.store.addSuccess("statisticsExported");
                    })
                    .catch((error) => {
                        this.store.addError("unknownError");
                    })
                    .finally(() => {
                        stopLoading();
                    });
            },
        });
    }

    get dateFrom(): DateValue {
        return this.store.getValue("dateFrom") as any;
    }

    get dateTo(): DateValue {
        return this.store.getValue("dateTo") as any;
    }
}

export class ExportStatisticsProps {
    @IsString()
    dateFrom: string;

    @IsString()
    dateTo: string;
}
