export enum ROLE {
    INACTIVE,
    FREE,
    PRO,
    ENTERPRISE,
    TEST,
    TRIAL,
    CUSTOM_1,
    CUSTOM_2,
    HOTEL,
    ADMIN,
}
export interface AccessToken {
    user_id: string;
    email: string;
    role_id: number;
    exp: number;
    iss: string;
}

export interface LoginProps {
    email: string;
    password: string;
}

export interface AuthState {
    accessToken?: AccessToken;
    /** If an access token exists. */
    authenticated: boolean;
    blockedTrialAccount: boolean;
}
