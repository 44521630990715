import React from "react";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import { makeStyles, Translation, TableService } from "lib";

export function UrlaSymbol(props: { service: TableService<any, any, any> }) {
    const classes = useStyles();
    const t = Translation.useMap(["urla"]);

    return (
        <div className={classes.root}>
            <p className={classes.label}>{t.render("urla")}</p>

            <RefreshButton {...props} />
        </div>
    );
}

export function ViewsSymbol(props: { service: TableService<any, any, any> }) {
    const classes = useStyles();
    const t = Translation.useMap(["views"]);

    return (
        <div className={classes.root}>
            <p className={classes.label}>{t.render("views")}</p>

            <RefreshButton {...props} />
        </div>
    );
}

const useStyles = makeStyles(
    {
        root: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            height: 24,
        },

        label: {},
    },
    { name: "UrlaSymbol" },
);

export function RefreshButton(props: { service: TableService<any, any, any> }) {
    const classes = useRefreshStyles({});

    const onRefresh = React.useCallback(
        (evt: React.MouseEvent<HTMLButtonElement>) => {
            if (evt) {
                evt.stopPropagation();
            }

            props.service.refresh();
        },
        [props.service],
    );

    return (
        <IconButton className={classes.button} onClick={onRefresh}>
            <RefreshIcon />
        </IconButton>
    );
}
const useRefreshStyles = makeStyles(
    {
        button: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginRight: -4,
            marginTop: -2,
            marginLeft: 2,
            padding: 0,
            width: 24,
            height: 24,

            "& svg": {
                width: 20,
                height: 20,
            },
        },
    },
    { name: "RefreshButton" },
);
