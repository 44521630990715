import React, { useEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DoneIcon from "@material-ui/icons/Done";
import InfoIcon from "@material-ui/icons/Info";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Button from "@material-ui/core/Button";
import {
    Paper,
    Box,
    makeStyles,
    Translation,
    Typography,
    Tooltip,
    clsx,
    Abon,
    SUBSCRIPTION_PLAN,
    CURRENCY,
    CURRENCY_SYMBOL,
    CURRENCY_PRICE,
    ROLE,
    Auth,
} from "lib";

import { UpgradeCheckout } from "../checkout";
import { CancelSubscription } from "../cancel-subscription";

import { UpgradePlanProps } from "./upgrade-plan.types";

export function UpgradePlan(props: UpgradePlanProps) {
    const { name, features, video, active, plan, monthlyPrice = PRICE_MAP[plan], disabled, openDowngradeSubscription } = props;
    let { price, priceInfo } = props;
    const roleId = Auth.use("accessToken", "role_id");

    const t = Translation.useMap([
        "get",
        "featuresIncluded",
        "billedMonthly",
        "perMonth",
        "cancelSubscription",
        "upgradeTo",
        "downgradeTo",
        "cancel",
        "commingSoon",
        "cancelProTrialSubscription",
        "proTrial",
    ]);
    const openCheckout = Abon.use(() => false);
    const openCancelSubscription = Abon.use(() => false);
    const canDrowngrade = Abon.use(() => false);
    const openDrowngrade = Abon.use(() => false);
    const downGradePlan = Abon.use(() => SUBSCRIPTION_PLAN.FREE);
    const classes = useStyles();

    const canDrowngradeHandler = () => {
        plan &&
        ((plan === SUBSCRIPTION_PLAN.PRO && roleId === ROLE.ENTERPRISE) || (plan === SUBSCRIPTION_PLAN.FREE && roleId === ROLE.TRIAL))
            ? canDrowngrade.set(true)
            : canDrowngrade.set(false);

        if (
            !openCheckout.current &&
            !openCancelSubscription.current &&
            openDowngradeSubscription.current &&
            plan &&
            ((plan === SUBSCRIPTION_PLAN.PRO && roleId === ROLE.ENTERPRISE) ||
                (plan === SUBSCRIPTION_PLAN.FREE && roleId === ROLE.PRO) ||
                (plan === SUBSCRIPTION_PLAN.FREE && roleId === ROLE.TRIAL))
        ) {
            openDrowngrade.set(true);
            if (roleId === ROLE.ENTERPRISE) {
                downGradePlan.set(SUBSCRIPTION_PLAN.PRO);
            } else if (roleId === ROLE.PRO || roleId === ROLE.TRIAL) {
                downGradePlan.set(SUBSCRIPTION_PLAN.FREE);
            }
        } else {
            openDrowngrade.set(false);
        }
    };

    useEffect(() => {
        if (!openDrowngrade.current && openDowngradeSubscription.current) {
            openDowngradeSubscription.set(false);
        }
    }, [openDrowngrade.current]);

    useEffect(canDrowngradeHandler, [
        openCheckout.current,
        openCancelSubscription.current,
        openDowngradeSubscription.current,
        plan,
        roleId,
    ]);

    if (monthlyPrice) {
        price = [
            CURRENCY_SYMBOL[CURRENCY.EUR],
            " ",
            monthlyPrice[CURRENCY.EUR],
            " / ",
            // CURRENCY_SYMBOL[CURRENCY.USD],
            // " ",
            // monthlyPrice[CURRENCY.USD],
            // " / ",
            monthlyPrice[CURRENCY.SEK],
            " ",
            CURRENCY_SYMBOL[CURRENCY.SEK],
        ].join(" ");

        if (!priceInfo) {
            priceInfo = t.get("billedMonthly");
        }
    }

    return (
        <Paper className={classes.root}>
            <Typography children={name} className={classes.head} />

            {(features && features.length && (
                <List subheader={<ListSubheader children={t.get("featuresIncluded")} />} className={classes.features}>
                    {features.map((feature, i) => (
                        <ListItem key={i}>
                            <ListItemIcon>
                                <DoneIcon />
                            </ListItemIcon>
                            <ListItemText primary={Translation.get(feature.label)} />

                            {feature.tooltip && (
                                <Tooltip title={Translation.get(feature.tooltip)}>
                                    <ListItemSecondaryAction>
                                        <ListItemIcon>
                                            <InfoIcon />
                                        </ListItemIcon>
                                    </ListItemSecondaryAction>
                                </Tooltip>
                            )}
                        </ListItem>
                    ))}
                </List>
            )) ||
                null}

            <Box className={classes.foot}>
                {video && (
                    <Box className={classes.video}>
                        <iframe title={name} frameBorder="0" scrolling="0" src={video} allow="autoplay; fullscreen" />
                    </Box>
                )}

                <Box className={classes.footDetails}>
                    {price && <Typography children={price} className={classes.price} />}
                    {priceInfo && <Typography children={priceInfo} className={classes.priceInfo} />}

                    {price && (
                        <Button
                            children={
                                active
                                    ? t.render("cancelSubscription")
                                    : disabled
                                    ? t.render("commingSoon")
                                    : [t.render(canDrowngrade.current ? "downgradeTo" : "get"), name].join(" ")
                            }
                            className={clsx(classes.get, (active || disabled) && classes.cancel)}
                            color={active || disabled ? "inherit" : "primary"}
                            disabled={disabled}
                            onClick={() => {
                                if (!plan || disabled) {
                                    return;
                                }

                                if (!active) {
                                    if (canDrowngrade.current) {
                                        openDowngradeSubscription.set(true);
                                    } else {
                                        openCheckout.set(true);
                                    }
                                } else {
                                    openCancelSubscription.set(true);
                                }
                            }}
                        />
                    )}

                    {plan == SUBSCRIPTION_PLAN.PRO && roleId == ROLE.TRIAL && (
                        <Button
                            children={t.render("cancelProTrialSubscription")}
                            className={clsx(classes.cancel)}
                            color={"inherit"}
                            disabled={disabled}
                            onClick={() => {
                                if (!plan || disabled) {
                                    return;
                                }
                                openCancelSubscription.set(true);
                            }}
                        />
                    )}
                </Box>
            </Box>

            {openCheckout.current && plan && (
                <UpgradeCheckout open={openCheckout} price={monthlyPrice} title={[t.render("upgradeTo"), name].join(" ")} plan={plan} />
            )}

            {openDrowngrade ? (
                <UpgradeCheckout
                    open={openDrowngrade}
                    price={monthlyPrice}
                    downgrade={true}
                    title={[t.render("downgradeTo"), downGradePlan.current].join(" ")}
                    plan={downGradePlan.current}
                />
            ) : null}

            {openCancelSubscription.current && plan && (
                <CancelSubscription
                    open={openCancelSubscription}
                    downgrade={openDowngradeSubscription}
                    title={[t.render("cancel"), plan == SUBSCRIPTION_PLAN.PRO && roleId == ROLE.TRIAL ? t.render("proTrial") : name].join(
                        " ",
                    )}
                    plan={plan}
                />
            )}
        </Paper>
    );
}

const PRICE_MAP: Partial<Record<SUBSCRIPTION_PLAN, CURRENCY_PRICE>> = {
    [SUBSCRIPTION_PLAN.PRO]: {
        [CURRENCY.EUR]: 3,
        [CURRENCY.SEK]: 29,
        [CURRENCY.USD]: 4,
    },
    [SUBSCRIPTION_PLAN.ENTERPRISE]: {
        [CURRENCY.EUR]: 125,
        [CURRENCY.SEK]: 1250,
        [CURRENCY.USD]: 140,
    },
};

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            flexShrink: 1,
            backgroundColor: "rgba(42, 42, 42, 0.85)",
            overflow: "hidden",
        },
        head: {
            backgroundColor: "rgba(31, 31, 31, 0.45)",
            padding: "1.1rem 2rem 0.9rem 2rem",
            textAlign: "center",
            fontSize: "1.35rem",
            fontWeight: 600,
        },
        features: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 0,
            flexShrink: 1,
            overflow: "hidden",
            width: "100%",
            padding: "1rem 0.5rem",
        },
        foot: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            flexGrow: 1,
            flexShrink: 0,
            padding: "0rem 1.5rem 0rem 1.5rem",
        },
        video: {
            position: "relative",
            width: "100%",
            height: 0,
            paddingBottom: "56.125%",

            "& > iframe": {
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
            },
        },
        footDetails: {
            textAlign: "center",
            padding: "0 0.5rem",
        },
        price: {
            fontWeight: 600,
            fontSize: "1.1rem",
            marginTop: "2rem",
        },
        priceInfo: {
            color: theme.palette.text.secondary,
        },
        get: {
            width: "100%",
            fontWeight: 600,
            margin: "0.5rem 0",
            padding: "0.5rem 1rem",
            fontSize: "1rem",
            color: theme.palette.primary.main,
        },
        cancel: {
            color: theme.palette.text.disabled,
            fontWeight: 500,
            padding: "0.65rem 1rem",
            fontSize: "0.85rem",
            margin: "0.5rem 0",
        },
    }),
    { name: "UpgradePlan" },
);
