import React from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles, Box } from "lib";
import { SearchActivity } from "./search";

export function MarketingActivityActions(props) {
    const classes = useStyles();
    const { service } = props;

    React.useEffect(() => {
        service.search.set(undefined);
    }, []);

    return (
        <Box className={classes.root}>
            <ButtonGroup className={classes.group}>
                <SearchActivity service={service} />
            </ButtonGroup>
        </Box>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
        },
        group: {
            display: "flex",
            flexGrow: 1,
            flexShrink: 0,
            flexDirection: "row",
            alignItems: "center",
            padding: "1rem 0",
            position: "relative",

            "&:last-of-type": {
                justifyContent: "flex-end",

                [theme.breakpoints.down("sm")]: {
                    flexGrow: 0,
                    flexShrink: 1,
                },
            },
        },
    }),
    { name: "HomeActions" },
);
