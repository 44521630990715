import { ValueMetaInterface, ValueMetaProps } from "./value-meta";

export interface TimeValueMeta extends TimeValueMetaProps, ValueMetaInterface<"time", string> {}

export enum TIME_FORMAT {
    MILITARY,
}

export interface TimeValueMetaProps extends ValueMetaProps<string> {
    /** The format of the date, defaults to `MILITARY`. */
    format?: TIME_FORMAT;
}
