/* eslint-disable @typescript-eslint/camelcase */
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { useService, makeStyles, TextValue, TextField, Api, ENDPOINT, ErrorAlert, TableCellComponentProps, Auth } from "lib";

import { HomeService } from "../service";
import { Urla } from "../shared";

export function HomeTableReceiverCell(props: TableCellComponentProps<Urla, "id">) {
    const classes = useStyles();

    const service = useService(HomeService);
    const value = service.values.use(props.id, "receiver_name");
    const blockedTrialAccount = Auth.use("blockedTrialAccount");
    const isMobile = service.isMobile.use().current;
    const isTablet = service.isTablet.use().current;

    const receiver = React.useMemo(() => {
        const textValue = new TextValue({
            async onBlur() {
                if (textValue.current === service.values.get(props.id, "receiver_name")) {
                    return;
                }

                const stopLoading = textValue.loading.start();

                try {
                    await Api.request(ENDPOINT.updateReceiver, {
                        urla_id: props.id,
                        receiver_name: textValue.current || "",
                    });

                    service.values.set(props.id, "receiver_name", textValue.current);
                } catch (error) {
                    textValue.addError();
                    ErrorAlert.alert({
                        error,
                        when: "updatingTheReceiver",
                    });
                } finally {
                    stopLoading();
                }
            },
        });

        return textValue;
    }, []);

    React.useMemo(() => {
        receiver.valueProps.initial = value;
        receiver.set(value);
    }, [value]);

    return (
        <TableCell className={classes.root} title={receiver.current}>
            {!blockedTrialAccount ? <TextField value={receiver} blurOnEnter fullWidth /> : receiver.current}
        </TableCell>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    }),
    { name: "ReceiverCell" },
);
