import React from "react";
import { ServiceProvider, useService, Class } from "servido";

import { CardStack, SnackStack } from "../services";
import { Stack } from "../services/stack";
import { StackItemProps } from "../types";

import { StackProviderCardTransitioner } from "./stack-provider.card-transitioner";
import { StackProviderSnackTransitioner } from "./stack-provider.snack-transitioner";

export function StackProvider<T extends Stack>(props: React.PropsWithChildren<StackProviderProps<T>>) {
    return (
        <ServiceProvider service={props.stack}>
            {props.children}

            <StackProviderRouter {...props} />
        </ServiceProvider>
    );
}

function StackProviderRouter<T extends Stack>(props: StackProviderProps<T>) {
    const stack = useService(props.stack);

    if (stack instanceof CardStack) {
        return <StackProviderCardTransitioner {...props} stack={stack} />;
    } else if (stack instanceof SnackStack) {
        return <StackProviderSnackTransitioner {...props} stack={stack} />;
    } else {
        return null;
    }
}

export interface StackProviderProps<T extends Stack> {
    stack: Class<T>;
    /** Defines the base `zIndex` that of the stack should start from. Defaults to `1`. */
    zIndex?: number;
}

export interface StackProviderTransitionerProps<T extends Stack> extends Omit<StackProviderProps<T>, "stack"> {
    stack: T;
}

export interface StackProviderItemProps<T extends Stack> extends Omit<StackItemProps<T>, "onClose">, StackProviderTransitionerProps<T> {}
