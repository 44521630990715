/* eslint-disable @typescript-eslint/camelcase */
import { Api, Auth, ENDPOINT, AbonDeep, ROLE, TableService, DateValue, TABLE_DIRECTION, Translation } from "lib";

import { UrlaStatistics } from "../shared";

export class StatisticsService extends TableService<UrlaStatistics, "id", StatisticsOrder> {
    dateFrom = new DateValue({ initial: null, placeholder: Translation.result("—"), label: "fromDate" });
    dateTo = new DateValue({ initial: null, placeholder: Translation.result("—"), label: "toDate" });
    statistics = new AbonDeep<UrlaStatistics[]>(null);

    constructor() {
        super({
            id: "id",
            initialOrderBy: "latest_view",
            perPage: 6,
            perPageOptions: true,

            fetch: async (props) => {
                if (
                    Auth.get("accessToken", "role_id") === ROLE.FREE ||
                    (Auth.get("accessToken", "role_id") === ROLE.TRIAL && Auth.get("blockedTrialAccount"))
                ) {
                    this.statistics.set([]);

                    return {
                        values: this.statistics.current,
                        totalCount: this.statistics.current.length,
                    };
                }

                if (props.forceHydrate || !this.statistics.current) {
                    const { response } = await Api.requestAny<
                        { response: [] | UrlaStatistics[]; num_pages: number; total_count: number },
                        any
                    >(ENDPOINT.getUrlaStatistics, {
                        date_from: this.dateFrom.current || "",
                        date_to: this.dateTo.current || "",
                        order_by: props.orderBy,
                        asc_desc: props.direction === TABLE_DIRECTION.ASC ? "ASC" : "DESC",
                        pagination: 1,
                        per_page: 99999,
                    });

                    this.statistics.set(response);
                }

                return {
                    values: this.statistics.current,
                    totalCount: this.statistics.current.length,
                };
            },

            translations: { single: "statisticThe", many: "statisticMany", theMany: "statisticTheMany", the: "statisticThe" },
        });

        this.dateFrom.value.subscribe(() => {
            this.refresh();
        });

        this.dateTo.value.subscribe(() => {
            this.refresh();
        });

        this.deconstructFns.add(
            Auth.subscribe("accessToken", "role_id", (roleId: ROLE) => {
                this.perPage.set(6);
            }),
        );

        this.deconstructFns.add(
            Auth.subscribe("blockedTrialAccount", (blockedTrialAccount: boolean) => {
                if (blockedTrialAccount) {
                    this.refresh();
                }
            }),
        );
    }
}

export type StatisticsOrder = "name" | "latest_view" | "created_date" | "project_name" | "link_name" | "receiver_name" | "num_views";
