import { TableService, Template, TemplateType, Api, ENDPOINT } from "lib";

export class TemplatesTable extends TableService<Template, "id"> {
    constructor(readonly type: TemplateType) {
        super({
            id: "id",
            fetch: async () => {
                const templates = await Api.request(ENDPOINT.getTemplates, { template_type: type });
                return { values: templates, totalCount: templates.length };
            },
            translations: { single: "templateMany", many: "templateMany", theMany: "templateTheMany", the: "templateThe" },
        });
    }
}
