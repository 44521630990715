import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import { uniqueString } from "lib/utils";
import { makeStyles } from "lib/material";

import { SwitchFieldProps } from "./switch-field.types";

export const SwitchField = React.forwardRef<HTMLInputElement, SwitchFieldProps>((props: SwitchFieldProps, ref) => {
    const {
        value,
        autoCorrect,
        spellCheck,
        error,
        skeleton,
        className,
        name = value.valueProps.name,
        helperText: explicitHelperText,
        id: passedId,
        disabled: passedDisabled,
        fullWidth,
        ...otherProps
    } = props;

    if (!value) {
        throw new Error(`If no "value" is passed, you should use the switch field exported directly from @material-ui/core/Switch.`);
    }

    const id = React.useMemo(() => passedId || `sf-${value.valueProps.name || uniqueString()}`, [passedId]);
    const usedProps = value.use();

    const helperText = explicitHelperText || usedProps.error || usedProps.success;

    if (skeleton) {
        return <Skeleton key={"_skeleton"} height={36} />;
    }

    const disabled = passedDisabled || (usedProps ? usedProps.loading || usedProps.readOnly : undefined);

    return (
        <FormControl key={"_switch"} className={className} error={!!usedProps.error} disabled={disabled} fullWidth={fullWidth}>
            {usedProps.label && <InputLabel shrink htmlFor={id} children={usedProps.label} />}

            <Switch
                color="primary"
                {...otherProps}
                checked={usedProps.current}
                onChange={value.onSwitch}
                disabled={disabled}
                inputProps={{
                    name,
                    id,
                    autoCorrect: autoCorrect || "off",
                    spellCheck: spellCheck || false,
                    ...otherProps.inputProps,
                }}
                inputRef={ref}
                name={name}
                placeholder={usedProps.placeholder}
            />

            {helperText && <FormHelperText children={helperText} />}
        </FormControl>
    );
});

const useStyles = makeStyles(() => ({}));
