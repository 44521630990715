import { TranslationThunk } from "lib/store/translation";

import { ValueMetaInterface, ValueMetaProps } from "./value-meta";

export interface DateTimeValueMeta
    extends DateTimeValueMetaProps,
        // text is the default type
        Partial<ValueMetaInterface<"date-time", string>> {}

export enum DATE_TIME_FORMAT {
    ISO8601,
    MILITARY,
}

export interface DateTimeValueMetaProps extends ValueMetaProps<string> {
    /** The format of the date, defaults to `ISO8601`. */
    format?: DATE_TIME_FORMAT;
    label?: TranslationThunk;
    disablePast?: boolean;
    disableFuture?: boolean;
}
