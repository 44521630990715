import en from "./translations.en";
import sv from "./translations.sv";

const translations: Record<"en" | "sv", Record<string, string>> = {
    en,
    sv,
};

// console.log("EN Translations keys length:", Object.keys(en).length);
// console.log("SV Translations keys length:", Object.keys(sv).length);

// Object.keys(en).forEach((enkey) => {
//     if (!Object.keys(sv).find((svkey) => svkey === enkey)) {
//         console.log("Missing Translation in SV:", enkey);
//     }
// });
// Object.keys(sv).forEach((svkey) => {
//     if (!Object.keys(sv).find((enkey) => enkey === svkey)) {
//         console.log("Missing Translation in EN:", svkey);
//     }
// });

export default translations;
