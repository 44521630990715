import { IsString, IsEmail, IsBoolean } from "class-validator";
import { Abon, CURRENCY, SelectValue, SUBSCRIPTION_PLAN } from "lib";

export interface UpgradeCheckoutProps {
    title?: React.ReactNode;
    price?: React.ReactNode;
    open?: Abon<boolean>;
    downgrade?: boolean;
    plan: SUBSCRIPTION_PLAN;
}

export class UpgradeCheckoutFormProps {
    @IsString()
    /** The name of the customer (defaults to the name of the user). */
    checkoutName: string;

    @IsEmail()
    /** The billing email of the customer (defaults to the email of the user). */
    billingEmail: string;

    @IsString()
    /** The currency of the payment (defaults to SEK or the user preferences). */
    currency: SelectValue<CURRENCY>;

    @IsBoolean()
    keepLatestUrlas?: boolean;
}

export interface StripeCustomer {
    name?: string;
    email?: string;
    currency?: string;
}
