import React from "react";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import { useTheme, makeStyles, clsx, Translation, Box, ButtonLink, Auth, Abon, MenuItemLink, useLocation, Typography, ROLE } from "lib";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { ContainerNavigation } from "./container.navigation";

export function ContainerHeader() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const t = Translation.useMap(["showMenu", "account", "upgrade", "logOut", "home"]);
    const authenticated = Auth.use("authenticated");
    const roleId = Auth.use("accessToken", "role_id");
    const email = Auth.use("accessToken", "email");

    const open = Abon.use(() => false);
    const menuAnchorRef = React.useRef<HTMLButtonElement>();

    const toggleOpen = React.useMemo(() => () => !!open.set(!open.current).current, []);

    const {
        location: { pathname },
    } = useLocation();

    const navigation =
        authenticated &&
        !(
            pathname.includes("/auth") ||
            pathname.includes("/account") ||
            pathname.includes("/upgrade") ||
            pathname.includes("/welcome") ||
            pathname.includes("/create")
        );

    return (
        <>
            {/** The `AppBar` is fixed, so to make sure siblings don't fall under the
             * menu a "shadow" with the same height is rendered. */}
            <div className={classes.shadow} />

            <AppBar position="fixed" className={clsx(classes.root)}>
                <Container className={classes.inner}>
                    <Toolbar className={classes.toolbar}>
                        <ButtonLink className={classes.logo} to={authenticated ? "/" : "#"}>
                            <img src="/images/logo.png" alt="Logo" />

                            {authenticated && !isMobile && (
                                <Typography
                                    className={classes.subscription}
                                    children={
                                        roleId === ROLE.ENTERPRISE
                                            ? Translation.render("enterprise")
                                            : roleId === ROLE.PRO
                                            ? Translation.render("pro")
                                            : roleId === ROLE.TRIAL
                                            ? Translation.render("proTrial")
                                            : Translation.render("free")
                                    }
                                />
                            )}
                        </ButtonLink>
                    </Toolbar>

                    <Toolbar className={classes.toolbar}>
                        {authenticated && (
                            <Button ref={menuAnchorRef} onClick={toggleOpen} className={classes.menuButton}>
                                <Avatar src="/images/personLogIn.png" variant="rounded" />

                                {!isMobile ? <Typography children={email} /> : null}
                            </Button>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>

            <Menu
                id="header-menu"
                anchorEl={menuAnchorRef.current}
                keepMounted
                open={open.current}
                onClose={toggleOpen}
                anchorOrigin={{ horizontal: "center", vertical: "center" }}
                transformOrigin={{ horizontal: "center", vertical: "bottom" }}
                getContentAnchorEl={null}
            >
                <MenuItemLink onClick={toggleOpen} to="/" children={t.render("home")} />
                <MenuItemLink onClick={toggleOpen} to="/account" children={t.render("account")} />
                {roleId !== ROLE.ENTERPRISE ? <MenuItemLink onClick={toggleOpen} to="/upgrade" children={t.render("upgrade")} /> : null}
                <MenuItem
                    onClick={React.useMemo(
                        () => () => {
                            Auth.logout();
                            toggleOpen();
                        },
                        [],
                    )}
                    children={t.render("logOut")}
                />
            </Menu>

            {navigation && <div className={classes.navigationShadow} />}

            <Collapse className={classes.navigation} in={navigation}>
                <ContainerNavigation />
            </Collapse>
        </>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            zIndex: theme.zIndex.appBar + 1,
            height: 80,
            backgroundColor: theme.palette.grey[900],
            color: "white",

            [theme.breakpoints.down("sm")]: {
                height: 64,
            },
        },
        shadow: {
            height: 80,

            [theme.breakpoints.down("sm")]: {
                height: 64,
            },
        },
        inner: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
        },
        toolbar: {
            height: 80,
            minHeight: 80,
            maxHeight: 80,
            padding: 0,

            [theme.breakpoints.down("sm")]: {
                height: 64,
                minHeight: 64,
                maxHeight: 64,
            },

            "&:first-of-type": {
                marginLeft: -8,
            },

            "&:last-of-type": {
                marginRight: -8,
            },
        },
        logo: {
            marginRight: 36,
            height: 64,
            color: "white",

            "& .MuiButton-label": {
                paddingTop: 0,
            },

            "& img": {
                height: 55,
                pointerEvents: "none",
            },

            [theme.breakpoints.down("sm")]: {
                height: 52,

                "& img": {
                    height: 48,
                },
            },
        },
        subscription: {
            color: "white",
            fontSize: 32,
            marginTop: 8,
            marginLeft: 9,
            textTransform: "lowercase",
        },
        menuButton: {
            "& span": {
                display: "flex",
                flexDirection: "column",
            },
            "& .MuiAvatar-root": {
                width: 32,
                height: 32,
            },
            "& p": {
                color: "white",
                fontSize: 14,
                marginTop: 2,
                textTransform: "none",
                opacity: 0.85,
            },
        },
        navigationShadow: {
            height: 48,
        },
        navigation: {
            position: "absolute",
            top: 80,
            left: 0,
            width: "100%",

            [theme.breakpoints.down("sm")]: {
                top: 64,
            },
        },
    }),
    { name: "ContainerAppBar" },
);
