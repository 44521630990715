import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import { animated } from "react-spring";
import { StackItemProps, CardStack, SnackStack } from "lib/stack";
import { makeStyles, Translation, Box, DateField, Abon, Typography, Form, useService, requireService, forgoService } from "lib";
import { FormHelperText } from "@material-ui/core";
import { ExportStatisticsService } from "./export-statistics.service";
import moment from "moment";

export function ExportStatistics(props: { open: Abon<boolean> }) {
    const { open = Abon.use(() => true) } = props;

    CardStack.use(
        open.current,
        () => ({
            onClose: () => open.set(false),
            renderer: (stackProps) => <ExportStatisticsModal {...stackProps} {...props} open={open} />,
        }),
        [open],
    );

    return null;
}

function ExportStatisticsModal(props: ExportStatisticsPropsModalProps) {
    const service = useService(ExportStatisticsService);
    const { onClose, interpolations } = props;
    const t = Translation.useMap(["cancel", "exportList", "exportListSubtitle"]);
    const classes = useStyles();

    const error = service.error.message.use().current;
    const success = service.success.message.use().current;
    const submittable = service.store.submittable.use().current;
    const dateFrom = service.dateFrom.use().current;
    const dateTo = service.dateFrom.use().current;

    const minDate = moment(dateFrom);
    const maxDate = moment();

    const onSuccessChange = () => {
        if (success && !error && !submittable) {
            onClose();
        }
    };

    useEffect(onSuccessChange, [success]);

    return (
        <animated.div className={classes.paper} style={{ transform: interpolations.transform }}>
            <Form className={classes.form} service={service}>
                <div className={classes.modalHeader}>
                    <Typography variant="h5" className={classes.modalHeaderTitle}>
                        {t.render("exportList")}
                    </Typography>
                </div>
                <Box className={classes.modalContent}>
                    <Box>
                        <DateField value={service.dateFrom} maxDate={maxDate} fullWidth key="_date" className={classes.dateField} />
                    </Box>
                    <Box>
                        <DateField
                            value={service.dateTo}
                            minDate={minDate}
                            maxDate={maxDate}
                            fullWidth
                            key="_date"
                            className={classes.dateField}
                        />
                    </Box>
                </Box>
                <div className={classes.modalFooter}>
                    <Button
                        color={"primary"}
                        variant={"contained"}
                        className={classes.confirmButton}
                        type={"submit"}
                        children={t.render("exportList")}
                        disabled={!submittable}
                        key="_submit"
                    />
                    <Button onClick={onClose} variant="outlined" className={classes.cancelButton}>
                        {t.render("cancel")}
                    </Button>
                    {error && <FormHelperText error={!!error} className={classes.status} children={error} />}
                </div>
            </Form>
        </animated.div>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        paper: {
            minWidth: 400,
            width: "auto",
            maxWidth: 450,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3, 4),
            outline: 0,
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
        },
        modalHeader: {
            marginBottom: "1rem",
            paddingBottom: "0.25rem",
            borderBottom: `1px solid ${theme.palette.grey[700]}`,
        },
        modalHeaderTitle: {
            fontWeight: 500,
            fontSize: "0.9rem",
            letterSpacing: 0.5,
            color: theme.palette.grey[600],
            textTransform: "uppercase",
        },
        modalContent: {
            flex: 1,
            marginBottom: "2rem",
        },
        modalFooter: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "0 -0.5rem",
            flexWrap: "wrap",
        },
        form: {},
        dateField: {
            marginTop: "1rem",
        },
        status: { marginTop: 0.5, width: "100%" },
        confirmButton: {
            fontWeight: 600,
            margin: "0.5rem",
        },
        cancelButton: {
            fontWeight: 600,
            margin: "0.5rem",
        },
    }),
    { name: "ExportStatisticsModal" },
);

interface ExportStatisticsProps {
    open: Abon<boolean>;
}
interface ExportStatisticsPropsModalProps extends StackItemProps, ExportStatisticsProps {}
