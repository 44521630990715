import React from "react";
import { animated } from "react-spring";
import { makeStyles, clsx } from "lib/material";
import { Abon } from "abon";

import { StackItemProps } from "../types";
import { CardStack } from "../services";

import { StackProviderItemProps } from "./stack-provider";

export function StackProviderCardItem(props: StackProviderItemProps<CardStack>) {
    let children: React.ReactNode;

    const onClose = React.useCallback(() => {
        if (props.item.onClose) {
            props.item.onClose("manual");
        }

        props.stack.remove(props.item.id);
    }, [props.stack, props.item.onClose, props.item.id]);

    const onClickaway = React.useCallback(() => {
        if (props.item.onClose) {
            props.item.onClose("clickaway");
        }

        props.stack.remove(props.item.id);
    }, [props.stack, props.item.onClose, props.item.id]);

    const itemProps: StackItemProps = { ...props, onClose };

    if (props.item.component) {
        children = React.createElement(props.item.component, { ...itemProps, key: "_c" });
    } else if (props.item.renderer) {
        children = props.item.renderer(itemProps);
    } else {
        const contentClasses = useContentStyles();

        let messageChildren: React.ReactNode;

        if (props.item.messageComponent) {
            messageChildren = React.createElement(props.item.messageComponent, { ...itemProps, key: "_mc" });
        } else if (props.item.messageRenderer) {
            messageChildren = props.item.messageRenderer(itemProps);
        } else {
            messageChildren = props.item.message;
        }

        if (messageChildren != null && (props.item.wrapMessage || (props.item.wrapMessage !== false && !props.item.messageComponent))) {
            messageChildren = <div key="_mw" className={contentClasses.message} children={messageChildren} />;
        }

        if (messageChildren !== undefined) {
            children = [messageChildren];
        }
    }

    React.useEffect(() => {
        if (props.item.first) {
            const listener = (event: KeyboardEvent) => {
                if (event.key === "Escape") {
                    onClickaway();
                }
            };

            document.addEventListener("keydown", listener);
            return () => document.removeEventListener("keydown", listener);
        }
    }, [onClickaway, props.item.first]);

    const classes = useWrapperStyles();

    if (props.item.wrap !== false && ((!props.item.component && !props.item.renderer) || props.item.wrap === true)) {
        children = (
            <animated.div
                style={{ transform: props.interpolations.transform }}
                className={clsx(classes.card)}
                children={children}
                key="_w"
            />
        );
    }

    const zIndex: number = Abon.useComposedValue(
        () => {
            const state = props.stack.state.current;
            const index = state.ids.indexOf(props.item.id);
            const zIndex = state.amount - index;

            if (props.zIndex) {
                return props.zIndex + zIndex;
            }
            return zIndex;
        },
        (listener) => [props.stack.state.subscribe(listener)],
        [props.item.id, props.zIndex],
    );

    const rootRef = React.useRef<HTMLDivElement>();

    const onRootClick = React.useCallback(
        function(event: React.MouseEvent<HTMLDivElement>) {
            if (event.target === rootRef.current) {
                return onClickaway();
            }
        },
        [onClickaway],
    );

    children = (
        <animated.div
            style={{ zIndex, pointerEvents: props.state === "leave" ? "none" : undefined, opacity: props.interpolations.opacity }}
            className={clsx(classes.root, props.item.dim === undefined || props.item.dim === "dark" ? classes.dimDark : undefined)}
            children={children}
            onClick={onRootClick}
            ref={rootRef}
        />
    );

    if (children === undefined) {
        children = null;
    }

    return children as any;
}

const useContentStyles = makeStyles(
    () => ({
        message: {},
    }),
    { name: "CardStackItem" },
);

const useWrapperStyles = makeStyles(
    () => ({
        card: {
            padding: "24px 16px",
            backgroundColor: "rgb(42,42,42)",
            borderRadius: 3,
        },

        root: {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: "0 0 auto",
        },

        dimDark: { backgroundColor: "rgba(0,0,0,0.35)" },
    }),
    { name: "CardStackItem" },
);
