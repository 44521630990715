import { TemplateType } from "lib";

export const SubjectPlugins = (type: TemplateType) => {
    if (type === TemplateType.email) {
        return ["noneditable"];
    } else {
        return [];
    }
};

export const ContentPlugins = (type: TemplateType) => {
    if (type === TemplateType.email) {
        return ["advlist autolink lists link image paste wordcount noneditable emoticons code"];
    } else {
        return ["lists paste wordcount noneditable emoticons"];
    }
};

export const SubjectToolbar = (type: TemplateType) => {
    const toolbar = [];

    if (type === TemplateType.email) {
        toolbar.push("customVariables");
    }

    return toolbar;
};

export const ContentToolbar = (type: TemplateType) => {
    const toolbar = [];

    if (type === TemplateType.email) {
        toolbar.push(
            "blankPage | loadTemplate saveTemplate removeTemplate | image openImageGallery link customVariables emoticons | code | undo redo |",
        );
        toolbar.push(
            "bold italic underline strikethrough | fontselect fontsizeselect styleselect | forecolor backcolor removeformat | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist",
        );
    } else {
        toolbar.push("blankPage | loadTemplate saveTemplate removeTemplate | customVariables emoticons | undo redo |");
    }

    return toolbar;
};

export const Formats = {
    primaryBtn: {
        selector: "a",
        styles: {
            backgroundColor: "#e65d00",
            padding: "12px 16px",
            color: "#ffffff",
            borderRadius: "4px",
            textDecoration: "none",
            fontWeight: "bold",
            display: "inline-block",
        },
    },
    secondaryBtn: {
        selector: "a",
        styles: {
            backgroundColor: "transparent",
            border: "1px solid #e65d00",
            padding: "12px 16px",
            color: "#e65d00",
            borderRadius: "4px",
            textDecoration: "none",
            fontWeight: "bold",
            display: "inline-block",
        },
    },
    textLink: {
        selector: "a",
        styles: {
            padding: "6px 8px",
            color: "#e65d00",
            textDecoration: "underline",
            display: "inline-block",
        },
    },
};

export const StyleFormats = (t) => [
    {
        title: "Headings",
        items: [
            { title: "Heading 1", format: "h1" },
            { title: "Heading 2", format: "h2" },
            { title: "Heading 3", format: "h3" },
            { title: "Heading 4", format: "h4" },
            { title: "Heading 5", format: "h5" },
            { title: "Heading 6", format: "h6" },
        ],
    },
    {
        title: "Inline",
        items: [
            { title: "Bold", format: "bold" },
            { title: "Italic", format: "italic" },
            { title: "Underline", format: "underline" },
            { title: "Strikethrough", format: "strikethrough" },
            { title: "Superscript", format: "superscript" },
            { title: "Subscript", format: "subscript" },
            { title: "Code", format: "code" },
        ],
    },
    {
        title: "Blocks",
        items: [
            { title: "Paragraph", format: "p" },
            { title: "Blockquote", format: "blockquote" },
            { title: "Div", format: "div" },
            { title: "Pre", format: "pre" },
        ],
    },
    {
        title: "Align",
        items: [
            { title: "Left", format: "alignleft" },
            { title: "Center", format: "aligncenter" },
            { title: "Right", format: "alignright" },
            { title: "Justify", format: "alignjustify" },
        ],
    },
    {
        title: t.render("buttons"),
        items: [
            { title: t.render("primaryButton"), format: "primaryBtn" },
            { title: t.render("secondaryButton"), format: "secondaryBtn" },
            { title: t.render("textLink"), format: "textLink" },
        ],
    },
];

export const NonEditableRegExp = /\{\{[^\}]+\}\}/g;

export const Variables = [
    { text: "urlaURL", value: "{{urla.url}}" },
    { text: "receiverName", value: "{{receiver.name}}" },
    { text: "receiverDate", value: "{{receiver.date}}" },
    { text: "receiverTime", value: "{{receiver.time}}" },
    { text: "unsubscribeURL", value: "{{unsubscribe.url}}" },
    { text: "unsubscribeSMS", value: "{{unsubscribe.sms}}" },
];

export const AddVariablesButton = (editor, translations, editorId, templateType) => {
    editor.ui.registry.addMenuButton("customVariables", {
        text: translations.render("variables"),
        tooltip: translations.render("variableTooltip"),
        fetch: (callback: any) => {
            let vars = Variables;
            if (editorId === "subjectEditor") {
                vars = Variables.filter(
                    (v) => v.value !== "{{urla.url}}" && v.value !== "{{unsubscribe.url}}" && v.value !== "{{unsubscribe.sms}}",
                );
            }
            if (templateType === TemplateType.sms) {
                vars = Variables.filter((v) => v.value !== "{{unsubscribe.url}}");
            } else {
                vars = Variables.filter((v) => v.value !== "{{unsubscribe.sms}}");
            }
            const items = vars.map((variable) => {
                return {
                    type: "menuitem",
                    text: translations.render(variable.text),
                    onAction: () => {
                        switch (variable.value) {
                            case "{{urla.url}}":
                                if (templateType === TemplateType.email) {
                                    const selectedCcontent = editor.selection.getContent();
                                    editor.execCommand("mceInsertLink", true, variable.value);

                                    if (selectedCcontent.length == 0) {
                                        editor.insertContent("{{urla.name}}");
                                        editor.execCommand("mceLink");
                                    }
                                } else {
                                    editor.insertContent(variable.value);
                                }
                                break;
                            case "{{unsubscribe.url}}":
                                if (templateType === TemplateType.email) {
                                    const selectedCcontent = editor.selection.getContent();
                                    editor.execCommand("mceInsertLink", true, variable.value);

                                    if (selectedCcontent.length == 0) {
                                        editor.insertContent(translations.render("unsubscribe"));
                                        editor.execCommand("mceLink");
                                    }
                                } else {
                                    editor.insertContent(variable.value);
                                }
                                break;
                            default:
                                editor.insertContent(variable.value);
                                break;
                        }
                    },
                };
            });
            callback(items);
        },
    });
};

export const AddNewDocumentButton = (editor, translations, callback) => {
    editor.ui.registry.addButton("blankPage", {
        icon: "new-document",
        tooltip: translations.render("newDocumentTooltip"),
        onAction: callback,
    });
};

export const AddLoadTemplateButton = (editor, translations, editorService, actionCallback) => {
    editor.ui.registry.addMenuButton("loadTemplate", {
        icon: "template",
        text: editorService.name.current ? editorService.name.current : translations.render("loadTemplateLabel"),
        tooltip: translations.render("loadTemplateTooltip"),
        fetch: (callback: any) => {
            let items = [];
            if (editorService.templates.values.array.length) {
                items = editorService.templates.values.map((template) => {
                    if (template) {
                        return {
                            type: "menuitem",
                            text: template.template_name,
                            onAction: (e) => {
                                actionCallback(template);
                            },
                        };
                    }
                });
            }

            callback(items);
        },
    });
};

export const AddSaveTemplateButton = (editor, translations, callback) => {
    editor.ui.registry.addButton("saveTemplate", {
        icon: "save",
        tooltip: translations.render("saveTemplateTooltip"),
        text: translations.render("saveTemplateLabel"),
        onAction: callback,
    });
};

export const AddRemoveTemplateButton = (editor, translations, callback) => {
    editor.ui.registry.addButton("removeTemplate", {
        icon: "remove",
        text: translations.render("removeTemplateLabel"),
        tooltip: translations.render("removeTemplateTooltip"),
        onAction: callback,
    });
};

export const AddImageGalleryButton = (editor, translations, callback) => {
    editor.ui.registry.addButton("openImageGallery", {
        icon: "gallery",
        text: translations.render("ImageGalleryTitle"),
        tooltip: translations.render("ImageGalleryTooltip"),
        onAction: callback,
    });
};

// STYLES

export const EditorStyles = `
.mceNonEditable {
    background-color: rgba(230, 93, 0, 0.1);
    padding: 1px 0;
    color: #e65d00;
}
`;
