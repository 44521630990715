/* eslint-disable @typescript-eslint/camelcase */
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import StatsIcon from "@material-ui/icons/TableChart";
import { makeStyles, Tooltip, IconButtonLink, TableCellComponentProps, Translation, useService, Auth, ROLE } from "lib";

import { Urla } from "../shared";
import { AccountRemindersService } from "account/reminders/account-reminders-service";
import IconButton from "@material-ui/core/IconButton";

export function HomeTableStatsCell(props: TableCellComponentProps<Urla, "id">) {
    const classes = useStyles();
    const reminders = useService(AccountRemindersService);
    const roleId = Auth.use("accessToken", "role_id");
    const blockedTrialAccount = Auth.use("blockedTrialAccount");
    const t = Translation.useMap(["viewStatistics"]);

    function handleBlockedAction() {
        reminders.open.set(true);
    }

    return (
        <Tooltip title={t.render("viewStatistics")}>
            <TableCell className={classes.root}>
                <div className={classes.inner}>
                    {roleId === ROLE.TRIAL && blockedTrialAccount ? (
                        <IconButton onClick={handleBlockedAction} children={<StatsIcon />} />
                    ) : (
                        <IconButtonLink to={`/${props.id}`} children={<StatsIcon />} />
                    )}
                </div>
            </TableCell>
        </Tooltip>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {},
        inner: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
        },
    }),
    { name: "StatsCell" },
);
