import { IsEmail, IsString, MinLength, MaxLength, IsOptional } from "class-validator";

export class AccountPasswordProfileProps {
    @IsString()
    @MinLength(6)
    newPassword: string;

    @IsString()
    @MinLength(6)
    newPasswordVerification: string;
}

export class AccountNameProfileProps {
    @IsString()
    @MinLength(3)
    @MaxLength(15)
    name: string;
}

export class AccountEmailProfileProps {
    @IsEmail()
    email: string;
}
