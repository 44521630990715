import React from "react";
import { Helmet } from "react-helmet";
import { TextField, makeStyles, useService, Translation, Form, ButtonLink } from "lib";

import { AuthHead, AuthSubmit, AuthLinks } from "../shared";

import { LogInService } from "./log-in.service";

export default function LogIn() {
    const classes = useStyles();

    const service = useService(LogInService);
    const t = Translation.useMap(["logIn", "forgotPassword", "signUpForUrla"]);

    const submittable = service.store.submittable.use().current;

    return (
        <>
            <Helmet title={[t.get("logIn"), "URLA"].join(" - ")} />

            <Form service={service}>
                <AuthHead title={t.get("logIn")} />

                <TextField value={service.store.getValue("email") as any} fullWidth className={classes.input} />
                <TextField value={service.store.getValue("password") as any} fullWidth className={classes.input} />

                <AuthSubmit disabled={!submittable} service={service} children={t.get("logIn")} />

                <AuthLinks>
                    <ButtonLink children={t.render("forgotPassword")} to="/auth/forgot-password" />
                    <ButtonLink children={t.render("signUpForUrla")} to="/auth/sign-up" />
                </AuthLinks>
            </Form>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    input: {
        marginBottom: "1rem",
    },
}));

interface loginParamTypes {
    token: string;
}
