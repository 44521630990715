import { IsString } from "class-validator";
import { Api, ENDPOINT, ErrorAlert, Translation, LoadingStatus, ServiceAsync, Status, SnackStack } from "lib";

export class AcceptInviteService extends ServiceAsync {
    status = new Status();
    initializing = new LoadingStatus();
    snacks: SnackStack;

    constructor() {
        super();
        this.snacks = this.require(SnackStack);
    }

    async confirmInvite(props: AcceptInviteProps) {
        const stopLoading = this.status.loading.start();
        return Api.request(ENDPOINT.AcceptUserInvitation, {
            activation_token: props.activation_token,
        })
            .then((r) => {
                this.snacks.add({ message: Translation.render("permissionGranted") });
            })
            .catch((error) => {
                ErrorAlert.alert({ error });
            })
            .finally(() => {
                stopLoading();
            });
    }
}

export class AcceptInviteProps {
    @IsString()
    activation_token: string;
}
