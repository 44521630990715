/* eslint-disable @typescript-eslint/camelcase */
import { DateValue, Api, ENDPOINT, TableService, AbonDeep, ErrorAlert, Auth, ROLE } from "lib";
import { Urla } from "home/shared";

import { LinkStatistic } from "./link-statistics.type";

export class LinkStatisticsService extends TableService<LinkStatistic, "index"> {
    dateFrom = new DateValue({});
    dateTo = new DateValue();
    urla = new AbonDeep<Urla>({} as any);

    constructor(readonly urlaId: string) {
        super({
            id: "index",
            fetch: async () => this.fetchStatistics(),
            perPage: 6,
            perPageOptions: false,
            translations: {
                single: "linkStatisticSingle",
                many: "linkStatisticMany",
                theMany: "linkStatisticTheMany",
                the: "linkStatisticThe",
            },
        });
    }

    async fetchStatistics() {
        if (Auth.get("accessToken", "role_id") === ROLE.FREE) {
            return {
                values: [],
                totalCount: 0,
            };
        }

        const response = await Api.requestAny<
            [LinkStatistic[]],
            { urla_id: number | string; date_from: string; date_to: string; excel: string }
        >(ENDPOINT.getLinkStatistics, {
            urla_id: this.urlaId,
            date_from: this.dateFrom.current || "",
            date_to: this.dateTo.current || "",
            excel: "",
        });

        const responseValues = Array.isArray(response[0]) ? response[0] : [];

        const values = responseValues.reverse().map((linkStatistic, i) => {
            linkStatistic.index = String(i);

            if (typeof linkStatistic.user_referer === "string") {
                if (linkStatistic.user_referer.includes("facebook.com")) {
                    linkStatistic.user_referer_name = "Facebook";
                } else if (linkStatistic.user_referer.includes("linkedin.com")) {
                    linkStatistic.user_referer_name = "LinkedIn";
                } else if (linkStatistic.user_referer.includes("twitter.com")) {
                    linkStatistic.user_referer_name = "Twitter";
                }
            }

            linkStatistic.user_location =
                linkStatistic.user_location || [linkStatistic.user_city, linkStatistic.user_country].filter(Boolean).join(", ");

            return { ...linkStatistic };
        });

        return {
            totalCount: values.length,
            values,
        };
    }

    async fetchUrla(urlaId = this.urlaId) {
        const stopLoading = this.status.loading.start();

        try {
            const urla: Urla = await Api.request<any, any>(ENDPOINT.getUrlaById, { urla_id: urlaId }).then((response) =>
                Array.isArray(response) ? response[0] : response,
            );

            this.urla.set(urla);
        } catch (error) {
            ErrorAlert.alert({
                when: "fetchingUrlas",
                error,
            });
        } finally {
            stopLoading();
        }
    }

    async constructorAsync() {
        const stopInitializing = this.initializing.start();

        await Promise.all([super.constructorAsync(), this.fetchUrla().then(stopInitializing)]);
    }
}
