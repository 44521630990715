import { ValidationOptions, registerDecorator, ValidatorConstraint, ValidatorConstraintInterface } from "class-validator";

@ValidatorConstraint({ name: "isFile" })
class IsFileConstraint implements ValidatorConstraintInterface {
    validate(value: any) {
        return value instanceof File;
    }
}

export function IsFile(options?: ValidationOptions) {
    // eslint-disable-next-line @typescript-eslint/ban-types
    return function(object: Object, propertyName: string) {
        registerDecorator({
            target: FilePrototype,
            propertyName,
            options,
            validator: IsFileConstraint,
        });
    };
}

export class FilePrototype extends File {
    constructor() {
        super([], "");
    }
}
