import React from "react";
import MuiTablePagination from "@material-ui/core/TablePagination";
import { useTheme, Translation } from "lib";
import { makeStyles, clsx } from "lib/material";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import { TableParsedProps } from "./table.types";

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const classes = paginationActionStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
    const t = Translation.useMap(["first", "previous", "next", "last"]);

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label={t.render("first")}>
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label={t.render("previous")}>
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label={t.render("next")}>
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label={t.render("last")}
            >
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

export function TablePagination<T extends KeyObject, I extends keyof T, O extends keyof any>(
    props: Pick<TableParsedProps<T, I, O>, "service">,
) {
    const { service } = props;
    const t = Translation.useMap(["rowsPerPage"]);

    const initializing = service.initializing.use().current;
    const loading = service.status.loading.use().current;
    const page = service.page.use().current;
    const rowsPerPage = service.perPage.use().current;
    const usePerPageOptions = service.perPageOptions.use().current;
    const totalCount = service.totalCount.use().current;
    const isMobile = service.isMobile.use().current;
    const isTablet = service.isTablet.use().current;
    const classes = paginationStyles();

    const handleChangePage = (event, newPage) => {
        service.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        service.setPerPage(parseInt(event.target.value, 10));
        service.setPage(0);
    };

    const perPageOptions = usePerPageOptions && !isMobile ? [6, 12, 24] : [];

    return (
        <MuiTablePagination
            component="div"
            rowsPerPageOptions={perPageOptions}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
                inputProps: { "aria-label": t.render("rowsPerPage") },
                native: true,
            }}
            labelRowsPerPage={t.render("rowsPerPage")}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            className={clsx(
                classes.root,
                initializing && classes.initializing,
                loading && classes.loading,
                isMobile && isTablet && classes.mobileToolbar,
            )}
        />
    );
}

const paginationStyles = makeStyles(
    () => ({
        root: {
            transition: "opacity 0.15s ease-in-out",
        },
        mobileToolbar: {
            "& .MuiToolbar-root": {
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "left",
                padding: 0,
            },
        },
        loading: {
            opacity: 0.5,
        },
        initializing: {
            opacity: 0,
        },
        placeholder: {
            height: 52,
        },
    }),
    { name: "StatisticsTablePagination" },
);

const paginationActionStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));
