export {};

declare global {
    namespace tifum {
        interface Process {
            /** Equals `env.NODE_ENV === "development"`. */
            development: boolean;
            /** Whether the window object is defined, meaning the code is executed in a browser. */
            browser: boolean;
        }
    }

    namespace NodeJS {
        interface Process extends tifum.Process {}
    }
}

process.development = process.env.NODE_ENV === "development";
process.browser = typeof window !== "undefined";
