import React, { useState } from "react";
import { Box, makeStyles, Template, Translation, clsx, Abon, LOCALE_CODE, LOCALE, Tooltip, Typography, TemplateType } from "lib";
import { Editor } from "@tinymce/tinymce-react";

import { TemplateEditorService } from "../../services";
import {
    SubjectPlugins,
    ContentPlugins,
    ContentToolbar,
    SubjectToolbar,
    AddVariablesButton,
    Formats,
    StyleFormats,
    EditorStyles,
    NonEditableRegExp,
    AddNewDocumentButton,
    AddLoadTemplateButton,
    AddSaveTemplateButton,
    AddRemoveTemplateButton,
    AddImageGalleryButton,
} from "./template-editor-options";
import { SaveTemplate } from "./save-template";
import { TemplateWithChanges } from "./template-with-changes";
import { TableConfirmDelete } from "lib/components/table/table.confirm-delete";
import { ImageGallery } from "./image-gallery";

export function TemplateEditor(props: TemplateEditorProps) {
    const { editor } = props;
    const t = Translation.useMap([
        "loadTemplateTooltip",
        "loadTemplateLabel",
        "saveTemplateLabel",
        "saveTemplateTooltip",
        "removeTemplateLabel",
        "removeTemplateTooltip",
        "ImageGalleryTitle",
        "ImageGalleryTooltip",
        "variables",
        "variableTooltip",
        "urlaURL",
        "urlaName",
        "receiverName",
        "receiverDate",
        "receiverTime",
        "unsubscribe",
        "unsubscribeURL",
        "unsubscribeSMS",
        "buttons",
        "primaryButton",
        "secondaryButton",
        "textLink",
        "template",
        "untitled",
        "notSavedWithChanges",
        "withoutChanges",
        "emailTitle",
        "sms",
    ]);
    const c = useStyles();

    const openSaveTemplate = Abon.use(() => false);
    const openTemplateWithChanges = Abon.use(() => false);
    const openTemplateDelete = Abon.use(() => false);
    const openImageGallery = Abon.use(() => false);
    const [temporaryTemplate, setTemporaryTemplate] = useState(null);
    const canSave = editor.canSave.use().current;
    const templateName = editor.name.use().current;
    const initialized = editor.initialized.use().current;
    const subject = editor.title.use().current;
    const content = editor.content.use().current;

    const newDocumentHandler = async () => {
        if ((!editor.canSave.current && editor.id.current) || (editor.empty.current && !editor.id.current)) {
            await editor.id.clear();
            editor.select(undefined);
        } else {
            openTemplateWithChanges.set(true);
        }
    };
    const loadTemplateHandler = (template) => {
        if ((!editor.canSave.current && editor.id.current) || (editor.empty.current && !editor.id.current)) {
            editor.select(template);
        } else {
            setTemporaryTemplate(template);
            openTemplateWithChanges.set(true);
        }
    };
    const changeTemplateHandler = () => {
        openTemplateWithChanges.set(false);
        setTimeout(async () => {
            if (editor) {
                if (temporaryTemplate) {
                    editor.select(temporaryTemplate.id);
                    setTemporaryTemplate(null);
                } else {
                    await editor.id.clear();
                    editor.select(undefined);
                }
            }
        }, 350);
    };
    const saveTemplateHandler = () => {
        openSaveTemplate.set(true);
    };
    const removeTemplateHandler = () => {
        if (editor.id.current) {
            openTemplateDelete.set(true);
        }
    };
    const onDelete = React.useCallback(() => {
        if (openTemplateDelete) {
            if (openTemplateDelete.current) {
                editor.deleteSelected();
                openTemplateDelete.set(false);
            } else {
                openTemplateDelete.set(true);
            }
        } else {
            editor.deleteSelected();
        }
    }, [editor, openTemplateDelete]);

    const openImageGalleryHandler = () => {
        openImageGallery.set(true);
    };

    const InsertImageHandler = (url: string) => {
        editor.contentEditor.current.execCommand("mceInsertRawHTML", false, '<img src="' + url + '" alt=""/>');
        openImageGallery.set(false);
    };

    const SMSLimitCounter = (value: number) => {
        let counter = 1;
        if (value <= 160) {
            counter = 1;
        } else if (value <= 306) {
            counter = 2;
        } else if (value >= 307) {
            counter = 3;
            if (Math.floor((value - 307) / 153) + 3 > 3) {
                counter = Math.floor((value - 307) / 153 + 3);
            }
        }
        return counter;
    };

    let subjectEditor = null;
    if (editor.type === TemplateType.email) {
        subjectEditor = (
            <Box className={c.subjectEditor}>
                <Editor
                    apiKey={"z97qd9mpr2a9nbrj59cul2bal9bvzdqo0bz5rpth5ubrnbs1"}
                    value={subject}
                    id={"subjectEditor"}
                    init={{
                        placeholder: t.get("emailTitle"),
                        menubar: false,
                        nowrap: false,
                        object_resizing: false,
                        inline: true,
                        language_load: true,
                        language: Translation.locale.current === LOCALE.en ? null : LOCALE_CODE[Translation.locale.current],
                        width: "100%",
                        height: 50,
                        plugins: SubjectPlugins(editor.type),
                        toolbar: SubjectToolbar(editor.type),
                        setup: (editorRef) => {
                            editor.subjectEditor.set(editorRef);
                            AddVariablesButton(editorRef, t, "subjectEditor", editor.type);
                        },
                        noneditable_regexp: NonEditableRegExp,
                        content_style: EditorStyles,
                    }}
                    outputFormat="text"
                    onEditorChange={editor.onSubjectChange}
                />
            </Box>
        );
    }

    return (
        <Box className={c.root}>
            <Box className={c.content}>
                {subjectEditor}
                <Editor
                    apiKey={"z97qd9mpr2a9nbrj59cul2bal9bvzdqo0bz5rpth5ubrnbs1"}
                    value={content}
                    id={"contentEditor"}
                    init={{
                        keep_styles: false,
                        statusbar: editor.type === TemplateType.email ? true : false,
                        language_load: true,
                        language: Translation.locale.current === LOCALE.en ? null : LOCALE_CODE[Translation.locale.current],
                        width: "100%",
                        min_height: 500,
                        max_height: 800,
                        menubar: false,
                        plugins: ContentPlugins(editor.type),
                        toolbar: ContentToolbar(editor.type),
                        toolbar_sticky: true,
                        image_advtab: true,
                        file_picker_types: "image",
                        automatic_uploads: false,
                        images_reuse_filename: true,
                        init_instance_callback: editor.onInitializing,
                        setup: (editorRef) => {
                            editor.contentEditor.set(editorRef);
                            AddVariablesButton(editorRef, t, "contentEditor", editor.type);
                            AddNewDocumentButton(editorRef, t, newDocumentHandler);
                            AddLoadTemplateButton(editorRef, t, editor, loadTemplateHandler);
                            AddSaveTemplateButton(editorRef, t, saveTemplateHandler);
                            AddRemoveTemplateButton(editorRef, t, removeTemplateHandler);

                            if (editor.type === TemplateType.email) {
                                AddImageGalleryButton(editorRef, t, openImageGalleryHandler);
                            }
                        },
                        noneditable_regexp: NonEditableRegExp,
                        content_style: EditorStyles,
                        formats: Formats,
                        style_formats: StyleFormats(Translation),
                    }}
                    onEditorChange={editor.onContentChange}
                    outputFormat={"html"}
                />
                <Tooltip
                    title={canSave || !editor.id ? t.get("notSavedWithChanges") : t.get("withoutChanges")}
                    className={clsx(c.templateName, canSave ? c.changes : null)}
                    hidden={!initialized}
                >
                    <Typography>{templateName || t.render("untitled")}</Typography>
                </Tooltip>
                {!initialized || (initialized && editor.type === TemplateType.email) || !editor.contentLimit.current || !content ? null : (
                    <Box className={c.statusBar}>
                        <Box>
                            {content.length} / {SMSLimitCounter(content.length)} {t.get("sms")}
                        </Box>
                    </Box>
                )}
            </Box>
            <SaveTemplate open={openSaveTemplate} service={editor} />
            <TemplateWithChanges open={openTemplateWithChanges} onConfirm={changeTemplateHandler} service={editor} />
            <TableConfirmDelete service={editor.templates} open={openTemplateDelete} onDelete={onDelete} key="_confirmDelete" />
            <ImageGallery open={openImageGallery} service={editor} onAdd={InsertImageHandler} />{" "}
        </Box>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            maxWidth: "100%",
            backgroundColor: "transparent",
            padding: "1.5rem",
            display: "flex",
            flexDirection: "column",
        },
        content: {
            width: "100%",
            flex: "1",
            position: "relative",
        },
        subjectEditor: {
            "& .mce-content-body": {
                padding: "17px 1.5rem 10px",
                fontSize: 15,
                color: "rgb(38,38,38)",
                overflow: "hidden",
                borderRadius: 0,
                backgroundColor: "rgb(255,255,255)",

                "& *": {
                    margin: 0,
                },
            },

            '& .mce-content-body:not([dir="rtl"])[data-mce-placeholder]:not(.mce-visualblocks)::before': {
                left: "auto",
                color: "rgb(38,38,38, .5)",
            },
        },
        templateName: {
            position: "absolute",
            backgroundColor: "#FFFFFF",
            padding: "0 0.5rem",
            lineHeight: "30px",
            height: "30px",
            top: -30,
            right: 0,
            zIndex: 1,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            borderBottom: "1px solid rgb(200,200,200)",
            maxWidth: 300,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "rgb(38,38,38)",
            fontSize: 12,
        },
        changes: {
            paddingRight: "0.75rem",
            "&:before": {
                content: "''",
                display: "block",
                width: 5,
                height: 5,
                borderRadius: "100%",
                position: "absolute",
                top: 2,
                right: 2,
                backgroundColor: "#F76400",
            },
        },
        wordCount: {
            backgroundColor: "#FFFFFF",
            color: "rgba(34,47,62,.7)",
            textAlign: "right",
            fontSize: 12,
        },
        templateField: {
            flex: "0 0 14rem",
            marginRight: 0,

            "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
                transform: "translate(12px, 12px) scale(0.75)",
            },

            "& .MuiFilledInput-root": {
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                paddingTop: 4,
                overflow: "hidden",
                backgroundColor: "rgba(31, 43, 46, 0.75)",
                paddingRight: "12px !important",
            },

            "& .MuiInputAdornment-root": {
                marginTop: "-3px",
                marginRight: "-6px",
            },

            "& .MuiFilledInput-underline:after": {},

            "& .MuiFilledInput-underline:before": {
                borderBottom: "1px solid rgba(55,55,55,1)",
            },
        },
        statusBar: {
            alignItems: "center",
            justifyContent: "flex-end",
            backgroundColor: "#fff",
            borderTop: "1px solid #ccc",
            color: "rgba(34,47,62,.7)",
            display: "flex",
            fontSize: "12px",
            fontWeight: 400,
            height: "18px",
            overflow: "hidden",
            padding: "0 8px",
            position: "relative",
            textTransform: "uppercase",
        },
    }),
    { name: "TemplateEditor" },
);

export interface TemplateEditorProps {
    editor: TemplateEditorService;
}

type CurrentTemplate = Omit<Template, "id"> & Partial<Pick<Template, "id">>;
