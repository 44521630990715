import React from "react";
import Container from "@material-ui/core/Container";
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import { useTheme, makeStyles, clsx, Translation, Auth, useLocation, TabLink, ROLE } from "lib";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export function ContainerNavigation() {
    const classes = useStyles();

    const t = Translation.useMap([
        "home",
        "statistics",
        "mailNotifications",
        "projects",
        "requiresUrlaProOrEnterprise",
        "requiresUrlaEnterprise",
        "marketing",
        "administration",
    ]);
    const userRole = Auth.use("accessToken", "role_id");
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));

    const {
        location: { pathname },
    } = useLocation();

    const value = "/" + pathname.split("/")[1];

    const tabs = [];
    tabs.push(<TabLink key="/" value="/" className={classes.tab} label={t.render("home")} />);
    tabs.push(<TabLink key="/statistics" value="/statistics" className={classes.tab} label={t.render("statistics")} />);

    if (!isMobile && !isTablet) {
        tabs.push(
            <TabLink
                key={userRole === ROLE.ENTERPRISE ? "/marketing" : "/upgrade"}
                value={userRole === ROLE.ENTERPRISE ? "/marketing" : "/upgrade"}
                className={classes.tab}
                label={t.render("marketing")}
                tooltip={userRole !== ROLE.ENTERPRISE && t.get("requiresUrlaEnterprise")}
            />,
        );
        if (userRole === ROLE.ENTERPRISE) {
            tabs.push(
                <TabLink
                    key="/admin"
                    hidden={userRole !== ROLE.ENTERPRISE}
                    value={"/admin"}
                    className={classes.tab}
                    label={t.render("administration")}
                />,
            );
        }
    }

    return (
        <AppBar position="relative" className={clsx(classes.root)}>
            <Container className={classes.inner}>
                <Tabs
                    value={
                        ["/", "/statistics", "upgrade", "/marketing", "/admin"].includes(value)
                            ? value
                            : !isNaN(Number(value.split("/").join("")))
                            ? "/"
                            : false
                    }
                    onChange={() => {}}
                    scrollButtons="on"
                    indicatorColor="primary"
                    className={classes.tabs}
                >
                    {tabs}
                </Tabs>
            </Container>
        </AppBar>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            backgroundColor: theme.palette.grey[900],
            color: "white",
        },
        inner: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",

            [theme.breakpoints.down("sm")]: {
                justifyContent: "center",
            },
        },

        link: {
            textDecoration: "none",
            color: "white",
            height: 48,
        },

        tabs: {
            display: "flex",
            flexGrow: 1,
            flexShrink: 1,
            justifyContent: "flex-start",
            flexDirection: "row",

            [theme.breakpoints.down("sm")]: {
                justifyContent: "center",
            },

            "& .MuiTab-root, a": {
                minWidth: 120,
                paddingTop: "0.6rem",
            },

            "& .MuiTabs-indicator": {
                opacity: 0.9,
                height: 3,
                borderTopLeftRadius: 1,
                borderTopRightRadius: 1,
            },
        },

        tab: {},
    }),
    { name: "Navigation" },
);
