import React from "react";
import ExportIcon from "@material-ui/icons/TableChart";
import { makeStyles, Box, Translation, Abon, useService, Auth, ROLE } from "lib";
import { Button } from "@material-ui/core";
import { ExportStatistics } from "./export-statistics/export-statistics";
import { AccountRemindersService } from "account/reminders/account-reminders-service";

export function StatisticsActions() {
    const classes = useStyles();
    const t = Translation.useMap(["exportList"]);
    const blockedTrialAccount = Auth.use("blockedTrialAccount");
    const roleId = Auth.use("accessToken", "role_id");
    const openExportStatistics = Abon.use(() => false);
    const reminders = useService(AccountRemindersService);

    const OpenExportStatisticsModal = () => {
        if (roleId === ROLE.TRIAL && blockedTrialAccount) {
            reminders.open.set(true);
        } else {
            openExportStatistics.set(true);
        }
    };

    return (
        <Box className={classes.root}>
            <Box></Box>
            <Box>
                <Button
                    className={classes.exportList}
                    onClick={OpenExportStatisticsModal}
                    variant={"contained"}
                    color={"primary"}
                    key="export_statistics_button"
                    endIcon={<ExportIcon />}
                >
                    {t.render("exportList")}
                </Button>
            </Box>

            <ExportStatistics open={openExportStatistics} />
        </Box>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            padding: "1rem 0",
            marginTop: 1,
        },
        group: {
            display: "flex",
            flexGrow: 0,
            flexShrink: 0,
            flexDirection: "row",
            alignItems: "center",
            padding: "1rem 0",
        },
        exportList: {
            fontWeight: 600,

            "& svg": {
                marginTop: -5,
            },
        },
    }),
    { name: "StatisticsActions" },
);
