import React from "react";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import {
    useService,
    Form,
    makeStyles,
    Translation,
    TextField,
    Box,
    Auth,
    ROLE,
    SliderField,
    FileField,
    DateField,
    CompletedTextField,
} from "lib";
import { HomeService } from "home/service";
import moment from "moment";

import { HomeAction } from "../shared";

import { CreateUrlaActionService } from "./create-urla.service";
import { Link } from "@material-ui/core";
import { AccountRemindersService } from "account/reminders/account-reminders-service";

export function CreateUrlaAction() {
    const service = useService(CreateUrlaActionService);
    const classes = useStyles();
    const t = Translation.useMap([
        "createUrla",
        "or",
        "orTypeALink",
        "projectsUpgradeInfo",
        "anyUrlToShare",
        "excelListUpgradeInfo",
        "proLinksAmountUpgradeInfo",
        "enterpriseLinksAmountUpgradeInfo",
        "amount",
        "attachReceivers",
        "expireDateUpgradeInfo",
        "fileFieldHelper",
        "downloadExcelSample",
    ]);
    const userRole = Auth.use("accessToken", "role_id");
    const blockedTrialAccount = Auth.use("blockedTrialAccount");
    const home = useService(HomeService);
    const reminders = useService(AccountRemindersService);

    const disabled = service.disabled.use().current;
    const initializing = home.initializing.use().current;
    const submittable = service.store.submittable.use().current;
    const error = service.error.use().current;
    const success = service.success.use().current;
    const file = service.file.useCurrent();
    const excelFile = service.excelFile.useCurrent();

    function handleBlockedAction() {
        reminders.open.set(true);
    }

    return (
        <HomeAction
            primary
            disabled={disabled || initializing}
            tooltip={
                disabled
                    ? userRole === ROLE.FREE || (userRole === ROLE.TRIAL && blockedTrialAccount)
                        ? t.render("proLinksAmountUpgradeInfo")
                        : t.render("enterpriseLinksAmountUpgradeInfo")
                    : null
            }
            service={service}
            label={t.get("createUrla")}
            position="left"
            onClick={userRole === ROLE.TRIAL && blockedTrialAccount ? handleBlockedAction : null}
        >
            <Form className={classes.root} service={service}>
                {file ? (
                    <Box className={classes.or} key={"_file"}>
                        <FileField value={service.file} helperText={t.get("fileFieldHelper")} fullWidth key={"_file"} />

                        <Button
                            variant="text"
                            children={t.render("orTypeALink")}
                            onClick={() => service.file.setClear()}
                            key={"_linkbtn"}
                            className={classes.orTypeButton}
                        />
                    </Box>
                ) : (
                    <Box className={classes.or} key={"_nofile"}>
                        <TextField value={service.link} helperText={t.get("anyUrlToShare")} key={"_link"} />
                        <p children={t.render("or")} />
                        <FileField value={service.file} helperText={t.get("fileFieldHelper")} fullWidth key={"_file"} />
                    </Box>
                )}

                <TextField className={classes.name} value={service.name} fullWidth key="_name" />

                {[ROLE.TRIAL, ROLE.PRO, ROLE.ENTERPRISE].includes(userRole) ? (
                    <TextField
                        className={classes.extension}
                        disabled={!!excelFile}
                        value={service.extension}
                        fullWidth
                        key={"_extension"}
                    />
                ) : null}

                <Box className={classes.or} key="_receivers">
                    <SliderField value={service.amount} disabled={!!excelFile} />
                    <p children={t.render("or")} />
                    {userRole !== ROLE.ENTERPRISE ? (
                        <Tooltip title={t.render("excelListUpgradeInfo")} key={"_excelFile-tooltip"}>
                            <div className={classes.inputInfo}>
                                <FileField
                                    value={service.excelFile}
                                    helperText={t.render("attachReceivers")}
                                    fullWidth
                                    key={"_excelFile"}
                                    disabled
                                />
                                <InfoIcon />
                            </div>
                        </Tooltip>
                    ) : (
                        <div>
                            <FileField value={service.excelFile} helperText={t.render("attachReceivers")} fullWidth key={"_excelFile"} />
                            <Link href="https://www.urla.se/Excel_NameEmailPhoneDateTime.xlsx" download className={classes.downloadLink}>
                                {t.render("downloadExcelSample")}
                            </Link>
                        </div>
                    )}
                </Box>

                {userRole === ROLE.FREE ? (
                    <Tooltip title={t.render("projectsUpgradeInfo")} key="_project-disabled">
                        <div className={classes.inputInfo}>
                            <CompletedTextField value={service.project} fullWidth disabled />
                            <InfoIcon />
                        </div>
                    </Tooltip>
                ) : (
                    <CompletedTextField value={service.project} fullWidth key="_project" />
                )}

                {userRole === ROLE.FREE ? (
                    <Tooltip title={t.render("expireDateUpgradeInfo")} key="_date-disabled">
                        <div className={classes.inputInfo}>
                            <DateField
                                value={service.expirationDate}
                                minDate={minExpirationDate}
                                fullWidth
                                disabled
                                className={classes.expirationDate}
                            />
                            <InfoIcon />
                        </div>
                    </Tooltip>
                ) : (
                    <DateField
                        value={service.expirationDate}
                        minDate={minExpirationDate}
                        fullWidth
                        key="_date"
                        className={classes.expirationDate}
                    />
                )}

                <Button
                    color={"primary"}
                    variant={"contained"}
                    className={classes.submit}
                    fullWidth
                    type={"submit"}
                    children={t.render("createUrla")}
                    disabled={!submittable}
                    key="_submit"
                />

                {(error || success) && (
                    <FormHelperText error={!!error} className={classes.status} children={error || success} key="_helper" />
                )}
            </Form>
        </HomeAction>
    );
}

const minExpirationDate = moment().add(1, "days");

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: "25rem",
            maxWidth: "100%",
            paddingTop: "0.75rem",
        },

        name: {
            margin: "1rem 0 0.5rem 0",
        },
        extension: {
            margin: "1rem 0 0.5rem 0",
        },

        or: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 0 0 0",

            "& > *": {
                flexGrow: 1,
            },

            "& > div": {
                width: 200,
            },

            "& > p": {
                marginBottom: -12,
                padding: "0 1.5rem",
                opacity: 0.75,
                fontWeight: 500,
                textTransform: "none",
                flexGrow: 0,
                color: theme.palette.text.secondary,
            },
        },

        orTypeButton: {
            marginLeft: "1rem",
            textTransform: "none",
            color: theme.palette.text.secondary,
        },

        expirationDate: {
            marginTop: "1rem",
        },

        submit: {
            marginTop: "1.5rem",
            fontWeight: 600,
            fontSize: "1rem",
        },
        status: { marginTop: 0.5 },
        inputInfo: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",

            "& > div": {
                flex: "1 1 auto",
            },

            "& svg": {
                height: "1rem",
                width: "1rem",
                marginLeft: "0.5rem",
                marginBottom: -3,
                fill: theme.palette.text.secondary,
            },
        },
        downloadLink: {
            fontSize: "12px",
        },
    }),
    { name: "createUrla" },
);
