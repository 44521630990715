import React from "react";
import { useTheme } from "lib/material";
import { StackProvider, SnackStack, CardStack } from "lib/stack";

export function AppCardStack(props: React.PropsWithChildren<{}>) {
    const theme = useTheme();

    return (
        <StackProvider stack={SnackStack} zIndex={theme.zIndex.snackbar}>
            <StackProvider stack={CardStack} zIndex={theme.zIndex.snackbar}>
                {props.children}
            </StackProvider>
        </StackProvider>
    );
}
