/* eslint-disable @typescript-eslint/camelcase */
import { Api, Auth, ENDPOINT, Abon, ROLE, AbonMap, TableService, TABLE_DIRECTION, AbonSet } from "lib";

import { Urla } from "../shared";

export class HomeService extends TableService<
    Urla,
    "id",
    "id" | "latest_view" | "created_date" | "project_name" | "link_name" | "receiver_name" | "num_views"
> {
    urlas = new Abon<Urla[]>(null);
    projectIds = new AbonSet<string>();
    urls = new AbonMap<Urla["url_id"], Abon<any>>();

    constructor() {
        super({
            id: "id",
            initialOrderBy: "created_date",
            perPage: Auth.get("accessToken", "role_id") === ROLE.FREE ? 4 : 6,
            perPageOptions: Auth.get("accessToken", "role_id") === ROLE.FREE ? false : true,
            search: { placeholder: "searchForUrlas" },

            fetch: async (props) => {
                if (props.forceHydrate || !this.urlas.current) {
                    const { result } = await Api.requestAny<{ num_pages: number; total_count: string; result: Urla[] }, any>(
                        ENDPOINT.getUrlas,
                        {
                            order_by: props.orderBy,
                            asc_desc: props.direction === TABLE_DIRECTION.ASC ? "ASC" : "DESC",
                            search_param: props.search || "",
                            pagination: 1,
                            per_page: 999999,
                            project_ids: Array.from(this.projectIds.values()),
                        },
                    );

                    this.urlas.set(result || []);
                }
                return {
                    values: this.urlas.current,
                    totalCount: this.urlas.current.length,
                };
            },

            delete:
                Auth.get("accessToken", "role_id") == ROLE.TRIAL && Auth.get("blockedTrialAccount")
                    ? null
                    : async (props) => {
                          await Api.request(ENDPOINT.deleteUrlas, {
                              urla_ids: props.ids,
                          });
                      },

            translations: { single: "urlaSingle", many: "urlaMany", theMany: "urlaTheMany", the: "urlaThe" },
        });

        this.projectIds.subscribe(() => {
            this.refresh();
        });

        this.deconstructFns.add(
            Auth.subscribe("accessToken", "role_id", (roleId: ROLE) => {
                if (roleId === ROLE.FREE) {
                    this.perPage.set(4);
                } else {
                    this.perPage.set(6);
                }
            }),
        );

        this.deconstructFns.add(
            Auth.subscribe("blockedTrialAccount", (blockedTrialAccount: boolean) => {
                if (Auth.get("accessToken", "role_id") === ROLE.TRIAL && blockedTrialAccount) {
                    this.canDelete.set(false);
                } else {
                    this.canDelete.set(true);
                }
            }),
        );

        this.deconstructFns.add(
            this.ids.subscribe((urlaIds) => {
                const urls = urlaIds.map((urlaId) => {
                    const urla = this.values.get(urlaId);

                    return { url_id: urla.url_id, url_name: urla.url_name };
                });

                [...this.urls.keys()].forEach((existingUrlId) => {
                    const url = urls.find((url) => url.url_id === existingUrlId);

                    if (!url) {
                        this.urls.delete(existingUrlId);
                        return;
                    }

                    this.urls.get(existingUrlId).set(url);
                });

                urls.forEach((url) => {
                    if (!this.urls.has(url.url_id)) {
                        this.urls.set(url.url_id, new Abon(url));
                    }
                });
            }),
        );
    }
}
