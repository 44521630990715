import React from "react";
import { Box, makeStyles, Abon, useService, clsx } from "lib";

import { ContainerHeader } from "./container.header";
import { ContainerFooter } from "./container.footer";
import { ContainerService } from "./container.service";

export function Container(props: React.PropsWithChildren<{}>) {
    const service = useService(ContainerService);
    const backgroundColor = service.backgroundColor.use().current;

    const classes = useStyles({ backgroundColor });

    return (
        <Box className={classes.root}>
            <ContainerHeader />

            <Box className={classes.children}>
                <div className={classes.childrenInner} children={props.children} />
                <div className={clsx("Background", classes.background)} />
            </Box>

            <ContainerFooter />
        </Box>
    );
}

const loadedImage = new Abon<boolean>(false);

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        width: "100%",
        overflow: "hidden",
    },

    children: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        position: "relative",
    },

    childrenInner: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        position: "relative",
    },

    background: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
        opacity: 0.3,
        // backgroundColor: theme.palette.grey[400],
        background: 'url("/images/Light_Bkg_kikare.png")',
        backgroundSize: "cover",
        backgroundPosition: "top",
        borderRadius: "0.2rem",
        overflow: "hidden",
    },

    backgroundPlaceholder: {},

    "@global": {
        body: {
            backgroundColor: theme.palette.grey[900],
        },
    },
}));
