import {
    FormService,
    Abon,
    ValueMetaMap,
    SelectValue,
    NOTIFICATION_DAY,
    TimeValue,
    ErrorAlert,
    Api,
    ENDPOINT,
    Service,
    useService,
} from "lib";

import { NotificationSettingsProps } from "./notification-settings.type";

export class NotificationSettingsService extends Service {
    open: Abon<boolean>;
    form: NotificationSettingsFormService;

    constructor() {
        super();

        this.open = new Abon(false);
        this.form = this.require(NotificationSettingsFormService, this.open);
    }

    useForm() {
        return this.form;
    }
}

export class NotificationSettingsFormService extends FormService<NotificationSettingsProps> {
    constructor(readonly open: Abon<boolean>) {
        super({
            type: NotificationSettingsProps,
            edit: true,
            metaMap: new ValueMetaMap<NotificationSettingsProps>({
                meta: {
                    notificationDay: {
                        label: "day",
                        type: "select",
                        required: true,
                        options: [
                            { value: NOTIFICATION_DAY.INSTANT, label: "instantly" },
                            { value: NOTIFICATION_DAY.DAILY, label: "everyDay" },
                            { value: NOTIFICATION_DAY.MONDAY, label: "monday" },
                            { value: NOTIFICATION_DAY.TUESDAY, label: "tuesday" },
                            { value: NOTIFICATION_DAY.WEDNESDAY, label: "wednesday" },
                            { value: NOTIFICATION_DAY.THURSDAY, label: "thursday" },
                            { value: NOTIFICATION_DAY.FRIDAY, label: "friday" },
                            { value: NOTIFICATION_DAY.SATURDAY, label: "saturday" },
                            { value: NOTIFICATION_DAY.SUNDAY, label: "sunday" },
                            { value: NOTIFICATION_DAY.NEVER, label: "never" },
                        ],
                    },
                    notificationTime: {
                        label: "time",
                        type: "time",
                        initial: null,
                        required: false,
                    },
                },
            }),
            onSubmit: async (props) => {
                const stopLoading = this.loading.start();

                try {
                    await Api.request(ENDPOINT.setNotificationSettings, {
                        notification_day: props.notificationDay,
                        notification_time: props.notificationTime,
                    });

                    this.open.set(false);
                } catch (error) {
                    ErrorAlert.alert({
                        error,
                        when: "changingNotificationSettings",
                    });
                } finally {
                    stopLoading();
                }
            },
        });

        this.onExited = this.onExited.bind(this);
    }

    async constructorAsync() {
        if (!this.open.current) {
            await Abon.resolve(this.open);
        }

        const settings = await Api.requestAny<{ notification_day: string; notification_time: string }, {}>(
            ENDPOINT.getNotificationSettings,
            {},
        );

        await this.store.setInitialValues({
            notificationDay: settings.notification_day ? Number(settings.notification_day) : undefined,
            notificationTime: settings.notification_time.slice(0, 5),
        });
    }

    onExited() {
        this.clear();
    }

    get day(): SelectValue<number> {
        return this.store.getValue("notificationDay") as any;
    }
    get time(): TimeValue {
        return this.store.getValue("notificationTime") as any;
    }
}
