/* eslint-disable @typescript-eslint/camelcase */
import React from "react";
import Chip from "@material-ui/core/Chip";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useService, makeStyles, Translation, useTheme, ButtonAnchor, clsx, CURRENCY, Value, CURRENCY_SYMBOL } from "lib";

import { AccountPaymentsService } from "../service";
import { CustomerInvoice } from "../types";

export function AccountPaymentsTableRow(props: { id: CustomerInvoice["invoice_id"] }) {
    const service = useService(AccountPaymentsService);

    const classes = useStyles();
    const t = Translation.useMap(["paid", "paymentDue", "invoice"]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));

    const value = service.values.use(props.id);

    if (!value) {
        return null;
    }

    const paid = value.amount_paid === value.amount_due;
    const paidLatest = paid && service.ids.current[0] === props.id;

    const priceFormatting = (value, currency) => {
        const priceText = [];
        switch (currency) {
            case CURRENCY.EUR:
            case CURRENCY.USD:
                priceText.push(...[CURRENCY_SYMBOL[currency], value]);
                break;
            case CURRENCY.SEK:
                priceText.push(...[value, CURRENCY_SYMBOL[currency]]);
            default:
                break;
        }

        return priceText;
    };

    return (
        <TableRow tabIndex={-1} className={classes.root}>
            <TableCell className={classes.status}>
                <Chip
                    className={clsx(classes.chip, paidLatest && classes.chipPaid, !paid && classes.chipPaymentDue)}
                    label={paid ? t.render("paid") : t.render("paymentDue")}
                />
            </TableCell>

            {!isMobile && !isTablet && (
                <TableCell className={classes.invoice} component="th">
                    <ButtonAnchor
                        className={classes.button}
                        children={value.invoice_id.split("in_").join("")}
                        href={value.url}
                        target="_blank"
                        size="large"
                    />
                </TableCell>
            )}

            {!isMobile && !isTablet && (
                <TableCell className={classes.invoice} children={priceFormatting(value.amount_paid, value.currency).join(" ")} />
            )}

            {!isMobile && !isTablet && (
                <TableCell
                    className={classes.invoice}
                    children={
                        value.amount_paid < value.amount_due
                            ? priceFormatting(value.amount_due - value.amount_paid, value.currency).join(" ")
                            : "—"
                    }
                    component="th"
                />
            )}
            <TableCell className={classes.pdf}>
                <ButtonAnchor
                    className={classes.button}
                    endIcon={<PdfIcon />}
                    children={t.render("invoice")}
                    href={value.pdf}
                    target="_blank"
                    size="large"
                />
            </TableCell>
        </TableRow>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            height: 53,
            maxHeight: 53,

            "& .MuiSkeleton-animate": {
                margin: 0,
            },

            "& .MuiTableCell-root": {
                padding: "0.25rem 1rem",
            },
        },
        status: {},
        chip: {
            "& .MuiChip-label": {
                marginBottom: -2,
            },
        },
        chipPaid: {
            backgroundColor: "#00c853",
        },
        chipPaymentDue: {
            backgroundColor: theme.palette.error.dark,
        },
        invoice: {},
        remainingAmount: {},
        pdf: {},
        button: {
            textTransform: "none",
            padding: "6px 11px",
            marginLeft: -11,

            "& svg": {
                marginTop: -3,
                marginLeft: 4,
            },
        },
    }),
    { name: "AccountPaymentsTableRow" },
);
