import React from "react";
import moment from "moment";
import { omit, pick } from "lib/utils";
import { Translation } from "lib/store";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";

import { Value, ValueProps } from "../value";

import { DateTimeValueProps } from "./date-time-value.types";
import { DATE_TIME_FORMAT } from "lib/form/value-meta";

export class DateTimeValue extends Value<string> {
    static useDateTimeValue(props: DateTimeValueProps) {
        const dateTimeValue = React.useMemo(() => new DateTimeValue(props), []);

        React.useEffect(() => () => dateTimeValue.clear(), []);

        return dateTimeValue;
    }

    static selectDateTimeValueProps<PT extends DateTimeValueProps, T = any>(
        props: PT,
    ): Omit<PT, keyof DateTimeValueProps> & {
        DateTimeValueProps: DateTimeValueProps;
    } {
        const { valueProps, ...allProps } = Value.selectValueProps(props);

        return {
            ...omit(allProps, "onChange", "format", "label"),
            dateTimeValueProps: {
                ...valueProps,
                ...pick(allProps, "onChange", "format", "label"),
            },
        } as any;
    }

    formatted: ParsableDate;
    format: string;

    dateTimeProps: Omit<DateTimeValueProps, keyof ValueProps<string>>;

    constructor(props: DateTimeValueProps = {}) {
        const { valueProps, ...dateTimeProps } = Value.selectValueProps(props);

        super(valueProps);

        this.dateTimeProps = dateTimeProps;

        this.onChange = this.onChange.bind(this);

        const formatCurrentDateTime = (current: string) => {
            if (current == null) {
                this.formatted = current;
            } else if (!this.dateTimeProps.format || this.dateTimeProps.format !== DATE_TIME_FORMAT.MILITARY) {
                this.format = "YYYY-MM-DD HH:mm";
                const formatted = moment(current, this.format);
                this.formatted = formatted;
            }
        };

        formatCurrentDateTime(this.current);
        this.value.subscribe(formatCurrentDateTime);
    }

    use() {
        const usedValue = super.use();

        const current = usedValue.current;

        let formatted: ParsableDate;

        if (current == null) {
            formatted = current;
        } else if (!this.dateTimeProps.format || this.dateTimeProps.format !== DATE_TIME_FORMAT.MILITARY) {
            this.format = "YYYY-MM-DD HH:mm";
            formatted = moment(current, this.format);
        }

        return {
            ...usedValue,
            current,
            formatted,
            label: Translation.use(this.valueProps.label),
        };
    }

    onChange(moment: moment.Moment) {
        if (moment != null) {
            this.formatted = moment;

            if (!moment.isValid()) {
            } else {
                this.set(moment.format(this.format));
            }
        } else {
            this.formatted = null;
            this.set(null);
        }

        if (this.current !== undefined) {
            if (this.dateTimeProps.onChange) {
                return this.dateTimeProps.onChange(this.current);
            }
        }
    }
}
