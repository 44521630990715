import { Abon, AbonMap } from "abon";
import useClearedMemo from "use-cleared-memo";

import { uniqueString } from "./misc.utils";

export class OneOf<T> extends Abon<T | undefined> {
    private readonly values: AbonMap<any, T>;

    constructor(initial?: T) {
        super(initial);

        this.values = new AbonMap();

        if (initial !== undefined) {
            this.values.set(Symbol("initial"), initial);
        }

        this.values.subscribe((entities) => {
            super.set(entities.values().next().value);
        });
    }

    set(): this {
        return this;
    }

    add(id: any, value: T) {
        this.values.set(id, value);
    }

    remove(id: any) {
        this.values.delete(id);
    }

    use(value: T): this;
    use(): this;
    use(value?: T): this {
        if (value !== undefined) {
            useClearedMemo(
                () => {
                    const key = uniqueString();
                    this.add(key, value);
                    return key;
                },
                (key) => this.remove(key),
                [value],
            );
        }
        return this;
    }

    clear() {
        this.values.clear();
    }
}

export class UsedValue<T> extends OneOf<T> {
    constructor(private readonly useValue: () => T, initial?: T) {
        super(initial);
    }

    use(override?: T) {
        const value = override || this.useValue();

        useClearedMemo(
            () => {
                const key = uniqueString();
                this.add(key, value);
                return key;
            },
            (key) => this.remove(key),
            [value],
        );

        return this;
    }
}
