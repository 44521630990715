import { FormService, ValueMetaMap } from "lib";
import { Api, ENDPOINT } from "lib/store";

import { ValidateResetPasswordTokenProps } from "./reset-password.props";

export class ValidateResetPasswordTokenService extends FormService<ValidateResetPasswordTokenProps> {
    constructor() {
        super({
            type: ValidateResetPasswordTokenProps,
            metaMap: new ValueMetaMap<ValidateResetPasswordTokenProps>({
                meta: {
                    token: {
                        accept: "text",
                        label: "token",
                        required: true,
                    },
                },
            }),
        });
    }

    async validateResetPasswordToken(props: ValidateResetPasswordTokenProps, store = this.store) {
        this.store.success.remove("submit");
        const stopLoading = this.store.loading.start();

        try {
            const response = await Api.request(ENDPOINT.validateResetPasswordToken, {
                token: props.token,
            });

            if (response.success && response.user_id) {
                return { id: response.user_id };
            } else {
                throw new Error("Unable to handle the request");
            }
        } catch (error) {
            throw new Error("Unable to handle the request");
        } finally {
            stopLoading();
        }
    }
}
