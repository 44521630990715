/* eslint-disable no-invalid-this */
/* eslint-disable @typescript-eslint/camelcase */
import { Api, ENDPOINT, AbonDeep, TableService, TemplateType, Abon, MessageActivity } from "lib";

export class CampaignsActivityService extends TableService<MessageActivity, "message_log_id", CampaignsActivityOrder> {
    messages = new AbonDeep<MessageActivity[]>(null);
    type = new Abon<TemplateType>();

    constructor(props: CampaignsActivityProps = {}) {
        super({
            id: "message_log_id",
            perPage: 6,
            perPageOptions: true,
            search: { placeholder: "searchForMarketingActivity" },

            fetch: async (props) => {
                if (props.forceHydrate || !this.messages.current) {
                    try {
                        const response = await Api.request(ENDPOINT.getCampaignsActivity, {
                            message_type: this.type.current,
                            search_param: props.search || "",
                        });
                        this.messages.set(response || []);
                    } catch (error) {
                        this.messages.set([]);
                    }
                }

                return {
                    values: this.messages.current,
                    totalCount: this.messages.current.length,
                };
            },

            translations: { single: "messageThe", many: "messageMany", theMany: "messageTheMany", the: "messageThe" },
        });

        this.type.set(props.type);
    }

    deleteScheduledMessage = async (message_log_id: string) => {
        await Api.request(ENDPOINT.deleteCampaignsActivity, {
            message_log_id: message_log_id,
            message_type: this.type.current,
        });
        this.refresh();
    };
}

export type CampaignsActivityOrder = "message_log_id" | "receiver_name" | "status" | "send_timestamp";

export interface CampaignsActivityProps {
    type?: TemplateType;
}
