import React from "react";
import { makeStyles, Table, useTheme, Auth, ROLE, ButtonLink, Translation, Paper, Typography, TemplateType } from "lib";
import Grow from "@material-ui/core/Grow";
import moment from "moment";

export function MarketingUnsubscriptionsTable(props) {
    const classes = useStyles();
    const theme = useTheme();
    const roleId = Auth.use("accessToken", "role_id");
    const t = Translation.useMap([
        "upgradeRequired",
        "unsubscriptionsUpgradeTitle",
        "unsubscriptionsUpgradeBody",
        "upgrade",
        "delete",
        "sms",
        "email",
    ]);
    const { service } = props;

    return (
        <>
            <Table
                service={service}
                rowHeight={54}
                columns={[
                    {
                        valueKey: "receiver_name",
                        label: "receiver",
                        weight: 3,
                        renderContent: (props) => props.value || "—",
                    },
                    {
                        valueKey: "message_type",
                        label: "contact",
                        weight: 3,
                        renderContent: (props) => {
                            if (props.row.message_type == TemplateType.sms) {
                                return `${t.render("sms")} | ${props.row["phone_number"]}`;
                            } else if (props.row.message_type == TemplateType.email) {
                                return `${t.render("email")} | ${props.row["email_address"]}`;
                            } else {
                                return "—";
                            }
                        },
                    },
                    {
                        valueKey: "project_name",
                        label: "project",
                        weight: 2,
                        renderContent: (props) => props.value || "—",
                    },
                    {
                        valueKey: "unsubscription_time",
                        label: "unsubscribed",
                        weight: 2.0,
                        renderContent: (props: any) => (props.value ? moment(props.value).format("YYYY-MM-DD HH:mm") : "—"),
                    },
                ]}
                orderOptions={{}}
            >
                {roleId === ROLE.FREE && (
                    <Grow in>
                        <Paper className={classes.info}>
                            <Typography variant="overline" className={classes.infoOverline} children={t.render("upgradeRequired")} />
                            <Typography variant="h2" className={classes.infoTitle} children={t.render("unsubscriptionsUpgradeTitle")} />
                            <Typography variant="body1" className={classes.infoBody} children={t.render("unsubscriptionsUpgradeBody")} />
                            <ButtonLink to="/upgrade" color="primary" className={classes.infoLink} children={t.render("upgrade")} />
                        </Paper>
                    </Grow>
                )}
            </Table>
        </>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        viewCell: {},
        view: {
            padding: "0.5rem",
            lineHeight: 1,
            fontWeight: 600,
            backgroundColor: "",
            margin: "0 0.5rem",
        },
        info: {
            backgroundColor: theme.palette.grey[900],
            padding: "1rem 1.5rem",
            maxWidth: "calc(100% - 2rem)",
            overflow: "hidden",
            pointerEvents: "all",
        },
        infoOverline: {
            color: theme.palette.text.secondary,
            opacity: 0.5,
        },
        infoTitle: {
            color: theme.palette.text.primary,
            fontSize: 24,
        },
        infoBody: {
            color: theme.palette.text.secondary,
            fontSize: 16,
            marginTop: 8,
        },
        infoLink: {
            marginLeft: "-0.5rem",
            fontWeight: 600,
        },
        chip: {
            "& .MuiChip-label": {
                marginBottom: -2,
            },
        },
        chipGreen: {
            backgroundColor: theme.palette.success.main,
        },
        chipBlue: {
            backgroundColor: theme.palette.info.main,
        },
        chipOrange: {
            backgroundColor: theme.palette.warning.main,
        },
        chipRed: {
            backgroundColor: theme.palette.error.main,
        },
        chipGrey: {
            backgroundColor: theme.palette.grey[700],
        },
        actionLink: {
            padding: "0.5rem",
            cursor: "pointer",
            margin: "0 0.5rem",
        },
    }),
    { name: "MarketingUnsubscriptionsTableRow" },
);
