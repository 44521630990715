/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import MuiTextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Skeleton from "@material-ui/lab/Skeleton";
import { uniqueString } from "lib/utils";
import { makeStyles, clsx } from "lib/material";
import { Translation } from "lib/store";

import { TextFieldProps } from "./text-field.types";

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>((props: TextFieldProps, ref) => {
    const {
        value,
        autoComplete,
        autoCorrect,
        spellCheck,
        error,
        helperText: explicitHelperText,
        skeleton,
        className,
        color = value.textProps.color,
        blurOnEnter,
        ...otherProps
    } = props;

    if (!value) {
        throw new Error(`If no "value" is passed, you should use the text field exported directly from @material-ui/core/TextField.`);
    }

    const id = React.useMemo(() => `tf-${value.valueProps.name || uniqueString()}`, []);
    const c = useStyles();
    const usedProps = value.use();

    const onKeyDown =
        blurOnEnter &&
        React.useMemo<React.KeyboardEventHandler<HTMLInputElement>>(
            () => (evt) => {
                if (evt.keyCode === 13) {
                    (evt.target as HTMLInputElement).blur();
                }
            },
            [],
        );

    if (skeleton) {
        return <Skeleton key={"_skeleton"} height={36} />;
    }

    const mustBeDefinedError =
        !explicitHelperText && usedProps.error && String(usedProps.error) === String(Translation.get("mustBeDefined"));

    return (
        <MuiTextField
            key={"_text-field"}
            onChange={value.onChange}
            onBlur={value.onBlur}
            onFocus={value.onFocus}
            type={usedProps.type}
            value={usedProps.current || ""}
            error={!!usedProps.error || usedProps.invalid}
            label={mustBeDefinedError && usedProps.label ? usedProps.label + "*" : usedProps.label}
            placeholder={
                mustBeDefinedError && !usedProps.label
                    ? (usedProps.placeholder || Translation.render("required")) + "*"
                    : usedProps.placeholder
            }
            disabled={usedProps ? usedProps.loading || usedProps.readOnly : undefined}
            ref={ref}
            helperText={(!mustBeDefinedError && usedProps.error) || usedProps.success || explicitHelperText}
            id={id}
            name={value.valueProps.name}
            onKeyDown={onKeyDown}
            {...otherProps}
            className={clsx(color === "green" && c.green, className)}
            inputProps={{
                autoComplete: autoComplete || "off",
                autoCorrect: autoCorrect || "off",
                spellCheck: spellCheck || false,
                ...otherProps.inputProps,
            }}
            InputProps={{
                startAdornment: usedProps.startAdornment ? <InputAdornment position="start" children={usedProps.startAdornment} /> : null,
                endAdornment: usedProps.endAdornment ? <InputAdornment position="end" children={usedProps.endAdornment} /> : null,
                ...otherProps.InputProps,
            }}
        />
    );
});

const useStyles = makeStyles(() => ({
    green: {},
}));
