let uniqueIndex = 0;

/** Get a string unique to this runtime. */
export function uniqueString() {
    return String(uniqueIndex++);
}

/** Construct an array of a specific `length` and iterate its values by their index. */
export function iterateLength<T extends any>(length: number, iteratee: (index: number) => T): T[] {
    return Array(Math.max(0, Math.floor(length)))
        .fill(null)
        .map((_, i) => iteratee(i));
}
