/* eslint-disable @typescript-eslint/camelcase */
import { Service, SUBSCRIPTION_PLAN, Status, Api, ENDPOINT, ErrorAlert, Auth, ROLE, SnackStack, Translation } from "lib";

export class CancelSubscriptionService extends Service {
    plan: SUBSCRIPTION_PLAN;

    snacks: SnackStack;
    status = new Status({ loading: { circular: true } });

    constructor() {
        super();

        this.snacks = this.require(SnackStack);
    }

    async onConfirm(_event: React.MouseEvent<HTMLButtonElement>) {
        const stopLoading = this.status.loading.start();

        try {
            let subscriptions = [];
            let response;
            if (Auth.get("accessToken", "role_id") == ROLE.TRIAL) {
                response = await Api.request(ENDPOINT.canceltrialSubscription, {});
            } else {
                subscriptions = await Api.requestAny<{ name: string; subscription_id: string }[], {}>(
                    ENDPOINT.getStripeCustomerSubscriptions,
                    {},
                );
                response = await Api.request(
                    ENDPOINT.cancelStripeSubscription,
                    !subscriptions.length
                        ? {}
                        : {
                              stripe_subscription_id: subscriptions[0]?.subscription_id,
                          },
                );
            }

            if (response && response.jwt) {
                Auth.setJwt(response.jwt);
                this.snacks.add({ message: Translation.render({ id: "cancelledSubscriptionForX", values: { x: this.plan } }) });
            } else {
                throw new Error((response && (response.error || response.message)) || "Unknown error");
            }

            return true;
        } catch (error) {
            console.error({ error });
            ErrorAlert.alert({
                error,
                when: "cancellingTheSubscription",
            });
        } finally {
            stopLoading();
        }

        return false;
    }
}
