import React from "react";
import MuiTablePagination from "@material-ui/core/TablePagination";
import { useService, makeStyles, clsx, useTheme, Translation } from "lib";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import { AccountPaymentsService } from "../service";

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const classes = paginationActionStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
    const t = Translation.useMap(["first", "previous", "next", "last"]);

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label={t.render("first")}>
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label={t.render("previous")}>
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label={t.render("next")}>
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label={t.render("last")}
            >
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

export function AccountPaymentsTablePagination() {
    const service = useService(AccountPaymentsService);
    const classes = paginationStyles();
    const t = Translation.useMap(["rowsPerPage"]);

    const initializing = service.initializing.use().current;
    const loading = service.status.loading.use().current;
    const page = service.page.use().current;
    const rowsPerPage = service.perPage.use().current;
    const ids = service.ids.use().current;

    const handleChangePage = (event, newPage) => {
        service.page.set(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        service.perPage.set(parseInt(event.target.value, 10));
        service.page.set(0);
    };

    const perPageOptions = [6, 12, 24];

    return (
        <MuiTablePagination
            component="div"
            rowsPerPageOptions={perPageOptions}
            count={ids.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
                inputProps: { "aria-label": t.render("rowsPerPage") },
                native: true,
            }}
            labelRowsPerPage={t.render("rowsPerPage")}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            className={clsx(classes.root, initializing && classes.initializing, loading && classes.loading)}
        />
    );
}

const paginationStyles = makeStyles(
    () => ({
        root: {
            transition: "opacity 0.15s ease-in-out",
        },
        loading: {
            opacity: 0.5,
        },
        initializing: {
            opacity: 0,
        },
        placeholder: {
            height: 52,
        },
    }),
    { name: "AccountPaymentsTable" },
);

const paginationActionStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));
