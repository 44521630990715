/* eslint-disable @typescript-eslint/camelcase */
import uniqBy from "lodash/uniqBy";
import { Api, ENDPOINT, Abon, TableService, Auth, ROLE } from "lib";
import { Project } from "home/shared";

import { HomeService } from "./home-service";

export class ProjectService extends TableService<Project, "id"> {
    projects = new Abon<Project[]>(null);
    home: HomeService;

    constructor() {
        super({
            id: "id",
            perPage: 6,
            perPageOptions: false,

            fetch: async (props) => {
                if (Auth.get("accessToken", "role_id") === ROLE.FREE) {
                    this.projects.set([]);
                } else if (props.forceHydrate || !this.projects.current) {
                    let projects: Project[] = await Api.request(ENDPOINT.getProjects, {});

                    if (!Array.isArray(projects)) {
                        projects = [];
                    } else {
                        projects = uniqBy(
                            projects.filter(
                                (project) =>
                                    project.project_name && project.project_name !== "DEFAULT" && project.project_name !== "Default",
                            ),
                            (project) => project[this.props.id],
                        );
                    }

                    this.projects.set(projects);
                }

                return { values: this.projects.current, totalCount: this.projects.current.length };
            },

            delete: async (props) => {
                await Api.request(ENDPOINT.deleteProjects, {
                    project_ids: props.ids,
                });

                if (props.ids.some((id) => this.home.projectIds.has(id))) {
                    this.home.projectIds.set(Array.from(this.home.projectIds).filter((id) => !props.ids.includes(id)));
                }

                await this.home.refresh();
            },

            translations: { single: "projectSingle", many: "projectMany", theMany: "projectTheMany", the: "projectThe" },
        });

        this.home = this.require(HomeService);
    }
}
