import { Button } from "@material-ui/core";
import {
    Abon,
    Auth,
    CardStack,
    StackItemProps,
    Translation,
    makeStyles,
    useService,
    Typography,
    ReminderType,
    ButtonLink,
    ROLE,
} from "lib";
import moment from "moment";
import React, { useEffect } from "react";
import { animated } from "react-spring";
import { AccountRemindersService, LS_REMINDER_KEY } from "./account-reminders-service";

export function AccountReminders() {
    const service = useService(AccountRemindersService);
    const authenticated = Auth.use("authenticated");
    const role = Auth.use("accessToken", "role_id");
    const reminder = service.reminder.use();
    const open = service.open.use();

    useEffect(checkUserRoleReminders, [authenticated, role]);
    useEffect(checkValidReminders, [reminder.current]);

    function checkUserRoleReminders() {
        if (authenticated && role == ROLE.TRIAL) {
            service.constructorAsync();
        }
    }

    function checkValidReminders() {
        if (reminder.current && reminder.current.type != null) {
            if (reminder.current.reminded_at == null || moment().isBefore(moment(reminder.current.reminded_at).add(24, "hours"))) {
                if (
                    reminder.current.type == ReminderType.TRIAL_VIEWS_LIMITS_HALF_REACHED ||
                    reminder.current.type == ReminderType.TRIAL_VIEWS_LIMITS_REACHED
                ) {
                    open.set(true);
                }
            }
        }
    }

    function handleCloseModal() {
        reminder.current.reminded_at = Date.now().toString();
        reminder.set(reminder.current);
        localStorage.setItem(`${LS_REMINDER_KEY}-${reminder.current.role}-${reminder.current.type}`, reminder.current.reminded_at);
        open.set(false);
    }

    CardStack.use(
        open.current,
        () => ({
            onClose: handleCloseModal,
            renderer: (stackProps) => <AccountRemindersModal {...stackProps} open={open} service={service} />,
        }),
        [open, service],
    );

    return null;
}

function AccountRemindersModal(props: AccountRemindersModalProps) {
    const { onClose, interpolations, service } = props;
    const reminder = service.reminder.use();
    const t = Translation.useMap([
        "viewsLimitReachedTitle",
        "viewsLimitHalfReachedTitle",
        "viewsLimitReachedDescription",
        "viewsLimitHalfReachedDescription",
        "viewsLimitOverReachedTitle",
        "viewsLimitOverReachedDescription",
        "upgradeToPro",
        "remindLater",
    ]);
    const classes = useClasses({});

    function ViewsLimitHalfReached() {
        return (
            <>
                <div className={classes.modalHeader}>
                    <Typography variant="h5">
                        {t
                            .render("viewsLimitHalfReachedTitle")
                            .replace("{views}", reminder?.current?.data["total_views"] || 0)
                            .replace("{limit}", "20")}
                    </Typography>
                </div>
                <div className={classes.modalContent}>
                    <Typography variant="body1">
                        {t
                            .render("viewsLimitHalfReachedDescription")
                            .replace("{views}", reminder?.current?.data["total_views"] || 0)
                            .replace("{limit}", "20")}
                    </Typography>
                </div>
                <div className={classes.modalFooter}>
                    <ButtonLink
                        to="/upgrade"
                        color={"primary"}
                        variant={"contained"}
                        className={classes.confirmButton}
                        children={t.render("upgradeToPro")}
                        onClick={onClose}
                    />

                    <Button onClick={onClose} variant="outlined" className={classes.cancelButton}>
                        {t.render("remindLater")}
                    </Button>
                </div>
            </>
        );
    }

    function ViewsLimitReached() {
        return (
            <>
                <div className={classes.modalHeader}>
                    <Typography variant="h5">{t.render("viewsLimitReachedTitle").replace("{limit}", "20")}</Typography>
                </div>
                <div className={classes.modalContent}>
                    <Typography variant="body1">{t.render("viewsLimitReachedDescription").replace("{limit}", "20")}</Typography>
                </div>
                <div className={classes.modalFooter}>
                    <ButtonLink
                        to="/upgrade"
                        color={"primary"}
                        variant={"contained"}
                        className={classes.confirmButton}
                        children={t.render("upgradeToPro")}
                        onClick={onClose}
                    />

                    <Button onClick={onClose} variant="outlined" className={classes.cancelButton}>
                        {t.render("remindLater")}
                    </Button>
                </div>
            </>
        );
    }

    function ViewsLimitOverReached() {
        return (
            <>
                <div className={classes.modalHeader}>
                    <Typography variant="h5">{t.render("viewsLimitOverReachedTitle").replace("{limit}", "20")}</Typography>
                </div>
                <div className={classes.modalContent}>
                    <Typography variant="body1">{t.render("viewsLimitOverReachedDescription").replace("{limit}", "20")}</Typography>
                </div>
                <div className={classes.modalFooter}>
                    <ButtonLink
                        to="/upgrade"
                        color={"primary"}
                        variant={"contained"}
                        className={classes.confirmButton}
                        children={t.render("upgradeToPro")}
                        onClick={onClose}
                    />

                    <Button onClick={onClose} variant="outlined" className={classes.cancelButton}>
                        {t.render("remindLater")}
                    </Button>
                </div>
            </>
        );
    }

    switch (reminder.current.type) {
        case ReminderType.TRIAL_VIEWS_LIMITS_HALF_REACHED:
            return (
                <animated.div className={classes.paper} style={{ transform: interpolations.transform }}>
                    <ViewsLimitHalfReached />
                </animated.div>
            );
        case ReminderType.TRIAL_VIEWS_LIMITS_REACHED:
            return (
                <animated.div className={classes.paper} style={{ transform: interpolations.transform }}>
                    {reminder?.current?.data["total_views"] && reminder?.current?.data["total_views"] > 20 ? (
                        <ViewsLimitOverReached />
                    ) : (
                        <ViewsLimitReached />
                    )}
                </animated.div>
            );
        default:
            return null;
    }
}

const useClasses = makeStyles(
    (theme) => ({
        paper: {
            width: "auto",
            maxWidth: 600,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3, 4),
            outline: 0,
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
        },
        modalHeader: {
            marginBottom: "1rem",
            paddingBottom: "0.25rem",
            borderBottom: `1px solid ${theme.palette.grey[700]}`,
        },
        modalContent: {
            flex: 1,
            marginBottom: "2rem",
        },
        modalFooter: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "0 -0.5rem",
        },
        confirmButton: {
            fontWeight: 600,
            margin: "0.5rem",
        },
        cancelButton: {
            fontWeight: 600,
            margin: "0.5rem",
        },
        status: {
            paddingTop: "0.5rem",
            lineHeight: 1.3,
        },
    }),
    { name: "AccountRemindersModal" },
);

interface AccountRemindersProps {
    open: Abon<boolean>;
    service: AccountRemindersService;
}

interface AccountRemindersModalProps extends StackItemProps, AccountRemindersProps {}
