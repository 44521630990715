import React from "react";
import { Abon, AbonSet } from "lib/utils";
import debounce from "lodash/debounce";

import { LoadingEntity } from "./loading.types";

class Loading {
    private set = new AbonSet<LoadingEntity>();

    useState() {
        let buffer: Abon<[number, number]>;
        let determinate: Abon<number>;

        const state = Abon.useComposedValue(
            () => {
                let linear: LoadingEntity["linear"] | "buffer" | "determinate";
                let circular: boolean | "white" | "transparent";
                let determinateCircular: boolean;
                const values = Array.from(this.set.values());

                values.forEach((value) => {
                    if (value.circular) {
                        circular = value.circular;
                    }

                    if (value.linear) {
                        linear = value.linear;
                    }

                    if (value.determinate) {
                        determinate = value.determinate;
                    }

                    if (value.buffer) {
                        buffer = value.buffer;
                    }

                    if (value.determinateCircular) {
                        determinateCircular = value.determinateCircular;

                        if (!circular) {
                            circular = true;
                        }
                    }
                });

                return { linear, circular, determinateCircular };
            },
            (listener) => [debounce(this.set.subscribe(listener), 500)],
        );
        return {
            ...state,
            determinate,
            buffer,
        };
    }

    use(id: string): void;
    use(entity: LoadingEntity): void;
    use(id: string, props: Omit<LoadingEntity, "id">): void;
    use(...args: any[]) {
        const remove = React.useMemo(() => this.add(...(args as [any])), []);

        React.useEffect(() => remove, []);

        return remove;
    }

    add(id: string): RemoveFn;
    add(entity: LoadingEntity): RemoveFn;
    add(id: string, props: Omit<LoadingEntity, "id">): RemoveFn;
    add(...args: any[]) {
        if (typeof args[0] === "string") {
            const [id, props = {}] = args as [string, Partial<LoadingEntity>];

            if (!this.has(args[0])) {
                this.set.add({ id, ...props });

                if (props.promise) {
                    props.promise.then(() => {
                        this.delete(id);
                    });
                }
            }

            return () => this.delete(id);
        } else {
            this.set.add(args[0]);

            return () => this.delete(args[0]);
        }
    }

    delete(id: string): boolean;
    delete(entity: LoadingEntity): boolean;
    delete(...args: any[]) {
        if (typeof args[0] === "string") {
            return this.set.delete([...this.set.values()].find((value) => value.id === args[0]));
        } else {
            return this.set.delete(args[0]);
        }
    }

    has(id: string): boolean;
    has(entity: LoadingEntity): boolean;
    has(loading: any) {
        if (typeof loading === "string") {
            return !![...this.set.values()].find((value) => value.id === loading);
        }

        return this.set.has(loading);
    }
}

interface RemoveFn {
    (): boolean;
}

const L = new Loading();

export { L as Loading };
