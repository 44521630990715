import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { useService, makeStyles, iterateLength, clsx } from "lib";

import { AccountPaymentsService } from "../service";

import { AccountPaymentsTableRow } from "./account-payments.table-row";
import { AccountPaymentsTableRowPlaceholder } from "./account-payments.table-row-placeholder";

export function AccountPaymentsTableBody() {
    const service = useService(AccountPaymentsService);
    const classes = useStyles();

    const initializing = service.initializing.use().current;
    const loading = service.status.loading.use().current;
    const page = service.page.use().current;
    const perPage = service.perPage.use().current;
    const ids = service.ids.use().current;

    const pageIds = ids.slice(page * perPage, page * perPage + perPage);
    return (
        <TableBody className={clsx(classes.root, initializing && classes.initializing, loading && classes.loading)}>
            {pageIds.map((id) => (
                <AccountPaymentsTableRow id={id} key={id} />
            ))}

            {iterateLength(perPage - pageIds.length, (index) => {
                return <AccountPaymentsTableRowPlaceholder key={"_" + index} initializing={initializing} />;
            })}
        </TableBody>
    );
}

const useStyles = makeStyles(
    () => ({
        root: {
            transition: "opacity 0.15s ease-in-out",
        },
        initializing: {},
        loading: {
            opacity: 0.5,
        },
        placeholder: {
            height: 52,
        },
    }),
    { name: "AccountPaymentsTableBody" },
);
