import React from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useService, makeStyles, Translation, Auth, ROLE, Typography, Table } from "lib";
import { ProjectService } from "home/service";

import { HomeAction } from "../shared";

import { ProjectsActionService } from "./projects.service";
import { ProjectFilterCell } from "./project.filter-cell";

export function Projects() {
    const service = useService(ProjectsActionService);
    const classes = useStyles();
    const t = Translation.useMap(["projects", "projectsUpgradeInfo", "update", "requiresSubscription"]);
    const userRole = Auth.use("accessToken", "role_id");

    return (
        <HomeAction service={service} label={t.get("projects")} className={classes.root}>
            {({ open }) => (
                <>
                    {userRole === ROLE.FREE && <Typography children={t.render("projectsUpgradeInfo")} className={classes.info} />}

                    <ProjectsTable />
                </>
            )}
        </HomeAction>
    );
}

function ProjectsTable() {
    const service = useService(ProjectService);
    const classes = useStyles();
    const userRole = Auth.use("accessToken", "role_id");
    const error = service.status.error.message.use().current;
    const success = service.status.success.message.use().current;

    return (
        <>
            {(error || success) && <FormHelperText error={!!error} className={classes.status} children={error || success} />}

            {userRole !== ROLE.FREE && (
                <Table
                    service={ProjectService}
                    noPaper
                    rowHeight={58}
                    columns={[
                        { valueKey: "project_name", label: "project", renderContent: ({ value }) => value || " " },
                        {
                            component: ProjectFilterCell,
                            width: 40,
                        },
                    ]}
                />
            )}
        </>
    );
}

const useStyles = makeStyles(
    () => ({
        root: {
            width: "25rem",
            maxWidth: "100%",
            padding: "1.5rem 1.5rem",
        },
        info: {
            marginBottom: "0.5rem",
        },
        status: { marginTop: 0.5 },
    }),
    { name: "Projects" },
);
