import React from "react";
import { omit, pick } from "lib/utils";
import { SelectOption } from "lib/form/value-meta";
import { Translation } from "lib/store/translation";

import { Value, ValueProps } from "../value";

import { RadioValueProps } from "./radio-value.types";

export class RadioValue<T> extends Value<T> {
    static NULL_LABEL = Translation.result("—");

    static useRadioValue<T>(props: RadioValueProps<T>) {
        const selectValue = React.useMemo(() => new RadioValue(props), []);

        React.useEffect(() => () => selectValue.clear(), []);

        return selectValue;
    }

    static selectRadioValueProps<PT extends RadioValueProps<T>, T = any>(
        props: PT,
    ): Omit<PT, keyof RadioValueProps<T>> & {
        selectValueProps: RadioValueProps<T>;
    } {
        const { valueProps, ...allProps } = Value.selectValueProps(props);

        return {
            ...omit(allProps, "options", "useOptions", "nullValue"),
            selectValueProps: {
                ...valueProps,
                ...pick(allProps, "options", "useOptions", "nullValue"),
            },
        } as any;
    }

    selectProps: Omit<RadioValueProps<T>, keyof ValueProps<string>>;

    constructor(props: RadioValueProps<T>) {
        const { valueProps, ...selectProps } = Value.selectValueProps(props);

        super({
            ...valueProps,
            initial: valueProps.initial == null && selectProps.nullValue != null ? selectProps.nullValue : valueProps.initial,
        });

        this.selectProps = selectProps;
    }

    use() {
        const usedValue = super.use();

        const setOptions = this.selectProps.options || (this.selectProps.useOptions && this.selectProps.useOptions()) || [];

        const current: T = usedValue.current == null ? ("" as any) : usedValue.current;

        const options: typeof setOptions =
            usedValue.current == null && !setOptions.find((option) => option.value == null || (option.value as any) === "")
                ? [
                      {
                          label: usedValue.placeholder || RadioValue.NULL_LABEL,
                          value: "",
                          disabled: usedValue.required,
                      },
                      ...setOptions.filter((option) => option.value != null && (option.value as any) !== ""),
                  ]
                : setOptions.map((option): any => (option.value == null ? { ...option, value: "" } : option));

        return {
            ...usedValue,
            current,
            currentOption: this.option(current, options),
            options,
        };
    }

    option(value: T, options = this.selectProps.options) {
        return value != null ? options.find((option) => option.value == value) : options.find((option) => option.value == null);
    }

    async onSelect(option: SelectOption<T>) {
        this.set((option.value as any) === "" ? null : option.value);
    }
}
