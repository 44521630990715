/* eslint-disable no-invalid-this */
/* eslint-disable @typescript-eslint/camelcase */
import {
    Api,
    ENDPOINT,
    Abon,
    ErrorAlert,
    LoadingStatus,
    Status,
    SnackStack,
    Translation,
    ServiceAsync,
    ROLE,
    AccountReminder,
    ReminderType,
    Auth,
} from "lib";

export const LS_REMINDER_KEY = "account-reminder-role";

export class AccountRemindersService extends ServiceAsync {
    reminder = new Abon<AccountReminder>(null);
    open = new Abon<boolean>(false);
    initializing = new LoadingStatus();
    status = new Status();
    snacks: SnackStack;
    translations: { single: "reminderThe"; the: "reminderThe" };

    constructor() {
        super();

        this.snacks = this.require(SnackStack);
        this.translations = { single: "reminderThe", the: "reminderThe" };
    }

    fetchPromise?: Promise<void>;

    async constructorAsync() {
        const stopLoading = this.initializing.start();

        await this.fetch();

        stopLoading();
    }

    async fetch(forceHydrate?: boolean) {
        if (this.fetchPromise && !forceHydrate) {
            return this.fetchPromise;
        }

        this.fetchPromise = this.status.loading.while(
            Promise.resolve()
                .then(async () => {
                    try {
                        const accountReminder: AccountReminder = await Api.requestAny<any, any>(ENDPOINT.getAccountReminder, {}).then(
                            (response) => {
                                return {
                                    role: Auth.current.accessToken.role_id,
                                    type: response.type,
                                    reminded_at: localStorage.getItem(`${LS_REMINDER_KEY}-${ROLE.TRIAL}-${response.type}`) || null,
                                    data: response?.data || null,
                                };
                            },
                        );

                        if (accountReminder.type == ReminderType.TRIAL_VIEWS_LIMITS_REACHED) {
                            Auth.set("blockedTrialAccount", true);
                        }

                        this.reminder.set(accountReminder);

                        if (accountReminder.type == ReminderType.TRIAL_VIEWS_LIMITS_REACHED) {
                            Auth.set("blockedTrialAccount", true);
                        }

                        this.reminder.set(accountReminder);
                    } catch (error) {
                        ErrorAlert.alert({
                            error,
                            when: Translation.result(
                                [Translation.render("fetching"), Translation.render(this.translations.single)].join(" "),
                            ),
                        });
                    }
                })
                .then(() => {
                    delete this.fetchPromise;
                }),
        );

        return this.fetchPromise;
    }
}
