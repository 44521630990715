import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles, Translation, useService } from "lib";

import { AccountPaymentsService } from "./service";
import { AccountPaymentsTable } from "./table";

export function AccountPayments() {
    const t = Translation.useMap(["payments", "account"]);
    const classes = useStyles();
    const service = useService(AccountPaymentsService);

    return (
        <>
            <Helmet title={[t.get("payments"), t.get("account"), "URLA"].join(" - ")} />
            <AccountPaymentsTable />
        </>
    );
}

const useStyles = makeStyles(
    () => ({
        root: {},
    }),
    { name: "AccountPayments" },
);
