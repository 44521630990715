import React from "react";
import Tabs from "@material-ui/core/Tabs";
import { Switch, Route, RouteProps, Redirect } from "react-router-dom";
import { useTheme, makeStyles, Translation, useLocation, TabLink, Container, Paper, Box } from "lib";

import { AdminUsers } from "./admin-users";

export default function Administration() {
    const theme = useTheme();
    const classes = useStyles();
    const t = Translation.useMap(["users"]);

    const {
        location: { pathname },
    } = useLocation();

    const value = "/" + pathname.split("/")[2];
    return (
        <Container className={classes.root}>
            <Paper className={classes.paper}>
                <Tabs
                    value={["/users"].includes(value) ? value : false}
                    onChange={() => {}}
                    scrollButtons="on"
                    indicatorColor="primary"
                    className={classes.tabs}
                >
                    <TabLink value="/users" to="/admin/users" label={t.render("users")} />
                </Tabs>

                <Box className={classes.children}>
                    <Switch>
                        <Route<RouteProps> exact path="/admin/users" component={AdminUsers} />
                        <Redirect to="/admin/users" />
                    </Switch>
                </Box>
            </Paper>
        </Container>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: "flex",
            flexGrow: 1,
            flexShrink: 0,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            paddingTop: "3rem",
            paddingBottom: "3rem",

            [theme.breakpoints.down("sm")]: {
                paddingTop: "2rem",
                paddingBottom: "2rem",
            },
        },

        paper: {
            backgroundColor: "rgba(42, 42, 42, 0.85)",
            width: "100%",
            maxWidth: "100%",

            [theme.breakpoints.down("md")]: {
                height: "auto",
            },
        },
        tabs: {
            flexGrow: 0,
            flexShrink: 0,
            "& .MuiTab-root, a": {
                paddingTop: "0.6rem",
            },

            borderBottom: `1px solid ${theme.palette.grey[800]}`,
        },
        row: {
            width: "100%",
            display: "flex",
            alignSelf: "flex-start",
            marginBottom: "1.5rem",
        },
        children: {},
    }),
    { name: "Admin" },
);
