import { Abon, AbonSet, uniqueString, UnsubscribeFn } from "lib/utils";
import { Loading, LoadingEntity } from "lib/store/loading";

export class LoadingStatus extends Abon<boolean> {
    ids = new AbonSet<string>();
    loadingId: string;

    protected timeoutIds = new AbonSet<string>();

    constructor(initial?: boolean, options?: Partial<LoadingEntity>) {
        super(initial || false);

        this.ids.subscribe((ids) => {
            super.set(!!ids.size);
        });

        this.loadingId = (options && options.id) || uniqueString();

        this.subscribe((loading) => {
            if (loading) {
                Loading.add(this.loadingId, options || { linear: "query" });
            } else {
                Loading.delete(this.loadingId);
            }
        });
    }

    set(loading: boolean) {
        console.warn(`The "set" method of a LoadingStatus should not be used.`);
        return super.set(loading);
    }

    add(id: string) {
        this.ids.add(id);
    }

    remove(id: string) {
        this.ids.delete(id);
    }

    start(id: string = uniqueString()): UnsubscribeFn {
        const removeLoading = () => {
            if (this.ids.has(id)) {
                this.remove(id);

                return true;
            }

            return false;
        };

        this.add(id);

        return removeLoading;
    }

    startThrottle(id: string = uniqueString(), timeout = 250) {
        let removed: boolean;

        const removeLoading = () => {
            removed = true;

            if (this.ids.has(id)) {
                this.remove(id);

                return true;
            }

            return false;
        };

        if (timeout) {
            this.timeoutIds.add(id);

            setTimeout(() => {
                this.timeoutIds.delete(id);

                if (!removed) {
                    this.add(id);
                }
            }, timeout);
        } else {
            this.add(id);
        }

        return removeLoading;
    }

    async while<T>(promise: Promise<T>) {
        const stop = this.start();

        return promise.then((value) => {
            stop();
            return value;
        });
    }

    /** Used for performing actions only after the status is sure to be finished loading, e.g. after initialization. If a callback is passed,
     * that will be called once the status is not loading, which could be immediately if it is not currently loading. A promise is returned, which will resolve
     * the returned value of the callback if passed, otherwise void.
     */
    async onDone<T = void>(callback?: () => T | Promise<T>) {
        if (!this.current) {
            if (callback) {
                return callback();
            }

            return undefined;
        }

        return new Promise<T>((resolve) => {
            const unsubscribe = this.subscribe(() => {
                unsubscribe();

                if (callback) {
                    return resolve(callback());
                } else {
                    resolve();
                }
            });
        });
    }

    clear() {
        this.ids.clear();
    }
}
