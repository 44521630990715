import React from "react";
import { makeStyles, Table, useTheme, Translation, clsx, Abon, ConnectedUserStatus } from "lib";
import { TableConfirmDelete } from "lib/components/table/table.confirm-delete";
import { Chip, Link } from "@material-ui/core";
import moment from "moment";

export function UserRelationsTable(props) {
    const classes = useStyles();
    const theme = useTheme();
    const t = Translation.useMap(["delete", "resendInvite"]);
    const { service } = props;
    const openRevokeUser = Abon.use(() => false);
    const selectedActivationToken = Abon.use(() => null);

    const openRevokeUserModal = (activation_token) => {
        selectedActivationToken.set(activation_token);
        openRevokeUser.set(true);
    };

    const onDelete = React.useCallback(() => {
        if (openRevokeUser) {
            if (openRevokeUser.current) {
                service.revokeUser(selectedActivationToken.current);
                openRevokeUser.set(false);
                selectedActivationToken.set(null);
            } else {
                openRevokeUser.set(true);
            }
        } else {
            service.revokeUser(selectedActivationToken.current);
            selectedActivationToken.set(null);
        }
    }, [service, openRevokeUser]);

    return (
        <>
            <Table
                service={service}
                rowHeight={54}
                columns={[
                    {
                        valueKey: "name",
                        label: "name",
                        weight: 3,
                        renderContent: (props) => props.value || "—",
                    },
                    {
                        valueKey: "username",
                        label: "email",
                        weight: 3,
                        renderContent: (props) => props.value || "—",
                    },
                    {
                        valueKey: "status",
                        label: "status",
                        weight: 1.5,
                        renderContent: (props) => {
                            switch (props.value) {
                                case ConnectedUserStatus.invited:
                                    return (
                                        <Chip className={clsx(classes.chip, classes.chipInvited)} label={Translation.render("invited")} />
                                    );
                                case ConnectedUserStatus.accepted:
                                    return (
                                        <Chip className={clsx(classes.chip, classes.chipAccepted)} label={Translation.render("accepted")} />
                                    );
                                case ConnectedUserStatus.revoked:
                                    return (
                                        <Chip className={clsx(classes.chip, classes.chipRevoked)} label={Translation.render("revoked")} />
                                    );
                                default:
                                    return null;
                            }
                        },
                    },
                    {
                        valueKey: "created_at",
                        label: "invited",
                        width: 115,
                        renderContent: (props: any) => (props.value ? moment(props.value).format("YYYY-MM-DD") : "—"),
                    },
                    {
                        valueKey: "activation_token",
                        label: "",
                        width: 150,
                        renderContent: (props) => {
                            switch (props.row.status) {
                                case ConnectedUserStatus.invited:
                                    return (
                                        <Link
                                            className={classes.actionLink}
                                            onClick={() => service.resendInvite(props.row.username)}
                                            color={"primary"}
                                            key="_button"
                                        >
                                            {t.render("resendInvite")}
                                        </Link>
                                    );
                                case ConnectedUserStatus.accepted:
                                    return (
                                        <Link
                                            className={classes.actionLink}
                                            onClick={() => openRevokeUserModal(props.value)}
                                            color={"primary"}
                                            key="_button"
                                        >
                                            {t.render("delete")}
                                        </Link>
                                    );
                                default:
                                    return null;
                            }
                        },
                    },
                ]}
                orderOptions={{
                    name: "name",
                    username: "username",
                    status: "status",
                    created_at: "created_at",
                    activation_token: "activation_token",
                }}
            ></Table>
            <TableConfirmDelete service={service} open={openRevokeUser} onDelete={onDelete} key="_confirmDelete" />
        </>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        viewCell: {},
        view: {
            padding: "0.5rem 0",
            lineHeight: 1,
            fontWeight: 600,
            backgroundColor: "rgba(31, 31, 31, 0.5)",
            width: "100%",
        },
        info: {
            backgroundColor: theme.palette.grey[900],
            padding: "1rem 1.5rem",
            maxWidth: "calc(100% - 2rem)",
            overflow: "hidden",
            pointerEvents: "all",
        },
        infoOverline: {
            color: theme.palette.text.secondary,
            opacity: 0.5,
        },
        infoTitle: {
            color: theme.palette.text.primary,
            fontSize: 24,
        },
        infoBody: {
            color: theme.palette.text.secondary,
            fontSize: 16,
            marginTop: 8,
        },
        infoLink: {
            marginLeft: "-0.5rem",
            fontWeight: 600,
        },
        chip: {
            "& .MuiChip-label": {
                marginBottom: -2,
            },
        },
        chipAccepted: {
            backgroundColor: theme.palette.success.main,
        },
        chipInvited: {
            backgroundColor: theme.palette.warning.main,
        },
        chipRevoked: {
            backgroundColor: theme.palette.error.main,
        },
        actionLink: {
            cursor: "pointer",
        },
    }),
    { name: "UserRelationsTableRow" },
);
