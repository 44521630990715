import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { uniqueString } from "lib/utils";
import { makeStyles } from "lib/material";
import { Translation } from "lib/store/translation";

import { SelectFieldProps } from "./select-field.types";

export const SelectField = React.forwardRef<HTMLInputElement, SelectFieldProps>((props: SelectFieldProps, ref) => {
    const {
        value,
        autoComplete,
        autoCorrect,
        spellCheck,
        error,
        skeleton,
        className,
        name = value.valueProps.name,
        helperText: explicitHelperText,
        id: passedId,
        fullWidth,
        disabled,
        ...otherProps
    } = props;

    if (!value) {
        throw new Error(`If no "value" is passed, you should use the select field exported directly from @material-ui/core/Select.`);
    }

    const id = React.useMemo(() => passedId || `sf-${value.valueProps.name || uniqueString()}`, [passedId]);
    const usedProps = value.use();

    const onChange = React.useMemo(
        () => (event: React.ChangeEvent<{ name?: string; value: unknown }>, _child: React.ReactNode) => {
            return value.onSelect(value.option(event.target.value, usedProps.options));
        },
        [usedProps.options],
    );

    const helperText = explicitHelperText || usedProps.error || usedProps.success;

    if (skeleton) {
        return <Skeleton key={"_skeleton"} height={36} />;
    }

    return (
        <FormControl
            key={"_select"}
            className={className}
            error={!!usedProps.error}
            disabled={disabled || (usedProps ? usedProps.loading || usedProps.readOnly : undefined)}
            fullWidth={fullWidth}
        >
            {usedProps.label && <InputLabel shrink htmlFor={id} children={usedProps.label} />}

            <Select
                {...otherProps}
                value={usedProps.current}
                onChange={onChange}
                inputProps={{
                    name,
                    id,
                    autoComplete: autoComplete || "off",
                    autoCorrect: autoCorrect || "off",
                    spellCheck: spellCheck || false,
                    ...otherProps.inputProps,
                }}
                inputRef={ref}
                displayEmpty
                name={name}
                fullWidth={fullWidth}
                placeholder={usedProps.placeholder}
            >
                {usedProps.options.map((option, i) => (
                    <MenuItem key={i} value={option.value as any} disabled={option.disabled}>
                        {Translation.get(option.label) || option.value}
                    </MenuItem>
                ))}
            </Select>

            {helperText && <FormHelperText children={helperText} />}
        </FormControl>
    );
});

const useStyles = makeStyles(() => ({}));
