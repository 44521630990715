import uniqBy from "lodash/uniqBy";
import { Status, LoadingStatus } from "lib/form/status";
import { TextValue } from "lib/form/value/text";
import { Abon, AbonDeep, iterateLength, Service, ServiceAsync, AbonItems } from "lib/utils";
import { ErrorAlert } from "lib/store/error-alert";
import debounce from "lodash/debounce";
import { Translation } from "lib/store";
import { SnackStack } from "lib/stack";

import { TABLE_DIRECTION, TableServiceProps, TableFetchProps } from "./table-service.types";

export class TableService<T extends KeyObject, I extends keyof T, O extends keyof any = never> extends ServiceAsync {
    snacks: SnackStack;
    status = new Status();
    initializing = new LoadingStatus();
    deleting = new LoadingStatus();
    page = new Abon(0);
    items: AbonItems<T, I>;
    search: TextValue;
    direction = new Abon<TABLE_DIRECTION>(TABLE_DIRECTION.DESC);
    orderBy: Abon<O>;
    perPage: Abon<number>;
    perPageOptions: Abon<boolean>;
    canDelete = new Abon<boolean>(true);
    // /** The ids if the current values in the order they were pushed/unshifted. */
    // ids = new Abon<T[I][]>([]);
    selectedIds = new Abon<T[I][]>([]);
    /** If the most recent fetch returned less items than the maximum. */
    fetchedAll = new Abon<boolean>();
    pagesCount = new Abon<number | null>(null);
    totalCount = new Abon<number>(0);
    // values = new AbonDeep<Record<T[I], T>>({} as any);
    isMobile = new Abon<boolean>(false);
    isTablet = new Abon<boolean>(false);

    constructor(readonly props: TableServiceProps<T, I, O>) {
        super();

        this.snacks = this.require(SnackStack);
        this.search = new TextValue({ placeholder: "searchForValues", ...props.search });
        this.orderBy = new Abon<O>(props.initialOrderBy);
        this.perPage = new Abon(props.perPage || 6);
        this.perPageOptions = new Abon(typeof props.perPageOptions !== "undefined" ? props.perPageOptions : true);
        this.items = new AbonItems(props.id);

        this.orderBy.subscribe(() => {
            this.refresh();
        });

        this.search.value.subscribe(
            debounce(
                () => {
                    this.refresh();
                },
                750,
                { trailing: true },
            ),
        );

        this.direction.subscribe(() => {
            this.refresh();
        });
    }

    async constructorAsync() {
        const stopInitializing = this.initializing.start(undefined);

        await this.fetchValues({ forceHydrate: true });

        stopInitializing();
    }

    async fetchValues(props: TableFetchProps<O> = {}) {
        const stopLoading = this.status.loading.start();

        try {
            const { values, pagesCount, totalCount, direction } = await this.requestValues(props);

            if (pagesCount != null) {
                this.pagesCount.set(pagesCount);
            }

            if (totalCount != null) {
                this.totalCount.set(totalCount);
            }

            if (props.forceHydrate) {
                this.items.set(values);
            } else {
                if (direction === TABLE_DIRECTION.ASC) {
                    this.items.unshift(...values);
                } else {
                    this.items.push(...values);
                }
            }
        } catch (error) {
            ErrorAlert.alert({ error });
        } finally {
            stopLoading();
        }
    }

    private paginationPromises = new Map<number, Promise<any>>();

    async requestValues(props: TableFetchProps<O> = {}) {
        const page = props.page || this.page.current;
        const perPage = props.perPage || this.perPage.current;
        const perPageOptions = props.perPageOptions || this.perPageOptions.current;
        const search = props.search || this.search.current;
        const orderBy = props.orderBy || this.orderBy.current;
        const direction = props.direction || this.direction.current;

        const promise = this.props
            .fetch({
                page,
                perPage,
                perPageOptions,
                search,
                orderBy,
                direction,
                forceHydrate: props.forceHydrate || false,
            })
            .then((values) =>
                Promise.all(
                    [...this.paginationPromises.keys()]
                        .filter((key) => key < page)
                        .map((lowerPage) => this.paginationPromises.get(lowerPage)),
                ).then(() => values),
            );

        this.paginationPromises.set(
            page,
            promise.then(() => {
                this.paginationPromises.delete(page);
            }),
        );

        const { values, totalCount } = await promise;

        const pagesCount = Math.ceil(totalCount / perPage);

        return {
            values,
            totalCount,
            pagesCount,
            page,
            perPage,
            perPageOptions,
            search,
            orderBy,
            direction,
        };
    }

    async setPage(page: number) {
        if (page > this.pagesCount.current) {
            return;
        }

        if (this.items.length < this.perPage.current * page) {
            if (this.items.length < this.totalCount.current) {
                await this.fetchValues({ page: page });

                this.page.set(page);
            } else {
                this.page.set(page);
            }
        } else {
            this.page.set(page);
        }
    }

    async setPerPage(perPage: number) {
        this.perPage.set(perPage || 6);

        await this.fetchValues({ page: Number(this.page), perPage: Number(this.perPage) });
    }

    private lastRefresh: symbol;

    fetchPromise?: Promise<void>;

    async fetch(forceHydrate?: boolean) {
        if (this.fetchPromise && !forceHydrate) {
            return this.fetchPromise;
        }

        this.fetchPromise = this.status.loading.while(
            Promise.resolve()
                .then(async () => {
                    try {
                        const refreshSymbol = Symbol();
                        this.lastRefresh = refreshSymbol;

                        const { totalCount, pagesCount, values } = await this.requestValues({
                            page: 0,
                            forceHydrate: true,
                        });

                        if (this.lastRefresh !== refreshSymbol) {
                            return;
                        } else {
                            delete this.lastRefresh;
                        }

                        if (pagesCount != null) {
                            if (this.page.current > 0) {
                                const additionalPages = await Promise.all(
                                    iterateLength(Math.min(pagesCount, this.page.current), async (i) =>
                                        this.requestValues({ page: i + 1 }),
                                    ),
                                );

                                for (const { values: additionalValues, direction } of additionalPages) {
                                    if (direction === TABLE_DIRECTION.ASC) {
                                        values.unshift(...additionalValues);
                                    } else {
                                        values.push(...additionalValues);
                                    }
                                }
                            }

                            if (this.page.current > Math.max(0, pagesCount)) {
                                this.page.set(Math.max(0, pagesCount));
                            }

                            this.pagesCount.set(pagesCount);
                        }

                        if (totalCount != null) {
                            this.totalCount.set(totalCount);
                        }

                        this.items.set(values);
                    } catch (error) {
                        ErrorAlert.alert({
                            error,
                            when: Translation.result(
                                [Translation.render("fetching"), Translation.render(this.props.translations.many)].join(" "),
                            ),
                        });
                    }
                })
                .then(() => {
                    delete this.fetchPromise;
                }),
        );

        return this.fetchPromise;
    }

    async refresh() {
        return this.fetch(true);
    }

    async deleteSelected() {
        if (!this.props.delete || !this.canDelete || !this.selectedIds.current.length) {
            return false;
        }

        const deleted = await this.delete(...this.selectedIds.current);

        if (deleted) {
            this.selectedIds.set([]);
        }

        return deleted;
    }

    async delete(...ids: T[I][]): Promise<boolean> {
        if (!this.props.delete || !this.canDelete) {
            return false;
        }
        ids = ids.filter(Boolean);
        if (!ids.length) {
            return true;
        }

        const stopLoading = this.status.loading.start();
        const stopDeleting = this.deleting.start();

        try {
            await this.props.delete({
                ids,
            });

            if (this.props.deleteNoHydrate) {
                this.values.set(this.values.array.filter((value) => !ids.includes(value[this.props.id])));
            } else {
                await this.refresh();
            }

            if (ids.length > 1) {
                this.snacks.add({
                    message: Translation.render({
                        id: "deletedValues",
                        values: { x: ids.length, many: Translation.render(this.props.translations.many) },
                    }),
                });
            } else {
                this.snacks.add({
                    message: Translation.render({
                        id: "deletedValue",
                        values: { single: Translation.render(this.props.translations.single) },
                    }),
                });
            }

            return true;
        } catch (error) {
            ErrorAlert.alert({ error });
            return false;
        } finally {
            stopLoading();
            stopDeleting();
        }
    }

    useTranslations() {
        Translation.locale.use();

        return {
            valueSingle: Translation.render(this.props.translations.single),
            valueThe: Translation.render(this.props.translations.the),
            valueMany: Translation.render(this.props.translations.many),
            valueTheMany: Translation.render(this.props.translations.theMany),
        };
    }

    get shouldManageSelected() {
        return !!this.props.delete && !!this.canDelete.current;
    }

    get ids() {
        return this.items.ids;
    }

    get values() {
        return this.items;
    }
}
