import React from "react";
import { Switch, Route, RouteProps, Redirect } from "react-router-dom";

import ForgotPassword from "./forgot-password";
import ResetPassword from "./reset-password";
import LogIn from "./log-in";
import SignUp from "./sign-up";
import AcceptInvite from "admin/admin-users/actions/accept-invite";
import { AuthContainer } from "./container";
export default function Auth() {
    return (
        <AuthContainer>
            <Switch>
                <Route<RouteProps> exact path="/auth/log-in" component={LogIn} />
                <Route<RouteProps> exact path="/auth/log-in/:token" component={LogIn} />
                <Route<RouteProps> exact path="/auth/sign-up" component={SignUp} />
                <Route<RouteProps> exact path="/auth/sign-up/:role" component={SignUp} />
                <Route<RouteProps> exact path="/auth/forgot-password" component={ForgotPassword} />
                <Route<RouteProps> exact path="/auth/reset-password/:token" component={ResetPassword} />
                <Route<RouteProps> exact path="/accept-invite/:token" component={AcceptInvite} />
                <Redirect to="/auth/log-in" />
            </Switch>
        </AuthContainer>
    );
}
