/* eslint-disable @typescript-eslint/camelcase */
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import Menu from "@material-ui/core/Menu";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ShareIcon from "@material-ui/icons/Share";
import MailIcon from "@material-ui/icons/Mail";
import ClipboardIcon from "@material-ui/icons/ListAlt";
import { useService, makeStyles, Tooltip, Translation, Abon, MenuItemAnchor, TableCellComponentProps, useTheme } from "lib";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { HomeService } from "../service";
import { Urla } from "../shared";

export function HomeTableShareCell(props: TableCellComponentProps<Urla, "id">) {
    const classes = useStyles();
    const theme = useTheme();
    const t = Translation.useMap(["shareUrlaInfo", "copyToClipboard", "email"]);
    const service = useService(HomeService);
    const uniqid = service.values.use(props.id, "uniqid");
    const name = service.values.use(props.id, "url_name");
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const openShare = Abon.use(() => false);
    const shareAnchorRef = React.useRef<HTMLButtonElement>();
    const toggleOpenShare = React.useCallback(() => !!openShare.set(!openShare.current), []);

    const link = "https://urla.se/" + uniqid;

    const onCopy = React.useCallback(
        (_event: React.MouseEvent<HTMLButtonElement>) => {
            const textField = document.createElement("input");
            textField.type = "text";
            textField.setAttribute("value", link);
            document.body.appendChild(textField);
            textField.select();
            document.execCommand("copy");
            textField.remove();
        },
        [link],
    );

    return (
        <TableCell component="th" id={props.id}>
            <div className={classes.root}>
                <Tooltip title={t.render("copyToClipboard")}>
                    <IconButton children={<ClipboardIcon />} className={classes.clipboard} onClick={onCopy} />
                </Tooltip>

                {!isMobile && (
                    <Tooltip title={t.render("shareUrlaInfo")}>
                        <IconButton children={<ShareIcon />} ref={shareAnchorRef} className={classes.share} onClick={toggleOpenShare} />
                    </Tooltip>
                )}

                <Menu id="header-menu" anchorEl={shareAnchorRef.current} keepMounted open={openShare.current} onClose={toggleOpenShare}>
                    <MenuItemAnchor
                        href={`mailto:?body=%0a%0a${encodeURIComponent(link)}`}
                        onClick={toggleOpenShare}
                        target="_blank"
                        rel="noopener nofollow noreferrer"
                    >
                        <ListItemIcon children={<MailIcon />} />
                        <ListItemText children={t.render("email")} />
                    </MenuItemAnchor>

                    <MenuItemAnchor
                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`}
                        onClick={toggleOpenShare}
                        target="_blank"
                        rel="noopener nofollow noreferrer"
                    >
                        <ListItemIcon>
                            <SvgIcon viewBox="2 1 16 16">
                                <path
                                    d={
                                        "M8.546 16V9.804H6.46V7.39h2.086V5.607c0-2.066 1.262-3.19 3.106-3.19.883 " +
                                        "0 1.642.064 1.863.094v2.16h-1.28c-1 0-1.195.48-1.195 1.18v1.54h2.39l-.31 2.42" +
                                        "h-2.08V16"
                                    }
                                />
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText children={"Facebook"} />
                    </MenuItemAnchor>

                    <MenuItemAnchor
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(link)}`}
                        onClick={toggleOpenShare}
                        target="_blank"
                        rel="noopener nofollow noreferrer"
                    >
                        <ListItemIcon>
                            <SvgIcon viewBox="0 0 16 16">
                                <path
                                    d={
                                        "M13.632 13.635h-2.37V9.922c0-.886-.018-2.025-1.234-2.025-1.235 0-1.424.964" +
                                        "-1.424 1.96v3.778h-2.37V6H8.51v1.04h.03c.318-.6 1.092-1.233 2.247-1.233 2.4 0 " +
                                        "2.845 1.58 2.845 3.637v4.188zM3.558 4.955c-.762 0-1.376-.617-1.376-1.377 0-.758" +
                                        ".614-1.375 1.376-1.375.76 0 1.376.617 1.376 1.375 0 .76-.617 1.377-1.376 1.377z" +
                                        "m1.188 8.68H2.37V6h2.376v7.635z"
                                    }
                                />
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText children={"LinkedIn"} />
                    </MenuItemAnchor>

                    <MenuItemAnchor
                        href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(link)}`}
                        onClick={toggleOpenShare}
                        target="_blank"
                        rel="noopener nofollow noreferrer"
                    >
                        <ListItemIcon>
                            <SvgIcon viewBox="0 0 16 16">
                                <path
                                    d={
                                        "M16 3.038c-.59.26-1.22.437-1.885.517.677-.407 1.198-1.05 1.443-1.816-.634.37-1.337.64" +
                                        "-2.085.79-.598-.64-1.45-1.04-2.396-1.04-1.812 0-3.282 1.47-3.282 3.28 0 .26.03.51.085.75-2.728" +
                                        "-.13-5.147-1.44-6.766-3.42C.83 2.58.67 3.14.67 3.75c0 1.14.58 2.143 1.46 2.732-.538-.017-1.045" +
                                        "-.165-1.487-.41v.04c0 1.59 1.13 2.918 2.633 3.22-.276.074-.566.114-.865.114-.21 0-.41-.02-.61" +
                                        "-.058.42 1.304 1.63 2.253 3.07 2.28-1.12.88-2.54 1.404-4.07 1.404-.26 0-.52-.015-.78-.045 1.46" +
                                        ".93 3.18 1.474 5.04 1.474 6.04 0 9.34-5 9.34-9.33 0-.14 0-.28-.01-.42.64-.46 1.2-1.04 1.64-1.7z"
                                    }
                                />
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText children={"Twitter"} />
                    </MenuItemAnchor>

                    <MenuItemAnchor
                        href={`https://www.addtoany.com/add_to/facebook_messenger?linkurl=${encodeURIComponent(
                            link,
                        )}&amp;linkname=${encodeURIComponent(name)}`}
                        onClick={toggleOpenShare}
                        target="_blank"
                        rel="noopener nofollow noreferrer"
                    >
                        <ListItemIcon>
                            <SvgIcon viewBox="0 0 24 24">
                                <path
                                    d={
                                        "M12 0c-6.627 0-12 4.975-12 11.111 0 3.497 1.745 6.616 4.472 8.652v4.237l4.086-2.242c1.09.301 " +
                                        "2.246.464 3.442.464 6.627 0 12-4.974 12-11.111 0-6.136-5.373-11.111-12-11.111zm1.193 14.963l-3.0" +
                                        "56-3.259-5.963 3.259 6.559-6.963 3.13 3.259 5.889-3.259-6.559 6.963z"
                                    }
                                />
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText children={"Messenger"} />
                    </MenuItemAnchor>

                    <MenuItemAnchor
                        href={`https://www.addtoany.com/add_to/google_gmail?linkurl=${encodeURIComponent(
                            link,
                        )}&amp;linkname=${encodeURIComponent(name)}`}
                        onClick={toggleOpenShare}
                        target="_blank"
                        rel="noopener nofollow noreferrer"
                    >
                        <ListItemIcon>
                            <MailIcon />
                        </ListItemIcon>
                        <ListItemText children={"Gmail"} />
                    </MenuItemAnchor>
                </Menu>
            </div>
        </TableCell>
    );
}

const useStyles = makeStyles(
    () => ({
        root: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
        clipboard: {
            marginLeft: "0.25rem",
            marginRight: "-0.25rem",
        },
        share: {
            marginLeft: "-0.25rem",
        },
    }),
    { name: "ShareCell" },
);
