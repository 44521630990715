import React from "react";
import assign from "lodash/assign";
import { Switch, Route, RouteProps, Redirect } from "react-router-dom";
import { useLocation, useService, RouterService, Auth, ROLE } from "lib";
import Statistics from "statistics/statistics";

import Account from "account";
import Home from "home";
import Upgrade from "upgrade";
import CreateUrla from "create-urla";
import Welcome from "welcome";
import Marketing from "marketing";
import Administration from "admin";
import AcceptInvite from "admin/admin-users/actions/accept-invite";

export function AppRouter() {
    const router = useLocation();
    const userRole = Auth.use("accessToken", "role_id");

    const routerService = useService(RouterService);
    assign(routerService, router);

    const availableRoutes = () => {
        const routes = [];
        switch (userRole) {
            case ROLE.ENTERPRISE:
                routes.push(<Route<RouteProps> key="/marketing" path="/marketing" component={Marketing} />);
                routes.push(<Route<RouteProps> key="/admin" path="/admin" component={Administration} />);
            case ROLE.ENTERPRISE:
            case ROLE.PRO:
            case ROLE.TRIAL:

            case ROLE.ENTERPRISE:
            case ROLE.PRO:
            case ROLE.TRIAL:
            case ROLE.FREE:
            default:
                routes.push(<Route<RouteProps> key="/account" path="/account" component={Account} />);
                routes.push(<Route<RouteProps> key="/upgrade" path="/upgrade" component={Upgrade} />);
                routes.push(<Route<RouteProps> key="/create" exact path="/create" component={CreateUrla} />);
                routes.push(<Route<RouteProps> key="/welcome" exact path="/welcome" component={Welcome} />);
                routes.push(<Route<RouteProps> key="/statistics" path="/statistics" component={Statistics} />);

                routes.push(<Route<RouteProps> key="/accept-invite/:token" exact path="/accept-invite/:token" component={AcceptInvite} />);
                routes.push(<Route<RouteProps> key="/" path="/" component={Home} />);
                break;
        }

        return routes;
    };

    return (
        <Switch>
            <Redirect exact from="/auth/sign-up" to="/welcome" />
            <Redirect exact from="/auth/sign-up/pro-trial" to="/welcome" />
            {availableRoutes()}
            <Redirect to={"/"} />
        </Switch>
    );
}
