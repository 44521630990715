import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import { animated } from "react-spring";
import { UserRelationsService } from "../../service";
import { StackItemProps, CardStack, SnackStack } from "lib/stack";
import { makeStyles, Translation, TextField, Abon, Typography, Form, useService, requireService, forgoService } from "lib";
import { FormHelperText } from "@material-ui/core";
import { InviteUserService } from "./invite-user.service";

export function InviteUser(props: { open: Abon<boolean>; service: UserRelationsService }) {
    const { open = Abon.use(() => true), service } = props;

    CardStack.use(
        open.current,
        () => ({
            onClose: () => open.set(false),
            renderer: (stackProps) => <InviteUserModal {...stackProps} {...props} open={open} service={service} />,
        }),
        [open, props.service],
    );

    return null;
}

function InviteUserModal(props: InviteUserPropsModalProps) {
    const IUService = useService(InviteUserService);
    const { onClose, interpolations, service } = props;
    const t = Translation.useMap(["cancel", "inviteUser", "inviteUserTitle", "inviteUserSubtitle"]);
    const classes = useStyles();

    const error = IUService.error.message.use().current;
    const success = IUService.success.message.use().current;
    const submittable = IUService.store.submittable.use().current;

    const onSuccessChange = () => {
        if (success && !error && !submittable) {
            const snacks = requireService({ service: SnackStack, dependent: this });
            snacks.add({ message: Translation.render("userInvited") });
            forgoService({ service: snacks, dependent: this });
            service.refresh();
            onClose();
        }
    };

    useEffect(onSuccessChange, [success]);

    return (
        <animated.div className={classes.paper} style={{ transform: interpolations.transform }}>
            <Form className={classes.form} service={IUService}>
                <div className={classes.modalHeader}>
                    <Typography variant="h5" className={classes.modalHeaderTitle}>
                        {t.render("inviteUserTitle")}
                    </Typography>
                </div>
                <div className={classes.modalContent}>
                    <Typography variant="body1">{t.render("inviteUserSubtitle")}</Typography>
                    <TextField fullWidth value={IUService.store.getValue("email") as any} />
                </div>
                <div className={classes.modalFooter}>
                    <Button
                        color={"primary"}
                        variant={"contained"}
                        className={classes.confirmButton}
                        type={"submit"}
                        children={t.render("inviteUser")}
                        disabled={!submittable}
                        key="_submit"
                    />
                    <Button onClick={onClose} variant="outlined" className={classes.cancelButton}>
                        {t.render("cancel")}
                    </Button>
                    {error && <FormHelperText error={!!error} className={classes.status} children={error} />}
                </div>
            </Form>
        </animated.div>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        paper: {
            minWidth: 400,
            width: "auto",
            maxWidth: 450,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3, 4),
            outline: 0,
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
        },
        modalHeader: {
            marginBottom: "1rem",
            paddingBottom: "0.25rem",
            borderBottom: `1px solid ${theme.palette.grey[700]}`,
        },
        modalHeaderTitle: {
            fontWeight: 500,
            fontSize: "0.9rem",
            letterSpacing: 0.5,
            color: theme.palette.grey[600],
            textTransform: "uppercase",
        },
        modalContent: {
            flex: 1,
            marginBottom: "2rem",
        },
        modalFooter: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "0 -0.5rem",
            flexWrap: "wrap",
        },
        form: {},
        status: { marginTop: 0.5, width: "100%" },
        nameField: {
            flex: "1 1 auto",

            "& .MuiFilledInput-root": {
                borderRadius: 0,
                backgroundColor: "rgba(255, 255, 255, 0)",
            },

            "& .MuiFilledInput-underline:before": {
                borderBottom: "1px solid rgba(255, 255, 255, 1)",
            },
        },
        confirmButton: {
            fontWeight: 600,
            margin: "0.5rem",
        },
        cancelButton: {
            fontWeight: 600,
            margin: "0.5rem",
        },
    }),
    { name: "InviteUserModal" },
);

interface InviteUserProps {
    open: Abon<boolean>;
    service: UserRelationsService;
}
interface InviteUserPropsModalProps extends StackItemProps, InviteUserProps {}
