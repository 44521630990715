import { ValueType } from "../value";

export class ValueMap<T extends KeyObject> extends Map<keyof T, ValueType> {
    get<K extends keyof T>(key: K): ValueType<T[K]> {
        return super.get(key);
    }

    set<K extends keyof T>(key: K, value: ValueType<T[K]>) {
        return super.set(key, value);
    }
}
