const sv: Record<string, string> = {
    //
    // GENERAL

    about: "Om oss",
    contact: "Kontakt",
    faq: "FAQ",
    createdAndDevelopedInSweden: "Byggt och utvecklat i Sverige",
    showMenu: "Visa meny",
    invalidEmail: "Ogiltig email",
    submit: "Skicka",
    mustBeDefined: "Måste vara definierad",
    unknownError: "Okänt fel",
    mustBeAtLeastXCharacters: "Måste vara åtminstone {x} tecken",
    mustBeLessThanXCharacters: "Måste innehålla mindre än {x} tecken ",
    fileIsTooLarge: "Max. filstorlek är 5 MB",
    fileFieldHelper: ".pdf, .xls, .word, .jpg < 5 MB",
    home: "Hem",
    statistics: "Statistik",
    mailNotifications: "Emailnotifikationer",
    projects: "Projekt",
    requiresUrlaProOrEnterprise: "Kräver Urla Pro eller Enterprise",
    requiresUrlaEnterprise: "Kräver Urla Enterprise",
    account: "Konto",
    upgrade: "Uppgradera",
    plans: "Planer",
    logOut: "Logga ut",
    unknownErrorOccured: "Okänt fel uppstod",
    errorOccured: "Ett fel uppstod",
    errorOccuredWhen: "Ett fel uppstod vid",
    fetchingUrlas: "Hämtningen av Urlor",
    updatedSuccessfully: "Framgångsrikt uppdaterat",
    required: "Krävs",
    invalid: "Ogiltigt",
    mustBeANumber: "Måste vara ett nummer",
    allValuesSelected: "Markerat alla {valueMany}",
    selectAllValues: "Markera alla {valueMany}",
    unselectAllValues: "Avmarkera alla {valueMany}",
    deleteSelectedValues: "Ta bort markerade {valueMany}",
    selectedValues: "valda {valueMany}",
    selectedValue: "vald {valueSingle}",
    language: "Språk",
    swedish: "Svenska",
    english: "Engelska",
    invalidUrl: "Ogiltig URL",
    deletion: "Borttagning",
    upgradeRequired: "Uppgradering behövs",
    to: "till",
    delete: "Ta bort",
    instantly: "Direkt",
    never: "Aldrig",
    noneFound: "Ingen hittad",
    uploadedFileX: 'Laddade upp fil "{x}"',
    deletedValue: "Tog bort en {single}",
    deletedValues: "Tog bort {x} {many}",
    deselectAllPage: "Avmarkera hela sidan",
    selectAllPage: "Välj hela sidan",
    deselectAll: "Avmarkera alla",
    selectAll: "Välj alla",

    //
    // AUTH

    logIn: "Logga in",
    signUp: "Bli medlem",
    email: "E-post",
    password: "Lösenord",
    passwordVerification: "Lösenordsverifiering",
    forgotPassword: "Glömt lösenord",
    resetPassword: "Återställa lösenord",
    signUpForUrla: "Bli medlem på Urla",
    signUpForFree: "Registrera dig gratis",
    signUpForProTrial: "Registrera dig för Pro Trial",
    incorrectPassword: "Fel lösenord",
    doesntMatch: "Matchar ej",
    userNotFound: "Användaren hittades inte",
    userAlreadyExists: "Användare finns redan",
    successfullySignedUp: "Blev framgångsrikt medlem",
    sentEmail: "Skickade email",
    recaptchaDetails: "Den här sidan är skyddad av reCAPTCHA",
    andTheGoogle: "och ",
    privacyPolicy: "integritetspolicyn",
    and: "och",
    termsOfService: "användarvillkoren",
    apply: "från Google gäller",
    iAcceptThe: "Jag accepterar",
    signUpEmailTooltip: "Denna e-postadress kommer du att logga in med",
    invalidToken: "Länken som du använder är trasig eller har gått ut",
    newPasswordSuccess: "Ditt nya lösenord uppdaterades.",
    signUpProTrialDescription: "Prova Urla Pro med en gräns på 20 visningar",
    //
    // HOME

    urla: "Urla",
    urlaTooltip:
        "Den unika länken som du skickar till mottagaren för att hålla koll på visningar. Du kan kopiera länken till ditt urklipp eller klicka på delningsknappen för fler alternativ.",
    linkName: "Länknamn",
    stats: "Statistik",
    created_date: "Skapad",
    latest_view: "Senast",
    expires: "Förfaller",
    notify: "Avisera",
    mailNotify: "Avis. email",
    receiver: "Mottagare",
    copy: "Kopiera",
    receiverTooltip:
        'Används för att markera vem eller vilka som länken är avsedd för och används därmed också i emailnotifikationerna, t.ex. "Jan Jansson - IKEA".',
    views: "Visningar",
    createUrla: "Skapa Urla",
    downloadExcelSample: "Ladda ner ett Excel-exempel",
    link: "Länk",
    name: "Namn",
    numberOfLinks: "Antal länkar",
    or: "eller",
    orTypeALink: "Eller fyll i en länk",
    project: "Projekt",
    notificationSettingsUpgradeInfo: "Med Urla Pro eller Enterprise kan du välja tid och veckodag så emailnotifikationerna ska skickas.",
    day: "Dag",
    days: "dagar",
    hours: "timmar",
    minutes: "minuter",
    time: "Tid",
    date: "Datum",
    now: "Nu",
    requiresSubscription: "Kräver medlemskap",
    everyDay: "Varje dag",
    monday: "Måndag",
    tuesday: "Tisdag",
    wednesday: "Onsdag",
    thursday: "Torsdag",
    friday: "Fredag",
    saturday: "Lördag",
    sunday: "Söndag",
    notifications: "Notifikationer",
    notificationSettings: "Emailnotifikationer",
    update: "Uppdatera",
    expireDateUpgradeInfo: "Med Urla Pro eller Enterprise kan du välja förfallodatumet för dina länkar.",
    projectsUpgradeInfo:
        "Med Urla Pro eller Enterprise kan du koppla länkar till projekt, vilket gör det lättare att organisera ett störe antal urlor.",
    file: "Fil",
    anyUrlToShare: "Någon länk att dela",
    excelList: "Excel-lista",
    excelListUpgradeInfo:
        "Med Urla Enterprise kan du ladda upp ett Exceldokument för att skapa en samling urlor med definierade mottagare.",
    proLinksAmountUpgradeInfo: "Med Urla Pro kan du skapa upp till 100 urlor, men tills dess måste du ta bort en för att skapa en ny.",
    enterpriseLinksAmountUpgradeInfo:
        "Med Urla Enterprise kan du skapa ett obegränsat antal urlor, men tills dess måste du ta bort en för att skapa en ny.",
    shareUrlaInfo: "Dela urlan med mailkontakter eller på sociala medier",
    updatingTheLinkName: "uppdateringen av länknamnet",
    updatingTheReceiver: "uppdateringen av mottagaren",
    updatingTheNotificationStatus: "uppdateringen av notifikationsstatusen",
    copyToClipboard: "Kopiera till urklipp",
    deletingUrlas: "borttagningen av Urlor",
    aLinkOrFileIsRequired: "En länk eller fil behövs",
    extension: "Custom link",
    amount: "Antal",
    searchForUrlas: "Sök efter urlor",
    newProject: "Nytt projekt",
    viewStatistics: "Visa statistik",
    attachReceivers: "Koppla mottagare",
    share: "Dela",
    changeExpiryDate: "Ändra förfallodatum",
    expiryDateUpgradeTooltip: "Med Urla Pro eller Enterprise kan du ändra förfallodatumet för dina skapade urlor.",
    changingNotificationSettings: "ändring av notifikationsinställningar",
    noExpiryDate: "Inget förfallodatum",
    deleteSelectedValuesConfirmation: "Är du säker på att du vill ta bort de valda {valueTheMany}?",
    deleteSelectedValueConfirmation: "Är du säker på att du vill ta bort den valda {valueThe}?",
    deleteSelectedValuedDescription: "Denna åtgärd är inte reversibel.",
    sortByCreationTime: "Sortera efter skapelsetid",
    filterUrlasByProject: "Filtrera urlor efter projekt",
    stopFilteringUrlasByProject: "Sluta filtrera urlor efter projekt",
    resetViews: "Återställ visningar",
    resetUrlaViewsConfirmation: "Är du säker på att du vill återställa visningarna?",
    createdXUrlas: "Skapade {x, plural, one {en urla} other {# urlor}}",
    createdProjectX: `Created project "{x}"`,
    updatedMailNotifications: "Uppdaterade inställningar för emailnotifikationer",
    changedExpiryDate: "Ändrade förfallodatum",
    toggledMailNotify: "Växlade emailnotifikationer",

    projectSingle: "projekt",
    projectThe: "projektet",
    projectTheMany: "projekten",
    projectMany: "projekt",
    urlaSingle: "urla",
    urlaThe: "urla",
    urlaTheMany: "urlorna",
    urlaMany: "urlor",
    statisticSingle: "statistik",
    statisticThe: "statistiken",
    statisticTheMany: "statistiken",
    statisticMany: "statistik",
    linkStatisticSingle: "länkstatistik",
    linkStatisticThe: "länkstatistiken",
    linkStatisticTheMany: "länkstatistiken",
    linkStatisticMany: "länkstatistikselectedValues",

    //
    // Account

    settings: "Inställningar",
    profile: "Profil",
    payments: "Betalningar",
    subscription: "Medlemskap",
    newEmail: "Ny emailadress",
    confirmNewEmail: "Bekräfta ny emailadress",
    updateEmail: "Uppdatera emailadress",
    currentPassword: "Nuvarande lösenord",
    newPassword: "Nytt lösenord",
    confirmNewPassword: "Bekräfta nytt lösenord",
    updatePassword: "Uppdatera lösenord",
    status: "Status",
    paid: "Betald",
    invoiceId: "Fakturakod",
    remainingAmount: "Återstående belopp",
    paidAmount: "Betalt belopp",
    invoice: "Faktura",
    pdf: "PDF",
    paymentDue: "Förfallodatum",
    active: "Aktiva",
    notActive: "Inte aktiv",

    //
    // UPGRADE

    perMonth: "per månad",
    get: "Skaffa",
    billedMonthly: "månatlig fakturering",
    featuresIncluded: "Inkluderar",
    links: "länkar",
    linksExpireAfterOneMonth: "Länkar förfaller efter en månad",
    toggleMailNotifications: "Emailnotifikationer av/på",
    scheduledEmailNotifications: "Schemalagda emailnotifikationer",
    customExpiryDates: "Valbara förfallodatum",
    advancedStatistics: "Länkstatistik",
    customUrls: "Custom Link",
    attachLinksToProjects: "Koppla länkar till projekt",
    allFeaturesFromPro: "Allt från Pro",
    unlimitedLinks: "Obegränsat antal länkar",
    adminAndUserAccess: "Admin & underanvändare",
    uploadReceiverSpreadsheets: "Ladda upp Exceldokument med mottagare",
    bulkMailTemplates: "Bulk Email & Mallar",
    bulkSMSTemplates: "SMS utskick med unika länkar",
    cancelSubscription: "Avbryt medlemskap",
    cancelProTrialSubscription: "Avsluta prenumeration på Pro Trial",
    free: "Free",
    pro: "Pro",
    proTrial: "Pro Trial",
    enterprise: "Enterprise",
    upgradeSubscription: "Uppgradera medlemskap",
    pay: "betala",
    currency: "Valuta",
    sek: "SEK",
    euro: "EUR",
    usd: "USD",
    billingEmail: "Faktureringsmail",
    paymentsAreHandledByStripe:
        "Betalningar hanteras av Stripe. Ditt kortnummer och annan känslig information kommer bara vara synlig för Stripe för att genomföra betalningen och blir därmed inte synlig för Urla.",
    upgradeTo: "Uppgradera till",
    downgradeTo: "Nedgradera till",
    cancel: "Avbryt",
    cancellingTheSubscription: "annuleringen av medlemskapet",
    cancelEnterpriseSubscriptionInfo:
        "Genom att säga upp ditt Enterprise abonnemang kommer du inte längre ha tillgång till Enterprise funktionerna.",
    cancelEnterpriseSubscriptionInfoExtra:
        "Du kommer också att förlora alla dina Urla-länkar. Om du vill behålla dina 100 senaste Urla-länkar, nedgradera till Urla Pro.",
    cancelProSubscriptionInfo: "Genom att säga upp ditt Urla Pro abonnemang kommer du inte längre ha tillgång till Pro funktionerna.",
    cancelProSubscriptionInfoExtra: "Du kommer också att förlora alla dina Urla-länkar.",
    cancelProTrialSubscriptionInfo:
        "Genom att säga upp ditt Urla Pro Trial abonnemang kommer du inte längre ha tillgång till Pro funktionerna.",
    cancelProTrialSubscriptionInfoExtra: "Du kommer också att förlora alla dina Urla-länkar.",
    completingThePayment: "slutförandet av betalningen",
    notAvailable: "Inte tillgängligt",
    commingSoon: "Kommer snart",
    upgradedToX: "Uppgraderad till {x}",
    downgradedToX: "Nedgraderas till {x}",
    cancelledSubscriptionForX: "Avbröt medlemskap for {x}",
    close: "Stänga",
    keepLatestUrlas: "Ta inte bort mina senaste 100 URLA: er",

    // Statistics

    viewData: "Visa data",
    user: "Användare",
    statisticsUserTooltip: "Användaren som skapade länken",
    lastView: "Senast visad",
    created: "Skapad",
    exportList: "Exportera lista",
    view: "Visa",
    ip: "IP",
    linkIpTooltip:
        "Se om olika IP-nummer använder din länk. Detta indikerar om länken delats med andra. Första användaren tilldelas IP1. Används länken från en annan IP-adress döps den användaren till IP2 osv.",
    referrer: "Referer",
    linkReferrerTooltip: "Ifall länken klickades på en hemsida, exempelvis på Facebook eller LinkedIn.",
    country: "Land",
    location: "Plats",
    linkLocationTooltip: "Visar platsen av din besökare (stad och land).",
    linkTimeTooltip: "Visar tiden för varje visning",
    statisticsUpgradeTitle: "Uppgradera för att se statistik",
    statisticsUpgradeBody: "Med Urla Pro eller Enterprise kan du se statistik för dina skapade urlor.",
    fromDate: "Från datum",
    toDate: "Till datum",

    // Create Flow

    create: "Skapa",
    pasteLink: "Klistra in en länk",
    pasteLinkPlaceholder: "Klistra in här (https://...)",
    pasteLinkTooltip:
        "Klistra in den länk som du vill spåra här, exempelvis en YouTube-video (https://www.youtube.com/watch?v=). " +
        "Om du vill spåra en fil kan du också ladda upp den här, så skapar Urla en spårbar länk åt dig (max. 5 MB).",
    chooseName: "Välj ett namn",
    chooseNameTooltip: 'Ge din länk ett namn så att du kan identifiera den, t.ex. "Min Video".',
    nameYourLink: "Namnge din länk",
    selectAmount: "Välj antal",
    selectAmountTooltip:
        "Välj antalet spårbara länkar som Urla ska skapa baserat på den länken eller filen du specificerade, t.ex. en för varje mottagare. " +
        "Du är begränsad till fyra länkar med Urla Free.",
    back: "Tillbaka",
    createUrlas: "Skapa urlor",
    creatingTheUrla: "skapelsen av urlan",

    // Welcome

    welcome: "Välkommen",
    welcomeCaption: "Du är bara några steg från att få reda vilka som klickar på dina länkar",
    welcomeToUrla: "Välkommen till Urla",
    getStarted: "Kom igång",

    // Marketing

    marketing: "Marknadsföring",
    campaignsEmailActivity: "E-postaktivitet",
    campaignsSMSActivity: "SMS-aktivitet",
    marketingActivityUpgradeTitle: "Uppgradera för att se marknadsföringsaktivitet",
    marketingActivityUpgradeBody: "I Urla Enterprise kan du se marknadsföringsaktivitet för din skapade urlas.",

    // Activity

    searchForMarketingActivity: "Sök efter meddelanden",
    unknown: "Okänd",
    sent: "Skickat",
    delivered: "Levererad",
    queued: "Kö",
    removed: "Tog bort",
    rejected: "Rejected",
    failed: "Avvisade",
    expired: "Utgånget",
    retry: "Försök igen",
    unsubscribed: "Avslutad",
    undelivered: "Olevererat",
    blocked: "Blockerad",

    // Unsubscriptions

    campaignsUnsubscriptions: "Avsluta prenumerationer",
    searchForUnsubscriptions: "Sök efter avanmälningar",
    unsubscriptionsUpgradeTitle: "Uppgradera för att se avbokningar",
    unsubscriptionsUpgradeBody: "I Urla Enterprise kan du se Unsubscriptions för ditt skapade urlas.",

    // Campaigns

    mailCampaigns: "E-postkampanjer",
    mailTemplateTitle: "Mailmall",
    mailTemplateSubhead: "Skapa en ny mailmall eller välj en tidigare skapad",
    mailReceivers: "Mailmottagare",
    mailReceiversTitle: "Mailmottagare",
    mailReceiversSubhead: "Välj mottagare från ett projekt.\nProjektet måste komma från en Excelfil som använts vid skapandet av urlas.",
    sendMailTitle: "Skicka email",
    sendMailSubhead: "Skicka iväg ett email till varje mottagare som är vald ovan",
    sendMailAction: "Skicka email",

    smsCampaigns: "SMS-kampanjer",
    smsTemplateTitle: "SMS-mall",
    smsTemplateSubhead: "Skapa en ny SMS-mall eller välj en sparad",
    smsReceivers: "SMS-mottagare",
    smsReceiversTitle: "SMS-mottagare",
    smsReceiversSubhead: "Välj mottagare från ett projekt.\nProjektet måste komma från en Excelfil som använts vid skapandet av urlas.",
    sendSMSTitle: "Skicka SMS",
    sendSMSSubhead: "Skicka ett SMS till varje mottagare som väljs ovan",
    sendSMSAction: "Skicka SMS",

    selectProject: "Välj projekt",
    loadProjectLabel: "Ladda projekt",
    loadProjectTooltip: "Lägg till mottagarna med en e-post från projektet",
    receivers: "Mottagare",
    addSelected: "Lägg till valda",
    addAll: "Lägg till alla",
    remove: "Ta bort",
    removeSelected: "Ta bort valda",
    removeAll: "Ta bort alla",
    selectedReceivers: "Valda mottagare",
    noReceiversFoundInProjectX: `Inga mottagare hittades i projektet "{x}"`,
    sendMessageAt: "Skickat meddelande kl",
    minMaxScheduleDate: "från {x1} {u1} till {x2} {u2}",
    send: "Skicka",
    sentMessageToTheReceivers: "Skickat meddelande till mottagarna",
    senderName: "Avsändare",

    // Templates

    emailTitle: "Ämne",
    templateThe: "mallen",
    templateSingle: "mall",
    templateTheMany: "mallarna",
    templateMany: "mallar",
    templateName: "Mallnamn",
    template: "Mall",
    variables: "Variabler",
    variableTooltip: "Infoga anpassad variabel",
    failedSelectingTemplate: "Misslyckades med att välja mallen",
    incorrectTemplateType: "Felaktig typ av mall",
    templateEditorPlaceholder: "Meddelande",
    selectTemplate: "Välj mall",
    createdMailTemplateX: `Skapade mailmall "{x}"`,
    savedMailTemplateX: `Sparade mailmall "{x}"`,
    createdSMSTemplateX: `Skapade sms-mall "{x}"`,
    savedSMSTemplateX: `Sparade sms-mall "{x}"`,
    loadTemplateLabel: "Ladda mall",
    loadTemplateTooltip: "Ladda innehåll från mall",
    saveTemplateLabel: "Spara mall",
    saveTemplateTooltip: "Spara email som mall",
    removeTemplateLabel: "Ta bort mall",
    removeTemplateTooltip: "Ta bort mall från listan",
    urlaURL: "URLA-länk",
    urlaName: "URLA-namn",
    receiverName: "Mottagarens namn",
    receiverDate: "Mottagardatum",
    receiverTime: "Mottagartid",
    unsubscribeURL: "Avanmäla länk",
    unsubscribeSMS: "Avsluta prenumeration på SMS ",
    unsubscribe: "Avanmäla",
    buttons: "Knappar",
    primaryButton: "Primär knapp",
    secondaryButton: "Sekundär knapp",
    textLink: "Textlänk",
    ImageGalleryTooltip: "Ladda upp och ordna dina bilder.",
    TemplateWithChangesModalTitle: "Vill du spara ändringarna du gjorde i",
    TemplateWithChangesModalDescription: "Dina ändringar kommer att gå förlorade om du inte sparar dem.",
    save: "Spara",
    dontSave: "Spara inte",
    untitled: "Ofrälse",
    newDocumentTooltip: "Ny tom mall",
    notSavedWithChanges: "Mall med ändringar",
    withoutChanges: "Mall utan ändringar",
    sms: "sms",

    // Image Manager

    ImageGalleryTitle: "Bildhanteraren",
    ImageGallerySubtitle: "Ladda upp och välj bilden som du vill infoga i innehållet.",
    uploadImageTooltip: "Ladda upp en bild för att använda den i dina mallar",
    uploadImage: "Ladda upp bild",
    imageSingle: "bild",
    imageMany: "bilder",
    imageTheMany: "bilderna",
    imageThe: "bilden",
    deleteImageTooltip: "Ta bort bild",

    // TABLE

    first: "Först",
    previous: "Tidigare",
    next: "Nästa",
    last: "Sista",
    rowsPerPage: "Rader per sida",

    // ADMIN
    administration: "Administrering",

    // USERS ADMIN
    users: "Användare",
    userSingle: "användare",
    userThe: "användare",
    userTheMany: "användare",
    userMany: "användare",

    inviteUser: "Bjud in användare",
    inviteUserTitle: "Bjud in en användare",
    inviteUserSubtitle: "Bjud in en användare att vara en del av din organisation. Användaren kommer att få en valideringslänk via e-post.",
    userInvited: "En e-postinbjudan har skickats till användaren.",
    permissionGranted: "Du har beviljat en användare behörighet att se din statistik.",
    permissionBeenGranted: "En ny användare har beviljat dig tillgång till sin statistik.",
    resendInvite: "Skicka inbjudan",

    invited: "Inbjuden",
    accepted: "Accepterad",
    revoked: "Återkallad",

    // Account Reminders

    viewsLimitHalfReachedTitle: "Du har nått {views} av {limit} gratis-visningar! ",
    viewsLimitHalfReachedDescription:
        "Ditt Urla Pro Trial konto har nått {views} av {limits} visningar. Uppgradera till Urla Pro för att behålla alla funktioner och obegränsat antal visningar!",
    viewsLimitReachedTitle: "Du har nått gränsen på {limit} visningar",
    viewsLimitReachedDescription:
        "Ditt Urla Pro Trial konto har nått gränsen på {limit} visningar. Uppgradera till Urla Pro och få obegränsat med visningar och tillgång till alla Pro funktioner.",
    viewsLimitOverReachedTitle: "Du har nått gränsen på {limit} visningar",
    viewsLimitOverReachedDescription:
        "Ditt Urla Pro Trial konto har nya visningar! Men då du har nått gränsen på 20 gratis visningar behöver du uppgradera till Urla pro för att ha tillgång till all statistik och alla Urla Pro funktioner.",
    upgradeToPro: "Uppgradera till Pro",
    reminLater: "Påminn mig senare!",
};

export default sv;
