import {
    Service,
    Abon,
    Class,
    TemplateType,
    LoadingStatus,
    ErrorAlert,
    Api,
    ENDPOINT,
    SnackStack,
    Translation,
    DateTimeValue,
    DATE_TIME_FORMAT,
    TextValue,
} from "lib";
import moment from "moment";
import { TemplateEditorProps } from "templates/components";

import { TemplateEditorService } from "./template-editor";
import { TemplateReceiversService, TemplateReceiversServiceProps } from "./template-receivers";

export class TemplateService extends Service {
    editor: TemplateEditorService;
    receivers: TemplateReceiversService;
    loading: LoadingStatus;
    snacks: SnackStack;
    senderName: TextValue;
    scheduledDateTime: DateTimeValue;
    scheduled: Abon<boolean>;
    canSend: Abon<boolean>;

    constructor(props: TemplateServiceProps = {}) {
        super();

        this.snacks = this.require(SnackStack);
        this.loading = new LoadingStatus(false, { circular: true });
        this.editor = this.require(props.editor || (TemplateEditorService as any), { type: props.type });
        this.receivers = this.require(props.receivers || (TemplateReceiversService as any), { type: props.type });
        this.senderName = new TextValue({ label: "senderName", name: "sender_name" });
        this.scheduledDateTime = new DateTimeValue({
            initial: null,
            format: DATE_TIME_FORMAT.ISO8601,
            placeholder: Translation.get("now"),
            label: "sendMessageAt",
        });
        this.scheduled = Abon.from(
            () => {
                if (this.scheduledDateTime.current && this.scheduledDateTime.value.current) {
                    return true;
                }
                return false;
            },
            (listener) => [this.scheduledDateTime.value.subscribe(listener)],
            this.deconstructFns,
        );
        this.canSend = Abon.from(
            () => {
                if (this.editor.empty.current) {
                    return false;
                } else if (!this.receivers.selected.ids.current.length) {
                    return false;
                } else if (this.scheduled && moment(this.scheduledDateTime.current).isSameOrBefore(moment().add(15, "minutes"))) {
                    return false;
                }
                return true;
            },
            (listener) => [
                this.editor.canSave.subscribe(listener),
                this.editor.empty.subscribe(listener),
                this.receivers.selected.ids.subscribe(listener),
                this.receivers.project.value.subscribe(listener),
                this.scheduled.subscribe(listener),
                this.scheduledDateTime.value.subscribe(listener),
            ],
            this.deconstructFns,
        );
        this.send = this.send.bind(this);
    }

    async send() {
        if (!this.canSend.current) {
            return;
        }

        const stopLoading = this.loading.startThrottle();
        if (!this.editor.content.current.includes("{{unsubscribe.url}}") && this.editor.type === TemplateType.email) {
            let newChanges = this.editor.content.current;
            newChanges += '<p style="text-align: center"><a href="{{unsubscribe.url}}">Unsubscribe</a></p>';
            this.editor.content.set(newChanges);
        }

        const getTemplateTextValue = () => {
            switch (this.editor.type) {
                case TemplateType.sms:
                    return this.editor.contentEditor.current.getContent({ format: "text" });
                case TemplateType.email:
                default:
                    return this.editor.content.current;
            }
        };

        try {
            const response = await Api.request(ENDPOINT.sendMessageToReceivers, {
                message_type: this.editor.type,
                receiver_ids: this.receivers.selected.ids.current,
                template_text: getTemplateTextValue(),
                template_title: this.editor.title.current || "",
                sender_name: this.senderName.current || null,
                send_at: this.scheduled.current ? moment(this.scheduledDateTime.current).unix() : null,
                project_id: parseInt(this.receivers.project.selected.current),
            });

            this.receivers.selected.set([]);
            this.receivers.selectable.set([]);
            await this.receivers.project.set(undefined);
            this.receivers.project.selected.set(undefined);
            this.scheduled.set(false);
            this.scheduledDateTime.set(undefined);

            this.snacks.add({ message: Translation.render("sentMessageToTheReceivers") });
        } catch (error) {
            console.error(error);
            ErrorAlert.alert({ error });
        } finally {
            stopLoading();
        }
    }
}

export interface TemplateServiceProps {
    editor?: TemplateEditorService | Class<TemplateEditorService, [Partial<TemplateEditorProps>]>;
    receivers?: TemplateReceiversService | Class<TemplateReceiversService, [Partial<TemplateReceiversServiceProps>]>;
    type?: TemplateType;
}
