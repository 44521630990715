import React from "react";
import { Helmet } from "react-helmet";
import { useTheme, Box, makeStyles, Translation, Container, Auth, ROLE, SUBSCRIPTION_PLAN, ButtonLink, Abon } from "lib";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import { UpgradePlan } from "./plan";

export default function Upgrade() {
    const t = Translation.useMap(["free", "links", "upgrade", "home"]);
    const theme = useTheme();
    const classes = useStyles();

    const roleId = Auth.use("accessToken", "role_id");
    const openDowngradeSubscription = Abon.use(() => false);

    return (
        <>
            <Helmet title={[t.get("upgrade"), "URLA"].join(" - ")} />

            <Container>
                <div className={classes.row}>
                    <ButtonLink
                        to={"/"}
                        color={"primary"}
                        children={
                            <>
                                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                {t.render("home")}
                            </>
                        }
                    />
                </div>
                <Box className={classes.root}>
                    <UpgradePlan
                        name={"Free"}
                        features={[
                            { label: Translation.result([4, t.get("links")].join(" ")) },
                            { label: "linksExpireAfterOneMonth" },
                            { label: "toggleMailNotifications" },
                        ]}
                        plan={SUBSCRIPTION_PLAN.FREE}
                        active={roleId === ROLE.FREE}
                        openDowngradeSubscription={openDowngradeSubscription}
                    />
                    <UpgradePlan
                        name={"Pro"}
                        features={[
                            { label: Translation.result([100, t.get("links")].join(" ")) },
                            { label: "scheduledEmailNotifications" },
                            { label: "customExpiryDates" },
                            { label: "advancedStatistics" },
                            { label: "customUrls" },
                            { label: "attachLinksToProjects" },
                        ]}
                        video={"https://urla.solidtango.com/widgets/embed/j095w3ko"}
                        active={roleId === ROLE.PRO}
                        plan={SUBSCRIPTION_PLAN.PRO}
                        openDowngradeSubscription={openDowngradeSubscription}
                    />
                    <UpgradePlan
                        name={"Enterprise"}
                        features={[
                            { label: "unlimitedLinks" },
                            { label: "adminAndUserAccess" },
                            { label: "uploadReceiverSpreadsheets" },
                            { label: "bulkMailTemplates" },
                            { label: "bulkSMSTemplates" },
                            { label: "allFeaturesFromPro" },
                        ]}
                        video={"https://urla.solidtango.com/widgets/embed/4lffvuqy"}
                        active={roleId === ROLE.ENTERPRISE}
                        plan={SUBSCRIPTION_PLAN.ENTERPRISE}
                        openDowngradeSubscription={openDowngradeSubscription}
                    />
                </Box>
            </Container>
        </>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            justifyContent: "space-between",
            flexWrap: "wrap",
            margin: "1.5rem -1vw 3rem -1vw",

            "& > div": {
                margin: "0 1vw",
                width: 300,
            },

            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                justifyContent: "flex-start",
                margin: "1rem 0",

                "& > div": {
                    margin: "1rem 0",
                    width: "100%",
                },
            },
        },
        row: {
            flex: 1,
            width: "100%",
            display: "flex",
            alignSelf: "flex-start",
            marginTop: "1.5rem",
        },
    }),
    { name: "Upgrade" },
);
