import { ValueMetaThunk, ValueMeta } from "lib/form/value-meta";

import { ValueStateProps } from "../value";
import { FileValue } from "../file";
import { TextValue } from "../text";
import { RadioValue } from "../radio";
import { SelectValue } from "../select";
import { DateValue } from "../date";
import { SliderValue } from "../slider";
import { TimeValue } from "../time";
import { DateTimeValue } from "../date-time";
import { SwitchValue } from "../switch";
import { CheckboxValue } from "../checkbox";
import { CompletedTextValue } from "../completed-text";

export type ValueType<T = any> =
    | TextValue
    | RadioValue<T>
    | SelectValue<T>
    | DateValue
    | FileValue
    | SliderValue
    | TimeValue
    | DateTimeValue
    | CheckboxValue
    | SwitchValue
    | CompletedTextValue<T>;

export function getValue<T>(thunk: ValueMetaThunk<T>, props?: ValueStateProps<any>): ValueType<T> {
    let meta: ValueMeta<T>;

    if (typeof thunk === "string") {
        meta = { type: thunk };
    } else {
        meta = thunk;
    }

    if (meta.tableOnly) {
        return null;
    }

    if (!meta.type) {
        meta.type = "text";
    }

    if (meta.type === "text") {
        return new TextValue({ ...meta, ...props });
    } else if (meta.type === "select") {
        return new SelectValue<T>({ ...meta, ...props });
    } else if (meta.type === "date") {
        return new DateValue({ ...meta, ...props });
    } else if (meta.type === "radio") {
        return new RadioValue<T>({ ...meta, ...props });
    } else if (meta.type === "file") {
        return new FileValue({ ...meta, ...props });
    } else if (meta.type === "slider") {
        return new SliderValue({ ...meta, ...props });
    } else if (meta.type === "time") {
        return new TimeValue({ ...meta, ...props });
    } else if (meta.type === "date-time") {
        return new DateTimeValue({ ...meta, ...props });
    } else if (meta.type === "switch") {
        return new SwitchValue({ ...meta, ...props });
    } else if (meta.type === "checkbox") {
        return new CheckboxValue({ ...meta, ...props });
    } else if (meta.type === "completed-text") {
        return new CompletedTextValue({ ...meta, ...props });
    }

    return null;
}
