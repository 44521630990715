import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles, Box, Translation, useService } from "lib";
import { MarketingUnsubscriptionsService } from "./marketing-unsubscriptions-service";
import { MarketingUnsubscriptionsTable } from "./marketing-unsubscriptions.table";
import { MarketingUnsubscriptionsActions } from "./actions";

export function MarketingUnsubscriptions() {
    const classes = useStyles();
    const t = Translation.useMap(["marketing", "campaignsUnsubscriptions"]);
    const service = useService(MarketingUnsubscriptionsService);

    return (
        <>
            <Helmet title={[t.get("campaignsUnsubscriptions"), t.get("marketing"), "URLA"].join(" - ")} />

            <Box className={classes.root}>
                <MarketingUnsubscriptionsActions service={service} />
                <MarketingUnsubscriptionsTable service={service} />
            </Box>
        </>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            padding: 0,
            position: "relative",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            flexShrink: 1,
        },
    }),
    { name: "MarketingUnsubscriptions" },
);
