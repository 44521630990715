/* eslint-disable @typescript-eslint/camelcase */
import {
    ValueMetaMap,
    Auth,
    FormService,
    Api,
    ENDPOINT,
    TextValue,
    SUBSCRIPTION_PLAN,
    Status,
    ErrorAlert,
    Abon,
    SnackStack,
    Translation,
    CURRENCY,
    CheckboxValue,
} from "lib";
import { Elements } from "react-stripe-elements";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import { UpgradeCheckoutFormProps, StripeCustomer } from "./upgrade-checkout.types";

export class UpgradeCheckoutService extends FormService<UpgradeCheckoutFormProps> {
    static STRIPE_PUBLISHABLE_KEY =
        process.env.NODE_ENV === "development"
            ? "pk_test_5TULVgajrB9T4m3aPzrXA5q800lQnXDt6G"
            : "pk_live_0IHkvKtOf6d01fNtgXG5UCAV00J6ISxG3i";
    static STRIPE_SCRIPT_ID = "$stripe";

    paymentStatus: Status;
    stripe: Promise<stripe.Stripe>;
    resolvedStripe: stripe.Stripe;
    customer: Promise<StripeCustomer>;
    elements: Elements;
    plan: SUBSCRIPTION_PLAN;
    open: Abon<boolean>;
    snacks: SnackStack;
    currency = new Abon<CURRENCY>(CURRENCY.SEK);
    card = new Abon(null);
    keepLatestUrlas = new CheckboxValue({
        required: false,
        initial: true,
    });
    downgrade = new Abon<boolean>(false);

    get element(): stripe.elements.Element {
        if (
            !this.elements ||
            !this.elements.state ||
            !(this.elements.state as any).registeredElements ||
            !(this.elements.state as any).registeredElements.length
        ) {
            return null;
        }

        return (this.elements.state as any).registeredElements[0].element;
    }

    constructor() {
        super({
            type: UpgradeCheckoutFormProps,
            // status,
            metaMap: new ValueMetaMap<UpgradeCheckoutFormProps>({
                meta: {
                    checkoutName: {
                        label: "name",
                        required: true,
                    },
                    billingEmail: {
                        label: "billingEmail",
                        required: true,
                    },
                },
            }),

            onSuccess: async (props) => {
                if (!props.checkoutName) {
                    this.store.addInputError("checkoutName", "mustBeDefined");
                    return;
                }

                if (!this.element) {
                    this.store.addError("unknownError");
                    return;
                }

                const stopLoading = this.paymentStatus.loading.start();

                try {
                    const stripe = await this.stripe;

                    const { token } = await stripe.createToken(this.element, {
                        name: props.checkoutName,
                    });

                    if (!token) {
                        return;
                    }

                    const response = await Api.request(ENDPOINT.registerStripeSubscription, {
                        stripeToken: token.id,
                        stripeEmail: props.billingEmail,
                        subscription_plan: this.plan,
                        currency: this.currency.current,
                        keep_latest_urlas: this.keepLatestUrlas.current,
                    });

                    if (response && response.jwt) {
                        this.open.set(false);
                        Auth.setJwt(response.jwt);
                        this.snacks.add({
                            message: Translation.render({
                                id: this.downgrade.current ? "downgradedToX" : "upgradedToX",
                                values: { x: this.plan },
                            }),
                        });
                    } else {
                        throw new Error((response && (response.error || response.message)) || "Unknown error");
                    }
                } catch (error) {
                    console.error({ error });
                    ErrorAlert.alert({
                        when: "completingThePayment",
                        error,
                    });
                } finally {
                    stopLoading();
                }
            },
        });

        this.snacks = this.require(SnackStack);

        this.paymentStatus = new Status({ loading: { circular: true } });

        this.paymentStatus.loading.subscribe((loading) => {
            if (loading) {
                this.loading.add("$payment");
            } else {
                this.loading.remove("$payment");
            }
        });

        const stopLoading = this.initializing.start();

        this.stripe = new Promise((resolve) => {
            if (document.getElementById(UpgradeCheckoutService.STRIPE_SCRIPT_ID)) {
                return resolve(Stripe(UpgradeCheckoutService.STRIPE_PUBLISHABLE_KEY));
            }

            const script = document.createElement("script");
            document.head.appendChild(script);

            script.onload = async () => {
                resolve(Stripe(UpgradeCheckoutService.STRIPE_PUBLISHABLE_KEY));
            };

            script.src = "https://js.stripe.com/v3/";
            script.id = UpgradeCheckoutService.STRIPE_SCRIPT_ID;
        }).then((stripe: stripe.Stripe) => {
            this.resolvedStripe = stripe;

            return stripe;
        });

        this.customer = Auth.jwt.then(async () => {
            const response = await Api.request(ENDPOINT.getStripeCustomer, {});
            return response["Stripe Customer"];
        });

        const namePromise = this.customer.then((customer: StripeCustomer) => {
            const initialEmail = customer.email || Auth.get("accessToken", "email");
            let initialName = customer.name;

            if (!initialName) {
                initialName = initialEmail;

                initialName = startCase(
                    toLower(
                        initialName
                            .split("@")[0]
                            .split(".")
                            .join(" "),
                    ),
                );
            }

            if (initialName) {
                const nameValue: TextValue = this.store.getValue("checkoutName") as any;
                const emailValue: TextValue = this.store.getValue("billingEmail") as any;

                emailValue.valueProps.initial = initialEmail;
                emailValue.value.current = initialEmail;
                emailValue.set(initialEmail);

                nameValue.valueProps.initial = initialName;
                nameValue.value.current = initialName;
                nameValue.set(initialName);
            }
        });

        Promise.all([this.stripe, this.customer, namePromise])
            .then(() => {
                stopLoading();
            })
            .catch((e) => {
                console.error(e);
                this.open.set(false);
                stopLoading();
            });
    }

    deconstruct() {
        const stripeScriptElement = document.getElementById(UpgradeCheckoutService.STRIPE_SCRIPT_ID);

        if (stripeScriptElement) {
            stripeScriptElement.parentElement.removeChild(stripeScriptElement);
        }
    }
}
