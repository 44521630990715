import { Service } from "servido";
import { AbonDeep, Abon } from "abon";
import merge from "lodash/merge";

import { StackItem, StackVariant } from "../types";

export class Stack<IT extends StackItem = StackItem> extends Service {
    state: StackAbon<this> = new StackAbon<this>(Stack.getState<this>([], {}));

    protected $idIndex: number;
    protected $parent?: Stack<IT>;

    constructor(readonly props: StackProps<IT> = {}) {
        super();

        if (typeof this[Service.key.id] === "string") {
            const parentHash = Stack.getParentHash(this);
            const parent = parentHash ? this.require(this.type, ...(([parentHash] as any) as [])) : this.require(this.type);

            Object.defineProperty(this, "$parent", {
                value: parent,
                writable: false,
                configurable: false,
                enumerable: false,
            });
        } else {
            delete this.$parent;
        }

        Object.defineProperty(this, "$idIndex", { value: 0, configurable: false, enumerable: false });
    }

    /** Add an item to the stacky. */
    add(item: IT): StackRemoveFn {
        const parsedItem: ParsedStackItem<this> = merge({}, item) as any;

        parsedItem.id = typeof parsedItem.id === "string" ? parsedItem.id : String(this.$idIndex++);

        let notifyAdd: boolean;

        let ids = this.state.current.ids;

        if (!ids.includes(parsedItem.id)) {
            notifyAdd = true;
        } else {
            ids = ids.filter((_id) => _id !== parsedItem.id);
        }

        if (this.props.unshift) {
            ids = [parsedItem.id].concat(...ids);
        } else {
            ids = ids.concat(parsedItem.id);
        }

        parsedItem.variant = this.props.variant || parsedItem.variant;
        parsedItem.addedIndex = ids.indexOf(parsedItem.id);

        if (typeof parsedItem.index === "number") {
            parsedItem.preferredIndex = parsedItem.index;
        }

        parsedItem.children = this.require(this.type, ...(([Stack.getChildrenHash(this, parsedItem)] as any) as [])) as this;

        if (this.props.parse) {
            this.props.parse(parsedItem as any);
        }

        this.state.set(Stack.getState(ids, { ...this.state.current.items, [parsedItem.id]: parsedItem }));

        const remove: StackRemoveFn = (() => this.remove(parsedItem.id)) as any;
        Object.defineProperty(remove, "name", { value: `remove(${parsedItem.id})` });
        remove.id = parsedItem.id;

        return remove;
    }

    /** Remove an item from the stack. */
    remove(id: StackItem["id"]) {
        let ids = this.state.current.ids;

        if (ids.includes(id)) {
            ids = ids.filter((_id) => _id !== id);

            let items = { ...this.state.current.items };

            items[id].children.clear();
            delete items[id];

            this.state.set(Stack.getState(ids, items));

            ids = ids.filter((_id) => _id !== id);

            items = { ...this.state.current.items };

            if (items[id]) {
                items[id].children.clear();
                this.forgo(items[id].children);
                delete items[id];
            }

            this.state.set(Stack.getState(ids, items));
        }
    }

    clear() {
        this.items.forEach((item) => {
            item.children.clear();
            this.forgo(item.children);
        });
        this.state.set(Stack.getState([], {}));
    }

    get items() {
        return this.state.items;
    }

    protected get type(): typeof Stack {
        return (this as any).__proto__.constructor;
    }

    static getState<T extends Stack>(ids: StackItem["id"][], items: Record<StackItem["id"], ParsedStackItem<T>>): StackState<T> {
        if (!ids.length) {
            return { ids, firstId: undefined, lastId: undefined, amount: 0, items };
        }

        ids = ids.sort((a, b) => {
            const preferredIndexA = items[a].preferredIndex;
            const preferredIndexB = items[b].preferredIndex;
            const addedIndexA = items[a].addedIndex;
            const addedIndexB = items[b].addedIndex;

            if (typeof preferredIndexA === "number" && typeof preferredIndexB === "number") {
                if (preferredIndexA === preferredIndexB) {
                    return addedIndexA - addedIndexB;
                }

                return preferredIndexA - preferredIndexB;
            } else if (typeof preferredIndexA === "number") {
                if (addedIndexB === preferredIndexA) {
                    return -1;
                }

                return preferredIndexA - addedIndexB;
            } else if (typeof preferredIndexB === "number") {
                if (addedIndexA === preferredIndexB) {
                    return 1;
                }

                return addedIndexA - preferredIndexB;
            } else {
                return addedIndexA - addedIndexB;
            }
        });

        const firstId = ids[0];
        const lastId = ids[ids.length - 1];

        ids.forEach((id, index) => {
            items[id] = { ...items[id], index, first: firstId === id, last: lastId === id };
        });

        return { ids, firstId, lastId, amount: ids.length, items };
    }

    static getChildrenHash<T extends Stack>(store: T, item: ParsedStackItem<T>) {
        return typeof store[Service.key.id] === "string"
            ? (store[Service.key.id] as string)
                  .split(Stack.hashDivider)
                  .concat(item.id)
                  .join(Stack.hashDivider)
            : item.id;
    }

    static getParentHash(store: Stack) {
        const ids = typeof store[Service.key.id] === "string" ? (store[Service.key.id] as string).split(Stack.hashDivider) : [];

        if (!ids.length) {
            return undefined;
        }

        return ids.slice(0, ids.length - 1).join(Stack.hashDivider);
    }

    static hashDivider = "-\u08fb-";
    static event: { [E in StackEvent]: E } = { entering: "entering", added: "added", removed: "removed", exiting: "exiting" };
}

interface StackProps<IT extends StackItem = StackItem> {
    variant?: StackVariant;
    /** Whether new items should be unshifted. */
    unshift?: boolean;
    parse?(item: IT): void;
}

class StackAbon<T extends Stack> extends AbonDeep<StackState<T>> {
    useItems() {
        return Abon.useComposedValue(
            () => this.items,
            (listener) => [this.subscribe(listener)],
            [this],
        );
    }

    get items() {
        return this.current.ids.map((id) => this.current.items[id]);
    }
}

export type StackEvent = "entering" | "added" | "exiting" | "removed";

/** Removes the item from the stack when called and resolves when the item has entered. */
export interface StackRemoveFn {
    (): void;

    /** `ParsedStackItem.id` */
    id: string;
}

export interface ParsedStackItemI<T extends Stack> extends Omit<StackItem, "id" | "index">, Required<Pick<StackItem, "id" | "index">> {
    /** If the pushed `StackItem` had an `index` defined. */
    preferredIndex?: number;
    /** The index after the item was pushed.  */
    addedIndex: number;
    /** If the `ParsedStackItem.id` is first of the `StackState.ids`.  */
    first: boolean;
    /** If the `ParsedStackItem.id` is last of the `StackState.ids`.  */
    last: boolean;
    /** The children of the item. */
    children: T;
    /** Defines the way the item should be wrapped. Unless `component` is defined, it defaults to `card`. */
    variant: StackVariant;
}

export type ParsedStackItem<T extends Stack> = Omit<StackItemType<T>, "id" | "index"> & ParsedStackItemI<T>;
export type StackItemType<T extends Stack> = T extends Stack<infer IT> ? IT : StackItem;

export interface StackState<T extends Stack> {
    /** The ids of the `StackItem`:s that exist in the `StackState.items` in the correct order, where most recently pushed `StackItem`:s have higher indexes. */
    ids: StackItem["id"][];
    /** The first of `ids` */
    firstId: StackItem["id"] | undefined;
    /** The last of `ids` */
    lastId: StackItem["id"] | undefined;
    /** Equals `StackState.ids.length`. */
    amount: number;
    /** The `StackItem`:s that currently exist. */
    items: Record<StackItem["id"], ParsedStackItem<T>>;
}
