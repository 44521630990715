import { IsString, MinLength } from "class-validator";

export class ResetPasswordProps {
    user_id: string;
    @MinLength(6)
    @IsString()
    password: string;

    @MinLength(6)
    @IsString()
    passwordVerification: string;

    @IsString()
    token: string;
    requestMessage: string;
}

export class ValidateResetPasswordTokenProps {
    @IsString()
    token: string;
}

export interface ResetPasswordParamTypes {
    token: string;
}
