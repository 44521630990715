import React from "react";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useService, Form, makeStyles, Translation, Auth, SelectField, ROLE, Typography, TimeField, NOTIFICATION_DAY } from "lib";
import servido from "servido";

import { HomeAction } from "../shared";

import { NotificationSettingsService } from "./notification-settings.service";

export function NotificationSettings() {
    const service = useService(NotificationSettingsService);
    const t = Translation.useMap(["notificationSettings", "notificationSettingsUpgradeInfo", "update", "requiresSubscription"]);

    return (
        <HomeAction service={service} label={t.get("notificationSettings")} position="right">
            <NotificationSettingsForm />
        </HomeAction>
    );
}

function NotificationSettingsForm() {
    const service = useService(NotificationSettingsService).useForm();
    const classes = useStyles();
    const t = Translation.useMap(["notificationSettings", "notificationSettingsUpgradeInfo", "update", "requiresSubscription"]);
    const userRole = Auth.use("accessToken", "role_id");
    const blockedTrialAccount = Auth.use("blockedTrialAccount");
    const submittable = service.store.submittable.use().current;
    const error = service.error.message.use().current;
    const success = service.success.message.use().current;
    const constructing = servido.useConstructing(service);
    const selectedDay = service.day.use().current;

    return (
        <Form className={classes.root} service={service}>
            {(userRole === ROLE.FREE || (userRole === ROLE.TRIAL && blockedTrialAccount)) && (
                <Typography children={t.render("notificationSettingsUpgradeInfo")} className={classes.info} />
            )}

            <SelectField
                value={service.day}
                fullWidth
                className={classes.day}
                disabled={userRole === ROLE.FREE || (userRole === ROLE.TRIAL && blockedTrialAccount) || constructing}
            />

            {selectedDay !== NOTIFICATION_DAY.INSTANT && !constructing ? (
                <TimeField
                    views={["hours"]}
                    value={service.time}
                    fullWidth
                    className={classes.time}
                    disabled={userRole === ROLE.FREE || (userRole === ROLE.TRIAL && blockedTrialAccount)}
                />
            ) : null}

            <Button
                color={"primary"}
                variant={"contained"}
                className={classes.submit}
                fullWidth
                type={"submit"}
                children={
                    userRole === ROLE.FREE || (userRole === ROLE.TRIAL && blockedTrialAccount)
                        ? t.render("requiresSubscription")
                        : t.render("update")
                }
                disabled={!submittable || userRole === ROLE.FREE || (userRole === ROLE.TRIAL && blockedTrialAccount)}
            />

            {(error || success) && <FormHelperText error={!!error} className={classes.status} children={error || success} />}
        </Form>
    );
}

const useStyles = makeStyles(
    () => ({
        root: {
            width: "25rem",
            maxWidth: "100%",
        },
        info: {},
        day: {
            marginTop: "1rem",
        },
        time: {
            marginTop: "1rem",
        },
        submit: {
            marginTop: "1.5rem",
            fontWeight: 600,
            fontSize: "1rem",
        },
        status: { marginTop: 0.5 },
    }),
    { name: "NotificationSettings" },
);
