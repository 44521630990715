/* eslint-disable @typescript-eslint/camelcase */
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { useService, makeStyles, TableCellComponentProps } from "lib";

import { HomeService } from "../service";
import { Urla } from "../shared";

export function HomeTableUrlaCell(props: TableCellComponentProps<Urla, "id">) {
    const classes = useStyles();

    const service = useService(HomeService);
    const uniqid = service.values.use(props.id, "uniqid");
    const link = process.env.NODE_ENV === "production" ? "https://urla.se/" + uniqid : "http://localhost/urla.se/" + uniqid;

    return (
        <TableCell component="th" id={props.id}>
            <div className={classes.root}>
                <a href={link} children={"urla.se/" + uniqid} className={classes.anchor} target="_blank" rel="noopener noreferrer" />
            </div>
        </TableCell>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
        anchor: {
            color: theme.palette.primary.main,
            backgroundColor: "rgba(0,0,0,0.1)",
            padding: "0.5rem 0.6rem 0.35rem 0.6rem",
            borderRadius: 3,
            marginRight: "auto",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
    }),
    { name: "UrlaCell" },
);
