import { Service, Abon } from "lib";

export class HomeActionService extends Service {
    open = new Abon(false);
    onExited?(): any;

    constructor() {
        super();
    }
}
