import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { uniqueString } from "lib/utils";
import { makeStyles } from "lib/material";
import { Translation } from "lib/store/translation";

import { RadioFieldProps } from "./radio-field.types";

export const RadioField = React.forwardRef<HTMLInputElement, RadioFieldProps>((props: RadioFieldProps, ref) => {
    const {
        value,
        autoComplete,
        autoCorrect,
        spellCheck,
        error,
        skeleton,
        className,
        name = value.valueProps.name,
        helperText: explicitHelperText,
        id: passedId,
        fullWidth,
        disabled,
        ...otherProps
    } = props;

    if (!value) {
        throw new Error(`If no "value" is passed, you should use the RadioGroup exported directly from @material-ui/core/RadioGroup.`);
    }

    const id = React.useMemo(() => passedId || `sf-${value.valueProps.name || uniqueString()}`, [passedId]);
    const usedProps = value.use();

    const onChange = React.useMemo(
        () => (event: React.ChangeEvent<HTMLInputElement>, _value: string) => {
            return value.onSelect(value.option(_value, usedProps.options));
        },
        [usedProps.options],
    );

    const helperText = explicitHelperText || usedProps.error || usedProps.success;

    if (skeleton) {
        return <Skeleton key={"_skeleton"} height={36} />;
    }

    return (
        <FormControl
            key={"_select"}
            className={className}
            error={!!usedProps.error}
            disabled={disabled || (usedProps ? usedProps.loading || usedProps.readOnly : undefined)}
            fullWidth={fullWidth}
        >
            {usedProps.label && <FormLabel htmlFor={id} children={usedProps.label} />}

            <RadioGroup
                aria-label={name}
                name={name}
                value={usedProps.current}
                onChange={onChange}
                id={id}
                innerRef={ref}
                autoCorrect={autoCorrect || "off"}
                spellCheck={spellCheck || false}
                placeholder={usedProps.placeholder}
            >
                {usedProps.options.map((option, i) => (
                    <FormControlLabel
                        key={i}
                        value={option.value as any}
                        disabled={option.disabled}
                        control={<Radio color={"primary"} />}
                        label={Translation.get(option.label) || option.value}
                    />
                ))}
            </RadioGroup>

            {helperText && <FormHelperText error={!explicitHelperText && !!usedProps.error} children={helperText} />}
        </FormControl>
    );
});

const useStyles = makeStyles(() => ({}));
