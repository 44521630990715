import React from "react";
import { Switch, RouteProps, Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { makeStyles, Container, useService } from "lib";
import { LinkStatisticsRoute } from "statistics/link";

import { HomeActions } from "./actions";
import { HomeTable } from "./home.table";

export default function Home() {
    const classes = useStyles();
    return (
        <>
            <Helmet title={"URLA"} />

            <Container className={classes.root}>
                <HomeActions />
                <HomeTable />
            </Container>

            <Switch>
                <Route<RouteProps> exact path="/:urlaId" component={LinkStatisticsRoute} />

                <Redirect to="/" />
            </Switch>
        </>
    );
}

const useStyles = makeStyles(
    () => ({
        root: {
            display: "flex",
            flexGrow: 1,
            flexShrink: 0,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            paddingTop: "2rem",
            paddingBottom: "2rem",
            position: "relative",
            overflowX: "auto",
        },
    }),
    { name: "Home" },
);
