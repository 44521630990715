import { FormService, ValueMetaMap } from "lib";
import { Api, ENDPOINT } from "lib/store";

import { ForgotPasswordProps } from "./forgot-password.props";

export class ForgotPasswordService extends FormService<ForgotPasswordProps> {
    constructor() {
        super({
            type: ForgotPasswordProps,
            metaMap: new ValueMetaMap<ForgotPasswordProps>({
                meta: {
                    email: {
                        accept: "email",
                        label: "email",
                        required: true,
                    },
                },
            }),

            onSubmit: async (props) => {
                this.store.success.remove("submit");
                const stopLoading = this.store.loading.start();

                try {
                    const response = await Api.request(ENDPOINT.resetPassword, {
                        username: props.email,
                    });

                    if (response.success) {
                        this.store.set(this.store.props.initial);

                        this.store.addSuccess("sentEmail");
                    } else {
                        this.store.addError("unknownError");
                    }
                } catch (error) {
                    if (!error) {
                        this.store.addError("unknownError");
                    } else if (!error.response.data.message) {
                    } else if (error.response.data.message.includes("not registered")) {
                        this.store.addInputError("email", "userNotFound");
                    } else {
                        this.store.addError("unknownError");
                    }
                } finally {
                    stopLoading();
                }
            },
        });
    }
}
