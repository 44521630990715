import React from "react";
import { Box, clsx, DateTimeField, makeStyles, TextField, TemplateType, Translation, useService } from "lib";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import { AccountProfileService } from "account/profile/account-profile.service";
import { TemplateService } from "../services/template";

import { TemplateHeader } from "./templates-header";
import { TemplateEditor } from "./editor";
import { TemplateReceivers } from "./receivers";
import moment from "moment";

export function TemplatesScreen(props: TemplatesScreenProps) {
    const c = useStyles();
    const t = Translation.useMap(["send", "minutes", "hours", "days", "senderName"]);

    const profileService = useService(AccountProfileService).name;
    props.service.senderName.set(
        profileService.store
            .getValue("name")
            .use()
            .current.toString(),
    );

    const canSend = props.service.canSend.use().current;
    const scheduleDateTime = props.service.scheduledDateTime;
    const type = props.service.editor.type;
    const minDateValue = type === TemplateType.email ? 15 : 60;
    const minDateUnit = type === TemplateType.email ? "minutes" : "minutes";
    const maxDateValue = type === TemplateType.email ? 72 : 7;
    const maxDateUnit = type === TemplateType.email ? "hours" : "days";

    const minDate = moment().add(minDateValue, minDateUnit);
    const maxDate = moment().add(maxDateValue, maxDateUnit);

    return (
        <Box className={c.root}>
            <TemplateHeader title={props.editorTitle} subhead={props.editorSubhead} step={1} />
            <Box className={c.headedContent}>
                <TemplateEditor editor={props.service.editor} />
            </Box>

            <TemplateHeader title={props.receiversTitle} subhead={props.receiversSubhead} step={2} />
            <Box className={c.headedContent}>
                <TemplateReceivers receivers={props.service.receivers} />
            </Box>

            <TemplateHeader title={props.sendTitle} subhead={props.sendSubhead} step={3} />
            <Box className={clsx(c.headedContent, c.row)}>
                <Box className={c.scheduled}>
                    {type === TemplateType.sms && <TextField value={props.service.senderName} fullWidth className={c.senderName} />}
                    <DateTimeField
                        initialFocusedDate={minDate}
                        value={scheduleDateTime}
                        helperText={Translation.render({
                            id: "minMaxScheduleDate",
                            values: { x1: minDateValue, u1: t.render(minDateUnit), x2: maxDateValue, u2: t.render(maxDateUnit) },
                        })}
                        minDate={minDate}
                        maxDate={maxDate}
                        variant="inline"
                        inputVariant="outlined"
                        size="small"
                        ampm={false}
                        fullWidth
                        key="_date"
                        minutesStep={15}
                        className={c.scheduledDate}
                    />
                </Box>
                <Box className={c.sendContent}>
                    <Button
                        children={props.sendAction || t.render("send")}
                        variant="contained"
                        color="primary"
                        className={c.sendButton}
                        startIcon={<SendIcon />}
                        disabled={!canSend}
                        onClick={props.service.send}
                    />
                </Box>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles(
    () => ({
        root: {
            position: "relative",
        },

        headedContent: {},
        row: {
            padding: "1.5rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
        },

        sendContent: {},
        scheduled: {},
        scheduledDate: {},
        senderName: {
            marginBottom: "1.5rem",
        },
        sendButton: {
            paddingLeft: "1rem",
            paddingRight: "1rem",
            paddingTop: "6px",
            paddingBottom: "5px",
            fontWeight: 600,
            marginRight: "0.5rem",

            "& svg": {
                marginTop: -4,
            },
        },
    }),
    { name: "TemplatesScreen" },
);

export interface TemplatesScreenProps {
    service: TemplateService;
    editorTitle: string;
    editorSubhead?: string;
    receiversTitle: string;
    receiversSubhead?: string;
    sendTitle: string;
    sendAction?: string;
    sendSubhead?: string;
}
