import React from "react";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useService, makeStyles, Translation, Typography, Abon, StackItemProps, CardStack, SUBSCRIPTION_PLAN, ROLE, Auth } from "lib";
import { animated } from "react-spring";

import { CancelSubscriptionService } from "./cancel-subscription.service";
import { CancelSubscriptionProps } from "./cancel-subscription.types";

export function CancelSubscription(props: CancelSubscriptionProps) {
    const { open = Abon.use(() => true), downgrade, plan } = props;

    CardStack.use(
        open.current,
        () => ({
            renderer: (stackProps) => <CancelSubscriptionModal {...stackProps} {...props} />,
            onClose: () => open.set(false),
            interpolations: { transform: { tension: 500 } },
        }),
        [open, plan, downgrade],
    );

    return null;
}
function CancelSubscriptionModal(props: CancelSubscriptionProps & StackItemProps) {
    const { open = Abon.use(() => true), downgrade, plan, interpolations } = props;
    const role = Auth.use("accessToken", "role_id");

    const service = useService(CancelSubscriptionService);
    const error = service.status.error.message.use().current;
    const success = service.status.success.message.use().current;
    const loading = service.status.loading.use().current;

    const classes = useClasses({});
    const t = Translation.useMap([
        "cancelEnterpriseSubscriptionInfo",
        "cancelEnterpriseSubscriptionInfoExtra",
        "cancelProSubscriptionInfo",
        "cancelProSubscriptionInfoExtra",
        "cancelProTrialSubscriptionInfo",
        "cancelProTrialSubscriptionInfoExtra",
        "cancelSubscription",
        "close",
        "downgradeTo",
    ]);

    service.plan = plan;

    const onDowngradeToPro = async (event) => {
        downgrade.set(true);
        open.set(false);
    };

    const onCancelSubscription = async (event) => {
        const cancelled = await service.onConfirm(event);

        if (cancelled) {
            open.set(false);
        }
    };
    const onClose = () => {
        open.set(false);
    };

    return (
        <animated.div className={classes.paper} style={{ transform: interpolations.transform }}>
            <div className={classes.modalHeader}>
                <Typography variant="h5" className={classes.modalHeaderTitle}>
                    {props.title || t.render("cancelSubscription")}
                </Typography>
            </div>
            <div className={classes.modalContent}>
                {plan === SUBSCRIPTION_PLAN.ENTERPRISE ? (
                    <>
                        <Typography variant="body1">{t.render("cancelEnterpriseSubscriptionInfo")}</Typography>
                        <br />
                        <Typography variant="body1">{t.render("cancelEnterpriseSubscriptionInfoExtra")}</Typography>
                    </>
                ) : plan === SUBSCRIPTION_PLAN.PRO && role == ROLE.TRIAL ? (
                    <>
                        <Typography variant="body1">{t.render("cancelProTrialSubscriptionInfo")}</Typography>
                        <br />
                        <Typography variant="body1">{t.render("cancelProTrialSubscriptionInfoExtra")}</Typography>
                    </>
                ) : plan === SUBSCRIPTION_PLAN.PRO && role != ROLE.TRIAL ? (
                    <>
                        <Typography variant="body1">{t.render("cancelProSubscriptionInfo")}</Typography>
                        <br />
                        <Typography variant="body1">{t.render("cancelProSubscriptionInfoExtra")}</Typography>
                    </>
                ) : null}
            </div>
            <div className={classes.modalFooter}>
                {plan === SUBSCRIPTION_PLAN.ENTERPRISE ? (
                    <Button
                        color={"primary"}
                        variant={"contained"}
                        className={classes.confirmButton}
                        children={[t.render("downgradeTo"), SUBSCRIPTION_PLAN.PRO].join(" ")}
                        disabled={loading}
                        onClick={onDowngradeToPro}
                    />
                ) : null}

                <Button
                    color={"primary"}
                    variant={plan === SUBSCRIPTION_PLAN.ENTERPRISE ? "outlined" : "contained"}
                    className={classes.confirmButton}
                    children={Translation.render("cancelSubscription")}
                    disabled={loading}
                    onClick={onCancelSubscription}
                />
                <Button onClick={onClose} variant="outlined" className={classes.cancelButton}>
                    {t.render("close")}
                </Button>
            </div>
            {(error || success) && <FormHelperText error={!!error} className={classes.status} children={error || success} />}
        </animated.div>
    );
}

const useClasses = makeStyles(
    (theme) => ({
        paper: {
            width: "auto",
            maxWidth: 600,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3, 4),
            outline: 0,
            borderRadius: 4,
            display: "flex",
            flexDirection: "column",
        },
        modalHeader: {
            marginBottom: "1rem",
            paddingBottom: "0.25rem",
            borderBottom: `1px solid ${theme.palette.grey[700]}`,
        },
        modalHeaderTitle: {
            fontWeight: 500,
            fontSize: "0.9rem",
            letterSpacing: 0.5,
            color: theme.palette.grey[600],
            textTransform: "uppercase",
        },
        modalContent: {
            flex: 1,
            marginBottom: "2rem",
        },
        modalFooter: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "0 -0.5rem",
        },
        confirmButton: {
            fontWeight: 600,
            margin: "0.5rem",
        },
        cancelButton: {
            fontWeight: 600,
            margin: "0.5rem",
        },
        status: {
            paddingTop: "0.5rem",
            lineHeight: 1.3,
        },
    }),
    { name: "CancelSubscription" },
);
