import React, { useEffect } from "react";
import MuiTable from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useService } from "lib/utils";
import { makeStyles, useTheme, clsx, Box } from "lib/material";

import { TableHead } from "./table.head";
import { TableBody } from "./table.body";
import { TablePagination } from "./table.pagination";
import { TableToolbar } from "./table.toolbar";
import { TableProps, TableParsedProps } from "./table.types";

export function Table<T extends KeyObject, I extends keyof T, O extends keyof any>(props: TableProps<T, I, O>) {
    const { service: passedService, orderOptions = {}, noPaper, noPagination, rowHeight, children: info } = props;
    let { columns } = props;

    const service = useService(passedService);
    const classes = useStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));
    if (isMobile) {
        service.isMobile.set(true);
    } else if (isTablet) {
        service.isTablet.set(true);
    }
    const canDelete = service.canDelete.use();

    useEffect(() => {}, [canDelete.current]);

    let totalWeight = 0;

    columns = columns.filter((column) => column && typeof column === "object");
    columns.forEach((column) => {
        totalWeight += column.weight || 0;
    });

    const parsedProps: TableParsedProps<T, I, O> = {
        service,
        columns,
        totalWeight,
        orderOptions,
        rowHeight,
        isMobile,
        isTablet,
    };

    let children: JSX.Element = (
        <MuiTable className={classes.table}>
            <TableHead {...parsedProps} />
            <TableBody {...parsedProps} />
        </MuiTable>
    );

    if (!noPagination || service.shouldManageSelected) {
        children = (
            <>
                {service.shouldManageSelected && service.canDelete.current && <TableToolbar {...parsedProps} />}

                {children}

                {!noPagination && <TablePagination {...parsedProps} />}
            </>
        );
    }

    if (info) {
        children = (
            <>
                {children}

                <Box className={classes.info}>{info}</Box>
            </>
        );
    }

    if (!noPaper) {
        children = <Paper className={clsx(classes.root)} children={children} />;
    }

    return children;
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: "100%",
            backgroundColor: "rgba(42, 42, 42, 0.85)",
            position: "relative",
            overflow: "auto",
        },
        table: {
            padding: "0 1.5rem",
            tableLayout: "fixed",
            minWidth: 700,

            "& .MuiSkeleton-animate": {
                margin: 0,
            },

            "& .MuiTableCell-root": {
                borderColor: "rgba(255,255,255,0.07)",
                padding: ".75rem",
            },

            "& .MuiTableBody-root .MuiTableCell-root, .MuiTableCell-paddingCheckbox": {
                padding: "0.75rem",
            },

            "& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover": {
                backgroundColor: "rgba(0,0,0,0.1)",
            },
        },
        info: {
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents: "none",
        },
    }),
    { name: "Table" },
);
