/* eslint-disable @typescript-eslint/camelcase */
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { useService, makeStyles, TextValue, TextField, Api, ENDPOINT, ErrorAlert, TableCellComponentProps, Abon, Auth } from "lib";

import { HomeService } from "../service";
import { Urla } from "../shared";

export function HomeTableLinkNameCell(props: TableCellComponentProps<Urla, "id">) {
    const classes = useStyles();

    const service = useService(HomeService);
    const urlId = service.values.use(props.id, "url_id");
    const url = urlId && service.urls.get(urlId);
    const value = Abon.useComposedValue(
        () => (url ? url.current.url_name : undefined),
        (listener) => [url && url.subscribe(listener)],
        [url],
    );
    const isMobile = service.isMobile.use().current;
    const isTablet = service.isTablet.use().current;
    const blockedTrialAccount = Auth.use("blockedTrialAccount");

    const linkName = React.useMemo(() => {
        const textValue = new TextValue({
            async onBlur() {
                if (!textValue.current) {
                    textValue.addError("mustBeDefined");
                    return;
                }

                const url = service.urls.get(urlId);

                if (!url || textValue.current === url.current.url_name) {
                    return;
                }

                const stopLoading = textValue.loading.start();

                try {
                    await Api.request(ENDPOINT.setUrlName, {
                        url_id: urlId,
                        url_name: textValue.current,
                    });

                    url.set({ ...url.current, url_name: textValue.current });
                } catch (error) {
                    textValue.addError();
                    ErrorAlert.alert({
                        error,
                        when: "updatingTheLinkName",
                    });
                } finally {
                    stopLoading();
                }
            },
        });

        return textValue;
    }, []);

    React.useMemo(() => {
        linkName.valueProps.initial = value;
        linkName.set(value);
    }, [value]);

    return (
        <TableCell className={classes.root} title={linkName.current}>
            {!blockedTrialAccount ? <TextField value={linkName} blurOnEnter fullWidth /> : linkName.current}
        </TableCell>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    }),
    { name: "LinkNameCell" },
);
