import { useClearedMemo, useService } from "lib/utils";

import { StackItem } from "../types";

import { Stack } from "./stack";

export class CardStack extends Stack {
    constructor() {
        super({ variant: "card" });
    }

    use(show: true, getItem: () => StackItem, deps?: readonly any[]): string;
    use(show: false, getItem: () => StackItem, deps?: readonly any[]): void;
    use(show: boolean, getItem: () => StackItem, deps?: readonly any[]): string | void;
    use(show: boolean, getItem: () => StackItem, deps: readonly any[] = []): string | void {
        const removeFn = useClearedMemo(
            () => (show ? this.add(getItem()) : undefined),
            (removeFn) => typeof removeFn === "function" && removeFn(),
            [show, this, ...deps],
        );

        if (removeFn) {
            return removeFn.id;
        }
    }

    static use(show: true, getItem: () => StackItem, deps?: readonly any[]): string;
    static use(show: false, getItem: () => StackItem, deps?: readonly any[]): void;
    static use(show: boolean, getItem: () => StackItem, deps?: readonly any[]): string | void;
    static use(show: boolean, getItem: () => StackItem, deps?: readonly any[]): string | void {
        return useService(this).use(show as any, getItem, deps);
    }
}
