import React from "react";
import { Drawer, Typography, makeStyles, clsx } from "lib/material";
import { ErrorAlert, Translation } from "lib/store";
import { ValidationError } from "class-validator";

export function AppErrorAlert() {
    const classes = useStyles();

    const errorAlert = ErrorAlert.useCurrent();

    const children = React.useRef<JSX.Element>();

    children.current = React.useMemo<JSX.Element>(() => {
        if (!errorAlert) {
            return children.current;
        }

        let title: string;
        let subtitle: string;
        let stack: any = errorAlert.stack;
        let ul: string[];

        if (errorAlert.title) {
            subtitle = Translation.get(errorAlert.title);
        } else if (errorAlert.at) {
            subtitle = Translation.get("errorOccuredAt") + " " + Translation.get(errorAlert.at);
        } else if (errorAlert.when) {
            subtitle = Translation.get("errorOccuredWhen") + " " + Translation.get(errorAlert.when);
        } else {
            subtitle = Translation.get("errorOccured");
        }

        if (errorAlert.error) {
            if (Array.isArray(errorAlert.error)) {
                const constraintsMap: Record<string, string[]> = {};

                const errorCallback = (error: ValidationError) => {
                    if (Array.isArray(error)) {
                        error.forEach(errorCallback);
                    } else {
                        if (!constraintsMap[error.property]) {
                            constraintsMap[error.property] = [];
                        }

                        Object.keys(error.constraints).forEach((constraint) => {
                            const message = error.constraints[constraint].split(error.property + " ").join("");

                            if (!constraintsMap[error.property].includes(message)) {
                                constraintsMap[error.property].push(message);
                            }
                        });
                    }
                };

                errorAlert.error.forEach(errorCallback);

                if (Object.keys(constraintsMap).length) {
                    ul = Object.keys(constraintsMap).map((property) => `${property}: ${constraintsMap[property].join(", ")}`);
                }
            } else if (errorAlert.error.request) {
                if (!errorAlert.error.request.responseType || errorAlert.error.request.responseType === "text") {
                    const responseText = errorAlert.error.request.responseText;

                    try {
                        const errorObject: { message: string; error?: string } = JSON.parse(responseText);

                        title = errorObject.error || errorObject.message;
                    } catch {
                        try {
                            const errorObject: { message: string; error?: string } = JSON.parse(responseText.split("\n")[2]);

                            title = errorObject.error || errorObject.message;
                        } catch {
                            title = errorAlert.error.message;
                        }
                    }
                } else {
                    title = errorAlert.error.message;
                }
            } else {
                title = errorAlert.error.message;

                if (!stack) {
                    stack = errorAlert.error.stack;

                    if (stack) {
                        stack = stack.split(`Error: ${title}`).join("");
                    }
                }
            }
        }

        if (!title && subtitle) {
            title = subtitle;
            subtitle = null;
        }

        return (
            <>
                {subtitle && <Typography className={classes.subtitle} children={subtitle} variant={"overline"} />}
                {title && <Typography children={title} variant={"h5"} />}
                {ul && ul.length && (
                    <ul className={classes.ul}>
                        {ul.map((li, i) => (
                            <li key={i} children={li} />
                        ))}
                    </ul>
                )}
                {stack && <Typography className={classes.stack} children={stack} variant={"body2"} />}
            </>
        );
    }, [errorAlert && errorAlert.timestamp]);

    React.useEffect(() => () => ErrorAlert.clear(), []);

    return (
        <Drawer
            anchor="bottom"
            variant="temporary"
            transitionDuration={150}
            classes={{
                paper: clsx(classes.drawer),
            }}
            open={!!errorAlert}
            onClose={() => ErrorAlert.remove()}
        >
            {children.current}
        </Drawer>
    );
}

const drawerHeight = 350;

const useStyles = makeStyles(
    (theme) => ({
        drawer: {
            padding: "2rem 3rem 2.5rem 3rem",
            flexShrink: 0,
            backgroundColor: theme.palette.error.dark,
            color: "white",
        },
        stack: {
            opacity: 0.5,
            fontSize: 10,
            marginTop: "1.5rem",
        },
        subtitle: {
            opacity: 0.75,
        },
        ul: {
            marginBottom: "0.25rem",
        },
        toolbar: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: theme.spacing(0, 1, 0, 3),
            ...theme.mixins.toolbar,
        },
    }),
    { name: "ErrorAlert" },
);
