import React from "react";
import { makeStyles, Box, Translation, Abon } from "lib";
import { Button } from "@material-ui/core";
import { UserRelationsService } from "../service/user-relations-service";
import { InviteUser } from "./invite-user/invite-user";

export function UserRelationsActions(props: UserActionsProps) {
    const { service } = props;
    const classes = useStyles();
    const t = Translation.useMap(["inviteUser"]);
    const openInviteUser = Abon.use(() => false);

    const OpenInviteUserModal = () => {
        openInviteUser.set(true);
    };

    return (
        <Box className={classes.root}>
            <Button className={classes.button} onClick={OpenInviteUserModal} variant={"contained"} color={"primary"} key="_button">
                {t.render("inviteUser")}
            </Button>
            <InviteUser open={openInviteUser} service={service} />
        </Box>
    );
}

export interface UserActionsProps {
    service: UserRelationsService;
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            padding: "1rem",
            marginTop: 1,
        },
        button: {
            fontWeight: "bold",
        },
    }),
    { name: "UserRelationsActions" },
);
