import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles, Box, Translation, TemplateType, useService } from "lib";
import { TemplatesScreen } from "templates/components";
import { TemplateService } from "templates/services";

export function MarketingEmail() {
    const classes = useStyles();
    const service = useService(TemplateService, { type: TemplateType.email });
    const t = Translation.useMap([
        "marketing",
        "mailCampaigns",
        "mailTemplateTitle",
        "mailTemplateSubhead",
        "mailReceiversTitle",
        "mailReceiversSubhead",
        "sendMailTitle",
        "sendMailSubhead",
        "sendMailAction",
    ]);

    return (
        <>
            <Helmet title={[t.get("mailCampaigns"), t.get("marketing"), "URLA"].join(" - ")} />

            <Box className={classes.root}>
                <TemplatesScreen
                    service={service}
                    editorTitle={t.render("mailTemplateTitle")}
                    editorSubhead={t.render("mailTemplateSubhead")}
                    receiversTitle={t.render("mailReceiversTitle")}
                    receiversSubhead={t.render("mailReceiversSubhead")}
                    sendTitle={t.render("sendMailTitle")}
                    sendSubhead={t.render("sendMailSubhead")}
                    sendAction={t.render("sendMailAction")}
                />
            </Box>
        </>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            padding: "2rem 0",
            position: "relative",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            flexShrink: 1,
        },
        plans: {
            display: "flex",
            flexGrow: 1,
            flexShrink: 1,
            flexDirection: "row",
            alignItems: "stretch",
            justifyContent: "space-between",
            margin: "0 -1vw",
            position: "relative",
            padding: "0 15%",

            "& > div": {
                margin: "0 1vw",
                width: 300,
            },

            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                justifyContent: "flex-start",
                padding: "0",
                margin: "1rem 0",

                "& > div": {
                    margin: "1rem 0",
                    width: "100%",
                },
            },
        },
    }),
    { name: "MarketingEmail" },
);
