import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import Modal from "@material-ui/core/Modal";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, Tooltip, clsx } from "lib/material";
import { Translation } from "lib/store";
import { Abon } from "lib/utils";

import { TableParsedProps } from "./table.types";
import { TableConfirmDelete } from "./table.confirm-delete";

export function TableToolbar<T extends KeyObject, I extends keyof T, O extends keyof any>(props: TableParsedProps<T, I, O>) {
    const { service, confirmDelete = true } = props;

    const values = service.useTranslations();
    const t = Translation.useMap([
        { id: "allValuesSelected", values },
        { id: "selectedValues", values },
        { id: "selectedValue", values },
        { id: "deleteSelectedValues", values },
        { id: "selectAllValues", values },
        { id: "unselectAllValues", values },
        { id: "deleteSelectedValuesConfirmation", values },
    ]);

    const classes = useStyles();

    const selectedIds = service.selectedIds.use().current;
    const ids = service.items.ids.use().current;

    const onToggleAll = React.useCallback(
        () =>
            service.selectedIds.current.length < service.items.length
                ? service.selectedIds.set(service.items.ids.current)
                : service.selectedIds.set([]),
        [],
    );

    const deleting = service.deleting.use().current;
    const confirmingDelete = confirmDelete && Abon.use(() => false);

    const onDelete = React.useCallback(() => {
        if (confirmingDelete) {
            if (confirmingDelete.current) {
                service.deleteSelected();
                confirmingDelete.set(false);
            } else {
                confirmingDelete.set(true);
            }
        } else {
            service.deleteSelected();
        }
    }, [confirmingDelete]);

    return (
        <>
            {selectedIds.length ? (
                <Toolbar
                    className={clsx(classes.root, {
                        [classes.highlight]: selectedIds.length > 0,
                    })}
                    key="_toolbar"
                >
                    <Typography className={classes.title} color="inherit" variant="subtitle1">
                        {selectedIds.length === ids.length
                            ? t.render("allValuesSelected")
                            : selectedIds.length > 1
                            ? `${selectedIds.length} ${t.render("selectedValues")}`
                            : `${selectedIds.length} ${t.render("selectedValue")}`}
                    </Typography>

                    <Tooltip title={t.render("deleteSelectedValues")} className={classes.cell}>
                        <IconButton aria-label="delete" disabled={deleting} onClick={onDelete}>
                            {deleting ? <CircularProgress size="1.25rem" /> : <DeleteIcon />}
                        </IconButton>
                    </Tooltip>

                    <Tooltip
                        title={
                            ids.length
                                ? selectedIds.length === ids.length
                                    ? t.render("unselectAllValues")
                                    : t.render("selectAllValues")
                                : "—"
                        }
                        className={classes.cell}
                    >
                        <Checkbox
                            indeterminate={selectedIds.length > 0 && selectedIds.length < ids.length}
                            checked={selectedIds.length === ids.length}
                            inputProps={{ "aria-label": t.render("selectAllValues") }}
                            color="default"
                            onChange={onToggleAll}
                        />
                    </Tooltip>
                </Toolbar>
            ) : null}

            {confirmDelete && <TableConfirmDelete service={service} open={confirmingDelete} onDelete={onDelete} key="_confirmDelete" />}
        </>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            paddingLeft: "0.75rem",
            paddingRight: "calc(0.25rem + 4px)",
            position: "sticky",
            marginBottom: -66,
            zIndex: 3,
            width: "100%",
            top: "0",
            left: "0",
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            minHeight: 66,
        },
        highlight: {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.grey[900],
        },
        title: {
            flex: "1 1 100%",
        },
        cell: {
            width: 48,
        },
    }),
    { name: "TableToolbar" },
);
