import { Abon } from "lib/utils";

import { ErrorAlertItem } from "./error-alert.types";

class ErrorAlert {
    protected alerts = new Abon<ParsedErrorAlertItem[]>([]);

    useCurrent(): ParsedErrorAlertItem {
        const current = Abon.use<ParsedErrorAlertItem>(() => {
            return this.alerts.current.sort((a, b) => a.timestamp - b.timestamp)[0];
        });

        const alerts = Abon.useFrom(
            (listener) => this.alerts.subscribe((alerts) => listener(alerts.sort((a, b) => a.timestamp - b.timestamp)[0])),
            () => this.alerts.current.sort((a, b) => a.timestamp - b.timestamp)[0],
            [this.alerts],
        );

        this.alerts.useSubscription((alerts) => {
            current.set(alerts.sort((a, b) => a.timestamp - b.timestamp)[0]);
        });

        return current.current;
    }

    remove(alert: ParsedErrorAlertItem = this.alerts.current.sort((a, b) => a.timestamp - b.timestamp)[0]) {
        this.alerts.set(this.alerts.current.filter((_alert) => _alert.timestamp !== alert.timestamp));

        return alert;
    }

    alert(alert: ErrorAlertItem) {
        const timestamp = Date.now();

        const parsedalert: ParsedErrorAlertItem = {
            ...alert,
            timestamp,
        };

        this.alerts.set([...this.alerts.current, parsedalert]);
    }

    clear() {
        this.alerts.set([]);
    }
}

interface ParsedErrorAlertItem extends ErrorAlertItem {
    timestamp: number;
}

const EA = new ErrorAlert();

export { EA as ErrorAlert };
