import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles, Translation, useService, Container } from "lib";
import { UserRelationsService } from "./service";
import { UserRelationsActions } from "./actions";
import { UserRelationsTable } from "./admin-users.table";

export function AdminUsers() {
    const classes = useStyles();

    const t = Translation.useMap(["users", "administration"]);
    const service = useService(UserRelationsService);
    return (
        <>
            <Helmet title={[t.get("users"), t.get("administration"), "URLA"].join(" - ")} />

            <Container className={classes.root}>
                <UserRelationsActions service={service} />
                <UserRelationsTable service={service} />
            </Container>
        </>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            padding: 0,
            position: "relative",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            flexShrink: 1,
        },
    }),
    { name: "AdminUsers" },
);
