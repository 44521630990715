import "flag-icon-css/css/flag-icon.min.css";

import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { theme } from "./app.theme";

export function AppStyle(props: React.PropsWithChildren<{}>) {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />

            {props.children}
        </ThemeProvider>
    );
}
