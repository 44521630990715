import { TextValueProps } from "lib/form";
import { TranslationThunk } from "lib/store";

export enum TABLE_DIRECTION {
    ASC,
    DESC,
}

export interface TableServiceProps<T extends KeyObject, I extends keyof T, O extends keyof any = never> {
    id: I;
    fetch(props: Required<TableFetchProps<O>>): Promise<TableFetchResponse<T>>;
    delete?(props: TableDeleteProps<T, I>): Promise<void | any>;
    deleteNoHydrate?: boolean;
    initialOrderBy?: O;
    search?: TextValueProps;
    perPage?: number;
    perPageOptions?: boolean;
    translations: { single: TranslationThunk; many: TranslationThunk; theMany: TranslationThunk; the: TranslationThunk };
}

export interface TableDeleteProps<T extends KeyObject, I extends keyof T> {
    ids: T[I][];
}

export interface TableFetchProps<O extends keyof any = never> {
    orderBy?: O;
    direction?: TABLE_DIRECTION;
    page?: number;
    perPage?: number;
    perPageOptions?: boolean;
    search?: string;
    forceHydrate?: boolean;
}

export interface TableFetchResponse<T extends KeyObject> {
    values: T[];
    totalCount: number;
}
