/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { FileValue } from "lib/form/value/file";
import { TextValue } from "lib/form/value/text";
import { RadioValue } from "lib/form/value/radio";
import { SelectValue } from "lib/form/value/select";
import { DateValue } from "lib/form/value/date";

import { FileField, FileFieldProps } from "../file-field";
import { TextField, TextFieldProps } from "../text-field";
import { RadioField, RadioFieldProps } from "../radio-field";
import { SelectField, SelectFieldProps } from "../select-field";
import { DateField, DateFieldProps } from "../date-field";

import { FieldProps } from "./field.types";

export const Field = React.forwardRef<HTMLInputElement, FieldProps>((props: FieldProps, ref) => {
    const { value, ...otherProps } = props;

    if (!value) {
        console.warn(`A "value" must be passed to a Field.`);
        return null;
    }

    if (value instanceof TextValue) {
        return <TextField {...(otherProps as TextFieldProps)} value={value} ref={ref} />;
    } else if (value instanceof RadioValue) {
        return <RadioField {...(otherProps as RadioFieldProps)} value={value} ref={ref} />;
    } else if (value instanceof SelectValue) {
        return <SelectField {...(otherProps as SelectFieldProps)} value={value} ref={ref} />;
    } else if (value instanceof DateValue) {
        return <DateField {...(otherProps as DateFieldProps)} value={value} ref={ref} />;
    } else if (value instanceof FileValue) {
        return <FileField {...(otherProps as FileFieldProps)} value={value} ref={ref} />;
    }

    return null;
});
