import { RouteComponentProps } from "react-router";
import { Service } from "lib/utils";

export class RouterService extends Service implements RouteComponentProps {
    history: RouteComponentProps["history"];
    location: RouteComponentProps["location"];
    match: RouteComponentProps["match"];
    staticContext?: RouteComponentProps["staticContext"];

    navigate(to: string, options: { replace?: boolean } = {}) {
        if (options.replace) {
            this.history.replace(to);
        } else {
            this.history.push(to);
        }
    }
}
