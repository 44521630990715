/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { makeStyles, clsx } from "lib/material";
import { useService } from "lib/utils";

import { FormProps } from "./form.types";

export function Form<T extends KeyObject>(props: React.PropsWithChildren<FormProps<T>>) {
    const { service: serviceThunk, className, children, ...otherProps } = props;

    const service = useService(serviceThunk);
    const classes = useStyles();

    return (
        <form
            {...otherProps}
            className={clsx(classes.root, className)}
            children={children}
            onSubmit={React.useMemo(
                () => (evt) => {
                    evt.preventDefault();

                    return service.onSubmit();
                },
                [service],
            )}
        />
    );
}

const useStyles = makeStyles(
    () => ({
        root: {},
    }),
    { name: "Form" },
);
