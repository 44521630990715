import React from "react";
import Button from "@material-ui/core/Button";
import { Box, makeStyles, Translation, Tooltip, CompletedTextField, Receiver, TemplateType } from "lib";
import InputAdornment from "@material-ui/core/InputAdornment";

import { TemplateReceiversService } from "../../services";

import { TemplateReceiversList } from "./template-receivers.list";
import { TemplateReceiversMover } from "./template-receivers.mover";

export function TemplateReceivers(props: TemplateReceiversProps) {
    const { receivers } = props;

    const c = useStyles();
    const t = Translation.useMap(["loadProjectTooltip", "selectedReceivers", "loadProjectLabel"]);

    const inputRef = React.useRef<HTMLInputElement>();
    const canLoadProject = receivers.canLoadProject.use().current;
    const onLoadProject = React.useCallback(async () => {
        setTimeout(() => inputRef.current.blur());
        await receivers.loadProject();
        inputRef.current.blur();
    }, [receivers]);

    return (
        <Box className={c.root}>
            <Box className={c.content}>
                <TemplateReceiversList
                    list={receivers.selectable}
                    getLabel={(receiver: Receiver) => getLabel(receiver, receivers.type)}
                    header={
                        <CompletedTextField
                            value={receivers.project}
                            variant="filled"
                            autocompleteClassName={c.projectField}
                            inputRef={inputRef}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip title={t.render("loadProjectTooltip")}>
                                            <span>
                                                <Button
                                                    disabled={!canLoadProject}
                                                    onClick={onLoadProject}
                                                    children={t.render("loadProjectLabel")}
                                                    size="small"
                                                />
                                            </span>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    }
                />
                <TemplateReceiversMover receivers={receivers} />
                <TemplateReceiversList
                    list={receivers.selected}
                    getLabel={(receiver: Receiver) => getLabel(receiver, receivers.type)}
                    title={t.render("selectedReceivers")}
                />
            </Box>
        </Box>
    );
}

const getLabel = (receiver: Receiver, type: TemplateType) => {
    if (type === TemplateType.email) {
        if (receiver.first_name) {
            return `${receiver.first_name}, ${receiver.email_address}`;
        } else {
            return receiver.email_address;
        }
    } else if (type === TemplateType.sms) {
        if (receiver.first_name) {
            return `${receiver.first_name}, ${receiver.phone_number}`;
        } else {
            return receiver.phone_number;
        }
    }
};

const useStyles = makeStyles(
    () => ({
        root: {
            maxWidth: "100%",
            backgroundColor: "transparent",
            padding: "1.5rem",
            display: "flex",
            flexDirection: "column",
        },
        content: {
            width: "100%",
            flex: "1",
            position: "relative",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        projectField: {
            width: "100%",
            maxWidth: "100%",
            marginRight: 0,

            "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
                transform: "translate(12px, 12px) scale(0.75)",
            },

            "& .MuiFilledInput-root": {
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                paddingTop: 7,
                paddingBottom: 3,
                backgroundColor: "#212121",
                paddingRight: "12px !important",
            },

            "& .MuiInputAdornment-root": {
                marginTop: "-3px",
                marginRight: "-5px",
                cursor: "pointer",

                "& .MuiButton-root": {
                    borderRadius: 3,
                    fontWeight: 600,
                    padding: "5px 12px 3px 12px",
                    backgroundColor: "rgba(255,255,255, 0.05)",
                },
            },

            "& .MuiFilledInput-underline:after": {
                bottom: 0,
            },

            "& .MuiFilledInput-underline:before": {
                borderBottom: "0",
            },
        },
    }),
    { name: "TemplateReceivers" },
);

export interface TemplateReceiversProps {
    receivers: TemplateReceiversService;
}
