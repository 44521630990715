/* eslint-disable @typescript-eslint/camelcase */
import { AbonDeep, UnsubscribeFn, Abon } from "lib/utils";
import { Status } from "lib/form/status";

import { AuthState, AccessToken, ROLE } from "./auth.types";

export const LS_AUTH_NAMES = {
    token: "$urla_token",
    jwt: "$urla_jwt",
};

class Auth extends AbonDeep<AuthState> {
    status = new Status({ loading: { circular: true } });

    constructor() {
        super({ accessToken: null, authenticated: false, blockedTrialAccount: false });

        this.subscribe("accessToken", (accessToken) => this.accessTokenCallback(accessToken));

        this.setJwt(localStorage.getItem(LS_AUTH_NAMES.jwt));

        this.logout = this.logout.bind(this);
    }

    logout() {
        if (this._unsubscribeAccessToken) {
            this._unsubscribeAccessToken();
        }

        this.set("accessToken", null);
        this.set("authenticated", false);
        this.set("blockedTrialAccount", false);
        localStorage.removeItem(LS_AUTH_NAMES.jwt);
    }

    setJwt(jwt: string) {
        if (!jwt) {
            this.logout();
            return;
        }

        const accessToken: AccessToken = this.parseJwt(jwt);

        if (accessToken.exp < Date.now()) {
            localStorage.setItem(LS_AUTH_NAMES.jwt, jwt);
            this.set("accessToken", accessToken);
        } else {
            this.set("accessToken", null);
            localStorage.removeItem(LS_AUTH_NAMES.jwt);
        }
    }

    parseJwt(jwt: string) {
        const token = jwt.split(".")[1];

        const {
            data: { username: email, user_id, role_id },
            exp,
            iss,
        } = JSON.parse(atob(token));

        const accessToken: AccessToken = {
            email,
            user_id,
            role_id: Number(role_id),
            exp: Number(exp),
            iss,
        };

        return accessToken;
    }

    private _unsubscribeAccessToken?: UnsubscribeFn;

    private accessTokenCallback(accessToken: AccessToken) {
        if (this._unsubscribeAccessToken) {
            this._unsubscribeAccessToken();
        }

        if (!accessToken) {
            this.logout();
            return;
        }

        const expiringIn = accessToken.exp - Date.now() / 1000;

        if (expiringIn > 60) {
            this.set("authenticated", true);

            const timeout = setTimeout(() => {
                this.logout();
            }, expiringIn * 1000);

            this._unsubscribeAccessToken = () => {
                clearTimeout(timeout);

                return true;
            };
        } else {
            this.logout();
        }
    }

    get jwt(): Promise<string> {
        if (this.current.accessToken) {
            return Promise.resolve(localStorage.getItem(LS_AUTH_NAMES.jwt));
        }

        return new Promise((resolve) => {
            const unsubscribe = this.subscribe("accessToken", (accessToken) => {
                if (accessToken != null) {
                    unsubscribe();
                    resolve(localStorage.getItem(LS_AUTH_NAMES.jwt));
                }
            });
        });
    }
}

const A = new Auth();

export { A as Auth };
