import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import TableCell from "@material-ui/core/TableCell";
import MuiTableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import { Translation } from "lib/store";
import { TABLE_DIRECTION } from "lib/services";
import { makeStyles, clsx, Typography } from "lib/material";

import { TableParsedProps } from "./table.types";

export function TableHead<T extends KeyObject, I extends keyof T, O extends keyof any>(props: TableParsedProps<T, I, O>) {
    const { service, columns, totalWeight, orderOptions, size } = props;

    const t = Translation.useMap(["deselectAllPage", "selectAllPage", "deselectAll", "selectAll", "deletion"]);
    const classes = useStyles();

    const direction = service.direction.use().current;
    const orderBy = service.orderBy.use().current;

    const selectedIds = service.selectedIds.use().current;
    const ids = service.ids.use().current;
    const sortDirection = direction === TABLE_DIRECTION.ASC ? "asc" : "desc";

    const onToggleAll = React.useMemo(
        () =>
            service.shouldManageSelected &&
            service.canDelete.current &&
            (() => {
                service.selectedIds.current.length < service.ids.current.length
                    ? service.selectedIds.set(
                          service.ids.current.splice(service.page.current * service.perPage.current, service.perPage.current),
                      )
                    : service.selectedIds.set([]);
            }),
        [service.shouldManageSelected, service.canDelete.current],
    );

    return (
        <>
            <colgroup>
                {columns.map((column, i) => (
                    <col key={i} style={{ width: column.width || `${((column.weight || 1) / (totalWeight || 1)) * 100}%` }} />
                ))}

                {onToggleAll && <col style={{ width: 64 }} />}
            </colgroup>

            <MuiTableHead className={classes.root}>
                <TableRow>
                    {columns.map((column, i) => {
                        let children: React.ReactNode = null;

                        const className = clsx(classes.cell, column.align === "center" && classes.center);

                        if (column.label) {
                            children = Translation.render(column.label);
                        } else if (column.labelChildren) {
                            children = column.labelChildren;
                        }

                        if (column.tooltip) {
                            if (column.labelChildren) {
                                children = (
                                    <div className={classes.infoRow}>
                                        {children}
                                        <InfoIcon className={classes.info} />
                                    </div>
                                );
                            } else {
                                children = (
                                    <>
                                        {children}
                                        <InfoIcon className={classes.info} />
                                    </>
                                );
                            }
                        }

                        if (column.valueKey && orderOptions[column.valueKey]) {
                            children = (
                                <TableSortLabel
                                    active={orderBy === orderOptions[column.valueKey]}
                                    direction={sortDirection}
                                    onClick={() => {
                                        if (service.orderBy.current === orderOptions[column.valueKey]) {
                                            service.direction.set(
                                                service.direction.current === TABLE_DIRECTION.ASC
                                                    ? TABLE_DIRECTION.DESC
                                                    : TABLE_DIRECTION.ASC,
                                            );
                                        } else {
                                            service.orderBy.set(orderOptions[column.valueKey]);
                                        }
                                    }}
                                    children={children}
                                />
                            );

                            children = (
                                <TableCell
                                    key={i}
                                    sortDirection={orderBy === orderOptions[column.valueKey] ? sortDirection : false}
                                    children={children}
                                    className={className}
                                    align={column.align}
                                />
                            );
                        } else {
                            children = <TableCell key={i} children={children} className={className} align={column.align} />;
                        }

                        if (column.tooltip) {
                            children = <Tooltip title={Translation.render(column.tooltip)} children={children as any} key={i} />;
                        }

                        return children;
                    })}

                    {onToggleAll && (
                        <Tooltip
                            title={
                                ids.length
                                    ? selectedIds.length === ids.length
                                        ? t.render("deselectAllPage")
                                        : t.render("selectAllPage")
                                    : "—"
                            }
                        >
                            <TableCell padding="checkbox" align="center" size={size}>
                                <Checkbox
                                    indeterminate={selectedIds.length > 0 && selectedIds.length < ids.length}
                                    checked={ids.length > 0 && selectedIds.length === ids.length}
                                    inputProps={{ "aria-label": t.render("selectAllPage") }}
                                    color="primary"
                                    onChange={onToggleAll}
                                    disabled={!ids.length}
                                />
                            </TableCell>
                        </Tooltip>
                    )}
                </TableRow>
            </MuiTableHead>
        </>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            minHeight: 64,
        },
        info: {
            height: "1rem",
            width: "1rem",
            marginLeft: "0.5rem",
            marginBottom: -2,
            marginTop: -5,
            fill: theme.palette.text.secondary,
        },
        infoRow: {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
        },
        cell: {
            whiteSpace: "nowrap",
            "& MuiTableSortLabel-root": { whiteSpace: "nowrap" },
        },
        center: {
            paddingLeft: "0.15rem",
            paddingRight: "0.15rem",
        },
    }),
    { name: "StatisticsTableHead" },
);
