import React from "react";
import { omit, pick, Abon, requireService, forgoService } from "lib/utils";
import { Translation, ErrorAlert } from "lib/store";
import { SnackStack } from "lib/stack";

import { Value, ValueProps } from "../value";

import { FileValueProps } from "./file-value.types";

export class FileValue extends Value<File> {
    static useFileValue(props: FileValueProps) {
        const fileValue = React.useMemo(() => new FileValue(props), []);

        React.useEffect(() => () => fileValue.clear(), []);

        return fileValue;
    }

    static selectFileValueProps<PT extends FileValueProps, T = any>(
        props: PT,
    ): Omit<PT, keyof FileValueProps> & {
        fileValueProps: FileValueProps;
    } {
        const { valueProps, ...allProps } = Value.selectValueProps(props);

        return {
            ...omit(allProps, "onChange"),
            fileValueProps: {
                ...valueProps,
                ...pick(allProps, "onChange"),
            },
        } as any;
    }

    fileName: Abon<string>;
    fileSize: Abon<string>;
    selecting: Abon<boolean>;
    fileProps: Omit<FileValueProps, keyof ValueProps<string>>;

    constructor(props: FileValueProps = {}) {
        const { valueProps, ...fileProps } = Value.selectValueProps(props);

        super(valueProps);

        this.fileProps = fileProps;
        this.fileName = new Abon<string>();
        this.fileSize = new Abon<string>();
        this.selecting = new Abon<boolean>(false);

        this.uploadFromEvent = this.uploadFromEvent.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    use() {
        const usedValue = super.use();

        const selecting = this.selecting.use().current;

        const current = usedValue.current;

        return {
            ...usedValue,
            current,
            fileSize: this.fileSize.use().current,
            fileName: this.fileName.use().current,
            label: Translation.use(this.valueProps.label),
            loading: usedValue.loading || selecting,
            type: "file" as "file",
        };
    }

    onClick() {
        this.selecting.set(true);
        this.caughtChange = false;

        const bodyListener = () => {
            document.body.onfocus = null;

            setTimeout(() => {
                if (!this.caughtChange) {
                    this.caughtChange = true;
                    this.onCancel();
                }
            }, 250);
        };

        document.body.onfocus = bodyListener;

        setTimeout(bodyListener, 3000);
    }

    onCancel() {
        if (this.selecting.current) {
            this.setClear();
        }
    }

    setClear() {
        this.selecting.set(false);
        this.fileName.set(undefined);
        this.fileSize.set(undefined);
        this.set(undefined);
        this.caughtChange = false;
    }

    private caughtChange: boolean;

    /** Upload an image from a change event on a `<input type="file" />` and set the external source to the value. */
    async uploadFromEvent(evt: React.ChangeEvent<HTMLInputElement>) {
        this.caughtChange = true;

        evt.preventDefault();

        const file = evt.target.files[0];

        if (!file) {
            this.onCancel();
            return;
        }

        if (file.size > MAX_FILE_SIZE) {
            this.onCancel();
            this.error.add("$size", Translation.get("fileIsTooLarge"));
            return;
        }

        const stopLoading = this.loading.start();

        try {
            this.set(file);
            this.fileName.set(file.name);
            this.fileSize.set(humanFileSize(file.size));

            const snacks = requireService({ service: SnackStack, dependent: this });
            snacks.add({ message: Translation.render({ id: "uploadedFileX", values: { x: file.name } }) });
            forgoService({ service: snacks, dependent: this });
        } catch (error) {
            ErrorAlert.alert({
                error,
                when: "uploadingTheFile",
            });
        } finally {
            stopLoading();
            this.selecting.set(false);
        }
    }
}

function humanFileSize(bytes: number, si = true) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + " B";
    }

    const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

    let u = -1;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);

    return bytes.toFixed(1) + " " + units[u];
}

const MAX_FILE_SIZE = 5242880;
