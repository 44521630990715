import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { Abon, makeStyles, Translation, ButtonAnchor, LOCALE, LOCALE_TRANSLATION } from "lib";

export function ContainerFooter() {
    const classes = useStyles();
    const t = Translation.useMap(["about", "contact", "faq", "createdAndDevelopedInSweden"]);

    const langMenuOpen = Abon.use(() => false);
    const menuAnchorRef = React.useRef<HTMLButtonElement>();

    const selectOptions = [
        { value: LOCALE.en, label: LOCALE_TRANSLATION.en },
        { value: LOCALE.sv, label: LOCALE_TRANSLATION.sv },
    ];

    const currentSelectOption = Translation.locale.use().current;

    const toggleOpen = () => !!langMenuOpen.set(!langMenuOpen.current).current;

    return (
        <Toolbar className={classes.root}>
            <Container className={classes.inner}>
                <nav className={classes.links}>
                    <ButtonAnchor href="https://urla.se/#about-us" target="_blank" children={t.render("about")} />
                    <ButtonAnchor href="https://urla.se/#about-us" target="_blank" children={t.render("contact")} />
                    <ButtonAnchor href="https://urla.se/#gdpr" target="_blank" children={t.render("faq")} />
                </nav>
                <div className={classes.actions}>
                    <Button ref={menuAnchorRef} onClick={toggleOpen} className={classes.languageBtn}>
                        <img src={`/images/${currentSelectOption}-flag.png`} alt="Logo" />
                        {Translation.get(LOCALE_TRANSLATION[currentSelectOption])}
                    </Button>
                    <p className={classes.description} children={t.render("createdAndDevelopedInSweden")} />
                </div>
            </Container>
            <Menu
                id="header-menu"
                anchorEl={menuAnchorRef.current}
                keepMounted
                open={langMenuOpen.current}
                onClose={toggleOpen}
                anchorOrigin={{ horizontal: "center", vertical: "center" }}
                transformOrigin={{ horizontal: "center", vertical: "bottom" }}
                getContentAnchorEl={null}
            >
                {selectOptions.map((lang) => {
                    return (
                        <MenuItem
                            className={classes.languageBtn}
                            key={lang.value}
                            onClick={() => {
                                Translation.setLocale(lang.value);
                                toggleOpen();
                            }}
                        >
                            <img src={`/images/${lang.value}-flag.png`} alt="Logo" />
                            {Translation.get(lang.label)}
                        </MenuItem>
                    );
                })}
            </Menu>
        </Toolbar>
    );
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            zIndex: 0,
            backgroundColor: theme.palette.grey[900],
        },
        inner: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 0,
        },

        links: {
            display: "flex",
            flexDirection: "row",
            padding: "0.5rem 1rem",
            "& span": {
                color: theme.palette.text.secondary,
            },
        },

        languageBtn: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",

            "& img": {
                width: "20px",
                marginRight: "5px",
                display: "inline-block",
            },
            "& > span": {
                padding: 0,
                color: theme.palette.text.secondary,
                fontSize: "12px",
                textTransform: "Capitalize",
            },
        },

        actions: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0.5rem 1rem",
            "& span": {
                color: theme.palette.text.secondary,
            },
        },

        description: {
            padding: "0.5rem 2rem 0.5rem 1rem",
            color: theme.palette.text.secondary,

            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
        },
    }),
    { name: "ContainerAppBar" },
);
