/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import MuiTextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import Skeleton from "@material-ui/lab/Skeleton";
import { uniqueString } from "lib/utils";
import { makeStyles, clsx } from "lib/material";
import { Translation } from "lib/store";

import { CompletedTextFieldProps } from "./completed-text-field.types";

export const CompletedTextField = React.forwardRef<HTMLInputElement, CompletedTextFieldProps>((props: CompletedTextFieldProps, ref) => {
    const {
        value,
        autoComplete,
        autoCorrect,
        spellCheck,
        error,
        helperText: explicitHelperText,
        skeleton,
        className,
        color = value.textProps.color,
        blurOnEnter,
        disabled: passedDisabled,
        autocompleteClassName,
        freeSolo,
        ...otherProps
    } = props;

    if (!value) {
        throw new Error(`If no "value" is passed, you should use the text field exported directly from @material-ui/core/TextField.`);
    }

    const id = React.useMemo(() => `tf-${value.valueProps.name || uniqueString()}`, []);
    const c = useStyles();
    const t = Translation.useMap(["noneFound"]);
    const usedProps = value.use();

    const disabled = passedDisabled || usedProps.disabled;

    const onKeyDown =
        blurOnEnter &&
        React.useMemo<React.KeyboardEventHandler<HTMLInputElement>>(
            () => (evt) => {
                if (evt.keyCode === 13) {
                    (evt.target as HTMLInputElement).blur();
                }
            },
            [],
        );

    if (skeleton) {
        return <Skeleton key={"_skeleton"} height={36} />;
    }

    const mustBeDefinedError =
        !explicitHelperText && usedProps.error && String(usedProps.error) === String(Translation.get("mustBeDefined"));

    return (
        <Autocomplete
            freeSolo={freeSolo || value.completedTextProps.freeSolo || false}
            key={"_completed-text-field"}
            options={usedProps.options.map((option) => option.label || option.value)}
            disabled={(usedProps ? usedProps.loading || usedProps.readOnly : undefined) || disabled}
            onInputChange={value.onChange as any}
            onBlur={value.onBlur as any}
            onFocus={value.onFocus as any}
            onKeyDown={onKeyDown as any}
            className={autocompleteClassName}
            noOptionsText={t.render("noneFound")}
            inputValue={usedProps.current.toString()}
            openOnFocus
            renderInput={(params) => (
                <MuiTextField
                    key={"_text-field"}
                    type={usedProps.type}
                    error={!!usedProps.error || usedProps.invalid}
                    label={mustBeDefinedError && usedProps.label ? usedProps.label + "*" : usedProps.label}
                    disabled={(usedProps ? usedProps.loading || usedProps.readOnly : undefined) || disabled}
                    placeholder={
                        mustBeDefinedError && !usedProps.label
                            ? (usedProps.placeholder || Translation.render("required")) + "*"
                            : usedProps.placeholder
                    }
                    ref={ref}
                    helperText={(!mustBeDefinedError && usedProps.error) || usedProps.success || explicitHelperText}
                    id={id}
                    name={value.valueProps.name}
                    {...params}
                    {...otherProps}
                    value={usedProps.current || ""}
                    className={clsx(color === "green" && c.green, className)}
                    inputProps={{
                        autoComplete: autoComplete || "off",
                        ...params.inputProps,
                        autoCorrect: autoCorrect || "off",
                        spellCheck: spellCheck || false,
                        ...otherProps.inputProps,
                    }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: usedProps.startAdornment ? (
                            <InputAdornment position="start" children={usedProps.startAdornment} />
                        ) : null,
                        endAdornment: usedProps.endAdornment ? <InputAdornment position="end" children={usedProps.endAdornment} /> : null,
                        ...otherProps.InputProps,
                    }}
                />
            )}
        />
    );
});

const useStyles = makeStyles(() => ({
    green: {},
}));
