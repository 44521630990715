import React from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { makeStyles, Translation, Typography, Divider, Box, TranslationThunk, FormService } from "lib";

export function AuthHead(props: { title: TranslationThunk; description?: TranslationThunk }) {
    const classes = useStyles();

    const title = Translation.use(props.title);
    const description = Translation.use(props.description);

    return (
        <>
            <Box className={classes.head}>
                <Typography variant="h5" children={String(title)} />
                <img src="/images/logo.png" alt="Logo" />
            </Box>

            <Divider className={classes.headDivider} />

            {description && <Typography variant="subtitle1" children={String(description)} />}
        </>
    );
}

export function AuthSubmit(props: ButtonProps & { service?: FormService<any> }) {
    const { service, ...otherProps } = props;

    const classes = useStyles();

    const error = service && service.error.message.use().current;
    const success = service && service.success.message.use().current;

    return (
        <>
            <Button color={"primary"} variant={"contained"} className={classes.submit} fullWidth type={"submit"} {...otherProps} />

            {(error || success) && <FormHelperText error={!!error} className={classes.status} children={error || success} />}
        </>
    );
}

export function AuthLinks(props: React.PropsWithChildren<{}>) {
    const classes = useStyles();

    return <Box className={classes.authLinks}>{props.children}</Box>;
}

const useStyles = makeStyles(
    (theme) => ({
        head: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",

            "& img": {
                height: "1.5rem",
                marginBottom: "0.3rem",
                pointerEvents: "none",
                userSelect: "none",
            },
        },

        headDivider: {
            margin: "0.35rem -0.5rem 1rem -0.5rem",
        },

        input: {
            marginBottom: "1rem",
        },

        submit: {
            marginTop: "0.5rem",
            fontWeight: 600,
            fontSize: "1rem",
        },

        authLinks: {
            margin: "1rem -0.5rem 0",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",

            "& > *": {
                padding: "0.1rem 0.5rem",
                textTransform: "none",
            },
        },

        status: {
            paddingTop: "0.5rem",
        },
    }),
    { name: "Auth" },
);
