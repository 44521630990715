/* eslint-disable @typescript-eslint/camelcase */
import { Api, ENDPOINT, Auth } from "lib";

import { LogInProps } from "./log-in.props";

export async function logIn(props: LogInProps) {
    const response = await Api.request(ENDPOINT.login, {
        username: props.email,
        password: props.password,
    });

    if (!response.jwt) {
        throw new Error("Invalid response format");
    }

    Auth.setJwt(response.jwt);
}
