import {
    ServiceAsync,
    TemplateType,
    CompletedTextValue,
    LoadingStatus,
    Abon,
    ErrorAlert,
    Api,
    ENDPOINT,
    AbonItems,
    Receiver,
    SnackStack,
    Translation,
} from "lib";
import { ProjectService } from "home/service";

export class TemplateReceiversService extends ServiceAsync {
    project: CompletedTextValue<string>;
    projects: ProjectService;
    loading: LoadingStatus;
    canLoadProject: Abon<boolean>;
    snacks: SnackStack;

    selectable: SelectableItems<Receiver, "id">;
    selected: SelectableItems<Receiver, "id">;

    constructor(readonly props: TemplateReceiversServiceProps = {}) {
        super();

        this.projects = this.require(ProjectService);
        this.snacks = this.require(SnackStack);

        this.loading = new LoadingStatus();
        this.project = new CompletedTextValue({
            placeholder: "selectProject",
            name: "project",
            useOptions: () => {
                this.projects.items.use();

                return this.projects.items.map((project) => {
                    return {
                        value: project.id,
                        label: project.project_name,
                    };
                });
            },
        });
        this.canLoadProject = Abon.from(
            () => !!this.project.selected.current,
            (listener) => [this.project.selected.subscribe(listener)],
            () => {},
        );
        this.selectable = new SelectableItems("id");
        this.selected = new SelectableItems("id");
    }

    async loadProject() {
        if (!this.project.selected.current) {
            return;
        }

        const stopLoading = this.loading.start();

        try {
            const receivers = await Api.request(ENDPOINT.getReceiversForProject, {
                project_id: this.project.selected.current,
                message_type: this.type,
            });

            if (Array.isArray(receivers) && receivers.length) {
                const added = receivers.filter(
                    (receiver) =>
                        !this.selectable.get(receiver[this.selectable.idKey]) && !this.selected.get(receiver[this.selected.idKey]),
                );

                this.selectable.unshift(...added);
            } else {
                this.snacks.add({
                    duration: 1500,
                    message: Translation.render({
                        id: "noReceiversFoundInProjectX",
                        values: { x: this.project.selectedOption.current.label },
                    }),
                });
            }
        } catch (error) {
            ErrorAlert.alert({ error });
        }

        stopLoading();
    }

    get type() {
        return this.props.type || TemplateType.email;
    }
}

export class SelectableItems<T extends object, I extends keyof T> extends AbonItems<T, I> {
    selectedIds: Abon<T[I][]>;

    constructor(idKey: I, initial?: T[]) {
        super(idKey, initial);

        this.selectedIds = new Abon([]);

        this.ids.subscribe((ids) => {
            if (!this.selectedIds.current.length) {
                return;
            }

            const filteredSelected = this.selectedIds.current.filter((id) => ids.includes(id));

            if (filteredSelected.length < this.selectedIds.current.length) {
                this.selectedIds.set(filteredSelected);
            }
        });
    }
}

export interface TemplateReceiversServiceProps {
    type?: TemplateType;
}
